import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';

import classes from './styles';

const AnimatedText = ({ text, ...props }) => {
  const isScreenWidth1600OrBigger = useMediaQuery('(min-width: 1600px)');
  const splitText =
    typeof text === 'string'
      ? text.split(' ').map((word, index) => <span key={index}>{word} </span>)
      : text;

  return (
    <Typography
      sx={classes.root}
      align="center"
      fontSize={isScreenWidth1600OrBigger ? '5.8vh' : '4.5vh'}
      {...props}
    >
      {splitText}
    </Typography>
  );
};

export default AnimatedText;
