import React from 'react';
import { Box, DialogContent, Typography } from '@mui/material';

import Dialog from 'components/dialogs/Dialog';
import classes from './styles';

const InvitingDialog = ({ open, onClose, ...restProps }) => (
  <Dialog
    open={open}
    name="inviting"
    title="Invitation"
    maxWidth="sm"
    onClose={onClose}
    sx={classes.root}
    showCloseIcon
    {...restProps}
  >
    <DialogContent>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt={3}
        px={3}
      >
        <Typography
          variant="h4"
          component="p"
          align="center"
          color="secondary"
          mb={0}
        >
          You can earn your PIPs via opening this referrer link <br />
          on your mobile phone.
        </Typography>
        <Box component="video" autoPlay muted loop width={200}>
          <source src="/assets/animations/bigPip.mp4" type="video/mp4" />
        </Box>
      </Box>
    </DialogContent>
  </Dialog>
);

export default InvitingDialog;
