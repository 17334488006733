import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import {
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  CardActions,
  Box,
  IconButton,
  TextField,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import Textarea from 'components/forms/Textarea';
import {
  FORM_FIELD_DEFAULT_VALUES,
  FORM_FIELD_TYPE_OPTIONS,
} from 'config/constants';

import classes from './styles';

const typesWithOptions = ['checkbox-group', 'select', 'radio-group'];

const FormField = ({ formData, onEdit, parentIndex, onDelete }) => {
  const [fieldType, setFieldType] = useState(formData?.type);
  const { control, register, watch } = useForm({
    defaultValues: formData,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
  });

  const onAddField = (type) => {
    const newField = { type };

    append(newField);
  };

  const onRemoveField = (index) => {
    remove(index);
  };

  const renderField = (index) => (
    <TextField
      {...register(`options.${index}.value`)}
      defaultValue={`Option ${index + 1}`}
      onFocus={(e) => e.target.select()}
      variant="standard"
      sx={{ marginBottom: '10px' }}
      fullWidth
      inputProps={{
        autoComplete: 'off',
      }}
    />
  );

  useEffect(() => {
    const subscription = watch((value) => {
      const inputType = value.type;
      let { options, ...result } = value;
      options = options.map((item) => ({
        ...item,
        label: item.value,
      }));

      if (typesWithOptions.includes(inputType)) {
        result.options = options.length ? options : FORM_FIELD_DEFAULT_VALUES;
      }

      onEdit(result, parentIndex);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form>
      <Card elevation={2} sx={classes.card}>
        <Box sx={classes.moveWrapper}>
          <DragIndicatorIcon sx={classes.dragIndicator} />
        </Box>

        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Textarea
              sx={{ marginBottom: '10px' }}
              {...register('label')}
              onFocus={(e) => e.target.select()}
            />
            <TextField
              sx={{ width: '45%', marginBottom: '10px' }}
              {...register('type', {
                onChange: (e) => setFieldType(e.target.value),
              })}
              select
              value={formData.type}
            >
              {FORM_FIELD_TYPE_OPTIONS.map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            {typesWithOptions.includes(fieldType) && (
              <>
                {fields.map((field, index) => (
                  <Box key={index} sx={classes.optionWrapper}>
                    {renderField(index)}
                    {fields.length > 1 && (
                      <IconButton
                        sx={classes.iconClear}
                        title="Delete"
                        onClick={() => onRemoveField(index)}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </Box>
                ))}

                <Box
                  sx={classes.addOptionButton}
                  size="small"
                  variant="outlined"
                  onClick={() => onAddField('text')}
                >
                  Add Option
                </Box>
              </>
            )}
          </Box>
        </CardContent>

        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FormControlLabel
            sx={{ color: 'grey' }}
            control={<Switch color="primary" {...register('isRequired')} />}
            label="Required"
            checked={formData.isRequired}
          />
          <IconButton
            sx={classes.iconButton}
            title="Delete"
            onClick={() => onDelete(parentIndex)}
          >
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    </form>
  );
};

export default FormField;
