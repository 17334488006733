import yup from 'services/yup';

import { messages } from 'config/messages';
import { phonePattern } from 'config/patterns';

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();
const day = today.getDate();
const todayDate13YearsAgo = new Date(year - 13, month, day, '23', '59');

export const FormValidationSchema = yup.object().shape(
  {
    firstName: yup
      .string()
      .typeError(messages.validation.STRING_TYPE)
      .notRequired()
      .max(64, messages.validation.MAX_LENGTH),
    lastName: yup
      .string()
      .typeError(messages.validation.STRING_TYPE)
      .notRequired()
      .max(64, messages.validation.MAX_LENGTH),
    dayOfBirth: yup
      .date(messages.validation.DATE)
      .notRequired()
      .max(todayDate13YearsAgo, messages.validation.MIN_DATE)
      .typeError(messages.validation.DATE),
    phone: yup
      .string()
      .notRequired()
      .when('phone', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(phonePattern, messages.validation.PHONE),
      })
      .max(25, messages.validation.PHONE_MAX_LENGTH),
  },
  [['phone', 'phone']]
);
