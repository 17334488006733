const classes = {
  root: {
    paddingTop: 2,
  },
  badge: {
    marginBottom: 1,
    textAlign: 'center',
  },
};

export default classes;
