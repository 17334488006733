import React from 'react';
import { Box, Typography } from '@mui/material';

import members from './members';
import UserCard from '../UserCard';
import classes from './styles';

const Directors = () => (
  <Box component="section" sx={classes.root}>
    <Typography variant="h4" align="center" sx={classes.title}>
      PIPs Education Fund Board of Directors
    </Typography>
    <Box component="ul" sx={classes.list}>
      {members.map((member, index) => (
        <Box component="li" sx={classes.listItem} key={index}>
          <UserCard user={member} />
        </Box>
      ))}
    </Box>
  </Box>
);

export default Directors;
