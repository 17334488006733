import yup from 'services/yup';

import { messages } from 'config/messages';
import { passwordPattern } from 'config/patterns';

export const FormValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required(messages.validation.REQUIRED)
    .min(8, messages.validation.PASSWORD_MIN)
    .matches(passwordPattern, messages.validation.PASSWORD),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], messages.validation.CONFIRM_PASSWORD),
});
