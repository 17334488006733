import React from 'react';
import { Box } from '@mui/material';
import palette from 'styles/theme/palette';

import Tabs from 'components/Tabs';
import Layout from 'components/Layout';
import TypographyUI from './TypographyUI';
import Buttons from './Buttons';
import Controls from './Controls';
import classes from './styles';

const UI = () => (
  <Layout bgColor={palette.white}>
    <Box maxWidth="lg" sx={classes.root}>
      <Tabs
        ariaLabel="MUI components"
        sx={classes.tabs}
        tabs={[
          {
            label: 'Typography',
            panel: <TypographyUI />,
          },
          {
            label: 'Buttons',
            panel: <Buttons />,
          },
          {
            label: 'Controls',
            panel: <Controls />,
          },
        ]}
      />
    </Box>
  </Layout>
);

export default UI;
