const classes = {
  root: {
    paddingTop: 4,
    paddingBottom: 3,
    color: 'white',
    bgcolor: 'primary.dark',
    position: 'relative',
    zIndex: 2,
  },
  rootContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    },
  },
  footerLeft: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
    '@media (min-width: 1024px)': {
      justifyContent: 'flex-start',
      width: 'auto',
    },
  },
  column: {
    '@media (min-width: 768px)': {
      marginRight: 2,
      marginBottom: 0,
    },
    '@media (min-width: 1280px)': {
      marginRight: 6,
    },
  },
  cbc: {
    maxWidth: '55px',
  },
  download: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    flexDirection: 'row',
    '@media (min-width: 1024px)': {
      justifyContent: 'flex-start',
      width: 'auto',
      flexDirection: 'column',
    },
    '& a': {
      display: 'block',
      marginBottom: 3,
      minWidth: '135px',
      '@media (min-width: 1024px)': {
        marginBottom: 1,
      },
      '& img': {
        display: 'block',
        maxWidth: '100%',
        height: 'auto',
      },
    },
  },
  footerRight: {
    display: 'flex',
    flexDirection: 'column',
  },
  logosList: {
    flex: 1,
    marginBottom: 2,
    '& li': {
      marginLeft: 'auto',
      '& a': {
        display: 'block',
      },
    },
  },
  copyright: {
    fontWeight: 'bold',
    textAlign: 'center',
    '@media (min-width: 1024px)': {
      textAlign: 'right',
    },
  },
};

export default classes;
