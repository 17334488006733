import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import {
  clearState,
  promoSelector,
  getPromoItem,
} from 'store/promos/PromoSlice';
import classes from './styles';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import DOMPurify from 'dompurify';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';
import { NotFound } from 'pages';
import BackButton from '../../components/BackButton';

const PromoDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, error, promo } =
    useSelector(promoSelector);

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(getPromoItem({ id: params.id }));
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  if (isFetching) return <Preloader />;
  if (!Object.keys(promo).length) return <NotFound />;

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Box sx={classes.detailsHeader}>
          <BackButton />
          <Typography variant="caption" sx={classes.categoryName}>
            {promo.categoryName}
          </Typography>
          <Typography variant="h2" color="primary" mb={1}>
            {promo.promoName}
          </Typography>
          <Typography variant="caption">
            {promo.promoShortDescription}
          </Typography>
        </Box>
        <Box sx={classes.content}>
          <Box sx={classes.image}>
            <CardMedia component="img" src={promo.imageUrl} />
          </Box>
          <Box sx={classes.description}>
            <Typography
              mb={6}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(promo.promoLongDescription),
              }}
            />
            {promo.buttonUrl && promo.buttonName && (
              <Button
                variant="contained"
                color="secondary"
                component="a"
                href={promo.buttonUrl}
                target="_blank"
              >
                {promo.buttonName}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default PromoDetails;
