const classes = {
  root: {
    paddingTop: '40px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  third: {
    flex: '0 0 100%',
    '@media (min-width: 1024px)': {
      flex: '0 0 32%',
    },
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
    '& button + button': {
      marginTop: '24px',
      '@media (min-width: 768px)': {
        marginLeft: '24px',
        marginTop: 0,
      },
    },
  },
};

export default classes;
