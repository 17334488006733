import palette from 'styles/theme/palette';

export default {
  styleOverrides: {
    scroller: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    flexContainer: {
      position: 'relative',
      overflow: 'auto',
      '@media (min-width: 768px)': {
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: 5,
          borderRadius: 10,
          background: palette.text.disabled,
        },
      },
    },
    indicator: {
      display: 'none',
      '@media (min-width: 768px)': {
        height: 5,
        borderRadius: 10,
        display: 'block',
      },
    },
  },
};
