import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import {
  clearState,
  getUser,
  switchUser,
  userSelector,
} from 'store/user/UserSlice';
import { handleError } from 'services/handleError';
import Preloader from 'components/Preloader';
import useQuery from 'hooks/useQuery';
import { showErrorNotification } from '../../services/notifications';

const SwitchToUser = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching } = useSelector(userSelector);

  const token = query.get('token');
  const redirect = query.get('redirect');

  useEffect(() => {
    dispatch(switchUser(token))
      .then((res) => {
        if (res.payload.status === 'OK') {
          const isVerified = res.payload.verified;
          dispatch(getUser()).then(() => {
            if (redirect) {
              navigate(redirect);
              return;
            }
            if (isVerified) {
              navigate('/dashboard/impact-carbon-footprint');
            } else {
              navigate('/verify-code');
            }
          });
        } else {
          showErrorNotification(res.payload);
          dispatch(clearState());
        }
      })
      .catch((err) => handleError(err));
  }, []);

  if (isFetching) return <Preloader />;

  return (
    <Layout bgColor={palette.white}>
      <Preloader />
    </Layout>
  );
};

export default SwitchToUser;
