import React, { useCallback } from 'react';
import { Box, Link, Typography } from '@mui/material';

import Layout from 'components/Layout';
import palette from 'styles/theme/palette';
import ChapterAccordion from './ChapterAccordion';

import { chaptersList } from './settings';
import classes from './styles';

const RatingSystem = () => {
  const scrollToChapter = useCallback((chapterID) => {
    const chapter = document.getElementById(chapterID);
    window.scrollTo({
      top: chapter.offsetTop - 50,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Typography variant="h1" textAlign="center" mb={4}>
          Rating Systems
        </Typography>
        <Box component="nav" sx={classes.nav}>
          {Object.keys(chaptersList).map((chapter) => (
            <Link
              key={chapter}
              onClick={() => scrollToChapter(chapter.replace(/[-/ ]/g, '_'))}
            >
              {chapter}
            </Link>
          ))}
        </Box>
        {Object.keys(chaptersList).map((chapter) => (
          <ChapterAccordion
            key={chapter}
            id={chapter.replace(/[-/ ]/g, '_')}
            title={chapter}
            data={chaptersList[chapter]}
          />
        ))}
      </Box>
    </Layout>
  );
};

export default RatingSystem;
