import React, { useEffect, useState } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import { handleError } from 'services/handleError';
import Preloader from 'components/Preloader';
import useQuery from 'hooks/useQuery';
import useToken from 'hooks/useToken';
import { earnPointsFromLink } from 'services/api';
import { showSuccessNotification } from 'services/notifications';
import { messages } from 'config/messages';

const EarnPoints = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useToken();
  const location = useLocation();
  const navigate = useNavigate();

  const query = useQuery();

  const q = query.get('q');
  const id = query.get('id');

  useEffect(() => {
    if (token) {
      earnPointsFromLink(q, id)
        .then((res) => {
          if (res.status === 'OK') {
            setIsLoading(false);
            showSuccessNotification(
              messages.EARN_POINT_LINK_ACTIVATION_SUCCESS
            );
            navigate('/dashboard/impact-carbon-footprint');
          } else {
            handleError(res);
            setIsLoading(false);
            navigate('/dashboard/impact-carbon-footprint');
          }
        })
        .catch((err) => {
          handleError(err);
          setIsLoading(false);
          navigate('/dashboard/impact-carbon-footprint');
        });
    }
  }, []);

  if (!token)
    return (
      <Navigate
        to="/?showLogin"
        state={{ from: location.pathname, fromSearch: location.search }}
      />
    );

  if (isLoading) return <Preloader />;

  return (
    <Layout bgColor={palette.white}>
      <Preloader />
    </Layout>
  );
};

export default EarnPoints;
