import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Tooltip, useMediaQuery } from '@mui/material';
import palette from 'styles/theme/palette';
import { copyToClipboard } from 'utils/copyToClipboard';
import theme from 'styles/theme';

const APCodeType = ({ card }) => {
  const [coupons, setCoupons] = useState([]);

  const { coupon } = card;

  const toolTipPlacement = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const couponArray = coupon.codes.map((el) => {
      return {
        code: el,
        tooltipText: 'Copy to clipboard',
      };
    });
    setCoupons(couponArray);
  }, [coupon.codes]);

  const handleCopyCoupon = (coupon) => {
    const callback = () => {
      const couponList = coupons.map((el, index) => {
        if (el.code === coupon.code) {
          return (coupons[index] = {
            code: el.code,
            tooltipText: 'Copied to clipboard',
          });
        } else {
          return (coupons[index] = {
            code: el.code,
            tooltipText: 'Copy to clipboard',
          });
        }
      });
      setCoupons(couponList);
    };
    copyToClipboard(coupon.code, callback);
  };
  return (
    <Box>
      <Typography mb={2}>
        {coupon.numCodes}{' '}
        <span style={{ color: palette.text.primary }}>{card.description}</span>
      </Typography>
      <Typography mb={2}>{card.instructions}</Typography>
      {coupons.map((el, i) => (
        <Box key={i}>
          <Tooltip
            placement={toolTipPlacement ? 'right' : 'top'}
            title={el.tooltipText}
            enterTouchDelay={0}
          >
            <Button
              variant="outlined"
              style={{ marginBottom: '16px' }}
              onClick={() => handleCopyCoupon(el)}
            >
              {el.code}
            </Button>
          </Tooltip>
        </Box>
      ))}
      {card.buttonUrl && card.buttonName && (
        <Button
          variant="contained"
          color="secondary"
          component="a"
          href={card.buttonUrl}
          target="_blank"
        >
          {card.buttonName}
        </Button>
      )}
    </Box>
  );
};

export default APCodeType;
