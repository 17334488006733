import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useMatch } from 'react-router-dom';
import { Container, Box, Link, useMediaQuery } from '@mui/material';
import { userSelector } from 'store/user/UserSlice';
import { LogoIcon } from 'assets/icons';
import classes from './styles';
import theme from 'styles/theme';
import HeaderMobile from '../HeaderMobile';

const Header = () => {
  const isQuestionnairesActive = useMatch('/admin/questionnaires/*');
  const { user, isAuthenticated } = useSelector(userSelector);
  const showHeaderMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box component="header" sx={classes.root}>
      {showHeaderMobile ? (
        <HeaderMobile />
      ) : (
        <>
          <Box sx={classes.header}>
            <Container maxWidth="lg">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height={1}
              >
                <Box component="nav" sx={classes.nav}>
                  <NavLink to="/">Home</NavLink>
                  {isAuthenticated && (
                    <strong className="username">
                      Hello, {user.firstName} {user.lastName}
                    </strong>
                  )}
                </Box>
              </Box>
            </Container>
          </Box>
          <Box sx={classes.subHeader}>
            <Container maxWidth="lg">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height={1}
              >
                <Link to="/" className="logo" sx={{ mr: 6 }}>
                  <LogoIcon />
                </Link>
                <Box component="nav" sx={classes.subLinks}>
                  {isAuthenticated && (
                    <>
                      <NavLink
                        to="/admin/questionnaires"
                        {...(isQuestionnairesActive && { className: 'active' })}
                      >
                        Manage Questionnaires
                      </NavLink>
                    </>
                  )}
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Header;
