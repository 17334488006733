const classes = {
  root: {
    position: 'relative',
    '& img': {
      filter: 'grayscale(0%)',
    },
    '& .tooltip-badge': {
      opacity: 0,
      visibility: 'hidden',
      transition: '0.3s',
    },
    '&:hover .tooltip-badge': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  disabled: {
    '& img': {
      filter: 'grayscale(100%)',
    },
  },
};

export default classes;
