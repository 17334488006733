import palette from 'styles/theme/palette';

const classes = {
  label: {
    display: 'block',
    fontSize: '14px',
    fontWeight: '500',
    paddingBottom: '10px',
    cursor: 'pointer',
  },
  textArea: {
    '.MuiOutlinedInput-root': {
      height: 'auto',
    },
  },
  errorMessage: {
    color: palette.error.main,
  },
};

export default classes;
