import * as React from 'react';
import sampleSize from 'lodash/sampleSize';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Typography, Box, Avatar } from '@mui/material';
import sx from 'mui-sx';

import Carousel from 'components/Carousel';
import { testimonials } from './settings';
import classes from './styles';

const Testimonials = () => (
  <Box id="testimonials" component="section" sx={classes.root}>
    <Carousel>
      {sampleSize(testimonials, 6).map((testimonial, index) => (
        <Box key={index} sx={classes.testimonial}>
          <Avatar
            sx={sx(classes.avatar, {
              condition: !testimonial.avatar,
              sx: classes.avatarBroken,
            })}
            src={testimonial?.avatar}
          />
          <Typography sx={classes.testimonialText}>
            {testimonial.text}
          </Typography>
          <Typography fontWeight="bold" align="center" fontStyle="italic">
            {testimonial.author}
          </Typography>
          <Typography fontWeight="bold" align="center" fontStyle="italic">
            {testimonial.position}
          </Typography>
        </Box>
      ))}
    </Carousel>
  </Box>
);

export default Testimonials;
