import React from 'react';
import Typography from '@mui/material/Typography';
import Layout from 'components/Layout';
import palette from 'styles/theme/palette';
import { Box } from '@mui/material';
import classes from './styles';

const RegistrationSuccess = () => {
  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Typography variant="h2" align="center">
          Thank you!
        </Typography>
        <Typography component="p" align="center">
          Now you can start earning and using PIPs.
        </Typography>
      </Box>
    </Layout>
  );
};

export default RegistrationSuccess;
