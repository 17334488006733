import { mergeThinkHumanTVAccount } from 'services/api';
import { showSuccessNotification } from 'services/notifications';
import { messages } from 'config/messages';
import { handleError } from 'services/handleError';

export const linkThinkTVAccount = async (token) => {
  const res = await mergeThinkHumanTVAccount(token);
  if (res.status === 'OK') {
    showSuccessNotification(messages.THINKHUMANTV_ACCOUNT_LINK_SUCCESS);
    localStorage.removeItem('th_token');
  } else {
    handleError(res);
  }
};
