import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogActions, DialogContent, Link, Typography } from '@mui/material';

import Dialog from 'components/dialogs/Dialog';
import LoginForm from './LoginForm';

import classes from './styles';

const LoginDialog = ({ open, onClose, state, ...restProps }) => {
  const navigate = useNavigate();

  const redirectToRegistration = () => {
    onClose();
    navigate('/registration');
  };

  return (
    <Dialog
      open={open}
      name="login"
      title="Login"
      maxWidth="sm"
      onClose={onClose}
      sx={classes.root}
      showCloseIcon
      {...restProps}
    >
      <DialogContent>
        <LoginForm onClose={onClose} state={state} />
      </DialogContent>
      <DialogActions>
        <Typography variant="subtitle2" align="center">
          Don’t have account?
        </Typography>
        <Typography align="center">
          <Link
            variant="subtitle2"
            underline="none"
            component="button"
            onClick={redirectToRegistration}
          >
            Sign Up
          </Link>
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
