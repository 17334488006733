import React, { useState, useRef, useCallback, useEffect } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { Card, CardContent, Button, Box } from '@mui/material';

import StrictModeDroppable from 'components/StrictModeDroppable';
import Textarea from 'components/forms/Textarea';
import { FORM_FIELD_DEFAULT_VALUES } from 'config/constants';
import { FormField } from './components';
import { Checkbox } from 'components/forms';
import classes from './styles';

const defaultTitle = 'Untitled Form';

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const FormBuilder = ({
  name,
  text,
  autoEarning,
  questionnaire,
  onSubmit,
}) => {
  const [formData, setFormData] = useState([
    { ...FORM_FIELD_DEFAULT_VALUES, id: uuidv4() },
  ]);
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState('');
  const [isAutoEarn, setIsAutoEarn] = useState(true);
  const formDataRef = useRef(formData);

  const setData = (newData) => {
    formDataRef.current = [...newData];
    setFormData([...newData]);
  };

  const onEdit = useCallback(
    (inputData, parentIndex) => {
      const newData = [...formDataRef.current];
      newData[parentIndex] = inputData;
      setData(newData);
    },
    [formData]
  );

  const handleOnDelete = (elementIndex) => {
    const newData = [...formDataRef.current];
    newData.splice(elementIndex, 1);
    setData(newData);
  };

  const handleDrag = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      formData,
      result.source.index,
      result.destination.index
    );

    formDataRef.current = [...items];
    setFormData([...items]);
  };

  const handleSubmit = () => {
    const formDataJSON = JSON.stringify(formDataRef.current, null, 2);
    onSubmit({
      name: title,
      description: description || '',
      autoEarning: isAutoEarn,
      payload: formDataJSON,
    });
  };

  useEffect(() => {
    if (!questionnaire || !name) return;
    formDataRef.current = questionnaire;
    setFormData(questionnaire);
    setDescription(text || '');
    setTitle(name || '');
    setIsAutoEarn(autoEarning);
  }, [questionnaire, name, text, autoEarning]);

  return (
    <>
      <Card elevation={2} sx={classes.card}>
        <CardContent>
          <Textarea
            name="title"
            value={title}
            sx={classes.title}
            onChange={(e) => {
              const value = e.target.value;
              setTitle(value);
            }}
            onBlur={(e) => {
              const value = e.target.value;
              if (!value.length) setTitle(defaultTitle);
            }}
          />

          <Textarea
            name="description"
            value={description}
            sx={{ marginBottom: 0 }}
            inputProps={{
              autoComplete: 'off',
              placeholder: 'description',
            }}
            onChange={(e) => setDescription(e.target.value)}
            onFocus={(e) => e.target.select()}
          />

          <Box sx={classes.checkboxWrap}>
            <Checkbox
              id="autoEarning"
              name="autoEarning"
              checked={isAutoEarn}
              onChange={(e) => {
                setIsAutoEarn(e.target.checked);
              }}
            />
            <label style={classes.label} htmlFor="autoEarning">
              Reward the user upon form submission
            </label>
          </Box>
        </CardContent>
      </Card>
      <DragDropContext onDragEnd={handleDrag} styles={{ marginBottom: '90px' }}>
        <StrictModeDroppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {formData?.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <div
                      key={`item-${index}-${item.id}`}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <FormField
                        formData={item}
                        parentIndex={index}
                        onEdit={(inputData, parentIndex) =>
                          onEdit(inputData, parentIndex)
                        }
                        onDelete={(data) => handleOnDelete(data)}
                      />
                      {item.content}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
      <Box sx={classes.actionWrapper}>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          type="submit"
          onClick={() => {
            const newItem = { ...FORM_FIELD_DEFAULT_VALUES, id: uuidv4() };
            formDataRef.current = [...formDataRef.current, newItem];
            setFormData([...formData, newItem]);
          }}
        >
          Add New Question
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={() => handleSubmit()}
        >
          Save Questionnaire
        </Button>
      </Box>
    </>
  );
};

export default FormBuilder;
