import palette from 'styles/theme/palette';
import { PADDING_MAIN } from 'config/ui';

const classes = {
  root: {
    position: 'relative',
    background: palette.background.blue,
    color: '#fff',
    paddingTop: '45px',
    paddingBottom: '45px',
    '@media (min-width: 1024px)': {
      paddingTop: '55px',
      paddingBottom: '75px',
    },
  },
  list: {
    '@media (min-width: 768px)': {
      margin: '0 -16px',
      '&:after': {
        content: '""',
        clear: 'both',
        display: 'block',
      },
    },
  },
  listItem: {
    marginBottom: '30px',
    '@media (min-width: 768px)': {
      width: '50%',
      paddingLeft: '16px',
      paddingRight: '16px',
      marginBottom: 0,
      '&:first-of-type': {
        float: 'left',
      },
      '&:not(:first-of-type)': {
        float: 'right',
      },
    },
    '& ul': {
      position: 'relative',
      paddingLeft: '55px',
      marginLeft: '45px',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: '-13px',
        top: 0,
        width: 0,
        height: '100%',
        border: '1px solid #FFF',
        '@media (min-width: 768px)': {
          left: 0,
        },
      },
      '& li': {
        fontSize: '18px',
        lineHeight: 1,
        '& + li': {
          marginTop: '20px',
        },
      },
    },
  },
  listItemInner: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
  },
  icon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '65px',
    height: '65px',
    background: '#fff',
    borderRadius: '50%',
    marginRight: '35px',
    boxShadow: `9px 4px 0 ${palette.background.main}`,
    flex: '0 0 65px',
    '& img': {
      width: 'auto',
      objectFit: 'contain',
      maxWidth: '100%',
    },
  },
  logo: {
    position: 'absolute',
    width: '170px',
    height: '170px',
    right: '50px',
    bottom: 0,
    transform: 'translateY(50%)',
    zIndex: 1,
    '@media (min-width: 1024px)': {
      right: `${PADDING_MAIN}px`,
    },
  },
};

export default classes;
