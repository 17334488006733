const classes = {
  root: {
    padding: '8px',
    color: '#fff',
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 500,
    borderRadius: 2,
    background: '#706d6d',
    zIndex: 999,
    position: 'absolute',
    bottom: 'calc(100% + 14px)',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '300px',
    minWidth: '170px',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-20px',
      left: '50%',
      transform: 'translateX(-50%)',
      borderStyle: 'solid',
      borderWidth: '10px',
      borderColor: '#706d6d transparent transparent transparent',
    },
  },
};

export default classes;
