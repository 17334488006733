import paletteScheme from './palette';

const palette = {
  fontSize: 18,
  fontFamily: 'inherit',
  color: paletteScheme.text.grey,
  lineHeight: 1.2,
  h1: {
    fontSize: 52,
    fontWeight: 700,
    marginBottom: 24,
  },
  h2: {
    fontSize: 38,
    fontWeight: 700,
    marginBottom: 24,
  },
  h3: {
    fontSize: 30,
    fontWeight: 700,
    marginBottom: 24,
  },
  h4: {
    fontSize: 26,
    fontWeight: 700,
    marginBottom: 24,
  },
  h5: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 16,
  },
  h6: {
    fontSize: 18,
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: 18,
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 500,
  },
  body1: {
    fontSize: 18,
  },
  button: {
    fontSize: 18,
    fontWeight: 700,
  },
  link: {
    fontSize: 18,
    fontWeight: 700,
  },
  linkSm: {
    fontSize: 16,
    fontWeight: 700,
  },
  caption: {
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  overline: {
    fontSize: 11,
    fontWeight: 500,
  },
};

export default palette;
