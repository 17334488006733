import React from 'react';
import { Box } from '@mui/material';

import { AnimatedText } from 'pages/Users/components';
import classes from './styles';

const JumpingPip = ({ id, shouldShowContent }) => (
  <Box
    id={id}
    sx={classes.root}
    display="flex"
    alignItems="center"
    flexDirection="column"
  >
    {shouldShowContent && (
      <>
        <Box component="video" autoPlay muted>
          <source src="/assets/animations/jumpingPip.mp4" type="video/mp4" />
        </Box>
        <AnimatedText text="PIPs are Positive Impact Points" />
      </>
    )}
  </Box>
);

export default JumpingPip;
