import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CardMedia, Typography } from '@mui/material';
import {
  clearState,
  promoSelector,
  getPromoItem,
} from 'store/promos/PromoSlice';
import classes from './styles';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import {
  GiftPromoType,
  InvestPromoType,
  CharitablePromoType,
  PipsForSchoolPromoType,
} from './components';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';
import { NotFound } from 'pages';
import BackButton from '../../components/BackButton';

const renderDonatePromoDetails = (promo) => {
  const { subType } = promo;
  switch (subType) {
    case 'CHARITABLE':
      return <CharitablePromoType />;
    case 'INVEST':
      return <InvestPromoType />;
    case 'GIFT':
      return <GiftPromoType />;
    case 'PIPS_FOR_SCHOOL':
      return <PipsForSchoolPromoType />;

    default:
      return <PipsForSchoolPromoType />;
  }
};

const DonateDetails = () => {
  const { isFetching, isSuccess, isError, error, promo } =
    useSelector(promoSelector);
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(getPromoItem({ id: params.id }));
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  if (isFetching) return <Preloader />;
  if (!Object.keys(promo).length) return <NotFound />;

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Box sx={classes.detailsHeader}>
          <BackButton />
          <Typography
            variant="h2"
            color="primary"
            mb={1}
            sx={classes.promoTitle}
          >
            {promo.promoName}
          </Typography>
          <Typography variant="caption">
            {promo.promoShortDescription}
          </Typography>
        </Box>
        <Box sx={classes.content}>
          <Box sx={classes.image}>
            <CardMedia component="img" src={promo.imageUrl} />
          </Box>
          <Box sx={classes.description}>{renderDonatePromoDetails(promo)}</Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default DonateDetails;
