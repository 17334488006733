const classes = {
  root: {
    paddingTop: '47px',
    paddingBottom: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media (min-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },

    '& .MuiTabs-root': {
      display: 'inline-flex',
      marginBottom: '48px',
    },
    '& .tabpanel': {
      flexGrow: 1,
      width: '100%',
      '& .MuiGrid-root': {
        width: '100%',
      },
    },
  },
};

export default classes;
