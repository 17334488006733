const classes = {
  root: {
    position: 'relative',
    paddingTop: '45px',
    paddingBottom: '45px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media (min-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};

export default classes;
