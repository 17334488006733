import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardMedia,
} from '@mui/material';

import { NextIcon } from 'assets/icons';
import classes from './styles';

const ChapterAccordion = ({ id, title, data }) => {
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) =>
    setExpanded(newExpanded ? panel : false);

  return (
    <Box id={id} sx={classes.root}>
      <Typography variant="h3" color="primary">
        {title}
      </Typography>
      {data.map(({ id, title, logo, whatItDoes, link }) => (
        <Accordion
          key={id}
          disableGutters
          elevation={0}
          expanded={expanded === id}
          onChange={handleChange(id)}
        >
          <AccordionSummary
            id={`${id}-header`}
            aria-controls={`${id}-content`}
            expandIcon={<NextIcon />}
          >
            <Typography variant="h4">{title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex">
              <CardMedia component="img" src={logo} sx={classes.logo} />
              <div>
                <p>
                  <b>What it does: </b>
                  {whatItDoes}
                </p>
                <a target="_blank" rel="noopener noreferrer" href={link}>
                  {link}
                </a>
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ChapterAccordion;
