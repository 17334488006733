import { colors } from '@mui/material';

const black = '#000000';
const white = '#ffffff';

const blueDark = '#1AABBC';
const blueMain = '#4AC2CE';

export const grey = '#3A3331';

const palette = {
  mode: 'light',
  black,
  white,
  primary: {
    contrastText: white,
    dark: blueDark,
    main: blueMain,
    light: '#56cad5',
  },
  secondary: {
    contrastText: white,
    dark: '#E35C30',
    main: '#F26C40',
    light: '#f5774e',
  },
  error: {
    contrastText: white,
    dark: '#a80000',
    main: '#ED3C16',
    light: '#EC674A',
  },
  warning: {
    contrastText: white,
    dark: '#FCAD2B',
    main: '#FDAE2C',
    light: '#E8D157',
  },
  info: {
    contrastText: white,
    main: '#1DD1B1',
    light: '#1ee1bf',
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  text: {
    primary: blueMain,
    secondary: '#F26C40',
    grey,
    disabled: '#EBEBEB',
    hint: grey,
    icon: grey,
    light: '#707070',
  },
  background: {
    default: white,
    blue: '#4876E6',
    paper: white,
    main: blueMain,
    white,
  },
};

export default palette;
