import palette from 'styles/theme/palette';

const classes = {
  tabs: {
    marginBottom: 5,
    '& .MuiTabs-flexContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '@media (min-width: 768px)': {
        flexDirection: 'row',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    flex: 1,
    padding: 0,
    opacity: 1,
    maxWidth: '100%',
    borderRadius: '24px',
    transition: '0.3s',
    color: palette.primary.main,
    backgroundColor: palette.white,
    border: `3px solid ${palette.primary.main}`,
    '&.Mui-selected': {
      backgroundColor: palette.primary.main,
      color: palette.white,
      '& .pie': {
        borderColor: palette.white,
      },
    },
    '&:not(:last-of-type)': {
      marginBottom: 3,
      '@media (min-width: 768px)': {
        marginRight: 3,
        marginBottom: 0,
      },
    },
  },
};

export default classes;
