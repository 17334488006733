import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPromoDetails, getPromos } from 'services/api';

export const getPromoList = createAsyncThunk(
  'promos/getPromoList',
  async ({ query }, thunkAPI) => {
    try {
      const response = await getPromos({ query });
      if (response.status === 'OK') {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPromoItem = createAsyncThunk(
  'promos/getPromoItem',
  async ({ id }, thunkAPI) => {
    try {
      const response = await getPromoDetails(id);
      if (response.status === 'OK') {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const PromoSlice = createSlice({
  name: 'promos',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: {},
    promos: [],
    promo: {},
    pipsAvailable: null,
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    [getPromoList.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.promos = payload.promos;
      state.pipsAvailable = payload.pipsAvailable;
    },
    [getPromoList.pending]: (state) => {
      state.isFetching = true;
    },
    [getPromoList.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [getPromoItem.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.promo = payload.promo;
      state.pipsAvailable = payload.pipsAvailable;
    },
    [getPromoItem.pending]: (state) => {
      state.isFetching = true;
    },
    [getPromoItem.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
  },
});
export const promoSelector = (state) => state.promos;

export const { clearState } = PromoSlice.actions;
export default PromoSlice.reducer;
