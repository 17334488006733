const classes = {
  img: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    marginBottom: 2,
    margin: '0 auto 20px',
  },
  name: {
    textDecoration: 'none',
    marginBottom: 0.5,
    '& h4': {
      marginBottom: 0,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
};

export default classes;
