import React from 'react';
import { Box, FormControlLabel, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ProfileInformationStep = ({ control, errors }) => {
  return (
    <>
      <Box>
        <Controller
          name="dayOfBirth"
          control={control}
          defaultValue={null}
          render={({ field: { name, value, onChange } }) => (
            <FormControlLabel
              labelPlacement="top"
              label="Date of Birth"
              control={
                <DatePicker
                  disableFuture
                  name={name}
                  value={value}
                  onChange={onChange}
                  inputProps={{
                    autoComplete: 'off',
                    placeholder: 'MM/DD/YYYY',
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        id="dayOfBirth"
                        fullWidth
                        error={Boolean(errors.dayOfBirth)}
                        helperText={
                          errors.dayOfBirth && errors.dayOfBirth.message
                        }
                      />
                    );
                  }}
                />
              }
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          name="phone"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <FormControlLabel
              labelPlacement="top"
              label="Mobile Phone"
              control={
                <TextField
                  name={name}
                  value={value}
                  fullWidth
                  inputProps={{
                    autoComplete: 'off',
                    placeholder: 'Add Mobile Phone',
                  }}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone && errors.phone.message}
                  onChange={onChange}
                />
              }
            />
          )}
        />
      </Box>
    </>
  );
};

export default ProfileInformationStep;
