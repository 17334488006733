import palette from 'styles/theme/palette';

export default {
  styleOverrides: {
    root: {
      color: palette.primary.main,
      padding: 0,
      '& svg': {
        width: 34,
        height: 34,
      },
      '& + .MuiFormControlLabel-label': {
        paddingBottom: 0,
      },
    },
  },
};
