const classes = {
  root: {
    paddingTop: '45px',
    background: 'white',
    textAlign: 'center',
    margin: '0 -16px',
    '@media (min-width: 768px)': {
      margin: 0,
    },
  },
  title: {
    marginBottom: '40px',
  },
};

export default classes;
