import React, { useState } from 'react';
import { Tabs as MuiTabs, Tab, Box } from '@mui/material';

const Tabs = ({ tabs, ariaLabel, sx, sxTab }) => {
  const [value, setValue] = useState(0);

  return (
    <>
      <MuiTabs
        textColor="inherit"
        value={value}
        aria-label={ariaLabel}
        sx={sx}
        onChange={(e, newValue) => setValue(newValue)}
      >
        {tabs.map(({ label }, index) => (
          <Tab
            key={index}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            label={label}
            sx={sxTab}
          />
        ))}
      </MuiTabs>
      {tabs.map(({ panel }, index) => (
        <Box
          key={index}
          index={index}
          role="tabpanel"
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          value={value}
          hidden={value !== index}
          className="tabpanel"
        >
          {value === index && <div className="panel-content">{panel}</div>}
        </Box>
      ))}
    </>
  );
};

export default Tabs;
