import React from 'react';
import Layout from 'components/Layout';
import palette from 'styles/theme/palette';
import { Box, Typography } from '@mui/material';
import classes from './styles';

const AuthenticatedUserPlaceholder = () => (
  <Layout bgColor={palette.white}>
    <Box sx={classes.root}>
      <Typography variant="h5" align="center">
        You are already logged in, please log out first if you want to register
        a new account
      </Typography>
    </Box>
  </Layout>
);

export default AuthenticatedUserPlaceholder;
