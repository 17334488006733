const classes = {
  root: {
    maxWidth: '33%',
    margin: '0 auto',
    '& img': {
      width: '80%',
      margin: '0 auto 32px',
    },
  },
};
export default classes;
