import React from 'react';
import { Box, Typography } from '@mui/material';

export const faqList = [
  {
    id: 'panel1',
    title: 'What are PIPs?',
    description: (
      <>
        <p>
          PIPs or “Positive Impact Points” are a currency of good that is earned
          and redeemed exclusively for engagement in verifiable daily life
          behaviors that deliver personal, community, and/or planetary benefit.
        </p>
        <p>
          The PIPs’ vision is of a world in which every time users ride the bus,
          bike, volunteer, recycle, etc., they are rewarded with PIPs that can
          be used to unlock scholarship grants or redeemed for discounts on
          healthy food and other essentials. In this world, PIPs are ubiquitous,
          giving value to a diversity of heretofore undervalued yet socially
          beneficial actions.
        </p>
      </>
    ),
  },
  {
    id: 'panel2',
    title: 'What is PIPs Rewards?',
    description: (
      <>
        <p>
          PIPs Rewards is an innovative mobile app operated by the woman-led
          PIPs Rewards LLC. The “behavior tech” platform generates a currency of
          good (“Positive Impact Points” or “PIPs”) each time users take a
          verifiable beneficial action such as biking, volunteering, or riding
          the bus. Backed by institutional subscribers, partners, sponsors, and
          donors, PIPs can be used to unlock highly valued rewards, which for
          students includes scholarship grants and food credits.
        </p>
        <p>
          The PIPs platform combines behavioral strategies, action verification,
          and data analytics to deliver on key outcomes (shifts in behavioral
          norms, improved education equity and access, progress toward UN SDGs,
          etc.).
        </p>
      </>
    ),
  },
  {
    id: 'panel3',
    title: 'What is PIPs for School?',
    description: (
      <>
        <p>
          PIPs for School is a program to which higher ed institutions subscribe
          that comes to life through the PIPs Rewards App. Students who are
          signed into the app (through a secure SSO) earn a digital currency
          (“Positive Impact Points” or “PIPs”) when they complete verified
          actions that provide personal, community, and/or planetary benefits.
          Our wide variety of Earn Actions include (but are not limited to)
          biking, riding the bus, refilling water bottles, attending events, and
          participating in a workout class. Once earned, PIPs can be converted
          into high-value rewards, including PIPs for School scholarships or
          gift cards for healthy food and other essentials.
        </p>
        <p>
          PIPs for School is operated by PIPs Rewards LLC, a wholly owned
          charitable subsidiary of PIPs Education Fund (PEF), a New York State
          501(c)(3) public charity. To learn more,{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://prod.pipsrewards.com/pips_for_school"
          >
            click here
          </a>
          .
        </p>
      </>
    ),
  },
  {
    id: 'panel4',
    title: 'How can I earn PIPs?',
    description: (
      <>
        <p>
          It is possible to earn for a whole host of positive impact actions,
          especially if one is a student at a PIPs-subscribed college or
          university or an employee at a PIPs-subscribed enterprise.
        </p>
        <p>Possible Earn Actions include:</p>
        <ul className="list--disc">
          <li>Walking, biking, and taking public transit</li>
          <li>Bike sharing, ride sharing, and carpooling</li>
          <li>Completing mindfulness, wellness, and fitness classes</li>
          <li>Saving, budgeting, and debt reduction</li>
          <li>Reducing, reusing, refilling, up-cycling, and conserving</li>
          <li>Shopping responsibly</li>
          <li>Participating in games, trivia, and challenges</li>
        </ul>
        <p>
          Even if you are not part of a subscribed community, you can count on
          PIPs to guide you to good, PIPs-worthy, everyday products and services
          offered by responsible brands.
        </p>
      </>
    ),
  },
  {
    id: 'panel5',
    title: 'How can one use PIPs?',
    description: (
      <>
        <p>
          Once earned, PIPs can be used in a number of ways. While only students
          at PIPs-subscribed higher ed institutions can convert their PIPs into
          scholarships, every user can redeem them for healthy food gift cards,
          music or book downloads, or discounts on everything from cool new
          gadgets to fitness club memberships to responsibly made products.
        </p>
        <p>
          You can also pool your PIPs and donate them to a cause of your choice.
          It’s a total win-win - good in, good out.
        </p>
      </>
    ),
  },
  {
    id: 'panel6',
    title: 'How does the PIPs Rewards platform work technically?',
    description: (
      <p>
        PIPs Rewards leverages a flexible and data-driven digital platform that
        includes iOS and Android mobile apps, a PIPs website, and an admin
        portal and content management system. Using the latest technologies in
        terms of data collection (QR codes, beacons, NFC scans, machine
        learning, and AI models), the PIPs platform allows PIPs clients and the
        PIPs team to know the effective actions taken by PIPs users and to
        monitor verified rather than declarative sustainable behavior data. The
        PIPs Rewards platform also leverages flexible integrations with a host
        of retail, rewards program, and activation partners through REST APIs or
        via lighter integrations through url management and attribution.
        Finally, PIPs offers a variety of data analytics reports available to
        PIPs clients, allowing for detailed analysis of PIPs’ impact on
        sustainable behaviors and knowledge of sustainable solutions for a
        healthier and more eco-friendly lifestyle.
      </p>
    ),
  },
  {
    id: 'panel7',
    title:
      'What is PIPs Rewards LLC, and what is its relationship to PIPs Education Fund?',
    description: (
      <>
        <p>
          PIPs Rewards LLC, which operates the PIPs mobile engagement platform
          to which universities and other enterprises subscribe, is a wholly
          owned charitable subsidiary of PIPs Education Fund (PEF), a New York
          State 501(c)(3) nonprofit organization. PEF’s charitable purpose is to
          help bridge the education equity gap by turning student engagement in
          health and sustainability into grant support for school and other
          essentials.
        </p>
        <p>
          To learn more about PEF,{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://pipseducationfund.org"
          >
            go to pipseducationfund.org.
          </a>
        </p>
      </>
    ),
  },
  {
    id: 'panel8',
    title: 'Is PIPs Rewards LLC a B Corp?',
    description: (
      <>
        <p>
          Indeed it is. In fact, January 2021 marks the third time the company
          that owns and operates the PIPs Rewards™ App was certified as a
          Benefit Corporation (B Corp). The first two times, the PIPs Rewards™
          App was owned by 3P Partners Inc., which when founded in 2012 became
          one of the very first B Corps in the state of New York, a corporate
          designation under state law.
        </p>
        <p>
          With operations of the app passing in summer 2020 to PIPs Rewards LLC,
          a wholly owned subsidiary of the New York State nonprofit PIPs
          Education Fund, PIPs Rewards LLC became the body to receive the
          certification for the third time.
        </p>
        <p>
          As a nonprofit AND a B Corp, we are all about positive change and
          social good. Click{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://prod.pipsrewards.com/pipsrewardsllc_bcorp_2021"
          >
            here
          </a>{' '}
          for our B Corp Assessment.
        </p>
      </>
    ),
  },
  {
    id: 'panel9',
    title: 'Has PIPs won any awards?',
    description: (
      <>
        <p>
          The PIPs App won the Big Apps NYC Award for Best Game in 2014 as it
          was developing its Minimal Viable Product.{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www1.nyc.gov/office-of-the-mayor/news/443-14/mayor-de-blasio-nycedc-president-kimball-winners-nyc-bigapps-2014"
          >
            Here
          </a>{' '}
          is the press release from the Mayor’s Office.
        </p>
        <p>PIPs also was a Finalist in the Ford Mobility Challenge NYC 2017.</p>
      </>
    ),
  },
  {
    id: 'panel10',
    title: 'Can I suggest ideas for PIPs?',
    description: (
      <p>
        Absolutely! We are always on the lookout for innovative new products,
        services, sharing opportunities, self-tracking apps, experiences, and
        causes. Please alert us to any that you believe positively benefit one
        or more of our three main impact categories: Mind and Body, Community,
        and Environment. We’ll review your suggestions and include them in our
        “Rewarding Better” catalog whenever possible. Send all ideas to{' '}
        <a href="mailto:ideas@pipsrewards.com">ideas@pipsrewards.com</a>.
      </p>
    ),
  },
  {
    id: 'panel11',
    title: 'How does PIPs identify PIPs-worthy Earn Actions?',
    description: (
      <>
        <p>
          PIPs are awarded by the PIPs team and by our partners for a wide range
          of actions that positively impact personal, community, and/or
          planetary health, from riding the bus and volunteering to
          participating in the sharing economy and purchasing up-cycled
          products. As a consequence, there is no one rating system by which to
          compare them all.
        </p>
        <p>
          In order to narrow in on those actions that are both measurable and
          meaningful, we developed a system of constraints for our selection
          process. A key constraint we put on PIPs-worthy actions is that they
          must be verifiable, meaning we will only award PIPs for actions that
          can be digitally recorded or tracked using smart phones, smart meters,
          quantified self apps, sensors, NFCs, e-payment platforms, QR Codes,
          transaction reports, etc.
        </p>
        <p>
          We also consider favorably actions that help reduce an individual’s
          and/or an institution’s carbon emissions, such as taking public
          transit, biking, carpooling, recycling, refilling water bottles,
          reducing food waste, saving energy, etc.
        </p>
        <p>
          For the products, services, and organizations that we include in our
          catalogs, we consider whether they have been vetted by a third-party
          certifier or have been compared/rated against recognized standards
          that were developed and are accepted by experts. As no rating system
          is 100% foolproof, we conduct a literature review on products and
          entities to help us evaluate their positive impact. In this{' '}
          <a target="_blank" rel="noopener noreferrer" href="/rating-system">
            chart
          </a>
          , we list the rating systems on which PIPs relies.
        </p>
      </>
    ),
  },
  {
    id: 'panel12',
    title:
      'How does PIPs measure carbon footprint for the Carbon Footprint Calculator?',
    description: (
      <>
        <p>
          This Carbon Footprint Calculator aggregates the data of select actions
          taken by all members of your community that have a calculable carbon
          footprint (i.e., biking, walking, recycling, riding the bus, etc).
        </p>
        <p>
          The following references, assumptions, emissions factors and formulas
          were used to estimate the number of pounds of carbon equivalents saved
          for each action included in this calculator. As we add more actions
          with a calculable carbon footprint, we’ll update the Calculator and
          this explanatory note.
        </p>
        <Box mb={5}>
          <Typography variant="h5">BIKING</Typography>
          <Typography variant="h6">References:</Typography>
          <p>
            Greenhouse Gas Protocols,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://ghgprotocol.org/calculation-tools#cross_sector_tools_id"
            >
              http://ghgprotocol.org/calculation-tools#cross_sector_tools_id
            </a>{' '}
            (March 2017);{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.energy.ca.gov/commissioners/rosenfeld_docs/Equivalence-Matrix_2001-05.pdf"
            >
              http://www.energy.ca.gov/commissioners/rosenfeld_docs/Equivalence-Matrix_2001-05.pdf
            </a>
          </p>
          <Typography variant="h6">Assumptions:</Typography>
          <ul>
            <li>
              1) As there is no direct CO2 emissions associated with walking or
              biking, CO2 saved is measured as a function of car miles not
              driven.
            </li>
            <li>
              2) Passenger car (Gasoline - Year 2005-present) emits
              0.86323223646818 lbs (or 0.391555555555556 kg) of CO2 per mile.
            </li>
            <li>3) Car is assumed to get 22.5 miles per gallon.</li>
            <li>
              4) In the case of biking, the average CU student commute by bike
              is estimated to be 2.5-3 miles/day.
            </li>
            <li>
              5) Biking and bike sharing is included in the total number of
              biking events.
            </li>
          </ul>
          <Typography variant="h6">Formula:</Typography>
          <p>
            CO2 saved per mile biked = Avg miles/trip (1.5) x Emissions factor
            (0.863232236) X # trips
          </p>
        </Box>
        <Box mb={5}>
          <Typography variant="h5">RIDING THE BUS</Typography>
          <Typography variant="h6">References:</Typography>
          <p>
            Greenhouse Gas Protocols,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://ghgprotocol.org/calculation-tools#cross_sector_tools_id"
            >
              http://ghgprotocol.org/calculation-tools#cross_sector_tools_id
            </a>{' '}
            (March 2017);{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.energy.ca.gov/commissioners/rosenfeld_docs/Equivalence-Matrix_2001-05.pdf"
            >
              http://www.energy.ca.gov/commissioners/rosenfeld_docs/Equivalence-Matrix_2001-05.pdf
            </a>
          </p>
          <Typography variant="h6">Assumptions:</Typography>
          <ul>
            <li>
              <p>
                1 ) To calculate CO2 saved per passenger-mile for riding bus,
                subtract CO2 emissions factor (Bus - Local - US) from CO2
                emissions factor (Passenger Car).
              </p>
              <p>
                - Emissions Factor (Passenger Car, gasoline, Yr 2005 to present)
                = 0.8632322365 (Table 14. CO2, CH4 and N2O Emission Factors for
                US and other regions by Vehicle Distance)
              </p>
              <p>- Emissions factor (Local bus/US) = 0.6273376365</p>
            </li>
            <li>2) Average bus ride assumed to be 1.375 miles.</li>
          </ul>
          <Typography variant="h6">Formula:</Typography>
          <p>
            Total lbs. CO2 saved riding the bus = # trips x emissions factor
            (0.86258925) X Avg Miles/Trip (1.375)
          </p>
        </Box>
        <Box mb={5}>
          <Typography variant="h5">CARPOOLING</Typography>
          <Typography variant="h6">References:</Typography>
          <p>
            Greenhouse Gas Protocols,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://ghgprotocol.org/calculation-tools#cross_sector_tools_id"
            >
              http://ghgprotocol.org/calculation-tools#cross_sector_tools_id
            </a>{' '}
            (March 2017); Table 14. CO2, CH4 and N2O Emission Factors for US and
            other regions by Vehicle;{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.energy.ca.gov/commissioners/rosenfeld_docs/Equivalence-Matrix_2001-05.pdf"
            >
              http://www.energy.ca.gov/commissioners/rosenfeld_docs/Equivalence-Matrix_2001-05.pdf
            </a>
          </p>
          <Typography variant="h6">Emissions Factor:</Typography>
          <p>lbs/passenger-mile = 0.8632322365</p>
          <Typography variant="h6">Assumptions:</Typography>
          <ul>
            <li>
              1) Sharing a ride with one other person, and that the weight of
              one extra person in the car does not increase the amount of
              gasoline consumed.
            </li>
            <li>2) Car gets 22.5 MPG.</li>
            <li>3) Average trip = 2.5 miles.</li>
          </ul>
          <Typography variant="h6">Formula:</Typography>
          <p>
            Total lbs. CO2 saved = Avg Miles/trip (2.5) X Emissions factor
            (2.158080591) X # trips
          </p>
        </Box>
        <Box mb={5}>
          <Typography variant="h5">WATER BOTTLE REFILLING</Typography>
          <Typography variant="h6">Reference:</Typography>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://sciencing.com/carbon-footprint-plastic-bottle-12307187.html"
            >
              https://sciencing.com/carbon-footprint-plastic-bottle-12307187.html
            </a>
          </p>
          <Typography variant="h6">Assumptions:</Typography>
          <p>
            Choosing to refill instead of purchasing a single-use plastic bottle
            saves 82.8 grams of CO2 ( 0.1825428 lbs of CO2).
          </p>
          <p>
            At the recommended water intake of about 2 liters per day (2000
            milliliters), every day you choose to use a reusable water bottle,
            you save 0.73 lbs of CO2 a day, or 266.5 lbs of CO2 in a year.
          </p>
          <Typography variant="h6">Formula:</Typography>
          <p>
            Calculating CO2 (pounds) saved based on # times a person chooses to
            refill = 0.1825428 lbs of CO2 * # of refills{' '}
          </p>
        </Box>
        <Box mb={5}>
          <Typography variant="h5">RECYCLING</Typography>
          <Typography variant="h6">Reference:</Typography>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.epa.gov/warm/individual-waste-reduction-model-iwarm-tool"
            >
              https://www.epa.gov/warm/individual-waste-reduction-model-iwarm-tool
            </a>
          </p>
          <Typography variant="h6">Emissions Factors:</Typography>
          <p>
            <strong>- (Aluminum can)</strong> = 0.3 kwh saved ( *1.786 lbs of
            CO2e) = 0 .5358 lbs of CO2e/can
          </p>
          <p>
            <strong>- (Water bottle (20 ounce))</strong> = 0.1 kwh saved (
            *1.786 lbs of CO2e)= 0.1786 lbs of CO2e/bottle
          </p>
          <Typography variant="h6">Formula:</Typography>
          <p>
            Total lbs CO2 saved recycling 1:1 mix of aluminum cans and plastic
            water bottles = (0.5)( Total Recycled Material) X (0.5358) +
            (0.5)(Total Recycled Material) X (0.1786)
          </p>
        </Box>
        <Box mb={5}>
          <Typography variant="h5">DINING IN</Typography>
          <Typography variant="h6">References:</Typography>
          <p>
            Plastic (LPDE or PET, polyethlene) is about 6 kg CO2 per kg of
            plastic.
          </p>
          <p>
            Amazon states the weight of 50 containers to be 4.75 lbs to ship.
          </p>
          <p>
            Weight of each container (lbs)- 0.095 lbs per container
            <br />
            Weight of container (kg)- 0.04309128 kg per container
            <br />
            <i>6kg of CO2 per kg of material</i>
          </p>
          <Typography variant="h6">Emissions Factors:</Typography>
          <p>Footprint per container (kg)- 0.25 kg of CO2 per container</p>
          <p>Footprint per container (lbs)- 0.57 lbs of CO2 per container</p>
          <Typography variant="h6">Formula:</Typography>
          <p>0.57lbs CO2 x [# Actions]</p>
        </Box>
        <Box mb={5}>
          <Typography variant="h5">COMPOSTING</Typography>
          <Typography variant="h6">References:</Typography>
          <p>Each ton of compost sequesters 0.5 tons of CO2.</p>
          <p>
            Source:{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.biocycle.net/2013/08/21/connection-co2-math-for-compost-benefits/"
            >
              https://www.biocycle.net/2013/08/21/connection-co2-math-for-compost-benefits/
            </a>
          </p>
          <p>
            USDA study found that every American wastes about 1 lb of food waste
            per day.
          </p>
          <Typography variant="h6">Emissions Factors:</Typography>
          <p>
            If 2000 lbs of composted food waste sequesters 0.5 tons of CO2 (
            1102.3 lbs of CO2), and
          </p>
          <p>Every American wastes about 1 lb of food waste per day</p>
          <p>
            Then a person who composts their food waste each day saves: 0.55115
            lbs of CO2/day
          </p>
          <Typography variant="h6">Formula:</Typography>
          <p>0.55115 lbs CO2/day X [days composted]</p>
        </Box>
        <Box mb={5}>
          <Typography variant="h5">WALKING</Typography>
          <Typography variant="h6">References:</Typography>
          <p>
            Greenhouse Gas Protocols,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://ghgprotocol.org/calculation-tools#cross_sector_tools_id"
            >
              http://ghgprotocol.org/calculation-tools#cross_sector_tools_id
            </a>{' '}
            (March 2017);{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.energy.ca.gov/commissioners/rosenfeld_docs/Equivalence-Matrix_2001-05.pdf"
            >
              http://www.energy.ca.gov/commissioners/rosenfeld_docs/Equivalence-Matrix_2001-05.pdf
            </a>
          </p>
          <Typography variant="h6">Assumptions:</Typography>
          <ul>
            <li>
              1) As there is no direct CO2 emissions associated with walking or
              biking, CO2 saved is measured as a function of car miles not
              driven.
            </li>
            <li>
              2) Passenger car (Gasoline - Year 2005-present) emits
              0.86323223646818 lbs (or 0.391555555555556 kg) of CO2 per mile
            </li>
            <li>3) Car is assumed to get 22.5 miles per gallon.</li>
            <li>4) The average person takes about 2000 steps in a mile.</li>
            <li>
              5) PIPs sets two daily steps threshold - 8000 steps and 12,000
              steps.
            </li>
          </ul>
          <Typography variant="h6">Formula:</Typography>
          <p>
            CO2 saved for walking = steps/mile: lower threshold (4) x emissions
            factor (0.86323224) X # walking events: lower threshold +
            steps/mile: higher threshold (6) x emissions factor (0.86323224) X #
            walking events:higher threshold
          </p>
        </Box>
        <Box mb={5}>
          <Typography variant="h5">OFFSETTING SHOPPING</Typography>
          <Typography variant="h6">References:</Typography>
          <p>
            The average American is responsible for over 20 tons of CO2 per
            year, according to our partner, UCapture.
          </p>
          <ul className="list--disc">
            <li>An 8 oz. steak causes 5 kgs. of CO2,</li>
            <li>A 50” TV creates 225 kgs. of CO2 per year,</li>
            <li>1 passenger from NY to LA causes 454 kgs. of CO2.</li>
          </ul>
          <p>
            UCapture has built a simple tool that it has shared with PIPs users
            to help us estimate, track and offset our carbon footprint. Click
            through the UCapture Earn Promo link in PIPs Earn Catalog to set up
            the UCapture extension. It will activate automatically on 25,000+
            stores, triggering them to fund carbon offset projects when you
            shop.{' '}
            <strong>
              You must be logged in to the PIPs App to set up the PIPs-UCapture
              extension. Otherwise you won’t earn PIPs as you shop and your
              offsets won’t appear in the PIPs Carbon Footprint calculator.
            </strong>
          </p>
          <Typography variant="h6">Assumptions:</Typography>
          <ul>
            <li>
              1) See{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.ucapture.com/about"
              >
                https://www.ucapture.com/about
              </a>{' '}
              to learn how UCapture estimates, tracks and offsets our carbon
              footprint.
            </li>
            <li>2) 1 kg = 2.2046226 lbs</li>
          </ul>
          <Typography variant="h6">Formula:</Typography>
          <p>CO2 offset = UCapture Kgs Offset per action x 2.2046226</p>
        </Box>
      </>
    ),
  },
  {
    id: 'panel13',
    title: 'How are actions verified?',
    description: (
      <>
        <p>
          The PIPs platform is fundamentally a transaction management system,
          capturing and keeping tallies of all user activity, including PIPs
          earn and redeem transactions, games, and challenges. The platform uses
          the following mix of action tracking tools, some in-app, to capture
          and reward user behavior:
        </p>
        <ul className="list--disc">
          <li>
            Machine Learning (via in app sensors — GPS sensor, accelerometer,
            gyroscope, camera)
          </li>
          <li>APIs</li>
          <li>NFC devices</li>
          <li>Beacons, sensors</li>
          <li>QR Codes</li>
          <li>Transaction reports</li>
        </ul>
        <p>
          Take the task of detecting when a user is riding a bus, for instance.
          We can use A) data from native apps/sensors installed on a device in
          order to complete the task, or B) beacons installed in a bus.
        </p>
      </>
    ),
  },
  {
    id: 'panel14',
    title: 'Do I need to pay to be a member of the PIPs Rewards community?',
    description: (
      <>
        <p>
          The PIPs Rewards platform is free to users. In fact, you get PIPs
          (Positive Impact Points) simply for joining!
        </p>
        <p>
          However, users who are part of a subscribing community, say a
          university or business, will have access to select Earn Actions — such
          as walking, biking, recycling, and volunteering — and Redeem Actions —
          such as scholarship grants — that are not available to general users.
          To learn more about subscription benefits for universities, colleges,
          and community colleges,{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://prod.pipsrewards.com/pips_for_school"
          >
            click here
          </a>
          .
        </p>
      </>
    ),
  },
];
