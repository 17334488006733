import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  promoSelector,
  clearState,
  getPromoList,
} from 'store/promos/PromoSlice';
import EarnItem from '../EarnItem';
import { EARN } from 'config/constants';
import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';

const EarnList = ({ categoryId }) => {
  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, error, promos } =
    useSelector(promoSelector);

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    const query = {
      promoType: EARN,
      page: 1,
      size: 500,
      /*We need to check typeof categoryId because 'Show all' option is presented in dropdown and to show it as selected I've set a value 'show all' for it and it's a string*/
      ...(categoryId && typeof categoryId === 'number' && { categoryId }),
    };
    dispatch(getPromoList({ query }));
  }, [categoryId]);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  if (isFetching) return <Preloader />;
  if (!promos.length) return <NoResultsPlaceholder />;

  return (
    <ul>
      {promos.map((promo) => (
        <EarnItem key={promo.id} promo={promo} />
      ))}
    </ul>
  );
};
export default EarnList;
