import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Layout from 'components/Layout';
import classes from './styles';

const OurStory = () => {
  return (
    <Layout>
      <Box sx={classes.root}>
        <Typography variant="h1" align="center" mb={6}>
          Our Story
        </Typography>
        <Typography mb={2}>
          Aristotle is believed to have said, “We are what we repeatedly do.
          Excellence, then, is not an act, but a habit.” Though probably not a
          direct quote, I’m sure you get the point. The philosopher and
          scientist was an advocate for the hard work of habit change.
        </Typography>
        <Typography mb={2}>
          We get it, habit change is hard. But when we first started musing
          about PIPs, my college friend David Sand and I, both aging
          environmentalists, wondered whether there was a remote chance that the
          hard work of habit change, especially habits that were good for our
          health and the planet, could be made just a little easier for people.
          Could we bring together modern day tools and knowledge to nudge people
          to make better everyday choices, from how we get to work or school to
          the container in which we carry our coffee.
        </Typography>
        <Typography mb={2}>
          As it turns out, the opportunity to goose a little good out of people
          is everywhere. I love this{' '}
          <Link
            href="http://www.treehugger.com/culture/pay-your-subway-ride-beijing-recycling-plastic-bottle.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            example from Beijing
          </Link>{' '}
          which installed reverse vending machines in subway stations so
          passersby can convert their empty plastic bottles into either transit
          credits or extra mobile phone minutes. What’s not to love about a
          device that derives positive value for individuals who recycle those
          ridiculously overpriced, single-use, last-a-lifetime-in-landfills
          plastic beverage bottles?
        </Typography>
        <Typography mb={2}>
          The author of the article about these vending machines suggested that
          it’s too bad it takes rewards to get people to care. I’m not so sure I
          agree, I believe, like Matt Wallaert, a behavioral scientist at
          Microsoft, that “most of us are well-intentioned.” We do care. It’s
          just that to act on our intentions is “cognitively expensive“ --
          meaning it takes more mental energy than we have to spare.
        </Typography>
        <Typography mb={2}>
          For David and me, the challenge was clear: use technology, like the
          ingenious “recycle for rides” vending machine that utilizes game-like
          features including rewards, to make everyday behavior change
          significantly more fun. Stores reward shopping. With all the many apps
          to track everything from the steps you take to the temperature in your
          home, let’s figure out a way to reward behaviors like exercise,
          carpooling or saving energy that deliver not only economic returns but
          positive social benefits as well.
        </Typography>
        <Typography mb={2}>
          Since my partner David and I weren’t techies, a colleague introduced
          us to Yaniv Eyny and Evan Sable,
          high-school-friends-turned-business-partners, whose expertise in
          behavioral science and technical know-how was just what we needed. By
          the end of our first meeting, they’d joined our team.
        </Typography>
        <Typography mb={2}>
          Fast forward to today, and the PIPs App, a flexible and data-driven
          digital platform available on both{' '}
          <Link
            href="https://apps.apple.com/ua/app/pips-rewards/id1087959967"
            target="_blank"
            rel="noopener noreferrer"
          >
            iPhone
          </Link>{' '}
          and{' '}
          <Link
            href="https://play.google.com/store/apps/details?id=biz.pips"
            target="_blank"
            rel="noopener noreferrer"
          >
            Android
          </Link>{' '}
          is being deployed by universities and other enterprises to motivate,
          verify, and reward engagement in everyday good habits from recycling,
          walking, and taking public transit to social and emotional learning,
          meeting with a mentor, and volunteering.
        </Typography>
        <Typography mb={2}>
          The PIPs App uses the three Rs of behavior change — repetition,
          reward, and reinforcement — plus a splash of surprise and delight to
          turn “good” habits into “gold” rewards, including scholarships.
          Consider Michelle, a recent graduate of CU Boulder who said, “I
          learned to bike so I could earn enough PIPs for a PIPs scholarship.”
        </Typography>
        <Typography mb={2}>
          Since students started using the PIPs app in 2017, they have:
        </Typography>
        <Box component="ul" sx={classes.list} mb={2}>
          <li>
            <strong>Taken over 1,100,000 positive actions</strong> tracked and
            recorded by the platform,
          </li>
          <li>
            <strong>Unlocked $130,000 in scholarships grants, and</strong>,
          </li>
          <li>
            <strong>Saved over 750,000 lbs in carbon emissions</strong>, he
            equivalent of planting over 15,000 trees.
          </li>
        </Box>
        <Typography mb={2}>
          Even after campuses shut in response to the COVID-19 outbreak,
          students were able to continue earning and redeeming PIPs. Indeed, the
          mobile PIPs App is perfectly suited for a hybrid model of both online
          and in-person classes. It helps students feel connected and supported
          wherever they are.{' '}
          <Link href="https://pipsrewards.com/programs" target="_blank">
            Click here to learn more about the PIPs for School
          </Link>{' '}
          program that we offer to higher ed institutions.
        </Typography>
        <Typography mb={2}>
          So what exactly is a “PIP?” By almost every common definition, a “pip”
          is a very small thing — an apple seed, the dots on dominoes, the stars
          on uniforms that an officer earns, the blip on a radar screen… In the
          world of currencies, a pip is one basis point or one-hundredth of one
          percent (1/100 x .01).
        </Typography>
        <Typography mb={2}>
          Blended together, these meanings add up to the very big idea of PIPs:
          That a <i>small</i> “currency of good” (a “positive Impact Point” or
          “PIP” that’s worth a penny) can be earned for game-like engagement in
          certain positive impact behaviors such as biking, completing a
          financial literacy workshop, or tutoring your younger cousins, that,
          while small in the scheme of things, can unlock great benefits,
          including scholarships and gift cards for healthy food and other
          essentials.
        </Typography>
        <Typography mb={2}>
          With research showing food insecurity on the rise among university
          students and as little as $1,000 in debt driving students to drop out,
          food and tuition credits made possible through PIPs offer students a
          means to stay on the path to success, all while improving one’s
          health, the community, and the planet. Now that’s a big idea.
        </Typography>
        <Typography mb={2}>
          I encourage you to learn more about our work by reading{' '}
          <Link href="https://pipsrewards.com/news">our blog</Link>, and
          listening to a few of our students -{' '}
          <Link href="https://pipseducationfund.org/dawn-l/" target="_blank">
            Dawn
          </Link>
          ,{' '}
          <Link
            href="https://pipseducationfund.org/kavindra-h/"
            target="_blank"
          >
            Kavindra
          </Link>
          ,{' '}
          <Link
            href="https://pipseducationfund.org/chrisber-v/"
            target="_blank"
          >
            Chrisber
          </Link>
          ,{' '}
          <Link href="https://pipseducationfund.org/sammi-z/" target="_blank">
            Sammi
          </Link>
          ,{' '}
          <Link href="https://pipseducationfund.org/kevin-p/" target="_blank">
            Kevin
          </Link>
          , and{' '}
          <Link href="https://pipseducationfund.org/cale-k/" target="_blank">
            Cale
          </Link>{' '}
          - tell of what they have accomplished and have learned as PIPs users.
        </Typography>
        <Typography mb={2}>
          We also hope you will join in supporting PIPs’ cycle of good{' '}
          <Link href="https://pipseducationfund.org" target="_blank">
            by clicking this link to make a one-time or recurring donation to
            the PIPs Education Fund
          </Link>
          , the non-profit umbrella organization that is home to the PIPs
          Rewards app and all PIPs programs. Every dollar you give goes to
          provide our students with the opportunity to achieve college success
          while learning the power of individual action.
        </Typography>
        <Typography mb={2}>With gratitude,</Typography>
        <Typography mb={2}>
          Wendy Gordon
          <br />
          Co-Founder and President
          <br />
          PIPs Rewards LLC
        </Typography>
        <Typography>Updated September 2022</Typography>
      </Box>
    </Layout>
  );
};

export default OurStory;
