export const messages = {
  common: {
    SUCCESS: 'Success',
    ERROR: 'An error has occurred.',
  },

  validation: {
    REQUIRED: 'This field is required',
    EMAIL: 'Please enter a valid email address',
    PASSWORD:
      'Password has to include at least one upper case, one lower case, one special character, and one number.',
    PASSWORD_MIN: 'Password minimum length: 8',
    CONFIRM_PASSWORD: 'Passwords do not match',
    DATE: 'Please enter a valid date',
    MIN_DATE:
      'Sorry, we are not able to process your registration, you must be at least 13 years old',
    PHONE: 'Please enter a valid phone number',
    PHONE_MAX_LENGTH: 'The max length of characters is 25',
    NUMBER: 'Please enter a valid number',
    NUMBER_POSITIVE: 'Please enter a positive number',
    INTEGER: 'Number should be integer',
    MAX_LENGTH: 'The max length of characters is 64',
    ACCEPT_TERMS: 'Please agree to our Privacy & Terms of use',
    STRING_TYPE: 'Value of this field must be a `string` type',
  },

  errors: {
    VALIDATION_ERROR:
      'Sorry, something went wrong. Please try again later or get assistance at support@pipsrewards.com',
    GENERIC_ERROR:
      'Sorry, something went wrong. Please try again later or get assistance at support@pipsrewards.com',
    NOT_ENOUGH_EARNED_PIPS:
      'User must earn 500 PIPs and Redeem or Donate 500 PIPs to make the gift',
    NOT_ENOUGH_REDEEMED_OR_DONATED_PIPS:
      'User must earn 500 PIPs and Redeem or Donate 500 PIPs to make the gift',
    NOT_ENOUGH_PIPS: 'Not enough available PIPs',
    RECIPIENT_NOT_FOUND: 'Recipient is not found',
    // eslint-disable-next-line
    RECIPIENT_THE_SAME: "You can't gift PIPs to yourself",
    INVALID_CREDENTIALS: 'The email or password is invalid',
    INVALID_PROMO: 'Promo is not found or not available',
    INVALID_USER: 'User is not found or not available',
    INVALID_AGE:
      'Sorry, we are not able to process your registration, you must be at least 13 years old',
    INVALID_PASSWORD:
      // eslint-disable-next-line
      "You've used an invalid password - it must be at least 8 symbols, contain at least 1 lowercase, 1 uppercase, 1 digit and 1 special symbol. Please try again. If some problem occurs, please contact support@pipsrewards.com",
    PASSWORD_THE_SAME:
      'New password should not be same as old password. Please try again. If some problem occurs, please contact support@pipsrewards.com',
    PASSWORD_OLD_EMPTY: 'The current password must be defined',
    PASSWORD_CURRENT_INVALID: 'The current password is incorrect',
    INVALID_EMAIL:
      'Sorry, something went wrong. Please try again later or get assistance at support@pipsrewards.com',
    EMAIL_NOT_FOUND: 'The email is not existing in the system',
    EMAIL_EXISTING: 'Account with this email already exists',
    INVALID_CUSTOM_TERMS: 'User must apply custom terms during registration',
    INVALID_CODE:
      'The verification code is invalid. Please try again. If some problem occurs, please contact support@pipsrewards.com',
    ALREADY_MERGED: 'Your account has already been linked with ThinkHumanTV',
    ACCOUNT_EXISTS_ERROR: 'This email already exists',
    INVALID_LINK: 'The link is invalid or corrupted',
    EXPIRED_LINK: 'The link is expired',
    ERROR_EARNING: 'Some error has occurred during earning',
    PARTNER_TRANSACTION_EXISTED: 'The link has been used already',
  },
  THINKHUMANTV_ACCOUNT_LINK_SUCCESS:
    'Your Think Human TV and PIPs accounts were successfully linked!',
  EARN_POINT_LINK_ACTIVATION_SUCCESS:
    'Your link has been successfully activated!',
};
