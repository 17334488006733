import palette from 'styles/theme/palette';

const classes = {
  root: {
    paddingTop: '30px',
    paddingBottom: '100px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media (min-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  table: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    th: {
      color: palette.text.grey,
    },
    td: {
      color: palette.text.grey,
    },
  },
  iconButton: {
    svg: {
      fontSize: '20px',
    },
  },
  button: {
    marginLeft: 'auto',
    marginBottom: '20px',
    height: '38px',
  },
  preloaderWrapper: {
    position: 'fixed',
    height: '100vh',
    width: '100%',
    zIndex: 10,
    top: 0,
    left: 0,
  },
};

export default classes;
