import { PADDING_MAIN } from 'config/ui';

const classes = {
  root: {
    paddingTop: `${PADDING_MAIN}px`,
  },
  wrapper: {
    marginBottom: '32px',
  },
  verificationCodeForm: {
    padding: '25px 0',
    '& .MuiFormControl-root': {
      marginBottom: '8px',
    },
    '& input': {
      marginBottom: 0,
    },
  },
  resendLink: {
    display: 'block',
    marginTop: '24px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
};

export default classes;
