import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import palette from 'styles/theme/palette';

import Layout from 'components/Layout';
import EarnList from './components/EarnList';
import EarnFilter from './components/EarnFilter';
import classes from './styles';

const Earn = () => {
  const [categoryId, setCategoryId] = useState('');

  const handleChange = (event) => {
    setCategoryId(event.target.value);
  };

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Box sx={classes.titleWrapper}>
          <Typography variant="h3">Earn</Typography>
          <EarnFilter categoryId={categoryId} handleChange={handleChange} />
        </Box>
        <EarnList categoryId={categoryId} />
      </Box>
    </Layout>
  );
};

export default Earn;
