import quotes from 'assets/icons/quotes.svg';

export const quotePositionX = 0;

const classes = {
  root: {
    position: ' relative',
    paddingTop: '30px',
    paddingBottom: '25px',
    background: 'white',
    '& .carousel .slide img': {
      width: '150px',
    },
    '& .slider-wrapper': {
      minHeight: '300px',
    },
    '@media (min-width: 768px)': {
      paddingTop: '85px',
    },
  },
  avatar: {
    width: '80px',
    height: '80px',
    margin: '0 auto 24px',
    '& svg': {
      width: '50px',
      height: '50px',
    },
  },
  avatarBroken: {
    opacity: 0.5,
  },
  testimonial: {
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundImage: `url(${quotes})`,
      opacity: '0.5',
      width: '35px',
      height: '35px',
      '@media (min-width: 768px)': {
        width: '45px',
        height: '45px',
      },
      '@media (min-width: 1024px)': {
        width: '65px',
        height: '65px',
      },
    },
    '&::before': {
      top: '70px',
      left: quotePositionX,
      transform: 'scale(-1, -1)',
      '@media (min-width: 768px)': {
        top: '80px',
      },
    },
    '&::after': {
      bottom: '70px',
      right: quotePositionX,
      '@media (min-width: 768px)': {
        bottom: '50px',
      },
    },
  },
  testimonialText: {
    maxWidth: '85%',
    margin: '0 auto 24px !important',
    textAlign: 'center',
    fontStyle: 'italic',
    lineHeight: 1.2,
    '@media (min-width: 768px)': {
      fontSize: '26px',
    },
  },
};

export default classes;
