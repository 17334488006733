import { PADDING_MAIN, PADDING_MAIN_MD } from 'config/ui';

const classes = {
  root: {
    background: '#fff',
    paddingBottom: '45px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      paddingTop: '45px',
    },
    '@media (min-width: 1024px)': {
      paddingTop: '115px',
      paddingBottom: '95px',
      flexDirection: 'row',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
    '@media (min-width: 1024px)': {
      textAlign: 'left',
      marginBottom: '48px',
    },
  },
  challengesImage: {
    position: 'relative',
    '@media (min-width: 1024px)': {
      flex: '1 0 35%',
    },
  },
  mainImage: {
    margin: '0 auto 30px',
    '@media (min-width: 768px)': {
      maxWidth: '80%',
    },
    '@media (min-width: 1024px)': {
      width: '450px',
      height: '450px',
      borderRadius: '50%',
      marginLeft: '-70px',
      marginRight: 0,
      marginBottom: 0,
      maxWidth: 'initial',
    },
  },
  logo: {
    position: 'absolute',
    width: '170px',
    height: '170px',
    right: `${PADDING_MAIN}px`,
    bottom: 0,
    transform: 'translateY(50%)',
  },
  challengesListWrapper: {
    paddingLeft: `${PADDING_MAIN_MD}px`,
    paddingRight: `${PADDING_MAIN_MD}px`,
    '@media (min-width: 1024px)': {
      flex: '1 0 65%',
      paddingLeft: `${PADDING_MAIN}px`,
      paddingRight: `${PADDING_MAIN}px`,
    },
  },
  challengesList: {
    '& li': {
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      flexDirection: 'column',
      '@media (min-width: 768px)': {
        flexDirection: 'row',
      },
      '& img': {
        maxWidth: '45px',
        width: 'auto',
        objectFit: 'contain',
        marginBottom: '15px',
        '@media (min-width: 768px)': {
          marginRight: '30px',
          marginBottom: 0,
        },
      },
      '& h4': {
        marginBottom: '20px',
      },
    },
  },
  challengeDescription: {
    flex: '1 1 100%',
  },
};

export default classes;
