import { PADDING_MAIN } from 'config/ui';
import palette from 'styles/theme/palette';

const classes = {
  root: {
    paddingTop: `${PADDING_MAIN}px`,
  },
  content: {
    fontSize: '22px',
    '& p': {
      fontSize: 'inherit',
    },
  },
  mark: {
    color: palette.primary.main,
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
};

export default classes;
