import React from 'react';
import { Box, Typography } from '@mui/material';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import classes from './styles';

const ResetPasswordSuccess = () => {
  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Typography variant="h2" align="center">
          Reset your password
        </Typography>
        <Typography component="p" align="center">
          Successfully updated your password. Please log in to continue.
        </Typography>
      </Box>
    </Layout>
  );
};

export default ResetPasswordSuccess;
