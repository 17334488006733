import React from 'react';
import { Typography } from '@mui/material';

export default [
  {
    name: 'David Helene',
    occupation: (
      <Typography variant="caption">
        Founder & CEO <br />
        at Edquity
      </Typography>
    ),
    avatar: 'assets/images/team-members/helene.jpg',
    linkedin: 'https://www.linkedin.com/in/david-helene/',
  },
  {
    name: 'Karim Abouelnaga',
    occupation: (
      <Typography variant="caption">
        CEO at PRACTICE <br />
        Benefit Corp
      </Typography>
    ),
    avatar: 'assets/images/team-members/abouelnaga.jpg',
    linkedin: 'https://www.linkedin.com/in/karimabouelnaga/',
  },
  {
    name: 'Nancy Bsales',
    occupation: (
      <Typography variant="caption">
        Chief Operating Officer, <br />
        4AIR
      </Typography>
    ),
    avatar: 'assets/images/team-members/bsales.jpg',
    linkedin: 'https://www.linkedin.com/in/nancybsales/',
  },
  {
    name: 'Ashley Grosh',
    occupation: (
      <Typography variant="caption">
        Vice President, <br />
        Breakthrough Energy
      </Typography>
    ),
    avatar: 'assets/images/team-members/grosh.jpeg',
    linkedin: 'https://www.linkedin.com/in/ashley-grosh-12171346/',
  },
  {
    name: 'Yaniv Eyny',
    occupation: (
      <Typography variant="caption">
        CEO, Co-Founder <br />
        at Novelution
      </Typography>
    ),
    avatar: 'assets/images/team-members/eyny.png',
    linkedin: 'https://www.linkedin.com/in/yaniveyny/',
  },
  {
    name: 'Evan Sable',
    occupation: (
      <Typography variant="caption">
        CTO, Co-Founder <br />
        at Novelution
      </Typography>
    ),
    avatar: 'assets/images/team-members/sable.png',
    linkedin: 'https://www.linkedin.com/in/evan-sable-039810/',
  },
  {
    name: 'Cale Kennamer',
    occupation: (
      <Typography variant="caption">
        Ecology Student, <br />
        University of Colorado Colorado Springs
      </Typography>
    ),
    avatar: 'assets/images/testimonials/Cale_Kennamer.png',
    linkedin: 'https://www.linkedin.com/in/cale-kennamer-a7a95269/',
  },
];
