import React from 'react';
import { Typography } from '@mui/material';

export default [
  {
    name: 'Stephanie Browner',
    occupation: (
      <Typography variant="caption">
        Special Advisor to the <br />
        President, New School
      </Typography>
    ),
    avatar: 'assets/images/team-members/browner.jpg',
    linkedin: 'https://www.newschool.edu/lang/faculty/stephanie-browner/',
  },
  {
    name: 'David Sand',
    occupation: (
      <Typography variant="caption">
        Chief Impact Strategist, <br />
        Community Capital Management LLC
      </Typography>
    ),
    avatar: 'assets/images/team-members/sand.jpeg',
    linkedin: 'https://www.linkedin.com/in/davidfsand/',
  },
  {
    name: 'Susan Tu',
    occupation: (
      <Typography variant="caption">
        Education Program Manager, <br />
        Facebook
      </Typography>
    ),
    avatar: 'assets/images/team-members/tu.jpg',
    linkedin: 'https://www.linkedin.com/in/susantu/',
  },
  {
    name: 'Debika Shome',
    occupation: (
      <Typography variant="caption">
        Co-Founder & Principal
        <br />
        Marigold Strategy
      </Typography>
    ),
    avatar: 'assets/images/team-members/debika.png',
    linkedin: 'https://www.linkedin.com/in/debikashome/',
  },
  {
    name: 'Pete Zheng',
    occupation: (
      <Typography variant="caption">
        Founder & CEO <br />
        Stealth Startup
      </Typography>
    ),
    avatar: 'assets/images/team-members/pete.jpeg',
    linkedin: 'https://www.linkedin.com/in/pete-zheng-729a52b4/',
  },
];
