import palette from 'styles/theme/palette';

const classes = {
  root: {
    paddingTop: '45px',
    paddingBottom: '45px',
    background: palette.background.main,
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    '@media (min-width: 1024px)': {
      paddingTop: '70px',
      paddingBottom: '120px',
      flexDirection: 'row',
    },
  },
  text: {
    '@media (min-width: 1024px)': {
      flex: '1 0 60%',
    },
  },
  divider: {
    width: '200px',
    height: '1px',
    background: '#fff',
    marginBottom: '30px',
    marginTop: '30px',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  listItem: {
    flex: '1 0 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: '15px',

    '@media (min-width: 768px)': {
      flex: '1 0 33.333333%',
      paddingRight: '16px',
      paddingLeft: '16px',
      marginBottom: '40px',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '75px',
    height: '75px',
    background: '#fff',
    borderRadius: '50%',
    marginBottom: '16px',
    '& img': {
      width: 'auto',
      objectFit: 'contain',
    },
  },
  image: {
    '@media (min-width: 1024px)': {
      flex: '1 0 40%',
      '& img': {
        marginTop: '-150px',
      },
    },
  },
};

export default classes;
