import palette from 'styles/theme/palette';

const classes = {
  root: {
    textAlign: 'center',
    margin: '0 auto',
    '@media (min-width: 1280px)': {
      maxWidth: '35%',
    },
    '& .MuiStepper-root': {
      maxWidth: '50%',
      margin: '0 auto 30px',
    },
    '& .MuiStep-root': {
      padding: 0,
    },
    '& .MuiStepLabel-iconContainer': {
      paddingRight: 0,
    },
    '& .MuiStepIcon-root': {
      width: '0.75em',
      height: '0.75em',
    },
    '& .MuiStepIcon-text': {
      fontSize: '14px',
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  privacySettingsStep: {
    '& .MuiFormGroup-root': {
      alignItems: 'center',
      // justifyContent: 'center',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '18px',
    },
  },
  radioGroupWrapper: {
    padding: '24px 0',
  },
  radioGroup: {},
  radioGroupLabel: {
    textAlign: 'right',
    flex: '0 0 25%',
  },
  radioGroupOptions: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 66.666%',
    paddingLeft: '35px',
    fontWeight: 400,
    '& .MuiFormControlLabel-root': {
      width: '100%',
      '& .MuiFormControlLabel-label': {
        fontSize: '18px',
        fontWeight: 400,
        marginLeft: 0,
      },
    },
  },
  checkbox: {
    paddingTop: '16px',
    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'left',
      },
    },
  },
  checkboxError: {
    color: palette.error.main,
    textAlign: 'left',
  },
};

export default classes;
