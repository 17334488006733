const classes = {
  root: {
    paddingTop: '50px',
    paddingBottom: '20px',
    bgcolor: 'background.blue',
  },
  title: {
    marginBottom: '30px',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  listItem: {
    marginBottom: 3,
    width: '100%',
    '@media (min-width: 768px)': {
      width: '50%',
    },
    '@media (min-width: 1024px)': {
      width: '33%',
    },
  },
};

export default classes;
