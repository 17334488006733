import React from 'react';
import Layout from 'components/Layout';
import { CardMedia, Typography, Box } from '@mui/material';
import classes from './styles';
import palette from 'styles/theme/palette';

const NotFound = () => {
  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Typography variant="h3" textAlign="center" mb={3}>
          Page Not Found
        </Typography>
        <CardMedia component="img" src="/assets/images/ic_no_promos.png" />
      </Box>
    </Layout>
  );
};

export default NotFound;
