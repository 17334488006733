import { createTheme } from '@mui/material/styles';

import * as overrides from './overrides';
import breakpoints from './breakpoints';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  components: { ...overrides },
  breakpoints,
  palette,
  typography,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
