import { HEADER_DESKTOP_HEIGHT } from 'config/ui';

const classes = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'primary.main',
    height: HEADER_DESKTOP_HEIGHT,
    background: 'white',
    paddingLeft: 2,
    paddingRight: 2,
    '& > div': {
      height: '100%',
    },
  },
  icon: {
    width: '100%',
    height: '100%',
  },
  drawer: {
    height: '100%',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  headerLinks: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    '& button': {
      paddingRight: 2,
    },
  },
};

export default classes;
