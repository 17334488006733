import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import palette from 'styles/theme/palette';
import classes from './styles';
import OneTimeDonation from '../OneTimeDonation';
import PledgeDonation from '../PledgeDonation';
import { promoSelector } from 'store/promos/PromoSlice';

const CharitablePromoType = () => {
  const [showOneTimeDonation, setShowOneTimeDonation] = useState(false);
  const [showPledgeDonation, setShowPledgeDonation] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { promo, pipsAvailable } = useSelector(promoSelector);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [successMessage]);

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
  };

  if (successMessage)
    return (
      <Typography variant="h4" fontWeight={700} textAlign="center">
        {successMessage}
      </Typography>
    );

  return (
    <Box>
      <Typography
        mb={2}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(promo.promoLongDescription),
        }}
        sx={classes.longDescription}
      />

      <Box
        style={{
          display: showOneTimeDonation || showPledgeDonation ? 'none' : 'block',
        }}
      >
        <Typography>
          <Typography color={palette.text.primary} variant="caption">
            Make a one-time donation
          </Typography>{' '}
          or
          <Typography color={palette.text.primary} variant="caption">
            {' '}
            pledge a certain percentage of future-earned PIPs{' '}
          </Typography>
          using the buttons below.
        </Typography>
        <Box sx={classes.btnWrapper}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowOneTimeDonation(true);
              setShowPledgeDonation(false);
            }}
          >
            Make one time donation
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowOneTimeDonation(false);
              setShowPledgeDonation(true);
            }}
          >
            Pledge %age of Future Earned PIPs
          </Button>
        </Box>
      </Box>
      {showOneTimeDonation && (
        <OneTimeDonation
          showOneTimeDonation={setShowOneTimeDonation}
          showPledgeDonation={setShowPledgeDonation}
          pipsAvailable={pipsAvailable}
          promoId={promo.id}
          handleSuccessMessage={handleSuccessMessage}
          promoType={promo.subType}
        />
      )}
      {showPledgeDonation && (
        <PledgeDonation
          showOneTimeDonation={setShowOneTimeDonation}
          showPledgeDonation={setShowPledgeDonation}
          promo={promo}
          handleSuccessMessage={handleSuccessMessage}
        />
      )}
    </Box>
  );
};

export default CharitablePromoType;
