import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import classes from './styles';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';
import { getCategories } from 'services/api';

const EarnFilter = ({ categoryId, handleChange }) => {
  const [categories, setCategories] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    getCategories()
      .then((res) => {
        if (res.status === 'OK') {
          setCategories(res.categories);
          setIsFetching(false);
        } else {
          handleError(res);
          setIsFetching(false);
        }
      })
      .catch((err) => {
        handleError(err);
        setIsFetching(false);
      });
  }, []);

  if (isFetching) return <Preloader />;

  return (
    <FormControl sx={classes.root}>
      <InputLabel id="category-select-label">Earn Filter</InputLabel>
      <Select
        labelId="category-select-label"
        id="category-select"
        value={categoryId}
        label="Earn Filter"
        onChange={handleChange}
        sx={classes.filter}
      >
        <MenuItem value="show all">Show All</MenuItem>
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {category.name}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EarnFilter;
