const displayText = (time) => {
  let styles = {};

  for (let i = 0; i < time; i++) {
    styles = {
      ...styles,
      [`&:nth-of-type(${i})`]: {
        animationDelay: `${i * 0.3}s`,
      },
    };
  }
  return styles;
};

const classes = {
  root: {
    position: 'relative',
    zIndex: 1,
    paddingLeft: 2,
    paddingRight: 5,
    '@media (min-width: 768px)': {
      paddingRight: 0,
    },
    '@media (max-width: 767px)': {
      whiteSpace: 'normal!important',
      width: '100%',
    },
    '& span': {
      opacity: 0,
      visibility: 'hidden',
      animationName: 'animatingText',
      animationFillMode: 'forwards',
      ...displayText(13),
    },
    '@keyframes animatingText': {
      '0%': {
        opacity: 0,
        visibility: 'hidden',
      },
      '100%': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
};

export default classes;
