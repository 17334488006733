import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import promoSliceReducer from 'store/promos/PromoSlice';
import userSliceReducer from 'store/user/UserSlice';
import myGiftCardsSliceReducer from 'store/myGiftCards/MyGiftCardsSlice';

const rootReducer = combineReducers({
  promos: promoSliceReducer,
  user: userSliceReducer,
  giftCards: myGiftCardsSliceReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
