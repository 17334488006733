import palette from 'styles/theme/palette';

export default {
  styleOverrides: {
    root: {
      height: 50,
      minWidth: 174,
      textTransform: 'initial',
      boxShadow: 'none',
      '&:hover': {
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 13%), 0px 2px 8px 0px rgb(0 0 0 / 8%), 0px 1px 10px 0px rgb(0 0 0 / 6%)',
      },
    },
    containedInherit: {
      color: palette.primary.main,
      backgroundColor: palette.white,
    },
  },
};
