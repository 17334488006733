export default {
  styleOverrides: {
    root: {
      borderRadius: 10,
    },
    notchedOutline: {
      borderColor: '#70707029',
    },
  },
};
