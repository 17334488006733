import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Box, FormControlLabel, TextField } from '@mui/material';
import { PasswordField } from 'components/forms';
import PasswordStrengthMeter from 'components/PasswordStrengthMeter';

const CreateAccountStep = ({ control, errors, formData }) => {
  const [password, setPassword] = useState('');

  useEffect(() => {
    setPassword(formData.password);
  }, [formData]);

  return (
    <>
      <Box>
        <Controller
          name="email"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <FormControlLabel
              labelPlacement="top"
              label="Email Address"
              control={
                <TextField
                  type="email"
                  name={name}
                  value={value}
                  fullWidth
                  inputProps={{
                    autoComplete: 'off',
                    placeholder: 'Add Email Address',
                  }}
                  error={Boolean(errors.email)}
                  helperText={errors.email && errors.email.message}
                  onChange={onChange}
                />
              }
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          name="firstName"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <FormControlLabel
              labelPlacement="top"
              label="First Name"
              control={
                <TextField
                  name={name}
                  value={value}
                  fullWidth
                  inputProps={{
                    autoComplete: 'off',
                    placeholder: 'Add First Name',
                  }}
                  error={Boolean(errors.firstName)}
                  helperText={errors.firstName && errors.firstName.message}
                  onChange={onChange}
                />
              }
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          name="lastName"
          control={control}
          render={({ field: { name, value, onChange } }) => (
            <FormControlLabel
              labelPlacement="top"
              label="Last Name"
              control={
                <TextField
                  name={name}
                  value={value}
                  fullWidth
                  inputProps={{
                    autoComplete: 'off',
                    placeholder: 'Add Last Name',
                  }}
                  error={Boolean(errors.lastName)}
                  helperText={errors.lastName && errors.lastName.message}
                  onChange={onChange}
                />
              }
            />
          )}
        />
      </Box>
      <Box>
        <Controller
          name="password"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              labelPlacement="top"
              label="Password"
              control={
                <PasswordField
                  fullWidth
                  error={Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  inputProps={{
                    autoComplete: 'dis-p',
                    placeholder: 'Add Password',
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    onChange(e);
                  }}
                  value={value}
                />
              }
            />
          )}
        />
        <PasswordStrengthMeter password={password} />
      </Box>
      <Box>
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              labelPlacement="top"
              label="Confirm Password"
              control={
                <PasswordField
                  fullWidth
                  error={Boolean(errors.confirmPassword)}
                  helperText={
                    errors.confirmPassword && errors.confirmPassword.message
                  }
                  inputProps={{
                    autoComplete: 'dis-p',
                    placeholder: 'Confirm Password',
                  }}
                  value={value}
                  onChange={onChange}
                />
              }
            />
          )}
        />
      </Box>
    </>
  );
};

export default CreateAccountStep;
