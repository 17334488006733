import React, { useEffect } from 'react';
import { Box, CardMedia, Grid, Link, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  clearState,
  getPromoList,
  promoSelector,
} from 'store/promos/PromoSlice';
import classes from './styles';
import { REDEEM } from 'config/constants';
import NoResultsPlaceholder from 'components/NoResultsPlaceholder';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';

const Redeem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, error, promos } =
    useSelector(promoSelector);

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    const query = {
      promoType: REDEEM,
      page: 1,
      size: 500,
    };
    dispatch(getPromoList({ query }));
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const redirectToDetails = (id) => {
    navigate(`/promo/redeem/${id}`);
  };

  if (isFetching)
    return (
      <Grid container>
        <Preloader />
      </Grid>
    );
  if (!promos.length) return <NoResultsPlaceholder />;

  return (
    <Grid container>
      <Box component="ul" sx={classes.list}>
        {promos.map((promo) => (
          <Box component="li" sx={classes.item} key={promo.id}>
            <Box sx={classes.image}>
              <CardMedia component="img" src={promo.imageUrl} />
            </Box>
            <Box sx={classes.wrapper}>
              <Box sx={classes.content}>
                {/*<Typography sx={classes.categoryName}>*/}
                {/*  {promo.categoryName}*/}
                {/*</Typography>*/}
                <Typography variant="h4" color="primary" mb={1}>
                  {promo.promoName}
                </Typography>
                <Typography sx={classes.promoDescription}>
                  {promo.promoDescription}
                </Typography>
              </Box>
              <Box sx={classes.btnGroup}>
                <Link
                  variant="link"
                  color="secondary"
                  onClick={() => redirectToDetails(promo.id)}
                  sx={classes.link}
                >
                  View Details
                </Link>
                {/*<Button*/}
                {/*  variant="contained"*/}
                {/*  color="secondary"*/}
                {/*  component="a"*/}
                {/*  href={promo.buttonUrl}*/}
                {/*  target="_blank"*/}
                {/*>*/}
                {/*  {promo.buttonName}*/}
                {/*</Button>*/}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Grid>
  );
};

export default Redeem;
