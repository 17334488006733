import React from 'react';
import Layout from 'components/Layout';
import { Box, Link, Typography } from '@mui/material';
import classes from './styles';
import { sortedNews } from './settings';

const News = () => {
  return (
    <Layout>
      <Box sx={classes.root}>
        <Typography variant="h1" textAlign="center" mb={4}>
          News
        </Typography>
        <Box component="ul">
          {sortedNews.map((el, index) => (
            <Box component="li" key={`${el.title}-${index}`} mb={3}>
              <Box sx={classes.newsHeader}>
                <Typography variant="caption" fontSize="20px" mr={1}>
                  {el.date}:
                </Typography>
                <Typography fontSize="20px" color="#929497">
                  {el.source}
                </Typography>
              </Box>
              <Typography fontSize="20px">
                {el.url ? (
                  <Link href={el.url} target="_blank" rel="noopener noreferrer">
                    {el.title}
                  </Link>
                ) : (
                  <span>{el.title}</span>
                )}
              </Typography>
              {el.author ? (
                <Typography fontSize="20px" color="#929497">
                  {el.author}
                </Typography>
              ) : null}
            </Box>
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default News;
