import palette from 'styles/theme/palette';

const classes = {
  iconClear: {
    width: 48,
    height: 48,
    svg: {
      fontSize: '24px',
    },
  },
  iconButton: {
    svg: {
      fontSize: '28px',
    },
  },
  card: {
    color: palette.text.grey,
    marginBottom: '20px',
    '.MuiCardContent-root': {
      paddingBottom: 0,
    },
  },
  moveWrapper: {
    height: 20,
    backgroundColor: '#4ac2ce4a',
    cursor: 'move',
    textAlign: 'center',
  },
  dragIndicator: {
    color: '#06656e4a',
    fontSize: '20px',
  },
  optionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
  },
  addOptionButton: {
    width: '100%',
    height: '50px',
    color: palette.text.grey,
    padding: '10px 10px',
    fontSize: '18px',
    lineHeight: '1.2',
    opacity: '0.8',
  },
};

export default classes;
