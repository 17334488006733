const classes = {
  root: {
    paddingTop: '45px',
    paddingBottom: '90px',
  },
  nav: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 5,
    '& a': {
      margin: '0 8px',
    },
  },
};

export default classes;
