import palette from 'styles/theme/palette';

const classes = {
  root: {},
  grantsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '20px',
    marginBottom: '40px',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      height: '150px',
    },
  },
  availableToConvert: {
    flex: '0 0 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: palette.primary.main,
    borderRadius: '24px',
    color: '#fff',
    marginBottom: 3,
    '@media (min-width: 768px)': {
      marginBottom: 0,
    },
  },
  totalCommitted: {
    flex: '0 0 48%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: `3px solid ${palette.primary.main}`,
    borderRadius: '24px',
    color: palette.primary.main,
  },
  title: {
    fontWeight: 700,
    textAlign: 'center',
    fontSize: '20px',
  },
  number: {
    fontSize: '52px',
    lineHeight: 1,
  },
  grantsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
    borderBottom: '1px solid #EBEBEB',
    fontSize: '18px',
    '&:first-of-type, &:last-of-type': {
      borderBottom: 0,
    },
    '&:first-of-type': {
      padding: 0,
    },
  },
  grantDate: {
    flex: '0 0 40%',
    '@media (min-width: 768px)': {
      flex: '0 0 75%',
    },
  },
  numbersWrapper: {
    flexGrow: 1,
    display: 'flex',
    '& p': {
      flex: '0 0 50%',
    },
  },
};

export default classes;
