const classes = {
  root: {
    '& video': {
      height: '82vh',
      position: 'absolute',
      left: '50%',
      top: 0,
      transform: 'translateX(-50%)',
    },
    '& p': {
      whiteSpace: 'nowrap',
      '& span': {
        position: 'relative',
        zIndex: 1,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        height: '160px',
        background: '#bdf4ba',
        boxShadow:
          '0 -34px 17px -37px rgb(0 0 0 / 30%), 0 34px 17px -37px rgb(0 0 0 / 30%)',
      },
    },
  },
};

export default classes;
