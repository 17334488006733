import React, { useState, useEffect } from 'react';

import { getImpacts } from 'services/api';

import PieTabs from 'pages/Dashboard/components/PieTabs';

const ActivityLabels = {
  USED: 'Use Activity',
  EARNED: 'Earn Activity',
};

const ImpactActivity = () => {
  const [impacts, setImpacts] = useState();

  useEffect(() => {
    getImpacts().then((res) => {
      if (res.status === 'OK') {
        setImpacts(
          res.impacts.map((impact) => ({
            ...impact,
            label: ActivityLabels[impact.type],
            title: 'Total',
            type: `PIPs ${impact.type.toLowerCase()}`,
          }))
        );
      }
    });
  }, []);

  return <PieTabs ariaLabel="Impact Activity" tabs={impacts} />;
};

export default ImpactActivity;
