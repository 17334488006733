const classes = {
  root: {
    margin: '0 auto',
    '@media (min-width: 768px)': {
      maxWidth: '33%',
    },
  },
};

export default classes;
