import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'components/Layout';
import palette from 'styles/theme/palette';
import classes from './styles';
import {
  userSelector,
  clearState,
  handleVerifyCode,
} from 'store/user/UserSlice';
import { FormValidationSchema } from './settings';
import { resendCode } from 'services/api';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';
import { linkThinkTVAccount } from 'utils/linkThinkTVAccount';

const VerifyCode = () => {
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess, isError, error, isFetching } = useSelector(userSelector);
  const thToken = localStorage.getItem('th_token');

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(FormValidationSchema),
    mode: 'onSubmit',
  });

  const watchedFormFields = watch(['code']);
  const isFormFilledCorrectly = watchedFormFields.every((val) => Boolean(val));

  const handleResendCode = () => {
    resendCode().then((res) => {
      if (res.status === 'OK') {
        setShowMessage(true);
      }
    });
  };

  const onSubmit = handleSubmit((data) => {
    dispatch(handleVerifyCode({ code: data.code })).then((res) => {
      if (res.payload.status === 'OK') {
        if (thToken) {
          linkThinkTVAccount(thToken);
        }
        navigate('/registration-success');
      } else {
        handleError(res);
      }
    });
  });

  if (isFetching) return <Preloader />;

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Typography variant="h3" align="center">
          Awesome! Just one more thing...
        </Typography>
        <Typography component="p" align="center">
          Please enter the code that was sent to your email from
          PIPs.Team@pipsrewards.com in order to complete the final registration
          step
        </Typography>
        <Box
          component="form"
          sx={classes.verificationCodeForm}
          onSubmit={onSubmit}
        >
          <Grid container>
            <Grid item xs={12} sx={classes.wrapper}>
              <Controller
                name="code"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <FormControlLabel
                    labelPlacement="top"
                    label="Code"
                    control={
                      <TextField
                        name={name}
                        value={value}
                        fullWidth
                        inputProps={{
                          autoComplete: 'off',
                          placeholder: 'Code',
                        }}
                        error={Boolean(errors.code)}
                        helperText={errors.code && errors.code.message}
                        onChange={onChange}
                      />
                    }
                  />
                )}
              />
              <Typography style={{ display: showMessage ? 'block' : 'none' }}>
                Code has been resent. Please check your email.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" component="div">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isFormFilledCorrectly}
                >
                  Complete Sign Up
                </Button>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" component="div">
                <Link
                  variant="link"
                  color="secondary"
                  onClick={() => handleResendCode()}
                  sx={classes.resendLink}
                >
                  Re-send Code
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default VerifyCode;
