import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { promoSelector } from 'store/promos/PromoSlice';
import { giftYourPips } from 'services/api';
import { FormValidationSchema } from './settings';
import { Checkbox } from 'components/forms';
import classes from './styles';
import palette from 'styles/theme/palette';
import { handleError } from 'services/handleError';

const GiftYourPips = () => {
  const [pipsAfterPurchase, setPipsAfterPurchase] = useState(null);

  const { pipsAvailable } = useSelector(promoSelector);
  const navigate = useNavigate();

  useEffect(() => {
    setPipsAfterPurchase(pipsAvailable - 1);
  }, [pipsAvailable]);

  const handlePipsChange = (val) => {
    setPipsAfterPurchase(pipsAvailable - val);
  };
  const handleGiftYourPips = (body) => {
    giftYourPips({ body })
      .then((res) => {
        if (res.status === 'OK') {
          const navigateUrl = '/promo/rewards/gift-your-pips';
          navigate('/give-pips-success', { state: { ...res, navigateUrl } });
        } else {
          if (res.code === 'INVALID_PROMO') {
            const resObj = {
              ...res,
              code: 'GENERIC_ERROR',
            };
            handleError(resObj);
          } else {
            handleError(res);
          }
        }
      })
      .catch((err) => handleError(err.response.data));
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      pips: 1,
      recipientEmail: '',
      recipientName: '',
      recipientMessage: '',
      pipsForSchool: false,
    },
    resolver: yupResolver(FormValidationSchema),
    mode: 'onChange',
  });

  const watchedFormFields = watch(['pips', 'recipientEmail', 'recipientName']);

  const onSubmit = handleSubmit((data) => {
    handleGiftYourPips(data);
  });

  const isFormFilledCorrectly = watchedFormFields.every((val) => Boolean(val));

  return (
    <Box sx={classes.root}>
      <Typography variant="h5" mb={2}>
        Did you know your can Gift Your PIPs?
      </Typography>
      <Typography>
        That’s right. Say your team just spent the day on a community service
        project, and you want to thank them. Or a friend helped you out in a
        pinch. Or maybe you know another PIPs user who is trying to save up and
        unlock a scholarship grant. For acts of kindness, great and small, pay
        it forward with PIPs, the purposeful points.
      </Typography>
      <Typography mb={2}>
        To bulk purchase PIPs for gifting to employees, team members, etc
        contact{' '}
        <Link href="mailto:theteam@pipsrewards.com">
          theteam@pipsrewards.com
        </Link>
        .
      </Typography>
      <Typography variant="h4">Gift your PIPs.</Typography>
      <Typography mb={2}>
        Just use the spinner below to pick the number of PIPs you&apos;d like to
        gift. Enter the email address of the recipient, add a personalized
        message and then click to submit. In moments an email will be delivered
        to the recipient with the gifted PIPs and your message.
      </Typography>
      <Box>
        <Typography mb={2}>
          Available PIPs: <strong>{pipsAvailable.toLocaleString()}</strong>
        </Typography>
        <form onSubmit={onSubmit}>
          <Box mb={2} sx={classes.pipsWrapper}>
            <Controller
              name="pips"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  labelPlacement="top"
                  label="PIPs to gift"
                  control={
                    <TextField
                      name={field.name}
                      value={field.value}
                      inputProps={{
                        autoComplete: 'off',
                        min: 1,
                        max: pipsAvailable,
                      }}
                      type="number"
                      error={Boolean(errors.pips)}
                      helperText={errors.pips && errors.pips.message}
                      onChange={async (e) => {
                        field.onChange(e);
                        handlePipsChange(e.target.value);
                      }}
                    />
                  }
                />
              )}
            />
            {pipsAfterPurchase < 0 ? (
              <Typography color={palette.error.main}>
                Not enough PIPs
              </Typography>
            ) : (
              <Typography>
                PIPs after gift:{' '}
                <strong>
                  {pipsAfterPurchase && pipsAfterPurchase.toLocaleString()}
                </strong>
              </Typography>
            )}
          </Box>
          <Box>
            <Controller
              name="recipientEmail"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormControlLabel
                  labelPlacement="top"
                  label="Recipient's Email"
                  control={
                    <TextField
                      type="email"
                      name={name}
                      value={value}
                      fullWidth
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      error={Boolean(errors.recipientEmail)}
                      helperText={
                        errors.recipientEmail && errors.recipientEmail.message
                      }
                      onChange={onChange}
                    />
                  }
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="recipientName"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormControlLabel
                  labelPlacement="top"
                  label="Recipient's Name"
                  control={
                    <TextField
                      name={name}
                      value={value}
                      fullWidth
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      error={Boolean(errors.recipientName)}
                      helperText={
                        errors.recipientName && errors.recipientName.message
                      }
                      onChange={onChange}
                    />
                  }
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name="recipientMessage"
              control={control}
              render={({ field: { name, value, onChange } }) => (
                <FormControlLabel
                  labelPlacement="top"
                  label="Recipient's Message"
                  control={
                    <TextField
                      name={name}
                      value={value}
                      fullWidth
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      error={Boolean(errors.recipientMessage)}
                      helperText={
                        errors.recipientMessage &&
                        errors.recipientMessage.message
                      }
                      onChange={onChange}
                    />
                  }
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="pipsForSchool"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      inputProps={{
                        'aria-label':
                          'Gift your PIPs to PIPs For School Account',
                      }}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  }
                  label="Gift your PIPs to PIPs For School Account"
                />
              )}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={!isFormFilledCorrectly || pipsAfterPurchase < 0}
            sx={classes.giftBtn}
          >
            Proceed With Gift
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default GiftYourPips;
