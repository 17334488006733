import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';

import classes from './styles';

const Textarea = forwardRef(function Textarea(props, ref) {
  return (
    <TextField
      ref={ref}
      fullWidth
      multiline
      minRows={1}
      maxRows={3}
      inputProps={{
        autoComplete: 'off',
      }}
      {...props}
      sx={{ ...classes.textArea, ...props.sx }}
    />
  );
});

export default Textarea;
