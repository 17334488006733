import { PADDING_MAIN, PADDING_MAIN_MD } from 'config/ui';

const classes = {
  root: {
    paddingTop: '32px',
    paddingBottom: `${PADDING_MAIN}px`,
    background: '#fff',
    fontSize: '20px',
    paddingLeft: '15px',
    paddingRight: '15px',
    margin: '0 -16px',

    '@media (min-width: 768px)': {
      paddingLeft: `${PADDING_MAIN_MD}px`,
      paddingRight: `${PADDING_MAIN_MD}px`,
      margin: 0,
    },
    '@media (min-width: 1280px)': {
      paddingLeft: `${PADDING_MAIN}px`,
      paddingRight: `${PADDING_MAIN}px`,
    },
  },
  newsHeader: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default classes;
