import React from 'react';
import { Box } from '@mui/material';

import { AnimatedText } from 'pages/Users/components';
import classes from './styles';

const FirstPip = ({ id }) => (
  <Box
    id={id}
    sx={classes.root}
    display="flex"
    alignItems="center"
    flexDirection="column"
  >
    <Box component="video" autoPlay muted loop>
      <source src="/assets/animations/bigPip.mp4" type="video/mp4" />
    </Box>
    <AnimatedText text="This is a PIP" />
  </Box>
);

export default FirstPip;
