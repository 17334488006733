export const chaptersList = {
  ['APPS']: [
    {
      id: 1,
      title: 'Common Sense Media',
      logo: 'assets/images/rating-system/logo_commonsense.png',
      whatItDoes:
        'Provides independent ratings and reviews for everything kids want to watch, read, play and learn based on age appropriateness. The unbiased ratings are conducted by expert reviewers and are not influenced by the creators or by Common Sense Media’s funders.',
      link: 'https://www.commonsensemedia.org/',
    },
    {
      id: 2,
      title: 'Parent’s Choice',
      logo: 'assets/images/rating-system/logo_parentschoice.png',
      whatItDoes:
        'Awards quality children’s media. The Parents’ Choice Foundation’s panel of educators, scientists, performing artists, librarians, parents and kids identify the very best products for children including books, toys, music and storytelling, magazines, software, videogames, television and websites.',
      link: 'http://www.parents-choice.org/',
    },
  ],
  ['CLEANING PRODUCTS']: [
    {
      id: 1,
      title: 'Chemical Footprint',
      logo: 'assets/images/rating-system/logo_chemicalfootprint.png',
      whatItDoes:
        'Chemical Footprint - a new project of Safer Chemicals, Healthy Families, a national effort to protect families from toxic chemicals - asks mainstream companies to quantitatively measure and report on their chemical footprint. Tracking chemical inputs and measuring progress to safer chemicals is an important metric in tracking progress to the global Sustainable Development Goals (SDGs) and meeting corporate reporting standards such as those developed by the Sustainability Accounting Standards Board (SASB).',
      link: 'https://www.chemicalfootprint.org',
    },
    {
      id: 2,
      title: 'Sustainable Product Certification',
      logo: 'assets/images/rating-system/logo_ecologo.jpg',
      whatItDoes:
        'Certifies that all products or services meet or exceed each of the listed criteria before receiving the mark. Sustainable Product Certification standards endeavor to address multiple environmental attributes related to both human health and environmental impacts across the life cycle of a product or service.',
      link: 'https://www.ul.com/services/solutions/sustainability-and-environment-solutions/sustainable-product-certification',
    },
    {
      id: 3,
      title: 'EWG Verified',
      logo: 'assets/images/rating-system/logo_ewg.png',
      whatItDoes:
        'With thousands of personal care and consumer products on the market, it can be overwhelming to know which ones are safer and healthier for your family. The EWG VERIFIED™ mark does the work for you. When you see the EWG VERIFIED™ mark on a product, you can be sure it’s free from EWG’s chemicals of concern and meets our strictest standards for your health.',
      link: 'https://www.ewg.org/ewgverified/',
    },
    {
      id: 4,
      title: 'EPA Safer Detergents Stewardship Initiative - Champions',
      logo: 'assets/images/rating-system/logo_epa.png',
      whatItDoes:
        'Recognizes environmental leaders who voluntarily commit to the use of safer surfactants. Safer surfactants break down quickly into non-polluting compounds and help protect aquatic life in both fresh and salt water.',
      link: 'https://www.epa.gov/saferchoice/design-environment-safer-detergents-stewardship-initiative',
    },
    {
      id: 5,
      title: 'Green Seal',
      logo: 'assets/images/rating-system/logo_greenseal.png',
      whatItDoes:
        'Certifies thousands of products and services that meet science-based environmental standards that are credible and transparent. It prohibits carcinogens, mutagens, and reproductive toxins as well as materials hazardous to human health or the environment.',
      link: 'http://www.greenseal.org/',
    },
    {
      id: 6,
      title: 'Made Safe Certified',
      logo: 'assets/images/rating-system/logo_ms.png',
      whatItDoes:
        'Provides comprehensive health-focused certification for nontoxic products across store aisles - from baby to personal care to household and beyond. A nonprofit organization, Made Safe also examines ingredients for bio-accumulation, persistence, and general and aquatic toxicity. An MSI Score is given to products containing 75-99% Made Safe Ingredients, thus making it easy for consumers and retailers to select products that aren’t known to cause harm.',
      link: 'http://madesafe.org/',
    },
  ],
  ['CLOTHING AND ACCESSORIES']: [
    {
      id: 1,
      title: 'Global Organic Textile Standard (GOTS)',
      logo: 'assets/images/rating-system/logo_gots.png',
      whatItDoes:
        'Defines high-level environmental criteria along the entire organic textiles supply chain and requires compliance with social criteria as well.',
      link: 'http://www.global-standard.org/',
    },
  ],
  ['CONSUMER PRODUCTS']: [
    {
      id: 1,
      title: 'Cradle to Cradle',
      logo: 'assets/images/rating-system/logo_cradletocradle.png',
      whatItDoes:
        'Certifies manufacturer’s achievement in multiple areas relating to human and environmental health across their life cycles; design products and systems for material reutilization, such as recycling or composting; use renewable energy; make efficient use of water; use water of maximum quality for production; and develop company strategies for social responsibility.',
      link: 'http://www.c2ccertified.org/',
    },
    {
      id: 2,
      title: 'EWG Verified',
      logo: 'assets/images/rating-system/logo_ewg.png',
      whatItDoes:
        'With thousands of personal care and consumer products on the market, it can be overwhelming to know which ones are safer and healthier for your family. The EWG VERIFIED™ mark does the work for you. When you see the EWG VERIFIED™ mark on a product, you can be sure it’s free from EWG’s chemicals of concern and meets our strictest standards for your health.',
      link: 'https://www.ewg.org/ewgverified/',
    },
    {
      id: 3,
      title: 'Leaping Bunny Certified',
      logo: 'assets/images/rating-system/logo_leapingbunny.png',
      whatItDoes:
        'Signifies that the manufacturer and its ingredient suppliers do not conduct or commission animal testing of their cosmetic, personal hygiene, or household products after the date of the agreement.',
      link: 'https://www.leapingbunny.org/',
    },
    {
      id: 4,
      title: 'Made Safe Certified',
      logo: 'assets/images/rating-system/1MadeSafeCertified.png',
      whatItDoes:
        'Provides comprehensive health-focused certification for nontoxic products across store aisles - from baby to personal care to household and beyond. A nonprofit organization, Made Safe also examines ingredients for bio-accumulation, persistence, and general and aquatic toxicity. An MSI Score is given to products containing 75-99% Made Safe Ingredients, thus making it easy for consumers and retailers to select products that aren’t known to cause harm.',
      link: 'http://madesafe.org/',
    },
    {
      id: 5,
      title: 'Member of Roundtable on Sustainable Palm Oil (RSPO)',
      logo: 'assets/images/rating-system/logo_rspo.png',
      whatItDoes:
        'Indicates the manufacturer of this product is a member of the Roundtable on Sustainable Palm Oil (RSPO) which promotes the production and use of sustainable palm oil. Cultivating palm oil, which may be in as many as 1 in 10 cosmetics products, can result in significant deforestation of tropical rainforests and threatens the survival of endangered species such as the orangutan and Sumatran tiger.',
      link: 'http://www.rspo.org/',
    },
    {
      id: 6,
      title: 'PETA’s Beauty Without Bunnies',
      logo: 'assets/images/rating-system/logo_peta.png',
      whatItDoes:
        'Identifies companies that have signed PETA’s statement of assurance confirming that they do not conduct or commission animal tests.',
      link: 'http://www.peta.org/about-peta/learn-about-peta/success-stories/beauty-without-bunnies-campaign/',
    },
  ],
  ['ELECTRONICS AND APPLIANCES']: [
    {
      id: 1,
      title: 'Consumer Reports',
      logo: 'assets/images/rating-system/logo_consumerreports.png',
      whatItDoes:
        'Provides a comprehensive rating system from the test results of thousands of products each year ranging from electronics, appliances, cars and food for consumers through their flagship magazine and website.',
      link: 'http://web.consumerreports.org/',
    },
    {
      id: 2,
      title: 'ENERGY STAR Most Efficient',
      logo: 'assets/images/rating-system/logo_energystar.png',
      whatItDoes:
        'Identifies the best-of-the-best ENERGY STAR products from air conditioners and refrigerators to televisions and stoves.',
      link: 'http://www.energystar.gov/?c=most_efficient.me_index',
    },
    {
      id: 3,
      title: 'GreenGuard',
      logo: 'assets/images/rating-system/logo_greenguard.png',
      whatItDoes:
        'Certifies products are scientifically proven to meet some of the world’s most rigorous, third-party chemical emissions standards - helping reduce indoor air pollution and the risk of chemical exposure while aiding in the creation of healthier indoor environments.',
      link: 'https://www.ul.com/resources/ul-greenguard-certification-program',
    },
    {
      id: 4,
      title: 'TopTenUSA',
      logo: 'assets/images/rating-system/logo_toptenusa.png',
      whatItDoes:
        'Provides the top ten most energy efficient consumer products based on annual energy use, lifetime cost and energy savings.',
      link: 'http://www.toptenusa.org/',
    },
    {
      id: 5,
      title: 'Underwriters Laboratories',
      logo: 'assets/images/rating-system/logo_ul.png',
      whatItDoes:
        'Certifies, validates, tests, inspects, audits, and advises and trains for a wide range of clients including manufacturers, retailers, policymakers and consumers. UL has developed 1,040 product standards and outlines, and actively participates in national and international standards development.',
      link: 'http://www.ul.com/',
    },
  ],
  ['E-WASTE']: [
    {
      id: 1,
      title: 'E-Stewards',
      logo: 'assets/images/rating-system/logo_estewards.png',
      whatItDoes:
        'Provides end-to-end accountability for e-waste recycling. The standard prohibits toxic waste from being disposed of in solid waste landfills and incinerators, and requires full compliance with international hazardous waste treaties for export/import of electronics among others.',
      link: 'http://www.e-stewards.org/',
    },
    {
      id: 2,
      title: 'The Responsible Recycling (R2) Standard for Electronic Recyclers',
      logo: 'assets/images/rating-system/logo_se.png',
      whatItDoes:
        'Provides a set of voluntary principles and guidelines designed to promote and assess responsible practices for electronics recyclers.',
      link: 'https://www.nsf.org/knowledge-library/responsible-recycling-r2',
    },
  ],
  ['FOOD']: [
    {
      id: 1,
      title: 'Fair Trade Certification',
      logo: 'assets/images/rating-system/logo_fairtrade.png',
      whatItDoes:
        'Certifies that members commit to biodiversity-enhancing practices, ensuring children’s rights, supporting safe working conditions and other fair-trade measures, and documenting fair-trade labor policies.',
      link: 'http://fairtradeusa.org/certification',
    },
    {
      id: 2,
      title: 'Marine Stewardship Council Certified',
      logo: 'assets/images/rating-system/logo_msc.png',
      whatItDoes:
        'Ensures that MSC-labeled seafood comes from, and can be traced back to, a sustainable fishery. The science-based MSC environmental standard for sustainable fishing offers fisheries a way to confirm sustainability, using a credible, independent, third-party assessment process.',
      link: 'http://www.msc.org/',
    },
    {
      id: 3,
      title: 'Non-GMO Verified',
      logo: 'assets/images/rating-system/logo_ngmo.png',
      whatItDoes:
        'Verifies that all ingredients (though not all final products) are tested for genetically modified organisms and may contain no more than 0.9 percent GMO.',
      link: 'http://www.nongmoproject.org/',
    },
    {
      id: 4,
      title: 'Oxfam’s Behind the Brand',
      logo: 'assets/images/rating-system/logo_oxfam.png',
      whatItDoes:
        'Provides a social good rating scheme which reviews big food brands and provides information about the companies policies on land, women, farmers, workers, climate and transparency.',
      link: 'https://www.oxfamamerica.org/explore/issues/humanitarian-response-and-leaders/hunger-and-famine/behind-the-brands/',
    },
    {
      id: 5,
      title: 'Rainforest Alliance Certified',
      logo: 'assets/images/rating-system/logo_rac.png',
      whatItDoes:
        'Assures consumers that the product they are purchasing has been grown and harvested using environmentally and socially responsible practices. Farms and forestlands that meet the rigorous, third-party standards of the Sustainable Agriculture Network or the Forest Stewardship Council are awarded the Rainforest Alliance Certified seal.',
      link: 'http://www.rainforest-alliance.org/certification-verification',
    },
    {
      id: 6,
      title: 'USDA National Organic Program',
      logo: 'assets/images/rating-system/logo_usda.png',
      whatItDoes:
        'Certifies that products, and their ingredients, are produced without the use of synthetic pesticides and fertilizers, antibiotics, hormones, genetically modified organisms or radiation. To obtain USDA Organic certification, a product must contain at least 95% certified organic ingredients.',
      link: 'https://www.ams.usda.gov/grades-standards',
    },
  ],
  ['FURNISHING']: [
    {
      id: 1,
      title: 'Carpet & Rug Institute',
      logo: 'assets/images/rating-system/logo_carpetrug.png',
      whatItDoes:
        'Establishes the highest standard for indoor air quality (IAQ) set by the carpet industry and identifies carpets and adhesives that are tested by an independent, certified laboratory to meet stringent criteria for low chemical emissions. The label ensures that customers are purchasing the very lowest emitting products on the market.',
      link: 'https://www.carpet-rug.org/CRI-Testing-Programs/Green-Label-Plus.aspx',
    },
    {
      id: 2,
      title: 'Forest Stewardship Council Chain-of-Custody Certification (FSC)',
      logo: 'assets/images/rating-system/logo_fsc.png',
      whatItDoes:
        'Certifies environmentally appropriate, socially beneficial, and economically viable management of the world’s forests.',
      link: 'https://us.fsc.org/',
    },
    {
      id: 3,
      title: 'GreenGuard',
      logo: 'assets/images/rating-system/logo_greenguard.png',
      whatItDoes:
        'Certifies products are scientifically proven to meet some of the world’s most rigorous, third-party chemical emissions standards - helping reduce indoor air pollution and the risk of chemical exposure while aiding in the creation of healthier indoor environments.',
      link: 'https://www.ul.com/resources/ul-greenguard-certification-program',
    },
    {
      id: 4,
      title: 'ISO 14001',
      logo: 'assets/images/rating-system/logo_iso.png',
      whatItDoes:
        'Certifies the company meets ISO environmental management standards, which require a commitment to minimizing environmental impact and improving environmental performance.',
      link: 'http://www.iso.org/',
    },
    {
      id: 5,
      title: 'Level',
      logo: 'assets/images/rating-system/logo_level.png',
      whatItDoes:
        'Identifies that a furniture product has been evaluated to the multi-attribute ANSI/BIFMA e3 Furniture Sustainability Standard by an independent, third-party certifier. Products are evaluated on a point-based system that spans social responsibility, energy use, materials selection, and human and ecosystem health impacts.',
      link: 'http://levelcertified.org/',
    },
    {
      id: 6,
      title: 'MAS Certification',
      logo: 'assets/images/rating-system/logo_mas.png',
      whatItDoes:
        'Provides independent emissions product testing and certification of a wide variety of residential and commercial building products through a nationally recognized MAS Certified Green� emissions testing program, using small, intermediate and large test chambers.',
      link: 'http://www.mascertifiedgreen.com/',
    },
    {
      id: 7,
      title: 'SCS Indoor Advantage',
      logo: 'assets/images/rating-system/logo_scs.png',
      whatItDoes:
        'Demonstrates that products meet indoor air quality standards pertaining to emissions that may be harmful to human health and the environment.',
      link: 'http://www.scsglobalservices.com/certified-indoor-air-quality',
    },
    {
      id: 8,
      title:
        'SMaRT (Sustainability Materials Rating Technology) from MTS (Market Transformation to Sustainability)',
      logo: 'assets/images/rating-system/logo_smart.png',
      whatItDoes:
        'Certifies by using data from a third-party-verified life cycle assessment to evaluate products across 41 attributes and 20 environmental impacts. It encourages owners and manufacturers of products to implement sustainable design and manufacturing practices, and to reduce the environmental impact of their products over their functional life-cycles.',
      link: 'http://mts.sustainableproducts.com/SMaRT_product_standard.html',
    },
  ],
  ['MULTI-VARIATE RATING SYSTEMS']: [
    {
      id: 1,
      title: 'B CORPS',
      logo: 'assets/images/rating-system/logo_bcorps.png',
      whatItDoes:
        'Certifies that the corporation has meet rigorous standards of social and environmental performance, accountability, and transparency.',
      link: 'https://www.bcorporation.net/en-us',
    },
    {
      id: 2,
      title: 'CSRHUB',
      logo: 'assets/images/rating-system/logo_csrhub.png',
      whatItDoes:
        'Provides access to corporate social responsibility and sustainability ratings and information on over 8,000 companies from 135 industries in 103 countries. Allows users to benchmark company performance, learn how stakeholders evaluate company CSR practices and seek ways to change the world.',
      link: 'http://www.csrhub.com/content/about-csrhub/',
    },
    {
      id: 3,
      title: 'Green America Seal of Approval - Gold Level',
      logo: 'assets/images/rating-system/logo_greenamerica.png',
      whatItDoes:
        'Assures that the business behind this product operates in ways that solve, rather than cause, both environmental and social problems. Pioneering businesses who show leadership in their industry and embed social responsibility into the DNA of their company earn Gold level certification.',
      link: 'http://www.greenamerica.org/greenbusiness/certification.cfm',
    },
    {
      id: 4,
      title: 'ISO 14001',
      logo: 'assets/images/rating-system/logo_iso.png',
      whatItDoes:
        'Certifies the company meets ISO environmental management standards, which require a commitment to minimizing environmental impact and improving environmental performance.',
      link: 'http://www.iso.org/',
    },
    {
      id: 5,
      title: 'Sourcemap',
      logo: 'assets/images/rating-system/logo_sourcemap.png',
      whatItDoes:
        'Provides information on carbon footprints, ingredient quality, water footprint, eco-labeling, the presence of forced labor, and conflict minerals by using a new supply chain tracking system.',
      link: 'http://sourcemap.com/',
    },
  ],
  ['NGOS']: [
    {
      id: 1,
      title: 'Charity Navigator',
      logo: 'assets/images/rating-system/logo_charitynav.png',
      whatItDoes:
        'Rates by examining two broad areas of a charity’s performance: financial health, accountability, and transparency.',
      link: 'http://www.charitynavigator.org/',
    },
    {
      id: 2,
      title: 'Guide Star',
      logo: 'assets/images/rating-system/logo_guidestar.png',
      whatItDoes:
        'Gathers and disseminates information about all IRS-registered nonprofit organizations that advances transparency, enables users to make better decisions, and encourages charitable giving while remaining neutral and without providing a rating.',
      link: 'http://www.guidestar.org/',
    },
  ],
  ['WOOD/PAPER']: [
    {
      id: 1,
      title: 'Forest Stewardship Council Chain-of-Custody Certification (FSC)',
      logo: 'assets/images/rating-system/logo_fsc.png',
      whatItDoes:
        'Certifies environmentally appropriate, socially beneficial, and economically viable management of the world’s forests.',
      link: 'https://ic.fsc.org/chain-of-custody-certification.39.htm',
    },
    {
      id: 2,
      title: 'Processed Chlorine Free',
      logo: 'assets/images/rating-system/logo_pcf.png',
      whatItDoes:
        'Indicates that paper has not been re-bleached with chlorine-containing compounds, which result in the release of carcinogenic dioxin into the environment. A minimum of 30 percent post-consumer content is required. The certification also considers energy and water consumption as well as emissions of heat-trapping pollutants. All fiber must be FSC chain-of-custody certified.',
      link: 'http://www.chlorinefreeproducts.org/',
    },
    {
      id: 3,
      title: 'Recycled Content Certification',
      logo: 'assets/images/rating-system/logo_rcc.png',
      whatItDoes:
        'Identifies the percentage of pulp derived from consumer-recycled paper waste. Standards require that manufacturers seek the highest percentage possible to maximize efficient reuse of paper.',
      link: 'http://www.scsglobalservices.com/recycled-content-certification',
    },
    {
      id: 4,
      title: 'SCS Certified',
      logo: 'assets/images/rating-system/logo_scsrf.png',
      whatItDoes:
        'Ensures that products have met FSC standards from raw materials through processing, transformation, manufacturing, and distribution.',
      link: 'http://www.scsglobalservices.com/',
    },
  ],
};
