import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  CardMedia,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  clearState,
  promoSelector,
  getPromoItem,
} from 'store/promos/PromoSlice';
import classes from './styles';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import DOMPurify from 'dompurify';
import { redeemPromo } from 'services/api';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';
import { NotFound } from 'pages';
import BackButton from 'components/BackButton';

const couponsAmountOptions = (amount) => {
  return Array.from({ length: amount }, (val, key) => {
    return {
      id: key + 1,
      value: key + 1,
    };
  });
};

const RedeemDetails = () => {
  const { isFetching, isSuccess, isError, error, promo, pipsAvailable } =
    useSelector(promoSelector);
  const [promoOptionId, setPromoOptionId] = useState('');
  const [pipsAfterPurchase, setPipsAfterPurchase] = useState(null);
  const [priceInPips, setPriceInPips] = useState(0);
  const [priceInPipsTotal, setPriceInPipsTotal] = useState(0);
  const [errorInPips, setErrorInPips] = useState(false);
  const [disabledState, setDisabledState] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [couponsAmount, setCouponsAmount] = useState(1);
  const [couponsAmountValue, setCouponsAmountValue] = useState(1);
  const [couponsAmountChoices, setCouponsAmountChoices] = useState([]);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(getPromoItem({ id: params.id }));
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleOptionChange = (event) => {
    setPromoOptionId(event.target.value);
    const option = promo.redeemOptions.find(
      (el) => el.id === event.target.value
    );

    if (option.numberLeft > 1) {
      const choices = couponsAmountOptions(option.numberLeft);
      setCouponsAmount(option.numberLeft);
      setCouponsAmountChoices(choices);
    } else {
      setCouponsAmount(1);
      setCouponsAmountValue(1);
    }

    setPriceInPips(option.priceInPips);
    setPriceInPipsTotal(option.priceInPips);
    if (option.priceInPips < pipsAvailable) {
      const pipsValue = pipsAvailable - option.priceInPips;
      setPipsAfterPurchase(pipsValue);
      setHasError(false);
      setErrorInPips(false);
    } else {
      setPipsAfterPurchase(pipsAvailable);
      setErrorInPips(true);
    }
  };

  const handleAmountChange = (e) => {
    setCouponsAmountValue(e.target.value);
    setPriceInPipsTotal(e.target.value * priceInPips);
    const pipsValue = pipsAvailable - e.target.value * priceInPips;
    setPipsAfterPurchase(pipsValue);
  };

  const handleProceed = () => {
    if (!promoOptionId) {
      setHasError(true);
    } else {
      setDisabledState(true);
    }
  };

  const handleRedeemPromo = () => {
    const body = {
      promoId: promo.id,
      redeemOptionId: promoOptionId,
      amount: couponsAmountValue,
    };
    redeemPromo({ body })
      .then((res) => {
        if (res.status === 'OK') {
          navigate('/promo/redeem-success');
        } else {
          handleError(res);
        }
      })
      .catch((err) => handleError(err.response.data));
  };

  if (isFetching) return <Preloader />;
  if (!Object.keys(promo).length) return <NotFound />;

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Box sx={classes.detailsHeader}>
          <BackButton />
          {/*<Typography variant="caption" sx={classes.categoryName}>*/}
          {/*  {promo.categoryName}*/}
          {/*</Typography>*/}
          <Typography
            variant="h2"
            color="primary"
            mb={1}
            sx={classes.promoTitle}
          >
            {promo.promoName}
          </Typography>
          <Typography variant="caption">
            {promo.promoShortDescription}
          </Typography>
        </Box>
        <Box sx={classes.content}>
          <Box sx={classes.image}>
            <CardMedia component="img" src={promo.imageUrl} />
          </Box>
          <Box sx={classes.description}>
            <Typography
              mb={2}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(promo.promoLongDescription),
              }}
            />
            <Typography mb={2} display="flex" justifyContent="space-between">
              Available PIPs: <span>{pipsAvailable.toLocaleString()}</span>
            </Typography>
            {promo.redeemOptions && promo.redeemOptions.length ? (
              <>
                <Box sx={classes.selectWrapper}>
                  <Box>
                    <FormControl sx={classes.redeemSelect}>
                      <InputLabel id="pips-select-label">
                        Select Value
                      </InputLabel>
                      <Select
                        labelId="pips-select-label"
                        id="pips-select"
                        value={promoOptionId}
                        label="Select Value"
                        onChange={handleOptionChange}
                        disabled={disabledState}
                      >
                        {promo.redeemOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            sx={classes.redeemSelectOption}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {hasError && (
                      <Typography color={palette.error.main} ml={2}>
                        Please select value
                      </Typography>
                    )}
                    {couponsAmount > 1 && (
                      <FormControl sx={classes.amountSelect}>
                        <InputLabel id="amount-select-label">
                          Select Amount
                        </InputLabel>
                        <Select
                          labelId="amount-select-label"
                          id="amount-select"
                          value={couponsAmountValue}
                          label="Select Amount"
                          onChange={handleAmountChange}
                          disabled={disabledState}
                        >
                          {couponsAmountChoices.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              sx={classes.redeemSelectOption}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {disabledState && (
                      <Button
                        color="secondary"
                        onClick={() => setDisabledState(false)}
                        sx={classes.editBtn}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                  {priceInPipsTotal ? (
                    priceInPipsTotal < pipsAvailable ? (
                      <Typography color={palette.error.main}>
                        - {priceInPipsTotal.toLocaleString()}
                      </Typography>
                    ) : (
                      <Typography color={palette.error.main}>
                        Not enough PIPs
                      </Typography>
                    )
                  ) : null}
                </Box>
                <Typography
                  mb={2}
                  display="flex"
                  justifyContent="space-between"
                >
                  PIPs after purchase:{' '}
                  <span>
                    {pipsAfterPurchase && pipsAfterPurchase.toLocaleString()}
                  </span>
                </Typography>
                {!disabledState && !errorInPips && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleProceed}
                    disabled={hasError}
                  >
                    Proceed To Checkout
                  </Button>
                )}
                {disabledState && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleRedeemPromo}
                  >
                    Complete Purchase
                  </Button>
                )}
                {errorInPips && (
                  <Box>
                    <Typography variant="h5" color={palette.text.primary}>
                      Save up some PIPS for this
                    </Typography>
                    <Typography>
                      You have {pipsAvailable}/{priceInPips} PIPs needed
                    </Typography>
                    <Typography>
                      Need more PIPs? Discover lots of ways to earn LOTS of PIPs
                      at <Link href="/promo/earn">pipsrewards.com/earn</Link>
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Typography color={palette.text.primary}>
                Sorry, for now there are no options available
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default RedeemDetails;
