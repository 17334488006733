import React from 'react';

export const slides = [
  {
    title: (
      <>
        Get rewarded for the good
        <br /> you do everyday!
      </>
    ),
    titleMarginBottom: 2,
    image: 'assets/images/working-pips/1.png',
    imageWidth: 330,
    imageHeight: 250,
    imageMarginBottom: 3,
    bgColor: '#e9b152',
    description:
      'Earn PIPs (Positive Impact Points) by taking actions that promote personal, community, and planetary health. Then, convert them into scholarships grants and gift cards.',
  },
  {
    title: (
      <>
        How are PIPs <br /> Earned?
      </>
    ),
    titleMarginBottom: 3,
    image: 'assets/images/working-pips/2.png',
    imageWidth: 290,
    imageHeight: 266,
    imageMarginBottom: 1,
    bgColor: '#e57d5a',
    description:
      'You can earn PIPs for riding the bus, biking, walking, recycling, volunteering, completing a wellness training, and more. That’s right, you can earn PIPs for things you do everyday.',
  },
  {
    title: (
      <>
        What will I be able to do <br /> with my PIPs?
      </>
    ),
    titleMarginBottom: 0,
    image: 'assets/images/working-pips/3.png',
    imageWidth: 340,
    imageHeight: 300,
    imageMarginBottom: 2,
    bgColor: '#cce1a6',
    description:
      'This is the best part. With your heard-earned PIPs, you’ll be able to unlock scholarship grants and gift cards!',
  },
  {
    title: (
      <>
        How are actions <br /> verified?
      </>
    ),
    titleMarginBottom: 3,
    image: 'assets/images/working-pips/4.png',
    imageWidth: 300,
    imageHeight: 210,
    imageMarginBottom: 3,
    bgColor: '#57cfd1',
    description:
      'PIPs uses a mix of action tracking tools, some in app, to capture and reward your behavior.\n' +
      'Including: Machine Learning (via in app sensors), APIs, NFC devices, beacons, sensors, QR Codes, transaction reports.',
  },
  {
    title: <>Are there games?</>,
    titleMarginBottom: 4,
    image: 'assets/images/working-pips/5.png',
    imageWidth: 340,
    imageHeight: 290,
    imageMarginBottom: 3,
    bgColor: '#84c8c0',
    description:
      'Another great question! Yes - The PIPs app comes packed with games for groups to play, including trivia contests, a fitness challenge, a climate challenge, and scavenger hunts.',
  },
  {
    title: (
      <>
        So it’s like &apos;360 degrees of good&apos;
        <br /> in your pocket, right?
      </>
    ),
    titleMarginBottom: 3,
    image: 'assets/images/working-pips/6.png',
    imageWidth: 310,
    imageHeight: 240,
    imageMarginBottom: 2,
    bgColor: '#35be72',
    description:
      'Wow - exactly! We couldn’t have said it better ourselves. PIPs is truly a self-nurturing, planet-protecting, socially-conscious ‘360 degree cycle of good’. So what are you waiting for? Start earning today!',
  },
  {
    title: <>What are users saying about PIPs?</>,
    titleMarginBottom: 3,
    image: 'assets/images/working-pips/7.png',
    imageWidth: 308,
    imageHeight: 347,
    imageMarginBottom: 2,
    bgColor: '#83cc99',
    description:
      'We love hearing from our users so that we can insure that their experience is the best it can be!',
  },
  {
    title: (
      <>
        Have a question or <br /> need some help?
      </>
    ),
    titleMarginBottom: 2,
    image: 'assets/images/working-pips/8.png',
    imageWidth: 350,
    imageHeight: 220,
    imageMarginBottom: 1,
    bgColor: '#83cc99',
    description:
      'Our FAQ Page is a great place to start! If that doesn’t solve your problem, send us an email.',
    isFAQ: true,
  },
];
