import { PADDING_MAIN, PADDING_MAIN_MD } from 'config/ui';

const classes = {
  root: {
    fontSize: '18px',
    paddingTop: '50px',
    background: '#fff',
    paddingBottom: `${PADDING_MAIN}px`,
    paddingLeft: '15px',
    paddingRight: '15px',
    margin: '0 -16px',

    '@media (min-width: 768px)': {
      paddingLeft: `${PADDING_MAIN_MD}px`,
      paddingRight: `${PADDING_MAIN_MD}px`,
      margin: 0,
    },
    '@media (min-width: 1280px)': {
      paddingLeft: `${PADDING_MAIN}px`,
      paddingRight: `${PADDING_MAIN}px`,
    },
    '& p': {
      marginBottom: 2,
    },
    '& a': {
      color: 'primary.main',
      textDecoration: 'underline',
    },
    '& h5': {
      marginTop: 3,
      marginBottom: 1,
    },
  },
};
export default classes;
