const classes = {
  root: {
    '& div.MuiFormControl-root': {
      marginRight: '3px',
    },
    '& button': {
      marginRight: '3px',
    },
  },
  tabs: {
    marginBottom: '6px',
  },
};

export default classes;
