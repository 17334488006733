const classes = {
  root: {
    paddingTop: '45px',
    paddingBottom: '45px',
    background: '#fff',
    '@media (min-width: 1024px)': {
      paddingTop: '70px',
      paddingBottom: '125px',
    },
  },
  title: {
    '@media (min-width: 1024px)': {
      fontSize: '52px',
    },
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginRight: '-30px',
    '@media (min-width: 768px)': {},
    '@media (min-width: 1024px)': {
      margin: '0 -45px',
    },
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    boxShadow: '0px 3px 6px #00000029',
    border: '1px solid #70707026',
    borderRadius: '10px',
    padding: '15px 45px 15px 60px',
    margin: '0 30px 30px',
    '@media (min-width: 768px)': {
      flex: '0 0 40%',
    },
    '@media (min-width: 1024px)': {
      flex: '0 0 25%',
      margin: '0 45px 30px',
      padding: '45px 10px 45px 60px',
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '-40px',
    marginTop: '-40px',
    width: '80px',
    height: '80px',
    borderWidth: '4px',
    borderStyle: 'solid',
    borderRadius: '50%',
    background: '#fff',
    '& img': {
      objectFit: 'contain',
      width: 'auto',
    },
  },
};

export default classes;
