import { HEADER_DESKTOP_HEIGHT } from 'config/ui';

const classes = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    '& main': {
      flex: '1 0 auto',
      zIndex: 1,
      paddingTop: `${HEADER_DESKTOP_HEIGHT}px`,
      '@media (min-width: 1024px)': {
        paddingTop: `${HEADER_DESKTOP_HEIGHT * 2}px`,
      },
    },
  },
  container: {
    position: 'relative',
    height: '100%',
    '@media (min-width: 1024px)': {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
};

export default classes;
