import quotes from 'assets/icons/quotes.svg';

export const arrowPositionX = 15;
export const quotePositionX = 0;

const classes = {
  root: {
    '& .carousel-slider': {
      paddingBottom: '70px',
    },
  },
  avatar: {
    width: '80px',
    height: '80px',
    margin: '0 auto 24px',
    '& svg': {
      width: '50px',
      height: '50px',
    },
  },
  avatarBroken: {
    opacity: 0.5,
  },
  testimonial: {
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      backgroundRepeat: 'no-repeat',
      width: '70px',
      height: '70px',
      backgroundSize: 'contain',
      bottom: '36px',
      backgroundImage: `url(${quotes})`,
      opacity: '0.5',
    },
    '&::before': {
      left: quotePositionX,
      transform: 'scale(-1, -1)',
    },
    '&::after': {
      right: quotePositionX,
    },
  },
  arrow: {
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    width: '43px',
    height: '43px',
    cursor: 'pointer',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  arrowStyles: {
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    width: '45px',
    height: '45px',
    cursor: 'pointer',
    bgcolor: 'text.disabled',
    color: 'white',
    '&.MuiButtonBase-root.MuiIconButton-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  indicatorStyles: {
    cursor: 'pointer',
    bgcolor: 'text.disabled',
    width: '14px',
    height: '14px',
    display: 'inline-block',
    margin: '0 4px',
    borderRadius: '50%',
    '@media (min-width: 768px)': {
      margin: '0 8px',
    },
  },
};

export default classes;
