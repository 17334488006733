import React from 'react';

export const badgesDetails = [
  {
    type: 'TRAILBLAZER',
    title: 'Trailblazer',
    iconName: '01_Pioneer',
    inActiveMessage:
      'Set your sites on the Trailblazer badge, earned by reaching the Walking for PIPs steps threshold every day for two straight weeks.',
    activeMessage:
      'You got it. The Trailblazer badge. For reaching the Walking for PIPs steps threshold every day for two straight weeks.',
  },
  {
    type: 'RINGLEADER',
    title: 'Ringleader',
    iconName: '02_Ringleader',
    inActiveMessage:
      'Get FOUR friends to join PIPs to earn the Ringleader badge.',
    activeMessage: (
      <>
        Popular! Huh? You&apos;re the proud owner of the Ringleader badge for
        getting FOUR friends to join PIPs.
      </>
    ),
  },
  {
    type: 'BENEFICENT_BEE',
    title: 'Beneficent Bee',
    iconName: '03_BeneficentBee',
    inActiveMessage:
      'The Beneficent Bee badge goes to the student who does 10 community-minded actions in a month.',
    activeMessage:
      'You are a leader, and deserving of the Beneficent Bee badge.',
  },
  {
    type: 'BONUS_BADGER',
    title: 'Bonus Badger',
    iconName: '04_BonusBadger',
    inActiveMessage:
      'Earn any THREE badges to get the Bonus Badger badge and unlock extra bonus PIPs for you to earn.',
    activeMessage:
      'Wowser! You earned the Bonus Badger badge. Be on the lookout for extra bonus PIPs.',
  },
  {
    type: 'SHERLOCK',
    title: 'Sherlock',
    iconName: '05_Sherlock',
    inActiveMessage:
      'Earn the Sherlock badge for answering 10 questions right.',
    activeMessage:
      'The Sherlock badge is yours for answering 10 questions right.',
  },
  {
    type: 'EINSTEIN',
    title: 'Einstein',
    iconName: '06_Einstein',
    inActiveMessage:
      'Answer 8 questions right IN A ROW to earn the Einstein badge.',
    activeMessage:
      'Your noggin is sharp! You answered 8 questions RIGHT IN A ROW and earned the Einstein badge.',
  },
  {
    type: 'LUCKY_LOSER',
    title: 'Lucky Loser',
    iconName: '07_LuckyDog',
    inActiveMessage: (
      <>
        Top the Climate Challenge leaderboard (showing lbs CO<sub>2</sub>{' '}
        reduced) for 2 weeks straight to earn the Lucky Loser badge.
      </>
    ),
    activeMessage: (
      <>
        Here&apos;s to you, Lucky Loser, for keeping your carbon emissions
        numbers down and your rank on the leaderboard up, up, up.
      </>
    ),
  },
  {
    type: 'BREADWINNER',
    title: 'Breadwinner',
    iconName: '08_Breadwinner',
    inActiveMessage:
      'The Busy Breadwinner badge goes to those who make FIVE DIFFERENT PIPs-earning actions in a week.',
    activeMessage:
      'You got the Busy Breadwinner badge for making FIVE DIFFERENT PIPs-earning actions in a week.',
  },
  {
    type: 'PIPSTER',
    title: 'PIPster',
    iconName: '09_Freak',
    inActiveMessage:
      'Convert your PIPs into a Scholarship to earn the PIPster badge.',
    activeMessage: (
      <>
        You&apos;re rockin&apos; it, PIPster. You got a scholarship grant and
        this sweet badge too.
      </>
    ),
  },
  {
    type: 'BRONZE',
    title: 'Bronze',
    iconName: '10_Bronze',
    inActiveMessage: 'Earn a total of 5,000 PIPs to get the Bronze badge.',
    activeMessage: '5,000 PIPs makes you a Bronze badge owner.',
  },
  {
    type: 'SILVER',
    title: 'Silver',
    iconName: '11_Silver',
    inActiveMessage: 'Earn a total of 25,000 PIPs to get the Silver badge.',
    activeMessage: <>No you didn&apos;t? 25,000 PIPs. Silver badge central!</>,
  },
  {
    type: 'GOLD',
    title: 'Gold',
    iconName: '12_Gold',
    inActiveMessage: 'Earn a total of 100,000 PIPs to get the Gold badge.',
    activeMessage: '100,000 PIPs! Incredible. Wear your Gold badge with pride.',
  },
];
