import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { updateQuestionnaire, getQuestionnaireByID } from 'services/api';
import { handleError } from 'services/handleError';
import { showSuccessNotification } from 'services/notifications';
import LayoutAdmin from 'components/LayoutAdmin';
import palette from 'styles/theme/palette';
import Preloader from 'components/Preloader';
import BackButton from 'components/BackButton';
import { FormBuilder } from 'components/FormBuilder';
import classes from './styles';

const AdminQuestionnaireDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [questionnaire, setQuestionnaire] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [autoEarning, setAutoEarning] = useState(false);

  const handleSubmit = (body) => {
    setIsFetching(true);
    updateQuestionnaire({ id: params.id, body })
      .then((res) => {
        setIsFetching(false);
        if (res.status === 'OK') {
          showSuccessNotification('Questionnaire was updated successfully');
          navigate('/admin/questionnaires');
        } else {
          handleError(res);
        }
      })
      .catch((err) => {
        setIsFetching(false);
        handleError(err.response.data);
      });
  };

  useEffect(() => {
    setIsFetching(true);
    getQuestionnaireByID(params.id)
      .then((res) => {
        if (res.status === 'OK' && res?.questionnaire) {
          const data = res.questionnaire;
          setQuestionnaire(JSON.parse(data.payload));
          setTitle(data.name);
          setDescription(data.description);
          setAutoEarning(data.autoEarning);
        } else {
          handleError(res);
          navigate('/admin/questionnaires');
        }
        setIsFetching(false);
      })
      .catch((err) => {
        handleError(err.response.data);
        setIsFetching(false);
      });
  }, []);

  return (
    <LayoutAdmin bgColor={palette.background.default}>
      {isFetching && (
        <Box sx={classes.preloaderWrapper}>
          <Preloader opacity />
        </Box>
      )}
      <Box maxWidth="lg" sx={classes.root}>
        <Box sx={classes.backButtonWrapper}>
          <BackButton />
        </Box>
        <Box sx={classes.wrapper}>
          <FormBuilder
            name={title}
            text={description}
            questionnaire={questionnaire}
            autoEarning={autoEarning}
            onSubmit={(data) => handleSubmit(data)}
          />
        </Box>
      </Box>
    </LayoutAdmin>
  );
};

export default AdminQuestionnaireDetails;
