export default {
  styleOverrides: {
    root: {
      textAlign: 'center',
      fontSize: 26,
      color: '#3A3331',
      position: 'relative',
      '& .close-btn': {
        position: 'absolute',
        right: 14,
        top: 14,
      },
    },
  },
};
