import React from 'react';
import { Box } from '@mui/material';

import classes from './styles';

const HandsBucketBoat = ({ id, shouldShowContent, isMacOS }) => (
  <Box
    id={id}
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="flex-end"
    sx={{
      ...classes.root,
      background: isMacOS ? 'rgb(198,245,196)' : 'rgb(189,244,186)',
    }}
  >
    {shouldShowContent && (
      <Box component="video" autoPlay muted>
        <source src="/assets/animations/handsBucketBoat.mp4" type="video/mp4" />
      </Box>
    )}
  </Box>
);

export default HandsBucketBoat;
