import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import useToken from 'hooks/useToken';

const PrivateRoute = ({ children }) => {
  const { token } = useToken();
  const params = useParams();
  if (params.th_token) localStorage.setItem('th_token', params.th_token);

  return token ? children : <Navigate to="/?showLogin" />;
};

export default PrivateRoute;
