import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Box,
  Button,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { donateOneTimePromo } from 'services/api';
import palette from 'styles/theme/palette';
import classes from './styles';
import { FormValidationSchema } from './settings';
import { handleError } from 'services/handleError';

const OneTimeDonation = ({
  showOneTimeDonation,
  showPledgeDonation,
  pipsAvailable,
  promoId,
  handleSuccessMessage,
  promoType,
}) => {
  const [pipsToCommit, setPipsToCommit] = useState(1);
  const [pipsAfterPurchase, setPipsAfterPurchase] = useState(null);

  useEffect(() => {
    setPipsAfterPurchase(pipsAvailable - pipsToCommit);
  }, [pipsAvailable]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oneTimeCommitment: 1,
    },
    resolver: yupResolver(FormValidationSchema),
    mode: 'onChange',
  });
  const watchedFormFields = watch(['oneTimeCommitment']);

  const handlePipsToCommitChange = (val) => {
    setPipsToCommit(val);
    const pipsValue = pipsAvailable - val;
    setPipsAfterPurchase(pipsValue);
  };

  const onSubmit = handleSubmit((data) => {
    const body = {
      promoId,
      pips: data.oneTimeCommitment,
    };
    donateOneTimePromo({ body })
      .then((res) => {
        if (res.status === 'OK') {
          handleSuccessMessage('Success!');
        } else {
          handleError(res);
        }
      })
      .catch((err) => handleError(err.response.data));
  });

  const isFormFilledCorrectly = watchedFormFields.every((val) => Boolean(val));

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Typography variant="h4">Give With PIPs</Typography>
      {promoType === 'CHARITABLE' ? (
        <Typography>
          Using the spinner below, select the number of PIPs you want to donate.{' '}
          <Typography component="span" color={palette.info.light}>
            Note, a PIP = a penny.
          </Typography>
        </Typography>
      ) : (
        <Typography>
          Using the spinner below, select the number of PIPs you want to commit.{' '}
          <Typography component="span" color={palette.info.light}>
            Note, a PIP = a penny.
          </Typography>
        </Typography>
      )}

      <Typography>
        {promoType === 'CHARITABLE' ? (
          <span>
            *If you don&apos;t have enough PIPs to make the donation you wish to
            make now,
          </span>
        ) : (
          <span>
            *If you don&apos;t have enough PIPs to make the commitment you wish
            to make now,
          </span>
        )}{' '}
        <Link
          onClick={() => {
            showOneTimeDonation(false);
            showPledgeDonation(true);
          }}
        >
          click here to pledge
        </Link>{' '}
        a certain percentage of future-earned PIPs.
      </Typography>
      <Box sx={classes.donationBox}>
        <Typography>
          Available PIPs: <strong>{pipsAvailable.toLocaleString()}</strong>
        </Typography>
        <Box sx={classes.inputWrapper}>
          {promoType === 'CHARITABLE' ? (
            <Typography>PIPs to donate:</Typography>
          ) : (
            <Typography>PIPs to commit:</Typography>
          )}

          <Controller
            name="oneTimeCommitment"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                control={
                  <TextField
                    name={name}
                    value={value}
                    fullWidth
                    inputProps={{
                      autoComplete: 'off',
                      min: 1,
                      max: pipsAvailable,
                    }}
                    type="number"
                    error={Boolean(errors.oneTimeCommitment)}
                    helperText={
                      errors.oneTimeCommitment &&
                      errors.oneTimeCommitment.message
                    }
                    onChange={(e) => {
                      handlePipsToCommitChange(e.target.value);
                      onChange(e);
                    }}
                  />
                }
              />
            )}
          />
        </Box>
        {pipsAfterPurchase < 0 ? (
          <Typography color={palette.error.main}>Not enough PIPs</Typography>
        ) : (
          <Typography>
            PIPs after purchase:{' '}
            <strong>
              {pipsAfterPurchase && pipsAfterPurchase.toLocaleString()}
            </strong>
          </Typography>
        )}
      </Box>
      {promoType === 'CHARITABLE' ? (
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={!isFormFilledCorrectly || pipsAfterPurchase < 0}
        >
          Proceed with donation
        </Button>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={!isFormFilledCorrectly || pipsAfterPurchase < 0}
        >
          Commit {pipsToCommit} PIPs now
        </Button>
      )}
    </Box>
  );
};

export default OneTimeDonation;
