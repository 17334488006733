import React from 'react';
import { Box, CardMedia, Typography, Grid } from '@mui/material';
import classes from './styles';

const NoResultsPlaceholder = () => {
  return (
    <Grid container>
      <Box sx={classes.root}>
        <Typography variant="h5" textAlign="center" mb={3}>
          It looks like there are currently no active promos. Check back later!
        </Typography>
        <CardMedia component="img" src="/assets/images/ic_no_promos.png" />
      </Box>
    </Grid>
  );
};

export default NoResultsPlaceholder;
