import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CardMedia, Typography } from '@mui/material';
import palette from 'styles/theme/palette';

import useQuery from 'hooks/useQuery';

import Layout from 'components/Layout';
import { InvitingDialog } from 'components/dialogs';
import classes from './styles';

const InvitingFriend = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [invitingDialogVisible, setInvitingDialogVisible] = useState(
    query.get('referrer') && typeof query.get('referrer') === 'string'
  );

  const closeInvitingDialog = () => {
    setInvitingDialogVisible(false);
    navigate('/');
  };

  return (
    <Layout bgColor={palette.white}>
      {invitingDialogVisible ? (
        <InvitingDialog
          open={invitingDialogVisible}
          onClose={closeInvitingDialog}
        />
      ) : (
        <Box sx={classes.root}>
          <Typography variant="h3" textAlign="center" mb={3}>
            Page Not Found
          </Typography>
          <CardMedia
            sx={classes.image}
            component="img"
            src="/assets/images/ic_no_promos.png"
          />
        </Box>
      )}
    </Layout>
  );
};

export default InvitingFriend;
