const classes = {
  root: {
    position: 'relative',
    '& video': {
      height: '74vh',
    },
    '& p': {
      width: '100%',
      position: 'absolute',
      left: '50%',
      top: '53vh',
      transform: 'translateX(-50%)',
      zIndex: 1,
    },
  },
};

export default classes;
