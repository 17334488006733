import palette from 'styles/theme/palette';

export default {
  styleOverrides: {
    root: {
      height: 50,
      color: palette.text.grey,
      padding: '10px 10px',
      fontSize: 18,
      lineHeight: 1.2,
    },
  },
};
