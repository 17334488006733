import axios from 'axios';
import { showErrorNotification } from './notifications';
import { logoutUser } from 'store/user/UserSlice';
import { store } from 'store';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
export const AxiosInterceptorsSetup = (navigate) => {
  client.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        showErrorNotification('Session has been expired. Please log in again');
        store.dispatch(logoutUser());
        navigate('/?showLogin');
      }
      return Promise.reject(error);
    }
  );
};

export default client;
