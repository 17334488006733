import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
  useMatch,
} from 'react-router-dom';

import PrivateRoute from 'components/routes/PrivateRoute';
import AdminRoute from 'components/routes/AdminRoute';
import {
  UI,
  Home,
  Programs,
  Users,
  OurStory,
  Team,
  Registration,
  HowPipsWorks,
  RegistrationSuccess,
  ForgotPassword,
  ResetPassword,
  ResetPasswordSuccess,
  Dashboard,
  GiftCardDetails,
  Earn,
  PromoDetails,
  VerifyCode,
  Rewards,
  RedeemDetails,
  DonateDetails,
  EditProfile,
  RedeemSuccess,
  News,
  PrivacyPolicy,
  TermsOfService,
  FAQ,
  RatingSystem,
  GivePipsSuccess,
  NotFound,
  ThinkHumanIntegration,
  InvitingFriend,
  SwitchToUser,
  EarnPoints,
  Questionnaire,
  AdminQuestionnaires,
  AdminCreateQuestionnaire,
  AdminQuestionnaireDetails,
} from 'pages';
import CustomToastContainer from 'components/CustomToastContainer';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/user/UserSlice';
import TermsAcceptedDialog from './components/dialogs/TermsAcceptedDialog';
import ValidatePromoQueryParams from './components/ValidatePromoQueryParams';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isPrivacyPage = useMatch('/privacy');
  const isTermsPage = useMatch('/terms');
  const { user, isAuthenticated } = useSelector(userSelector);
  const verifiedUser = localStorage.getItem('verified');

  const [termsDialogVisible, setTermsDialogVisible] = useState(false);

  useEffect(() => {
    if (isAuthenticated && verifiedUser !== null && verifiedUser === 'false') {
      navigate('/verify-code');
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && !user.customTermsAccepted) {
      if (!isPrivacyPage && !isTermsPage) {
        setTermsDialogVisible(true);
      }
    }
  }, [location, user.customTermsAccepted]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="programs" element={<Programs />} />
        <Route path="users" element={<Users />} />
        <Route path="ui" element={<UI />} />
        <Route path="ourstory" element={<OurStory />} />
        <Route path="team" element={<Team />} />
        <Route path="registration" element={<Registration />} />
        <Route path="verify-code" element={<VerifyCode />} />
        <Route path="registration-success" element={<RegistrationSuccess />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="login" element={<InvitingFriend />} />
        <Route
          path="reset-password-success"
          element={<ResetPasswordSuccess />}
        />
        <Route path="news" element={<News />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<TermsOfService />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="rating-system" element={<RatingSystem />} />
        <Route
          path="how-pips-works"
          element={
            <PrivateRoute>
              <HowPipsWorks />
            </PrivateRoute>
          }
        />
        <Route
          path="profile/*"
          element={
            <PrivateRoute>
              <EditProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="dashboard/*"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="card/:id"
          element={
            <PrivateRoute>
              <GiftCardDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="promo/earn/*"
          element={
            <PrivateRoute>
              <Earn />
            </PrivateRoute>
          }
        />
        <Route
          path="promo/rewards/*"
          element={
            <PrivateRoute>
              <Rewards />
            </PrivateRoute>
          }
        />
        <Route
          path="promo/redeem/:id/*"
          element={
            <PrivateRoute>
              <RedeemDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="promo/redeem-success"
          element={
            <PrivateRoute>
              <RedeemSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path="promo/donate/:id/*"
          element={
            <PrivateRoute>
              <DonateDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="promo/:id"
          element={
            <PrivateRoute>
              <PromoDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="promo"
          element={
            <PrivateRoute>
              <ValidatePromoQueryParams />
            </PrivateRoute>
          }
        />
        <Route
          path="give-pips-success"
          element={
            <PrivateRoute>
              <GivePipsSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path="partnerlogin/thinkhumantv/merge/:th_token"
          element={
            <PrivateRoute>
              <ThinkHumanIntegration />
            </PrivateRoute>
          }
        />
        <Route path="switchtouser" element={<SwitchToUser />} />
        <Route path="earnpoints1" element={<EarnPoints />} />
        <Route
          path="admin/questionnaires"
          element={
            <AdminRoute>
              <AdminQuestionnaires />
            </AdminRoute>
          }
        />
        <Route path="admin" element={<Navigate to="questionnaires" />} />
        <Route
          path="admin/questionnaire"
          element={
            <AdminRoute>
              <AdminCreateQuestionnaire />
            </AdminRoute>
          }
        />
        <Route
          path="admin/questionnaires/:id"
          element={
            <AdminRoute>
              <AdminQuestionnaireDetails />
            </AdminRoute>
          }
        />
        <Route
          path="questionnaire/:id"
          element={
            <PrivateRoute>
              <Questionnaire />
            </PrivateRoute>
          }
        />
      </Routes>
      <CustomToastContainer />
      <TermsAcceptedDialog
        open={termsDialogVisible}
        onClose={() => setTermsDialogVisible(false)}
      />
    </>
  );
};

export default App;
