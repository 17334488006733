import React from 'react';
import { Box } from '@mui/material';

import { AnimatedText } from 'pages/Users/components';
import classes from './styles';

const Medal = ({ id, shouldShowContent, isMacOS }) => (
  <Box
    id={id}
    display="flex"
    alignItems="center"
    flexDirection="column"
    sx={{
      ...classes.root,
      background: isMacOS ? 'rgb(191,225,251)' : 'rgb(183,220,253)',
    }}
  >
    {shouldShowContent && (
      <>
        <Box component="video" autoPlay muted loop>
          <source src="/assets/animations/medal.mp4" type="video/mp4" />
        </Box>
        <AnimatedText text="Rewards you earn when you do good things" />
      </>
    )}
  </Box>
);

export default Medal;
