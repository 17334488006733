import { toast } from 'react-toastify';

export const showErrorNotification = (message) => {
  toast.error(message, {
    toastId: 'error',
    position: 'top-center',
    autoClose: 5000,
    closeOnClick: true,
    theme: 'colored',
    delay: 0,
  });
};

export const showSuccessNotification = (message) => {
  toast.success(message, {
    toastId: 'success',
    position: 'top-center',
    autoClose: 5000,
    closeOnClick: true,
    theme: 'colored',
  });
};

export const showInfoNotification = (message) => {
  toast.info(message, {
    toastId: 'info',
    position: 'top-center',
    autoClose: 5000,
    closeOnClick: true,
    theme: 'colored',
  });
};

export const showWarningNotification = (message) => {
  toast.warning(message, {
    toastId: 'warning',
    position: 'top-center',
    autoClose: 5000,
    closeOnClick: true,
    theme: 'colored',
  });
};
