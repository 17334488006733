import { alpha } from '@mui/material/styles';
import { grey } from 'styles/theme/palette';

export const PROMOS_HEIGHT = 540;

const classes = {
  root: {
    paddingBottom: '30px',
    background: 'white',
    '@media (min-width: 768px)': {
      paddingTop: '45px',
      paddingBottom: '190px',
    },
    '& .promo-slider': {
      margin: '0 -15px',
    },
  },
  promosWrapper: {
    height: `${PROMOS_HEIGHT}px`,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: 8,
    color: 'white',
  },
  mediaWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    transition: '0.2s',
    width: '100%',
  },
  media: {
    height: '400px',
    width: '100%',
    '@media (min-width: 768px)': {
      height: '540px',
    },
  },
  promoButtons: {
    position: 'relative',
    display: 'flex',
    '& li': {
      flex: 1,
      '&:not(:last-of-type)': {
        marginRight: '3px',
      },
    },
  },
  button: {
    cursor: 'pointer',
    width: '100%',
    background: alpha(grey, 0.8),
    position: 'relative',
    height: '180px',
    transition: '0.2s',
    transform: 'translateY(45px)',
    '@media (min-width: 768px)': {
      padding: '15px 15px 0',
    },
    '@media (min-width: 1280px)': {
      padding: '25px 40px 0',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '-10px',
      width: '100%',
      height: '10px',
      bgcolor: 'warning.main',
      transition: '0.3s',
      transform: 'scale(1,0)',
      transformOrigin: 'center bottom',
    },
    '& .link': {
      opacity: 0,
      visibility: 'none',
      transition: '0.3s',
    },
  },
  buttonActive: {
    transform: 'translateY(0)',
    background: alpha(grey, 0.9),
    '&:before': {
      transform: 'scale(1,1)',
    },
    '& .link': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  title: {
    marginBottom: '8px !important',
    display: 'inline-block',
  },
  headline: {
    lineHeight: '1.3 !important',
    marginBottom: '8px !important',
  },
  description: {
    maxWidth: '767px',
    margin: '0 auto',
  },
  promoText: {
    paddingTop: '60px',
    '@media (min-width: 768px)': {
      padding: 0,
    },
  },
  sliderItem: {
    position: 'relative',
  },
  sliderCaption: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    background: alpha(grey, 0.9),
    color: 'white',
    padding: '15px',
    borderTopWidth: '5px',
    borderTopStyle: 'solid',
    borderTopColor: 'warning.main',
  },
};

export default classes;
