import React from 'react';
import { useLocation } from 'react-router-dom';
import Layout from 'components/Layout';
import palette from 'styles/theme/palette';
import { Box, Typography } from '@mui/material';
import classes from './styles';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/user/UserSlice';
import BackButton from 'components/BackButton';

const GivePipsSuccess = () => {
  const { user } = useSelector(userSelector);
  const { state } = useLocation();
  const { pips, recipientName, recipientEmail, recipientMessage, navigateUrl } =
    state;

  return (
    <Layout bgColor={palette.white}>
      <BackButton navigateUrl={navigateUrl} givePipsSuccess />
      <Box sx={classes.root}>
        <Typography variant="h3" align="center">
          Nice{' '}
          <Typography sx={classes.mark} component="span">
            {user.firstName}
          </Typography>
          ,
        </Typography>
        <Box sx={classes.content}>
          <Typography align="center">
            You have just gifted{' '}
            <Typography sx={classes.mark} component="span">
              {pips} pips
            </Typography>{' '}
            to{' '}
            <Typography sx={classes.mark} component="span">
              {recipientName}
            </Typography>
          </Typography>
          <Typography align="center">
            An email has been sent to{' '}
            <Typography sx={classes.mark} component="span">
              {recipientEmail}
            </Typography>{' '}
            {recipientMessage && <span>with the following message:</span>}
          </Typography>
          {recipientMessage && (
            <Typography align="center" sx={classes.mark}>
              &quot;{recipientMessage}&quot;
            </Typography>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default GivePipsSuccess;
