import React from 'react';
import { Box } from '@mui/material';
import { ToastContainer, Slide } from 'react-toastify';
import classes from './styles';

const CustomToastContainer = () => (
  <Box sx={classes.root}>
    <ToastContainer transition={Slide} />
  </Box>
);

export default CustomToastContainer;
