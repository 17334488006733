import * as React from 'react';
import { Carousel as BaseCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IconButton, Box } from '@mui/material';

import { NextIcon } from 'assets/icons';
import classes, { arrowPositionX } from './styles';

const Carousel = ({ children, sx, ...props }) => (
  <Box sx={sx || classes.root}>
    <BaseCarousel
      swipeable={false}
      swipeScrollTolerance={100}
      showThumbs={false}
      showStatus={false}
      infiniteLoop
      autoPlay
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <IconButton
            title={label}
            sx={{
              ...classes.arrowStyles,
              left: arrowPositionX,
              transform: 'scaleX(-1)',
            }}
            onClick={onClickHandler}
          >
            <NextIcon />
          </IconButton>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <IconButton
            title={label}
            sx={{ ...classes.arrowStyles, right: arrowPositionX }}
            onClick={onClickHandler}
          >
            <NextIcon />
          </IconButton>
        )
      }
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        if (isSelected) {
          return (
            <Box
              sx={{
                ...classes.indicatorStyles,
                bgcolor: 'warning.main',
              }}
              aria-label={`Selected: ${label} ${index + 1}`}
            />
          );
        }
        return (
          <Box
            component="span"
            role="button"
            key={index}
            tabIndex={0}
            sx={classes.indicatorStyles}
            aria-label={`${label} ${index + 1}`}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
          />
        );
      }}
      {...props}
    >
      {children}
    </BaseCarousel>
  </Box>
);

export default Carousel;
