import yup from 'services/yup';
import { messages } from 'config/messages';

export const FormValidationSchema = yup.object().shape({
  oneTimeCommitment: yup
    .number()
    .typeError(messages.validation.NUMBER)
    .required(messages.validation.REQUIRED)
    .positive(messages.validation.NUMBER_POSITIVE)
    .integer(messages.validation.INTEGER),
});
