import React, { useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import classes from './styles';

import { LogoIcon } from 'assets/icons';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/user/UserSlice';

const HeaderMobile = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { user, isAuthenticated } = useSelector(userSelector);

  return (
    <Box sx={classes.root}>
      <Link href="/">
        <LogoIcon />
      </Link>
      <Box display="flex" alignItems="center">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={classes.drawer}
      >
        <List sx={classes.list}>
          {isAuthenticated ? (
            <ListItem>
              <ListItemText>
                <strong className="username">
                  Hello, {user.firstName} {user.lastName}
                </strong>
              </ListItemText>
            </ListItem>
          ) : null}
          {isAuthenticated ? (
            <>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link href="/admin/questionnaires" underline="none">
                    Manage Questionnaires
                  </Link>
                </ListItemText>
              </ListItem>
            </>
          ) : null}
        </List>
      </Drawer>
    </Box>
  );
};

export default HeaderMobile;
