import React from 'react';
// If it's necessary to add news, push it to the end of news array below. This array is sorted by the date: from oldest to newest

export const news = [
  {
    date: 'May 2014',
    source: 'The Cornerstone Journal of Sustainable Finance and Banking',
    url: 'http://cornerstonecapinc.com/wp-content/uploads/2014/05/2014-May-Subscriber-Edition-JSFB.pdf?utm_source=Cornerstone+Capital+Inc.+-+Distribution+Lists&amp;utm_campaign=321e496be2-JSFB_May_2014_Subscriber_Edition_5_29_2014&amp;utm_medium=email&amp;utm_term=0_e49598ded0-321e496be2-119053605',
    title: 'Journal of Sustainable Finance and Banking (p. 61)',
    author: 'By Wendy Gordon',
  },
  {
    date: 'May 16, 2014',
    source: 'Fox Business',
    title: 'Fox Business, the Risk and Rewards Show, with Deirdre Bolton',
    author: '',
  },
  {
    date: 'August 7, 2014',
    source: 'Deseret News',
    title: 'Expo Guests Leave Business Behind for Salt Lake Service Project',
    author: 'By McKenzie Romero',
  },
  {
    date: 'August 19, 2014',
    source: 'AlleyWatch',
    url: 'http://www.alleywatch.com/2014/08/nyc-bigapps-finalist-pips-the-frequent-flyer-program-for-social-good/',
    title:
      'NYC BigApps Finalist PIPs: The Frequent Flyer Program for Social Good',
    author: 'By AlleyWatch',
  },
  {
    date: 'September 16, 2014',
    source: 'The Official Website of the City of New York',
    url: 'http://www1.nyc.gov/office-of-the-mayor/news/443-14/mayor-de-blasio-nycedc-president-kimball-winners-nyc-bigapps-2014',
    title:
      'Press Release: Mayor de Blasio and NYCEDC President Kimball Announce Winners of NYC BigApps 2014',
    author: 'By pressoffice@cityhall.nyc.gov',
  },
  {
    date: 'September 17, 2014',
    source: 'Village Voice',
    title: 'All of Your Problems Solved by These Seven Winning Apps',
    author: 'By Tessa Stuart',
  },
  {
    date: 'October 2, 2014',
    source: 'Homelife',
    url: 'http://homelifeclt.blogspot.com/2014/10/earn-rewards-for-donating-old-furniture.html',
    title: 'Earn Rewards for Donating Old Furniture',
    author: 'By Karen Sullivan',
  },
  {
    date: 'October 3, 2014',
    source: 'The Editor at Large',
    url: 'http://editoratlarge.com/articles/4785/sfc-promotes-sustainability-by-rewarding-donations?page=1',
    title: 'SFC Promotes Sustainability by Rewarding Donations',
    author: '',
  },
  {
    date: 'October 8, 2014',
    source: 'Huffington Post Blog',
    url: 'http://www.huffingtonpost.com/wendy-gordon/second-life-for-your-things_b_5939544.html',
    title: 'More Space for You, a Second Life for Your Things',
    author: 'By Wendy Gordon',
  },
  {
    date: 'November 11, 2014',
    source: 'Triple Pundit',
    url: 'http://www.triplepundit.com/2014/11/getting-little-good-everyday-choices/',
    title: 'Getting a Little Good out of Everyday Choices',
    author: 'By Wendy Gordon',
  },
  {
    date: 'November 24, 2014',
    source: 'Huffington Post',
    url: 'http://www.huffingtonpost.com/wendy-gordon/found-money-buys-a-little-happiness_b_6211238.html',
    title: 'Found Money Buys a Little Happiness',
    author: 'By Wendy Gordon',
  },
  {
    date: 'April 3, 2015',
    source: 'Triple Pundit',
    url: 'http://www.triplepundit.com/2015/04/its-certified-pre-owned-no-not-the-car-the-ipad/',
    /*eslint-disable-next-line*/
    title: "It's Certified Pre-Owned: No, Not the Car, the iPad",
    author: 'By Wendy Gordon',
  },
  {
    date: 'October 28, 2015',
    source: 'Huffington Post',
    url: 'http://www.huffingtonpost.com/wendy-gordon/mash-up-of-tech-games-and_b_8408242.html',
    title: 'Mashup of Tech, Games and Rewards Turns Workplace Fitness into Fun',
    author: 'By Wendy Gordon',
  },
  {
    date: 'April 2, 2016',
    source: 'Story of Stuff Blog',
    title: 'Buy a Patagonia Backpack, Rack Up Points, and Donate. Easy, Right?',
    author: 'By Wendy Gordon',
  },
  {
    date: 'August 10, 2016',
    source: 'Home Business Magazine',
    url: 'http://homebusinessmag.com/management/employees/new-fitness-challenge-mobile-engagement-tool-increases-physical-activity-employees-lowers-healthcare-costs/',
    title:
      'New Fitness Challenge Mobile Engagement Tool Increases Physical Activity of Employees, Lowers Healthcare Costs',
    author: 'By Wendy Gordon',
  },
  {
    date: 'December 16, 2016',
    source: 'BardMBA Sustainable Business Fridays Podcast',
    url: 'http://bardmba.podbean.com/e/sustainable-business-fridays-completing-the-cycle-of-good-with-positive-impact-points/',
    title:
      'Completing the Cycle of Good with Positive Impact Points: PIPs Rewards',
    author: 'Interview with Wendy Gordon',
  },
  {
    date: 'August 29, 2018',
    source: 'CU Boulder Today',
    url: 'https://www.colorado.edu/today/2018/08/29/cu-boulder-earns-stars-gold-rating-sustainability-achievements',
    title:
      'CU Boulder earns STARS Gold rating for sustainability achievement, including Innovations Credit for PIPs Rewards App',
    author: '',
  },
  {
    date: 'January 10, 2019',
    source: 'CU Leeds School of Business',
    url: 'https://www.colorado.edu/business/deming/deming-center-news/2019/01/09/alumni-spotlight-spencer-turk',
    title: 'Alumni Spotlight: Spencer Turk',
    author: '',
  },
  {
    date: 'January 2019',
    source: 'Solutions Journal, Vol 10, Issue 1',
    url: 'https://www.thesolutionsjournal.com/article/pips-rewards-valuing-good-every-day/',
    title: 'Review of PIPs Rewards: Valuing the Good You Do Every Day',
    author: '',
  },
  {
    date: 'March 2019',
    source: 'CU Boulder Today',
    url: 'https://www.colorado.edu/today/2019/03/18/how-refilling-your-water-bottle-can-help-pay-your-tuition',
    title: 'How refilling your water bottle can help pay your tuition',
    author: '',
  },
  {
    date: 'March 31, 2019',
    source: 'Daily Camera',
    url: 'http://www.dailycamera.com/cu-news/ci_32545070/cu-boulder-students-can-earn-tuition-scholarships-riding',
    title:
      'CU Boulder students can earn tuition scholarships riding bikes, volunteering',
    author: 'By Madeline St. Amour',
  },
  {
    date: 'March 31, 2019',
    source: 'Denver Post',
    url: 'https://www.denverpost.com/2019/03/31/cu-boulder-scholarships-riding-bikes-volunteering/',
    title:
      'CU Boulder students can earn tuition scholarships riding bikes, volunteering',
    author: 'By Madeline St. Amour',
  },
  {
    date: 'April 8, 2019',
    source: 'Forbes',
    url: 'https://www.forbes.com/sites/jeffkart/2019/04/08/earth-pay-colorado-students-can-earn-scholarships-for-green-deeds/#70a624e12be0',
    title: 'Earth Pay: Colorado Students Can Earn Scholarships For Green Deeds',
    author: '',
  },
  {
    date: 'May 2019',
    source: 'Aim to Flourish',
    url: 'https://aim2flourish.com/innovations/the-pip-rewards-digital-impact-platform',
    title: 'The PIP Rewards ‘Digital Impact’ Platform',
    author: 'By Larry Clay',
  },
  {
    date: 'August 23, 2019',
    source: 'UCCS Communique',
    url: 'http://communique.uccs.edu/?p=114915&amp;fbclid=IwAR0cEH_6vnPy99UVRiMdPAL0TYW0ixMJfqLtnjM6iZyUOFt00-iMWmPoejE',
    title: 'How refilling water bottles can help students pay their tuition',
    author: 'By Kimberly Reeves',
  },
  {
    date: 'September 19, 2019',
    source: '',
    /*eslint-disable-next-line*/
    title: "Certified B Corp's Best for the World 2019",
    author: '',
  },
  {
    date: 'October 2, 2019',
    source: '',
    title: 'Students can earn money for their tuition...',
    author: '',
  },
  {
    date: 'October 11, 2019',
    source: '',
    url: 'https://drive.google.com/file/d/1s3Vp-LBS8nY_o_2BApVm1JJbegW0CXri/view',
    title: (
      <span>
        Method to the Madness host <strong>Lisa Kiefer</strong> speaks with{' '}
        <strong>Ashley Grosh</strong> of <strong>PIPS Rewards</strong>
      </span>
    ),
    author: '',
  },
  {
    date: 'February 24, 2020',
    source: 'Denver Post',
    url: 'https://www.denverpost.com/2020/02/24/pips-university-of-colorado-sustainability-app/',
    title: 'Colorado colleges back programs to make students better people',
    author: 'By Elizabeth Hernandez',
  },
  {
    date: 'October 2020',
    source: '',
    url: 'https://www.youtube.com/watch?v=lQN0jtPoYx8&amp;feature=youtu.be',
    title:
      'Can you really change behavior with an app? - AASHE Presentation 2020',
    author: 'By Jennifer Ballinger and Ryan Maikell',
  },
  {
    date: 'March 2021',
    source: 'Medium',
    url: 'https://medium.com/@PIPsRewards/why-reward-people-for-doing-things-they-should-be-doing-anyway-10357534dd13',
    title: 'Why reward people for doing things they should be doing anyway?',
    author: 'By Wendy Gordon',
  },
  {
    date: 'March 2021',
    source: 'UCCS Communique',
    url: 'https://communique.uccs.edu/?p=131490',
    title: 'Earth Month: Students can earn scholarships for sustainability',
    author: 'By Anna Squires',
  },
  {
    date: 'April 2021',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/training-an-app-to-know-when-you-are-riding-a-bus-785c612e98e9',
    title: 'Training an App to Know When You Are Riding a Bus',
    author: 'By Adrienne Jan',
  },
  {
    date: 'June 2021',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/thinkhuman-tv-makes-binge-watching-good-for-you-b449c8ee7e6b',
    title: 'ThinkHuman.tv Makes Binge-Watching Good for You',
    author: 'By Ilya Lyashevsky and Melissa Cesarano',
  },
  {
    date: 'July 2021',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/new-action-to-study-program-turns-doing-good-in-one-s-community-into-grants-for-school-a7c0de28b7b4',
    title:
      'New “Action-to-Study” Program Turns “Doing Good” in One’s Community into Grants for School',
    author: 'By Wendy Gordon',
  },
  {
    date: 'August 2021',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/spiral-new-fintech-company-puts-the-heart-in-banking-2c0851fe5e50',
    title: 'Spiral: New FinTech Company Puts the Heart in Banking',
    author: 'By Mikayla Zeitlin',
  },
  {
    date: 'November 2021',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/students-credit-pips-for-increased-sense-of-agency-b02fc4b2f8c1',
    title: 'Students Credit PIPs for Increased Sense of Agency',
    author: 'By Wendy Gordon',
  },
  {
    date: 'January 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-walking-challenge-steps-up-fitness-with-fun-36e571348318',
    title: 'PIPs’ Walking Challenge Steps Up Fitness with Fun',
    author: 'By Mikayla Zeitlin and Wendy Gordon',
  },
  {
    date: 'February 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/we-have-the-power-to-change-things-bfa2101772d5',
    title: 'We Have the Power to Change Things',
    author: 'By Wendy Gordon',
  },
  {
    date: 'March 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/cu-boulder-students-learn-the-power-of-engagement-through-pips-c0465792bca7',
    title: 'CU Students Learn the Power of Engagement through PIPs',
    author: 'By Wendy Gordon',
  },
  {
    date: 'March 2022',
    source: 'PIPs Education Fund',
    url: 'https://prod.pipsrewards.com/2021-pef-annual-report',
    title: '2021 PIPs Education Fund Annual Report',
    author: 'By Wendy Gordon',
  },
  {
    date: 'April 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-for-school-scholar-sammi-zheng-f48425972aee',
    title: 'PIPs for School Scholar: Sammi Zheng',
    author: 'By Wendy Gordon',
  },
  {
    date: 'April 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-for-school-scholar-dawn-lugo-ffa6349c2fa0',
    title: 'PIPs for School Scholar: Dawn Lugo',
    author: 'By Mikayla Zeitlin',
  },
  {
    date: 'April 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-for-school-scholar-chrisber-vasquez-27b5255403c0',
    title: 'PIPs for School Scholar: Chrisber Vasquez',
    author: 'By Mikayla Zeitlin',
  },
  {
    date: 'May 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/where-valuing-the-good-they-do-helps-narrow-the-persistence-gap-4a13901b6435',
    title: 'Where Valuing the “Good They Do” Helps Narrow the Persistence Gap',
    shortDescription:
      '“I will be the first person in my family to graduate with a bachelor’s degree,” Karen Ortiz, a CUNY Hunter student, explains. “That motivates me a lot. My mom didn’t have the opportunity to do it. My grandma. My parents. None of them. So I really want to do it. I really want to achieve it.”',
    author: 'By Wendy Gordon',
    image: 'assets/images/news/news3.jpeg',
  },
  {
    date: 'May 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-for-school-scholar-kevin-persaud-c47a437b40c3',
    title: 'PIPs for School Scholar: Kevin Persaud',
    shortDescription:
      'For 26-year-old Kevin Persaud, being a PIPs for School (P4S) Scholar is one of the many, many hats he wears. A master’s student at the City College of New York and aspiring medical student, Kevin works in a laboratory to develop novel bioactive molecules for viral or cancer therapeutic solutions. ',
    author: 'By Mikayla Zeitlin',
    image: 'assets/images/news/news2.jpeg',
  },
  {
    date: 'May 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-for-school-scholar-kavindra-horil-e34c3fdcf068',
    title: 'PIPs for School Scholar: Kavindra Horil',
    shortDescription:
      'Originally from Guyana, South America, Kavindra lives in New York City and attends CUNY Queens College, studying economics. His hopes for the future? In the short term, it’s to get his degree, maybe even a master’s. “The college degree is really important these days,” he says. ',
    author: 'By Mikayla Zeitlin',
    image: 'assets/images/news/news4.jpeg',
  },
  {
    date: 'June 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-for-school-spotlight-cale-kennamer-ecology-student-at-uccs-94298bee5b8f',
    title: 'PIPs for School Spotlight: Cale Kennamer',
    shortDescription:
      'Cale Kennamer, an ecology student at the University of Colorado Colorado Springs (UCCS), aims through his studies to learn ways to “mitigate and even reverse some of the human-mediated effects of environmental change.” He’s relying on PIPs, earned as a participant in the PIPs for School program at UCCS, to help him pursue these goals.',
    author: 'By Wendy Gordon',
    image: 'assets/images/news/news1.jpeg',
  },
  {
    date: 'June 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/students-build-up-social-emotional-skills-while-tuning-in-to-their-favorite-shows-95dbd0ce981d',
    title:
      'Students Build Up Social-Emotional Skills While Tuning in to Their Favorite Shows',
    shortDescription:
      'Cale Kennamer, an ecology student at the University of Colorado Colorado Springs (UCCS), aims through his studies to learn ways to “mitigate and even reverse some of the human-mediated effects of environmental change.” He’s relying on PIPs, earned as a participant in the PIPs for School program at UCCS, to help him pursue these goals.',
    author: 'By Mikayla Zeitlin',
    image: 'assets/images/news/news5.png',
  },
  {
    date: 'July 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/hope-for-a-positive-future-pips-for-school-scholars-sets-students-up-for-success-d535b6381aa9',
    title:
      '“Hope for a Positive Future:” PIPs for School Scholars Sets Students Up for Success',
    shortDescription:
      'As the pilot year of the PIPs for School (P4S) Scholars program, hosted by RIVER FUND NY, the poverty-fighting Community Based Organization (CBO) in Queens, wraps up, and we ready ourselves to triple the number of Host Partners and students in the program, we’re pausing to take stock of how the year went for our first class of Scholars, what they learned and were able to accomplish.',
    author: 'By Mikayla Zeitlin and Wendy Gordon',
    image: 'assets/images/news/news6.jpeg',
  },
  {
    date: 'August 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/want-to-know-the-secret-to-boosting-student-engagement-hint-it-fits-in-the-palm-of-your-hand-f2850eccdc4f',
    title:
      'Want to know the secret to boosting student engagement? (Hint: It fits in the palm of your hand)',
    shortDescription:
      'The PIPs for School (P4S) program “helps you to set everyday goals that help the environment,” explains a University of Colorado Colorado Springs (UCCS) student participant. “You feel accomplished at the end of each day.”',
    author: 'By Mikayla Zeitlin and Wendy Gordon',
    image: 'assets/images/news/news7.jpeg',
  },
  {
    date: 'September 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/p4s-scholars-grows-by-4x-in-2nd-year-d9a6ce9435a3',
    title: 'P4S Scholars Grows by 4x in 2nd Year',
    shortDescription:
      'We did it! Thanks to help from our donors, PIPs for School (P4S) Scholars is kicking off its second year with two more NYC-based Host Partners and nearly four times the number of Scholars. Our newest Host Partners, PRACTICE Benefit Corporation (PRACTICE) and Goddard Riverside’s Options Center (Options Center), join RIVER FUND NY, our flagship Host Partner, which launched its second community of Scholars in July.',
    author: 'By Wendy Gordon',
    image: 'assets/images/news/news8.png',
  },
  {
    date: 'October 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/bringing-out-the-change-maker-in-all-of-us-8cbbde5faa61',
    title: 'Bringing out the Change-Maker in All of Us',
    shortDescription:
      'Can you think of a game that uses some form of currency (coins, tokens, etc.) to encourage people to keep playing? Have you ever gotten hooked on such a game, motivated to earn a reward?',
    author: '',
    image: 'assets/images/news/news9.png',
  },
  {
    date: 'November 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-for-school-scholar-maria-boyan-azumally-6913ac604d6f',
    title: 'PIPs for School Scholar: Maria Boyan-Azumally',
    shortDescription:
      '“My life goal is to always be truthful to myself,” says Maria Boyan-Azumally, a student at Southern New Hampshire University, aspiring private investigator, mother of two, and PIPs for School (P4S) Scholar, “Whether it’s in my personal life or…in my career…I just want to hold [on] to that part where I’m truthful and honest in everything I do.”',
    author: '',
    image: 'assets/images/news/news12.jpeg',
  },
  {
    date: 'November 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/what-possible-difference-could-it-make-vol-1-riding-the-bus-72ad5b6dafaf',
    title: 'What Possible Difference Could It Make? Vol. 1: Riding the Bus',
    shortDescription:
      'People have been known to snicker when I tell them we award PIPs, or Positive Impact Points, to our PIPs Rewards™ App users for taking the bus, refilling water bottles, and recycling. These are valuable points that can be converted into scholarship grants and gift cards for food and other essentials.',
    author: '',
    image: 'assets/images/news/news10.jpeg',
  },
  {
    date: 'December 2022',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/what-possible-difference-could-it-make-vol-2-climate-positive-banking-9c459553da01',
    title:
      'What Possible Difference Could It Make? Vol. 2 Climate-Positive Banking',
    shortDescription:
      'Dear Friends: I’ll tell you more below about why you want to switch to the world’s leading climate-positive banking alternative, but first, I want to be totally clear that when you open and fund an Atmos account through this link, they will make a donation to PIPs Education Fund (PEF) in support of the PIPs for School (P4S) Scholars program. ',
    author: '',
    image: 'assets/images/news/news13.png',
  },
  {
    date: 'January 2023',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/what-possible-difference-could-it-make-vol-2-acts-of-kindness-b63de55cbd3a',
    title: 'What Possible Difference Could It Make? Vol. 3: Acts of Kindness',
    shortDescription:
      'February, Schmebruary. For some, there is no worse month. It may be short, they say, but when will it ever end?',
    author: '',
    image: 'assets/images/news/news11.jpeg',
  },
  {
    date: 'March 2023',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/pips-for-school-scholar-daneyda-l-122c20d8a1c1',
    title: 'PIPs for School Scholar: Daneyda G.',
    shortDescription:
      'Daneyda (Danny) is passionate about school, her mom, and helping others. “Mostly it’s my mother,” she explains. “Because thanks to her I have this great opportunity to be in this place right now and to be studying,” something she acknowledges her mother wasn’t able to do in her home country.',
    author: '',
    image: 'assets/images/news/news12.jpg',
  },
  {
    date: 'April 2023',
    source: 'Medium',
    url: 'https://pipsrewards.medium.com/what-possible-difference-could-it-make-vol-4-reusable-water-bottles-7d099f1a3eb0',
    title:
      'What Possible Difference Could It Make, Vol. 4: Reusable Water Bottles',
    shortDescription:
      'Yuck is all I can say. According to our friends at Beyond Plastic, “We are all ingesting roughly a credit card worth (5 grams) of plastics each week.” ',
    author: '',
    image: 'assets/images/news/news13.jpg',
  },
  {
    date: 'May 2023',
    source: 'Medium',
    url: 'https://medium.com/@wendy_72986/p4s-scholar-lutfia-c-881b336e64e1',
    title: 'P4S Scholar Profile: Lutfia C.',
    shortDescription:
      'Lutfia C. is working toward her PhD in public health at the University at Buffalo. She was born in Bangladesh but now lives in Buffalo with her mom and  brother. She wants to pursue a career as an environmental  health educator. ',
    author: '',
    image:
      'https://pipseducationfund.org/wp-content/uploads/2023/05/Lutfia-Chowdhury-square.jpeg',
  },
  {
    date: 'June 2023',
    source: 'Medium',
    url: 'https://medium.com/@wendy_72986/what-possible-difference-could-it-make-vol-7-eliminating-plastic-waste-3998f5a5b78f',
    title: 'What Possible Difference Could It Make, Vol.5: Recycling',
    shortDescription:
      'I know what you are going to say. We’ve known for decades “what difference recycling can make,” so why is it still so darn hard? ',
    author: '',
    image: 'assets/images/news/news15.jpg',
  },
  {
    date: 'August 2023',
    source: 'Medium',
    url: 'https://medium.com/@wendy_72986/what-possible-difference-could-it-make-vol-6-social-and-emotional-learning-94bb777a5977',
    title:
      'What Possible Difference Could It Make, Vol. 6: Social and Emotional Learning',
    shortDescription:
      'While it’s become a political hot button issue in some parts of the country, Social and Emotional Learning (SEL) programs have clear benefits for students. ',
    author: '',
    image: 'assets/images/news/news16.png',
  },
  {
    date: 'October 2023',
    source: 'Medium',
    url: 'https://medium.com/@wendy_72986/what-possible-difference-could-it-make-vol-7-eliminating-plastic-waste-3998f5a5b78f',
    title:
      'What Possible Difference Could It Make?, Vol.7 Eliminating Plastic Waste',
    shortDescription:
      'In What Possible Difference Could It Make, Vol. 5, I extolled the virtues of recycling and exhorted us all to recycle our way out of our giant waste problem. But the truth is: recycling isn’t making a dent, at least not when it comes to plastics. ',
    author: '',
    image: 'assets/images/news/news17.png',
  },
  {
    date: 'December 2023',
    source: 'Medium',
    url: 'https://medium.com/@wendy_72986/p4s-scholar-aramatoulaye-d-5b93889d77e1',
    title: 'P4S Scholar Spotlight: Aramatoulaye D.',
    shortDescription:
      'Aramatoulaye is a freshman at Fordham University in the Bronx, NY. Her family is from Mali. Once she graduates, her dream job would be to work at the Embassy of The Republic of Mali in NYC.',
    author: '',
    image: 'assets/images/news/news18.jpeg',
  },
  {
    date: 'December 2023',
    source: 'Medium',
    url: 'https://medium.com/@wendy_72986/pessimism-is-out-d6002501d54d',
    title: 'Pessimism is Out',
    shortDescription:
      'I’m really into the message in this video. Pessimism is a downer. Don’t let that voice in your head tell you that your choosing Rainforest Alliance Certified products, or switching off of single use plastic containers in your home, doesn’t make a difference. You’re not just one person making this change. You are part of an entire world of people who care.',
    author: '',
    image: 'assets/images/news/news19.jpg',
  },
  {
    date: 'January 2024',
    source: 'Medium',
    url: 'https://medium.com/@wendy_72986/pips-for-school-scholar-alex-c-b79841c9748f',
    title: 'P4S Scholar: Alex Colome Peguero',
    shortDescription:
      'Having just graduated from John Jay College for Criminal Justice, Alex Colome is joining the National Guard. Upon completing his deployment, his plan is to become a correctional officer with the NYPD. Alex is excited to join the National Guard and to “serve this wonderful country.”',
    author: '',
    image: 'assets/images/news/news20.jpg',
  },
];

// This array is used on News page
export const sortedNews = news.slice(0).reverse();

// This array is used on Home page for Recent news block
export const recentNews = news.slice(-3).reverse();
