const classes = {
  root: {
    '& video': {
      height: '54vh',
    },
    '& p': {
      whiteSpace: 'nowrap',
      position: 'absolute',
      left: '50%',
      top: '47vh',
      transform: 'translateX(-50%)',
    },
  },
};

export default classes;
