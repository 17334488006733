import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { donateOneTimePromo } from 'services/api';
import palette from 'styles/theme/palette';
import classes from './styles';
import { FormValidationSchema } from './settings';
import { promoSelector } from 'store/promos/PromoSlice';
import { handleError } from 'services/handleError';

const InvestPromoType = () => {
  const [pipsToCommit, setPipsToCommit] = useState(1);
  const [pipsAfterPurchase, setPipsAfterPurchase] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const { promo, pipsAvailable } = useSelector(promoSelector);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [successMessage]);

  useEffect(() => {
    setPipsAfterPurchase(pipsAvailable - pipsToCommit);
  }, [pipsAvailable]);

  const handleSuccessMessage = (message) => {
    setSuccessMessage(message);
  };

  const handlePipsToCommitChange = (val) => {
    setPipsToCommit(val);
    const pipsValue = pipsAvailable - val;
    setPipsAfterPurchase(pipsValue);
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oneTimeInvestment: 1,
    },
    resolver: yupResolver(FormValidationSchema),
    mode: 'onChange',
  });
  const watchedFormFields = watch(['oneTimeInvestment']);

  const onSubmit = handleSubmit((data) => {
    const body = {
      promoId: promo.id,
      pips: data.oneTimeInvestment,
    };
    donateOneTimePromo({ body })
      .then((res) => {
        if (res.status === 'OK') {
          handleSuccessMessage('Success!');
        } else {
          handleError(res);
        }
      })
      .catch((err) => handleError(err.response.data));
  });

  const isFormFilledCorrectly = watchedFormFields.every((val) => Boolean(val));

  if (successMessage)
    return (
      <Typography variant="h4" fontWeight={700} textAlign="center">
        {successMessage}
      </Typography>
    );

  return (
    <Box component="form" onSubmit={onSubmit}>
      <Typography mb={2}>
        Upon completing this action, you will receive an email confirmation from
        PIPs. At the same time, the company will be notified of the value of
        your investment, along with your name and email address and an agent,
        from the company, will contact you directly to complete the process.
        Thank you.
      </Typography>
      <Typography variant="h4">Invest With PIPs</Typography>
      <Typography>
        Using the spinner below, select the number of PIPs you want to invest.{' '}
        <Typography component="span" color={palette.info.light}>
          Note, a PIP = a penny.
        </Typography>
      </Typography>

      <Box sx={classes.donationBox}>
        <Typography>
          Available PIPs: <strong>{pipsAvailable.toLocaleString()}</strong>
        </Typography>
        <Box sx={classes.inputWrapper}>
          <Typography>PIPs to invest:</Typography>
          <Controller
            name="oneTimeInvestment"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                control={
                  <TextField
                    name={name}
                    value={value}
                    fullWidth
                    inputProps={{
                      autoComplete: 'off',
                      min: 1,
                      max: pipsAvailable,
                    }}
                    type="number"
                    error={Boolean(errors.oneTimeInvestment)}
                    helperText={
                      errors.oneTimeInvestment &&
                      errors.oneTimeInvestment.message
                    }
                    onChange={(e) => {
                      handlePipsToCommitChange(e.target.value);
                      onChange(e);
                    }}
                  />
                }
              />
            )}
          />
        </Box>
        {pipsAfterPurchase < 0 ? (
          <Typography color={palette.error.main}>Not enough PIPs</Typography>
        ) : (
          <Typography>
            PIPs after purchase:{' '}
            <strong>
              {pipsAfterPurchase && pipsAfterPurchase.toLocaleString()}
            </strong>
          </Typography>
        )}
      </Box>
      <Button
        variant="contained"
        color="secondary"
        type="submit"
        disabled={!isFormFilledCorrectly || pipsAfterPurchase < 0}
      >
        Proceed With Investment
      </Button>
    </Box>
  );
};

export default InvestPromoType;
