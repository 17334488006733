const benefits = [
  {
    id: 1,
    text: '12-months access to PIPs Rewards™ App fully loaded with:',
    url: 'assets/icons/benefits/benefit1.svg',
    list: [
      {
        id: 1,
        description: 'Earn Actions',
      },
      {
        id: 2,
        description: 'Valued Reward Options',
      },
      {
        id: 3,
        description: 'Challenges',
      },
      {
        id: 4,
        description: 'Dashboards',
      },
      {
        id: 5,
        description: 'Personal and Community Carbon Calculators',
      },
    ],
  },
  {
    id: 2,
    text: 'Personalized Communications Strategy',
    url: 'assets/icons/benefits/benefit2.svg',
  },
  {
    id: 3,
    text: 'User Support',
    url: 'assets/icons/benefits/benefit3.svg',
  },
  {
    id: 4,
    text: 'Data Analytics',
    url: 'assets/icons/benefits/benefit4.svg',
  },
];

export default benefits;
