const classes = {
  root: {
    '& .MuiDialogContent-root': {
      margin: '0 auto',
      maxWidth: '100%',
      paddingLeft: '15px',
      paddingRight: '15px',
      '@media (min-width: 1024px)': {
        maxWidth: '81%',
      },
    },
    '& .MuiGrid-root>.MuiGrid-item': {
      paddingLeft: 0,
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '30px 0',
  },
  divider: {
    display: 'block',
    opacity: 0.2,
    bgcolor: 'text.light',
    height: 1,
    flexGrow: 1,
  },
  dividerText: {
    color: 'text.light',
    fontSize: 14,
    opacity: 0.2,
    display: 'inline-block',
    verticalAlign: 'middle',
    padding: '0 20px',
  },
};

export default classes;
