import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { RegistrationForm } from './components';
import classes from './styles';
import Layout from 'components/Layout';
import palette from 'styles/theme/palette';
import { userSelector } from 'store/user/UserSlice';
import AuthenticatedUserPlaceholder from './components/AuthenticatedUserPlaceholder';

const Registration = () => {
  const { isAuthenticated } = useSelector(userSelector);
  const verifiedUser = localStorage.getItem('verified');

  if (isAuthenticated && verifiedUser) {
    return <AuthenticatedUserPlaceholder />;
  }

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <RegistrationForm />
      </Box>
    </Layout>
  );
};

export default Registration;
