import palette from 'styles/theme/palette';

export default {
  styleOverrides: {
    paper: {
      color: palette.text.grey,
      border: '1px solid #707070',
      borderRadius: '24px',
    },
  },
};
