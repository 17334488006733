import React from 'react';
import { Box, Typography } from '@mui/material';
import palette from 'styles/theme/palette';

import Layout from 'components/Layout';
import NavTabs from 'components/NavTabs';
import PipsForSchool from './components/PipsForSchool';
import Redeem from './components/Redeem';
import Donate from './components/Donate';
import GiftYourPips from './components/GiftYourPips';
import classes from './styles';

const Rewards = () => (
  <Layout bgColor={palette.white}>
    <Box maxWidth="lg" sx={classes.root}>
      <Typography variant="h3" display={'inline-block'} mb={0}>
        Rewards
      </Typography>
      <NavTabs
        ariaLabel="Rewards"
        tabs={[
          {
            label: 'PIPs For School',
            panel: <PipsForSchool />,
            pathname: 'pips-for-school',
          },
          {
            label: 'Redeem',
            panel: <Redeem />,
            pathname: 'redeem',
          },
          {
            label: 'Donate',
            panel: <Donate />,
            pathname: 'donate',
          },
          {
            label: 'Gift Your PIPs',
            panel: <GiftYourPips />,
            pathname: 'gift-your-pips',
          },
        ]}
      />
    </Box>
  </Layout>
);

export default Rewards;
