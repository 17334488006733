import React from 'react';
import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material';
import classes from './styles';
import { challenges } from './settings';
import { useTheme } from '@mui/system';

const Challenges = () => {
  const theme = useTheme();
  const showLogo = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={classes.root}>
      <Box sx={classes.challengesImage}>
        <CardMedia
          component="img"
          src="assets/images/programs/challenges.jpeg"
          sx={classes.mainImage}
        />
        {showLogo && (
          <CardMedia sx={classes.logo} component="img" src="logo512.png" />
        )}
      </Box>
      <Box sx={classes.challengesListWrapper}>
        <Typography variant="h2" sx={classes.title}>
          Challenges Students Face
        </Typography>
        <Box component="ul" sx={classes.challengesList}>
          {challenges.map((challenge) => (
            <Box component="li" key={challenge.id} mb={3}>
              <CardMedia
                component="img"
                src={`assets/icons/challenges/challenge${challenge.id}.svg`}
              />
              <Typography variant="h4">{challenge.title}</Typography>
              <Box sx={classes.challengeDescription}>
                {challenge.description}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Challenges;
