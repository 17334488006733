const classes = {
  longDescription: {
    '& p': {
      wordWrap: 'break-word',
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    marginTop: 'auto',
    flexDirection: 'column',
    '@media (min-width: 1024px)': {
      flexDirection: 'row',
    },
    '& .MuiButton-root': {
      padding: '6px',
      lineHeight: 1,
      marginBottom: '10px',
      '@media (min-width: 1024px)': {
        marginBottom: 0,
        '& + .MuiButton-root': {
          marginLeft: '10px',
        },
      },
    },
  },
};

export default classes;
