import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { messages } from 'config/messages';
import { handleError } from 'services/handleError';
import FormFieldRender from 'components/FormFieldRender';
import Preloader from 'components/Preloader';
import { getQuestionnaireByID, submitQuestionnaireAnswer } from 'services/api';
import classes from './styles';

const Questionnaire = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const params = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [questionnaire, setQuestionnaire] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  const onSubmit = handleSubmit((data) => {
    const body = JSON.stringify(data, null, 2);
    setIsFetching(true);
    submitQuestionnaireAnswer({
      id: params.id,
      body,
    })
      .then((res) => {
        setIsFetching(false);
        if (res.status === 'OK') {
          setIsFormSubmitted(true);
        } else {
          handleError(res);
        }
      })
      .catch((err) => {
        setIsFetching(false);
        handleError(err.response.data);
      });
  });

  useEffect(() => {
    setIsFetching(true);
    getQuestionnaireByID(params.id)
      .then((res) => {
        if (res.status === 'OK' && res?.questionnaire) {
          const data = res.questionnaire;
          setQuestionnaire(JSON.parse(data.payload));
          setTitle(data.name);
          setDescription(data.description);
        } else {
          handleError(res);
          setIsError(true);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        handleError(err.response.data);
        setIsFetching(false);
      });
  }, []);

  return (
    <Box sx={classes.root}>
      {isFetching && (
        <Box sx={classes.preloaderWrapper}>
          <Preloader />
        </Box>
      )}
      {(() => {
        if (isError) {
          return (
            <Typography variant="h5" align="center" mb={1}>
              {messages?.errors?.GENERIC_ERROR ||
                'Sorry, something went wrong.'}
            </Typography>
          );
        } else {
          return (
            <>
              <Typography variant="h5" align="center" mb={1}>
                {title}
              </Typography>
              {isFormSubmitted ? (
                <Box>
                  <Typography variant="h5" align="center" mb={1}>
                    Your response has been recorded.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Typography align="center" mb={4}>
                    {description}
                  </Typography>

                  <form onSubmit={onSubmit}>
                    {questionnaire?.map((field, index) => (
                      <FormFieldRender
                        key={field.id}
                        field={field}
                        index={index}
                        register={register}
                        validate={true}
                        getValues={getValues}
                        errors={errors}
                      />
                    ))}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Send
                    </Button>
                  </form>
                </>
              )}
            </>
          );
        }
      })()}
    </Box>
  );
};

export default Questionnaire;
