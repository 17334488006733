import React from 'react';
import classes from './styles';
import { Box, Typography } from '@mui/material';
import { images } from './settings';

const PipsForSchool = () => {
  return (
    <Box id="P4S" component="section" sx={classes.root}>
      <Box sx={classes.text}>
        <Typography variant="h2" sx={classes.title}>
          PIPs for School (P4S)
        </Typography>
        <Typography variant="body1">
          An innovative “action-study” program that utilizes PIPs Rewards
          <sup>TM</sup> behavior motivation and tracking app to incentivize
          student engagement in personal, community, and planetary action.
        </Typography>
        <Box component="div" sx={classes.divider} />
        <Typography variant="body1">
          By providing multiple “touch points” through which engagement in good
          is tracked and meaningfully rewarded, P4S gives agency to those who
          lead as citizens at home, school, and in the community.
        </Typography>
      </Box>
      <Box sx={classes.images}>
        <ul>
          {images.map((image) => (
            <li key={image.id}>{image.card}</li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};
export default PipsForSchool;
