import React, { useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Box, CardMedia, Container, Link, Typography } from '@mui/material';

import { LogoIcon } from 'assets/icons';
import classes from './styles';

const Footer = () => {
  const navigate = useNavigate();
  const isHome = useMatch('/');

  const scrollToTestimonials = useCallback(async () => {
    if (!isHome) await navigate('/');
    const testimonials = document.getElementById('testimonials');
    window.scrollTo({
      top: testimonials.offsetTop - 100,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <Box component="footer" sx={classes.root}>
      <Container maxWidth="lg">
        <Box sx={classes.rootContent}>
          <Box sx={classes.footerLeft}>
            <Box sx={classes.column}>
              <Link color="white" variant="subtitle1" underline="none" href="/">
                <LogoIcon />
              </Link>
            </Box>
            <Box component="ul" sx={classes.column}>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  href="https://pipseducationfund.org"
                  underline="none"
                  target="_blank"
                >
                  PIPs Education Fund
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  href="https://prod.pipsrewards.com/pips_for_school"
                  underline="none"
                  target="_blank"
                >
                  PIPs for School
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  underline="none"
                  onClick={() => navigate('/ourstory')}
                >
                  Our Story
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  underline="none"
                  onClick={() => navigate('/team')}
                >
                  Team
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  underline="none"
                  onClick={scrollToTestimonials}
                >
                  Testimonials
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  onClick={() => navigate('/news')}
                  underline="none"
                >
                  News
                </Link>
              </li>
            </Box>
            <ul>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  underline="none"
                  href="https://vimeo.com/104361111"
                  target="_blank"
                >
                  What’s a PIP?
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  underline="none"
                  onClick={() => navigate('/faq')}
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  underline="none"
                  onClick={() => navigate('/privacy')}
                >
                  Privacy
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  underline="none"
                  onClick={() => navigate('/terms')}
                >
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  color="white"
                  variant="linkSm"
                  href="mailto:support@pipsrewards.com"
                  underline="none"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </Box>
          <Box pt={2} sx={classes.download}>
            <Link
              href="https://apps.apple.com/ua/app/pips-rewards/id1087959967"
              target="_blank"
            >
              <img src="/assets/images/appStore.svg" alt="appStore" />
            </Link>
            <Link
              href="https://play.google.com/store/apps/details?id=biz.pips"
              target="_blank"
            >
              <img src="/assets/images/googlePlay.svg" alt="googlePlay" />
            </Link>
          </Box>
          <Box sx={classes.footerRight}>
            <Box display="flex" component="ul" sx={classes.logosList}>
              <li>
                <Link
                  href="https://www.bcorporation.net/en-us/certification/"
                  color="white"
                  variant="linkSm"
                  underline="none"
                  target="_blank"
                >
                  <CardMedia
                    sx={classes.cbc}
                    component="img"
                    src="/assets/images/logos/b_corporation.png"
                  />
                </Link>
              </li>
            </Box>
            <Typography variant="subtitle2" sx={classes.copyright}>
              &copy; {currentYear}, PIPS REWARDS LLC
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
