import { useState } from 'react';

const useToken = () => {
  const userToken = localStorage.getItem('token');

  const [token, setToken] = useState(userToken);

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
};

export default useToken;
