import palette from 'styles/theme/palette';

const classes = {
  card: {
    color: palette.text.grey,
    marginBottom: '20px',
  },
  title: {
    marginBottom: '10px',
    textarea: {
      fontWeight: 700,
    },
  },
  actionWrapper: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    background: 'white',
    paddingBottom: '20px',
    paddingTop: '20px',
    width: '704px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 2,
    '@media (max-width: 768px)': {
      width: '100%',
      paddingLeft: '29px',
      paddingRight: '29px',
    },
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
    marginRight: '4px',
    cursor: 'pointer',
  },
  checkboxWrap: {
    paddingRight: '6px',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row-reverse',
  },
};

export default classes;
