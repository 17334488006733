import { PADDING_MAIN } from 'config/ui';

const classes = {
  root: {
    maxWidth: '80%',
    margin: '0 auto',
    paddingTop: `${PADDING_MAIN}px`,
  },
  wrapper: {
    marginBottom: '32px',
  },
  forgotPasswordForm: {
    padding: '25px 0',
    '& .MuiFormControl-root': {
      marginBottom: '8px',
    },
    '& input': {
      marginBottom: 0,
    },
  },
};

export default classes;
