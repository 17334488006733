import { PADDING_MAIN, PADDING_MAIN_MD } from '../../config/ui';

const classes = {
  root: {
    paddingTop: '45px',
    paddingBottom: '106px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media (min-width: 768px)': {
      paddingLeft: `${PADDING_MAIN_MD}px`,
      paddingRight: `${PADDING_MAIN_MD}px`,
    },
    '@media (min-width: 1280px)': {
      paddingLeft: `${PADDING_MAIN}px`,
      paddingRight: `${PADDING_MAIN}px`,
    },
  },
};

export default classes;
