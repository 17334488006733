const classes = {
  root: {
    '& .Toastify__toast-theme--colored.Toastify__toast--default': {},
    '& .Toastify__toast-theme--colored.Toastify__toast--info': {
      bgcolor: 'info.main',
    },
    '& .Toastify__toast-theme--colored.Toastify__toast--success': {
      bgcolor: 'success.main',
    },
    '& .Toastify__toast-theme--colored.Toastify__toast--warning': {
      bgcolor: 'warning.main',
    },
    '& .Toastify__toast-theme--colored.Toastify__toast--error': {
      bgcolor: 'error.main',
    },
  },
};

export default classes;
