import React from 'react';
import { Box, Container } from '@mui/material';

import Header from './Header';
import classes from './styles';

const LayoutAdmin = ({ children, bgColor }) => {
  const mainBackgroundColor = !bgColor ? '#ebebeb' : bgColor;

  return (
    <Box sx={classes.root}>
      <Header />
      <main style={{ background: mainBackgroundColor }}>
        <Container sx={classes.container} maxWidth="lg">
          {children}
        </Container>
      </main>
    </Box>
  );
};

export default LayoutAdmin;
