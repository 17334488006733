import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CardMedia,
  Typography,
  useMediaQuery,
} from '@mui/material';

import classes from './styles';
import { articles } from './settings';
import { useTheme } from '@mui/system';

const About = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const showImage = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box component="section" sx={classes.root}>
      {showImage && (
        <Box
          alt="earth"
          component="img"
          src="assets/images/earth.png"
          sx={classes.imgEarth}
        />
      )}
      <Box component="ul">
        {articles.map((article) => (
          <Box component="li" key={article.id} sx={classes.listItem}>
            <CardMedia
              sx={classes.image}
              component="img"
              src={`assets/images/about/about${article.id}.jpeg`}
            />
            <div className="text">
              <Typography variant="h2" mb="19px">
                {article.title}
              </Typography>
              <Box mb="27px">{article.description}</Box>
              <Button
                variant="contained"
                color="secondary"
                {...(article?.link
                  ? { component: 'a', href: article.link, target: '_blank' }
                  : { onClick: () => navigate('programs') })}
              >
                Learn More
              </Button>
            </div>
          </Box>
        ))}
      </Box>
      {showImage && (
        <Box
          alt="pips"
          component="img"
          src="logo512.png"
          sx={classes.imgPips}
        />
      )}
    </Box>
  );
};

export default About;
