const classes = {
  dialog: {
    fontSize: 18,
    '& p:not(:last-of-type)': {
      marginBottom: 1.5,
    },
    '& br': {
      display: 'none',
    },
  },
  dialogContent: {
    marginTop: 4,
    marginBottom: 4,
    position: 'relative',
    marginRight: '35px',
  },
  header: {
    position: 'sticky',
    left: 0,
    top: 0,
    width: '100%',
    background: '#fff',
    boxShadow: '0 7px 15px 6px rgba(255 255 255 / 100%)',
    paddingBottom: 1,
  },
  img: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    marginRight: 4,
  },
  name: {
    textDecoration: 'none',
    marginBottom: 0.5,
    '& h4': {
      marginBottom: 0,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
};

export default classes;
