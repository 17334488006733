import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Button,
  Pagination,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { getQuestionnaires, deleteQuestionnaireByID } from 'services/api';
import { handleError } from 'services/handleError';
import { showSuccessNotification } from 'services/notifications';
import Dialog from 'components/dialogs/Dialog';
import Preloader from 'components/Preloader';
import LayoutAdmin from 'components/LayoutAdmin';
import palette from 'styles/theme/palette';
import classes from './styles';

const AdminQuestionnaires = () => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const PER_PAGE = 10;

  const redirectToDetails = (id) => {
    navigate(`/admin/questionnaires/${id}`);
  };

  const redirectToCreateQuestionnaire = () => {
    navigate('/admin/questionnaire');
  };

  const requestData = () => {
    setIsFetching(true);
    const query = { page: page, size: PER_PAGE };
    getQuestionnaires({ query })
      .then((res) => {
        if (res.status === 'OK' && res?.questionnaires) {
          setQuestionnaires(res.questionnaires || []);
          setCount(res.pageCount);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        handleError(err.response.data);
        setIsFetching(false);
      });
  };

  const onClickDeleteHandler = (id) => {
    setIsFetching(true);
    deleteQuestionnaireByID(id)
      .then((res) => {
        if (res.status === 'OK') {
          requestData();
          showSuccessNotification('Questionnaire was successfully deleted');
        } else {
          handleError(res);
        }
        setIsFetching(false);
      })
      .catch((err) => {
        handleError(err.response.data);
        setIsFetching(false);
      });
  };
  const resetSelected = () => {
    setSelectedID('');
    setSelectedName('');
  };

  const handleChange = (e, page) => {
    setPage(page);
  };

  useEffect(() => {
    requestData();
  }, [page]);

  return (
    <LayoutAdmin bgColor={palette.white}>
      <Box maxWidth="lg" sx={classes.root}>
        <Dialog open={open} maxWidth="xs" name="prompt">
          <DialogContent>
            <Typography>
              Are you sure you want to delete &quot;{selectedName}&quot;
              questionnaire?
            </Typography>
          </DialogContent>
          <DialogActions sx={{ textAlign: 'center' }}>
            <Button
              variant="text"
              onClick={() => {
                onClickDeleteHandler(selectedID);
                setOpen(false);
              }}
            >
              Yes
            </Button>
            <Button
              variant="text"
              onClick={() => {
                setOpen(false);
                resetSelected();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {isFetching && (
          <Box sx={classes.preloaderWrapper}>
            <Preloader opacity />
          </Box>
        )}
        <Box display="flex">
          <Button
            sx={classes.button}
            variant="contained"
            color="secondary"
            component="a"
            size="small"
            onClick={() => redirectToCreateQuestionnaire()}
          >
            Add New Questionnaire
          </Button>
        </Box>
        <TableContainer component={Paper} sx={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '400px' }}>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionnaires.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">
                    <Box sx={{ display: 'flex', whiteSpace: 'nowrap' }}>
                      <IconButton
                        sx={classes.iconButton}
                        title="Edit"
                        onClick={() => redirectToDetails(row.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        sx={classes.iconButton}
                        title="Delete"
                        onClick={() => {
                          setOpen(true);
                          setSelectedID(row.id);
                          setSelectedName(row.name);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display="flex" justifyContent="end" sx={{ marginTop: '20px' }}>
          <Pagination
            count={count}
            size="small"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </Box>
      </Box>
    </LayoutAdmin>
  );
};

export default AdminQuestionnaires;
