export const earnList = [
  {
    id: 1,
    color: '#452880',
    text: 'Taking public transit, biking, and walking',
  },
  {
    id: 2,
    color: '#2DAB72',
    text: 'Volunteering, interning, helping family, and neighbors',
  },
  {
    id: 3,
    color: '#64DEA6',
    text: 'Seeking tutoring, mentoring, skills training, and career advice',
  },
  {
    id: 4,
    color: '#FDAE2C',
    text: 'Completing emotional wellness modules',
  },
  {
    id: 5,
    color: '#CF5499',
    text: 'Saving, budgeting, and debt reduction',
  },
  {
    id: 6,
    color: '#F26C40',
    text: 'Attending campus programs and events',
  },
  {
    id: 7,
    color: '#2EAB70',
    text: 'Reducing, reusing, refilling, up-cycling, and conserving',
  },
  {
    id: 8,
    color: '#4AC2CE',
    text: 'Participating in games, trivia, and challenges',
  },
];
