// import palette from 'styles/theme/palette';

const classes = {
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    margin: '-15px 8px 15px 0',
  },
  strengthBarsWrapper: {
    display: 'flex',
    marginLeft: '8px',
  },
  strengthTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 500,
  },
  strengthTitle: {
    marginRight: '5px',
  },
};

export default classes;
