import React from 'react';
import { CardMedia } from '@mui/material';

export const images = [
  {
    id: 1,
    card: (
      <CardMedia component="img" src={'assets/images/programs/P4S_1.jpeg'} />
    ),
  },
  {
    id: 2,
    card: (
      <CardMedia component="img" src={'assets/images/programs/P4S_2.jpeg'} />
    ),
  },
  {
    id: 3,
    card: (
      <CardMedia component="img" src={'assets/images/programs/P4S_3.jpeg'} />
    ),
  },
  {
    id: 4,
    card: (
      <CardMedia component="img" src={'assets/images/programs/P4S_4.jpeg'} />
    ),
  },
];
