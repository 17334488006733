import React from 'react';

import Tabs from 'components/Tabs';
import TabLabel from './TabLabel';
import TabPanel from './TabPanel';
import classes from './styles';

const PieTabs = ({ ariaLabel, tabs }) => {
  if (!(Array.isArray(tabs) && tabs.length > 0)) return;

  return (
    <Tabs
      sx={classes.tabs}
      sxTab={classes.tab}
      ariaLabel={ariaLabel}
      tabs={tabs.map((tab) => ({
        label: <TabLabel {...tab} />,
        panel: <TabPanel actions={tab.actions} />,
      }))}
    />
  );
};

export default PieTabs;
