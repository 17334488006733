import yup from 'services/yup';

import { messages } from 'config/messages';
import { emailPattern, passwordPattern, phonePattern } from 'config/patterns';

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth();
const day = today.getDate();
const todayDate13YearsAgo = new Date(year - 13, month, day, '23', '59');

export const FormValidationSchema = yup.object().shape(
  {
    email: yup
      .string()
      .email(messages.validation.EMAIL)
      .matches(emailPattern, messages.validation.EMAIL)
      .typeError(messages.validation.EMAIL)
      .required(messages.validation.REQUIRED),
    firstName: yup
      .string()
      .required(messages.validation.REQUIRED)
      .max(64, messages.validation.MAX_LENGTH),
    lastName: yup
      .string()
      .required(messages.validation.REQUIRED)
      .max(64, messages.validation.MAX_LENGTH),
    password: yup
      .string()
      .required(messages.validation.REQUIRED)
      .min(8, messages.validation.PASSWORD_MIN)
      .max(64, messages.validation.MAX_LENGTH)
      .matches(passwordPattern, messages.validation.PASSWORD),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], messages.validation.CONFIRM_PASSWORD),
    dayOfBirth: yup
      .date(messages.validation.DATE)
      .max(todayDate13YearsAgo, messages.validation.MIN_DATE)
      .typeError(messages.validation.DATE)
      .required(messages.validation.REQUIRED),
    phone: yup
      .string()
      .notRequired()
      .when('phone', {
        is: (value) => value?.length,
        then: (rule) => rule.matches(phonePattern, messages.validation.PHONE),
      })
      .max(25, messages.validation.PHONE_MAX_LENGTH),
    customTermsAccepted: yup
      .bool()
      .oneOf([true], messages.validation.ACCEPT_TERMS),
  },
  [['phone', 'phone']]
);
