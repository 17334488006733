import React from 'react';
import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material';
import classes from './styles';
import { useTheme } from '@mui/system';

const GeneralInformation = () => {
  const theme = useTheme();
  const showImage = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={classes.root}>
      <Box component="section" sx={classes.generalInfoTop}>
        <Box sx={classes.generalInfoWrapper}>
          <Typography variant="h1">Imagine…</Typography>
          <Typography mb={2}>
            If every time a student takes public transit, recycles, meets with a
            tutor, or helps out at home, they are rewarded with a “currency of
            good” they can use to unlock grants for school.
          </Typography>
          <Typography fontWeight="bold">Now they can with PIPs!</Typography>
        </Box>
      </Box>
      <Box component="section" sx={classes.generalInfoBottom}>
        <Box sx={classes.generalInfoWrapper}>
          <Typography variant="h4" sx={{ fontStyle: 'italic' }}>
            What are PIPs?
          </Typography>
          <Typography>
            Positive Impact Points, or PIPs, is a currency of good earned
            exclusively for engagement in verifiable daily life behaviors that
            benefit the planet, community, and personal health.
          </Typography>
        </Box>
      </Box>
      {showImage && (
        <Box sx={classes.generalInfoImage}>
          <CardMedia component="img" src="assets/images/programs/imagine.jpg" />
        </Box>
      )}
    </Box>
  );
};

export default GeneralInformation;
