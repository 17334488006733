import palette from 'styles/theme/palette';

const classes = {
  root: {
    color: '#fff',
    position: 'relative',
  },
  generalInfoTop: {
    paddingTop: '45px',
    paddingBottom: '45px',
    background: palette.background.blue,
    '@media (min-width: 1024px)': {
      paddingTop: '85px',
      paddingBottom: '65px',
    },
  },
  generalInfoBottom: {
    background: palette.background.main,
    paddingTop: '45px',
    paddingBottom: '45px',
    '@media (min-width: 1024px)': {
      paddingTop: '55px',
      paddingBottom: '55px',
    },
  },
  generalInfoWrapper: {
    '@media (min-width: 1024px)': {
      maxWidth: '50%',
    },
  },
  generalInfoImage: {
    '@media (min-width: 1024px)': {
      position: 'absolute',
      bottom: '-75px',
      right: '-100px',
      width: '600px',
      height: '600px',
      borderRadius: '50%',
      overflow: 'hidden',
      '& img': {
        height: '100%',
      },
    },
  },
};
export default classes;
