const tools = [
  {
    id: 1,
    text: 'Machine Learning (via in-app sensors)',
  },
  {
    id: 2,
    text: 'APIs',
  },
  {
    id: 3,
    text: 'NFC Devices',
  },
  {
    id: 4,
    text: 'Beacons',
  },
  {
    id: 5,
    text: 'QR Codes',
  },
  {
    id: 6,
    text: 'Transaction Reports',
  },
];

export default tools;
