import React, { useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import classes from './styles';

import { LogoIcon } from 'assets/icons';
import { useSelector } from 'react-redux';
import { userSelector } from 'store/user/UserSlice';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const HeaderMobile = ({ openLoginDialog, handleLogout }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { user, isAuthenticated } = useSelector(userSelector);

  return (
    <Box sx={classes.root}>
      <Link href="/">
        <LogoIcon />
      </Link>
      <Box display="flex" alignItems="center">
        {isAuthenticated ? (
          <Link href="/profile/edit-profile" sx={classes.editProfileLink}>
            <AccountCircleIcon sx={classes.icon} />
          </Link>
        ) : (
          <Box sx={classes.headerLinks}>
            <Link
              variant="subtitle1"
              component="button"
              onClick={openLoginDialog}
              fontSize={16}
            >
              Login
            </Link>
            <Link href="/registration">Sign Up</Link>
          </Box>
        )}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setOpenDrawer(!openDrawer)}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={classes.drawer}
      >
        <List sx={classes.list}>
          {isAuthenticated ? (
            <ListItem>
              <ListItemText>
                <strong className="username">
                  Hello, {user.firstName} {user.lastName}
                </strong>
              </ListItemText>
            </ListItem>
          ) : null}
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link href="/programs" underline="none">
                Programs
              </Link>
            </ListItemText>
          </ListItem>
          {!isAuthenticated ? (
            <>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link href="/users" underline="none">
                    Users
                  </Link>
                </ListItemText>
              </ListItem>
            </>
          ) : null}
          {isAuthenticated ? (
            <>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link href="/promo/earn" underline="none">
                    Earn
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link href="/promo/rewards/pips-for-school" underline="none">
                    Rewards
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link
                    href="/dashboard/impact-carbon-footprint"
                    underline="none"
                  >
                    Dashboard
                  </Link>
                </ListItemText>
              </ListItem>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link href="/how-pips-works" underline="none">
                    How PIPs Works
                  </Link>
                </ListItemText>
              </ListItem>
            </>
          ) : null}
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link
                variant="link"
                color="secondary"
                href="https://pipseducationfund.org/donate/"
                target="_blank"
              >
                Support PIPs Education Fund
              </Link>
            </ListItemText>
          </ListItem>
          {isAuthenticated ? (
            <ListItem onClick={handleLogout}>
              <ListItemText>
                <strong>Logout</strong>
              </ListItemText>
            </ListItem>
          ) : null}
        </List>
      </Drawer>
    </Box>
  );
};

export default HeaderMobile;
