const possibilities = [
  {
    id: 1,
    text: 'Keeps them connected & engaged',
    src: 'assets/images/programs/possibility_1.jpg',
  },
  {
    id: 2,
    text: 'Gives them a sense that their actions do matter',
    src: 'assets/images/programs/possibility_2.png',
  },
  {
    id: 3,
    text: 'Motivates them to maintain healthy habits',
    src: 'assets/images/programs/possibility_3.jpg',
  },
];

export default possibilities;
