import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import MyGiftCard from '../MyGiftCard';
import Preloader from 'components/Preloader';
import { getMyGiftCards } from 'services/api';
import MyGiftCardsPlaceholder from '../MyGiftCardsPlaceholder';
import { handleError } from 'services/handleError';

const MyGiftCards = () => {
  const [cards, setCards] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    const query = { page: 1, size: 500 };
    getMyGiftCards({ query })
      .then((res) => {
        if (res.status === 'OK') {
          setCards(res.cards);
          setIsFetching(false);
        } else {
          handleError(res);
        }
      })
      .catch((err) => {
        setIsFetching(false);
        handleError(err);
      });
  }, []);

  if (isFetching) return <Preloader />;
  if (!cards.length) return <MyGiftCardsPlaceholder />;

  return (
    <Box>
      <ul>
        {cards.map((card) => (
          <MyGiftCard key={card.transactionId} card={card} />
        ))}
      </ul>
    </Box>
  );
};

export default MyGiftCards;
