import React from 'react';
import { Typography } from '@mui/material';

export default [
  {
    name: 'Wendy Gordon',
    occupation: (
      <Typography variant="caption">
        Co-Founder, <br />
        President
      </Typography>
    ),
    avatar: 'assets/images/team-members/gordon.png',
    description:
      'An experienced entrepreneur and pioneer in the conscious consumer movement, Wendy co-created the PIPs Rewards mobile engagement platform to leverage…',
    descriptionFull: (
      <>
        <p>
          An experienced entrepreneur and pioneer in the conscious consumer
          movement, Wendy co-created the PIPs Rewards mobile engagement platform
          to leverage the power of a rewards currency (called “Positive Impact
          Points” or “PIPs”), smart tools, and games to track and reward daily
          life choices that deliver personal and planetary benefit. When it was
          just a prototype, the platform won the “Best Game” prize in Big Apps
          NYC 2014 Competition, the nation’s leading civic innovations
          competition.
        </p>
        <p>
          Today the tech platform is helping to bridge the education equity gap
          by turning student engagement in climate, health, and community
          behaviors into grant support for school fees and credits to meet other
          basic needs.
        </p>
        <p>
          Previously, Wendy worked as senior scientist at the Natural Resources
          Defense Council, co-founded Mothers & Others with Meryl Streep, and
          originated the Green Guide, which was acquired by National Geographic
          in 2007. She has co-authored numerous books, including the
          award-winning True Food: 8 Simple Steps to a Healthier You (National
          Geographic, 2009).
        </p>
        <p>
          Wendy holds a BS in Geological and Geophysical Sciences from Princeton
          University and an MS in Environmental Health Sciences from the Harvard
          School of Public Health. She currently serves on the board of The
          Rainforest Alliance, the Board of Governors of Eugene Lang/The New
          School, and is also a member of the PIPs Education Fund (PEF) board.
        </p>
        <p>
          When she’s not busy with PIPs, Wendy enjoys cross-country skiing,
          hiking, gardening, and spending time with her family (which now
          includes three grandchildren).
        </p>
      </>
    ),
  },
  {
    name: 'Tamera Brown',
    occupation: (
      <Typography variant="caption">
        Partnerships <br />
        Associate
      </Typography>
    ),
    avatar: 'assets/images/team-members/brown.png',
    description:
      'As a former Educator and Community Service Director, Tamera, Tam for short, will be utilizing her passion for people, service and connection to serve…',
    descriptionFull: (
      <>
        <p>
          As a former Educator and Community Service Director, Tamera, Tam for
          short, will be utilizing her passion for people, service and
          connection to serve as our Development Fellow. After graduating in
          2019 from Spelman College with a BA in Political Science and a
          concentration in International Studies, Tamera committed to two years
          of service with Teach For America. On this journey, she rooted herself
          in the belief that one day, all students will have access to a quality
          education. She was thrilled upon her discovery of PIPs as she will be
          able to combat education inequity from another angle. She is delighted
          to be a part of the PIPs team and eager to develop strategies for
          fundraising, communications and business.
        </p>
        <p>
          Tamera spends her free time riding her bike, creating short stories
          and exploring all the world has to offer.
        </p>
      </>
    ),
  },
  {
    name: 'Sam Meliboev',
    occupation: (
      <Typography variant="caption">
        Data Analytics <br />
        Specialist
      </Typography>
    ),
    avatar: 'assets/images/team-members/meliboev.png',
    description:
      'Samariddin Meliboev, Sam for short, is PIPs’ go-to guy for data analytics and customer support. Currently a junior at University of Colorado Boulder (CU)…',
    descriptionFull: (
      <>
        Samariddin Meliboev, Sam for short, is PIPs’ go-to guy for data
        analytics and customer support. Currently a junior at University of
        Colorado Boulder (CU), Sam is majoring in Information Management and
        Business Analytics. As PIPs’ Data Analytics Specialist, he executes data
        analyses, investigates and solves user queries, and manages much of
        PIPs’ rewards distribution. Outside of work, Sam enjoys exercising,
        traveling, and playing team sports.
      </>
    ),
  },
];
