import { showErrorNotification } from 'services/notifications';

export const copyToClipboard = (text, callback) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      () => callback(),
      (err) => {
        showErrorNotification('Failed to copy the text to clipboard.', err);
      }
    );
  } else if (window.clipboardData) {
    window.clipboardData.setData('Text', text);
  }
};
