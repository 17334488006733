import React from 'react';
import { Box, useMediaQuery } from '@mui/material';

import { AnimatedText } from 'pages/Users/components';
import classes from './styles';

const ColoredText = ({ id, shouldShowContent }) => {
  const isScreenWidth1600OrBigger = useMediaQuery('(min-width: 1600px)');

  return (
    <Box
      id={id}
      sx={classes.root}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {shouldShowContent && (
        <AnimatedText
          fontSize={isScreenWidth1600OrBigger ? '7vh' : '6vh'}
          lineHeight={1.3}
          align="left"
          text={
            <>
              <span>It&apos;s</span> <span>a</span>
              <br />
              <span style={{ color: '#fcad2d' }}>self</span>
              <span style={{ color: '#fcad2d' }}>-nurturing,</span>
              <br />
              <span style={{ color: '#2fae72' }}>planet</span>
              <span style={{ color: '#2fae72' }}>-protecting,</span>
              <br />
              <span style={{ color: '#4cc4d1' }}>socially </span>
              <span style={{ color: '#4cc4d1' }}>conscious</span>
              <br />
              <span>360°</span> <span>cycle</span> <span>of</span>{' '}
              <span>good.</span>
            </>
          }
        />
      )}
    </Box>
  );
};

export default ColoredText;
