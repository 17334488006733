import palette from 'styles/theme/palette';

const classes = {
  root: {
    '& .MuiDialogContent-root': {
      maxWidth: '80%',
      margin: '0 auto',
    },
    '& .MuiGrid-root>.MuiGrid-item': {
      paddingLeft: 0,
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
  checkbox: {
    marginBottom: '32px',
    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        fontSize: '18px',
        fontWeight: 400,
        textAlign: 'left',
      },
    },
  },
  checkboxError: {
    color: palette.error.main,
    textAlign: 'left',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default classes;
