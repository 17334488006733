import yup from 'services/yup';

import { messages } from 'config/messages';
import { passwordPattern } from 'config/patterns';

export const FormValidationSchema = yup.object().shape(
  {
    currentPassword: yup
      .string()
      .when('currentPassword', {
        is: (val) => val && val.length > 0,
        then: yup
          .string()
          .required(messages.validation.REQUIRED)
          .min(8, messages.validation.PASSWORD_MIN)
          .max(64, messages.validation.MAX_LENGTH)
          .matches(passwordPattern, messages.validation.PASSWORD),
        otherwise: yup.string().notRequired(),
      })
      .when('updatedPassword', (updatedPassword, schema) => {
        if (updatedPassword && updatedPassword.length > 0) {
          return yup
            .string()
            .required(messages.validation.REQUIRED)
            .min(8, messages.validation.PASSWORD_MIN)
            .max(64, messages.validation.MAX_LENGTH)
            .matches(passwordPattern, messages.validation.PASSWORD);
        } else {
          return schema;
        }
      })
      .when('confirmUpdatedPassword', (confirmUpdatedPassword, schema) => {
        if (confirmUpdatedPassword && confirmUpdatedPassword.length > 0) {
          return yup
            .string()
            .required(messages.validation.REQUIRED)
            .min(8, messages.validation.PASSWORD_MIN)
            .max(64, messages.validation.MAX_LENGTH)
            .matches(passwordPattern, messages.validation.PASSWORD);
        } else {
          return schema;
        }
      }),
    updatedPassword: yup.string().when('currentPassword', {
      is: (val) => val && val.length > 0,
      then: yup
        .string()
        .required(messages.validation.REQUIRED)
        .min(8, messages.validation.PASSWORD_MIN)
        .max(64, messages.validation.MAX_LENGTH)
        .matches(passwordPattern, messages.validation.PASSWORD),
      otherwise: yup.string().notRequired(),
    }),
    confirmUpdatedPassword: yup.string().when('updatedPassword', {
      is: (val) => val && val.length > 0,
      then: yup
        .string()
        .required(messages.validation.REQUIRED)
        .oneOf(
          [yup.ref('updatedPassword'), null],
          messages.validation.CONFIRM_PASSWORD
        ),
      otherwise: yup.string().notRequired(),
    }),
  },
  [
    ['currentPassword', 'currentPassword'],
    ['currentPassword', 'updatedPassword'],
    ['currentPassword', 'confirmUpdatedPassword'],
  ]
);
