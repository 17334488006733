const classes = {
  root: {
    zIndex: 1,
    position: 'relative',
    bgcolor: 'background.blue',
    color: 'white',
    fontSize: 18,
    paddingTop: '30px',
    '@media (min-width: 1024px)': {
      '& li:nth-of-type(even) img': {
        order: 1,
        position: 'relative',
        transform: 'translateX(-27px)',
      },
      '& li:nth-of-type(even) .text': {
        marginLeft: 0,
        marginRight: 'auto',
      },
    },
    '& .text': {
      textAlign: 'center',
      paddingTop: '38px',
      paddingBottom: '38px',
      '@media (min-width: 1024px)': {
        width: '50%',
        marginLeft: 'auto',
        lineHeight: 1.3,
        textAlign: 'left',
        paddingBottom: 0,
      },
    },
    '@media (min-width: 768px)': {
      paddingTop: 0,
    },
  },
  imgPips: {
    width: 170,
    height: 170,
    display: 'block',
    margin: '-100px 0 0 auto',
    position: 'relative',
    transform: 'translateY(50%)',
  },
  imgEarth: {
    width: 300,
    height: 300,
    display: 'block',
    margin: '0 auto -80px',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
  image: {
    width: '280px',
    height: '238px',
    borderRadius: '24px',
    boxShadow: '27px 27px 0px -4px #64dea6',
    '@media (min-width: 600px)': {
      width: '350px',
      height: '318px',
    },
  },
  list: {
    marginBottom: '27px',
    '& li': {
      paddingLeft: 1.5,
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 1,
        top: '12px',
        width: '4px',
        height: '4px',
        borderRadius: '50%',
        background: 'white',
      },
    },
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (min-width: 1024px)': {
      flexDirection: 'row',

      marginBottom: '84px',
    },
  },
};

export default classes;
