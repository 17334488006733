import yup from 'services/yup';

import { messages } from 'config/messages';
import { emailPattern } from 'config/patterns';

export const FormValidationSchema = yup.object().shape({
  pips: yup
    .number()
    .typeError(messages.validation.NUMBER)
    .required(messages.validation.REQUIRED)
    .positive(messages.validation.NUMBER_POSITIVE)
    .integer(messages.validation.INTEGER),
  recipientEmail: yup
    .string()
    .email(messages.validation.EMAIL)
    .matches(emailPattern, messages.validation.EMAIL)
    .typeError(messages.validation.EMAIL)
    .required(messages.validation.REQUIRED),
  recipientName: yup.string().required(messages.validation.REQUIRED),
  pipsForSchool: yup.boolean().required(messages.validation.REQUIRED),
});
