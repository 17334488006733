import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import classes from './styles';
import {
  clearState,
  getUser,
  updateUser,
  userSelector,
} from 'store/user/UserSlice';
import { handleError } from 'services/handleError';
import { showSuccessNotification } from 'services/notifications';

const SettingsTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSuccess, isError, error, user } = useSelector(userSelector);

  const { control, handleSubmit, reset } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    const defaultValues = user;
    reset({ ...defaultValues });
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const onSubmit = handleSubmit((data) => {
    const body = {
      usePrivate: data.use === 'private',
      donatePrivate: data.donate === 'private',
      earnPrivate: data.earn === 'private',
    };

    dispatch(updateUser({ body })).then((res) => {
      if (res.payload.status === 'OK') {
        dispatch(getUser()).then(() =>
          showSuccessNotification('Updated successfully')
        );
      } else {
        if (res.payload.code === 'INVALID_TOKEN') {
          const resObj = {
            ...res.payload,
            code: 'GENERIC_ERROR',
          };
          handleError(resObj);
        } else {
          handleError(res.payload);
        }
      }
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Box sx={classes.root}>
        <Typography textAlign="center">Who can see my actions:</Typography>
        <Box sx={classes.radioGroupWrapper}>
          <Box>
            <Controller
              control={control}
              name="earn"
              render={({ field }) => (
                <RadioGroup {...field} row sx={classes.radioGroup}>
                  <Typography
                    variant="body2"
                    fontWeight={'bold'}
                    sx={classes.radioGroupLabel}
                  >
                    Earn:{' '}
                  </Typography>
                  <Box sx={classes.radioGroupOptions}>
                    <FormControlLabel
                      value="public"
                      control={<Radio checked={field.value === 'public'} />}
                      label="Public"
                    />
                    <FormControlLabel
                      value="private"
                      control={<Radio checked={field.value === 'private'} />}
                      label="Just Me"
                    />
                  </Box>
                </RadioGroup>
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="use"
              render={({ field }) => (
                <RadioGroup {...field} row sx={classes.radioGroup}>
                  <Typography
                    variant="body2"
                    fontWeight={'bold'}
                    sx={classes.radioGroupLabel}
                  >
                    Use:{' '}
                  </Typography>
                  <Box sx={classes.radioGroupOptions}>
                    <FormControlLabel
                      value="public"
                      control={<Radio checked={field.value === 'public'} />}
                      label="Public"
                    />
                    <FormControlLabel
                      value="private"
                      control={<Radio checked={field.value === 'private'} />}
                      label="Just Me"
                    />
                  </Box>
                </RadioGroup>
              )}
            />
          </Box>
          <Box>
            <Controller
              control={control}
              name="donate"
              render={({ field }) => (
                <RadioGroup {...field} row sx={classes.radioGroup}>
                  <Typography
                    variant="body2"
                    fontWeight={'bold'}
                    sx={classes.radioGroupLabel}
                  >
                    Donate:{' '}
                  </Typography>
                  <Box sx={classes.radioGroupOptions}>
                    <FormControlLabel
                      value="public"
                      control={<Radio checked={field.value === 'public'} />}
                      label="Public"
                    />
                    <FormControlLabel
                      value="private"
                      control={<Radio checked={field.value === 'private'} />}
                      label="Just Me"
                    />
                  </Box>
                </RadioGroup>
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={classes.btnGroup}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button variant="contained" color="secondary" type="submit">
          Save Changes
        </Button>
      </Box>
    </form>
  );
};

export default SettingsTab;
