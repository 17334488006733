import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

const Buttons = () => {
  const [value, setValue] = useState('');
  const [email, setEmail] = useState('email@gmail.com');

  return (
    <Box display="flex" py={4}>
      <Box pr={8} width={500}>
        <FormControlLabel
          labelPlacement="top"
          label="TextField empty"
          control={
            <TextField
              name="email"
              value={value}
              fullWidth
              inputProps={{
                autoComplete: 'off',
                placeholder: 'Enter Your Email',
              }}
              error={false}
              helperText={false}
              disabled={false}
              onChange={(event) => setValue(event.target.value)}
            />
          }
        />
        <FormControlLabel
          labelPlacement="top"
          label="TextField filled"
          control={
            <TextField
              name="email"
              value={email}
              fullWidth
              inputProps={{ autoComplete: 'off' }}
              error={false}
              helperText={false}
              disabled={false}
              onChange={(event) => setEmail(event.target.value)}
            />
          }
        />
        <FormControlLabel
          labelPlacement="top"
          label="TextField incorrect"
          control={
            <TextField
              name="email"
              value="email*gmail.com"
              fullWidth
              inputProps={{ autoComplete: 'off' }}
              error
              helperText="Incorrect entry"
              disabled={false}
            />
          }
        />
        <FormControlLabel
          labelPlacement="top"
          label="TextField disabled"
          control={
            <TextField
              name="email"
              defaultValue="email@gmail.com"
              fullWidth
              inputProps={{ autoComplete: 'off' }}
              error={false}
              helperText={false}
              disabled={true}
            />
          }
        />
      </Box>
      <Box width={1}>
        <Box mb={4}>
          <Typography variant="h3">Checkbox</Typography>
          <Box display="flex" justifyContent="flex-start">
            <FormControlLabel control={<Checkbox />} label="Unchecked" />
            <FormControlLabel control={<Checkbox checked />} label="Checked" />
            <FormControlLabel
              control={<Checkbox disabled />}
              label="Disabled"
            />
            <FormControlLabel
              control={<Checkbox checked disabled />}
              label="Checked disabled"
            />
          </Box>
        </Box>
        <Box>
          <Typography variant="h3">Switcher</Typography>
          <Switch color="primary" />
          <Switch color="primary" checked />
          <Switch color="primary" disabled />
          <Switch color="primary" checked disabled />
        </Box>
      </Box>
    </Box>
  );
};

export default Buttons;
