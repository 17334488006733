import React from 'react';
import { Box, Typography } from '@mui/material';

import Layout from 'components/Layout';
import { MainStaff, AdvisoryCouncil, Directors } from './components';
import classes from './styles';

const Team = () => (
  <Layout>
    <Box sx={classes.root}>
      <Typography
        variant="h3"
        color="primary"
        align="center"
        sx={classes.title}
      >
        PIPs Rewards LLC Staff, Advisors, PEF Board
      </Typography>
      <MainStaff />
      <AdvisoryCouncil />
      <Directors />
    </Box>
  </Layout>
);

export default Team;
