import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CardMedia, Typography } from '@mui/material';
import {
  clearState,
  giftCardsSelector,
  getGiftCard,
} from 'store/myGiftCards/MyGiftCardsSlice';
import classes from './styles';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import {
  AnimationType,
  APCodeType,
  BulkLinkType,
  LinkType,
  QRCodeType,
  TwoFactorType,
} from './components';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';
import { NotFound } from 'pages';
import BackButton from 'components/BackButton';

const renderCouponDetails = (card) => {
  const { coupon } = card;

  switch (coupon.type) {
    case 'ANIMATION':
      return <AnimationType card={card} />;
    case 'AP_CODE':
      return <APCodeType card={card} />;
    case 'BULK_LINK':
      return <BulkLinkType card={card} />;
    case 'LINK':
      return <LinkType card={card} />;
    case 'QR_CODE':
      return <QRCodeType card={card} />;
    case 'TWO_FACTOR':
      return <TwoFactorType card={card} />;
  }
};

const GiftCardDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { isFetching, isSuccess, isError, error, card } =
    useSelector(giftCardsSelector);

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(getGiftCard({ id: params.id }));
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  if (isFetching) return <Preloader />;
  if (!Object.keys(card).length) return <NotFound />;

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Box sx={classes.detailsHeader}>
          <BackButton />
          <Typography variant="h2" color="primary" mb={1}>
            {card.title}
          </Typography>
        </Box>
        <Box sx={classes.content}>
          <Box sx={classes.image}>
            <CardMedia component="img" src={card.imageUrl} />
          </Box>
          <Box sx={classes.description}>
            {Object.keys(card).length && renderCouponDetails(card)}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default GiftCardDetails;
