export const testimonials = [
  {
    author: 'Kavindra H.',
    avatar: 'assets/images/testimonials/Kavindra_Horil.png',
    position: 'CUNY Queens College',
    text: 'When you become conscious of what you are doing, the impact it has, it motivates you to keep on doing it.',
  },
  {
    author: 'Dawn L.',
    avatar: 'assets/images/testimonials/Dawn_Lugo.png',
    position: 'Long Island University',
    text: 'The goal is to get more points, but if [you] keep doing the actions—[it’s like] muscle memory—you’ll just do them automatically and sometimes even forget about the points.',
  },
  {
    author: 'Kevin P.',
    avatar: 'assets/images/testimonials/Kevin_Persaud.png',
    position: 'City College of NY',
    text: '[PIPs is] about developing skills, being more open, [and] being more aware that there are problems in the world and [that] we have the power to change things.',
  },
  {
    author: 'Chrisber V.',
    avatar: 'assets/images/testimonials/Chrisber_Vasquez.png',
    position: 'High School Senior',
    text: 'I was not aware of the amount of actions, good actions, I do in the day. I was surprised to see the calculator of the amount of CO2 reduced by me just walking. It’s crazy. It really makes you more conscious of the fact that making little decisions in life can affect all of us.',
  },
  {
    author: 'Little  A.',
    avatar: 'assets/images/testimonials/Little_Azeez.png',
    position: 'NYC College of Technology',
    text: 'The P4S program helps me visualize what I am doing now to get to my goals and being mindful about the process.. that the biggest thing, it encourages mindfulness.',
  },
  {
    author: 'Kylie F.',
    avatar: 'logo512.png',
    position: 'University of Colorado Colorado Springs',
    text: 'I got super excited [to learn about PIPs] because I’ve been struggling to pay for school by myself! I am from Thornton, Colorado, I am a psychology major and want to help people with disabilities.. I love that PIPs is helping students with financial need like me.',
  },
  {
    author: 'Cale K.',
    avatar: 'assets/images/testimonials/Cale_Kennamer.png',
    position: 'University of Colorado Colorado Springs',
    text: 'PIPs can be the difference maker for students and their families. [As I guide new students around campus] I really love to show them that there are opportunities here to add to the quality of their education AND make a difference while mitigating your financial cost.',
  },
  {
    author: 'John',
    avatar: 'logo512.png',
    position: 'CU Boulder',
    text: 'PIPs is a great way to raise awareness for environmental causes while also offering financial aid to students. I’m a full-time graduate student. Having PIPs as an extra ‘scholarship’ makes saving money a little easier. I knocked almost $1,000 off my school expenses last year!',
  },
  {
    author: 'Linda Kogan',
    avatar: 'logo512.png',
    position: 'Former Director of Sustainability at UCCS',
    text: 'This program is significant and has an opportunity for the Office of Sustainability to be elevated as an important partner in student engagement and retention efforts on campus.',
  },
  {
    author: 'Ofelia Morales',
    avatar: 'assets/images/testimonials/Ofelia_Morales.png',
    position: 'Director, Office of Financial Aid at CU Boulder',
    text: 'We are very concerned, of course, with keeping college affordable. We’re also very concerned with just the overall financial wellness of students. When PIPs was presented to us, it really aligned with our strategic goals.',
  },
  {
    author: 'Dave Newport',
    avatar: 'assets/images/testimonials/Dave_Newport.jpg',
    position: 'Director, Environmental Center at CU Boulder',
    text: 'An engaged freshman is a retained sophomore.',
  },
  {
    author: 'Stephanie Browner',
    avatar: 'assets/images/testimonials/Stephanie_Browner.png',
    position: 'Professor, Special Advisor to the President, New School',
    text: 'Adding the scholarship component knits together the whole endeavor of points-for-good in the higher education sector. The agency it gives students is appealing, and it should be attractive to individual donors and foundations who want to support scholarships and the kinds of “goods” that PIPs rewards.',
  },
  {
    author: 'Wendy Gordon',
    avatar: 'assets/images/testimonials/Wendy_Gordon.png',
    position: 'President, PIPs Education Fund',
    text: 'If we can instill in our students a greater sense of personal responsibility that becomes central to who they are, that’s how we believe we can make everyday engagement more meaningful.',
  },
  {
    author: 'Chris Molaro',
    avatar: 'assets/images/testimonials/Chris_Molaro.jpg',
    position: 'CEO and Founder, Neuroflow',
    text: 'It is proven that rewards and behavioral economics mechanisms can change human behavior, develop habits, and ultimately lead to positive change. PIPs Rewards is an early innovator in this space with a mission-driven culture to help drive positive change in schools and for our youth.',
  },
  {
    author: 'Kevin Crouse',
    avatar: 'logo512.png',
    position: 'CEO, Boulder B-cycle',
    text: 'We reward our riders for redistributing bikes from full stations to empty ones. They love PIPs, and we love having pedal power do our balancing!',
  },
  {
    author: 'Ilya Lyashevsky',
    avatar: 'logo512.png',
    position: 'CEO, Affectifi Inc.',
    text: 'We are delighted to be using PIPs to help reward our users as they improve their emotional skills with ThinkHuman.tv. Our platform was built to be a truly enjoyable way to enhance emotional literacy, and PIPs adds that extra level of fun and motivation with points people can use in the real world, particularly as a way to pay for school.',
  },
  {
    author: 'Carol Ash',
    avatar: 'assets/images/testimonials/Carol_Ash.jpg',
    position: 'Vice Chair, Palisades Parks Conservancy',
    text: 'We all benefit when kids are familiar with natural resources - both the tangible environment and the language and ethic of the environment.',
  },
  {
    author: 'Ryan Maikell',
    avatar: 'assets/images/testimonials/Ryan_Maikell.jpeg',
    position: '',
    text: 'Working as the PIPs Engagement Specialist at UCCS was the most rewarding experience of my time in college. Through this work, which was offered through the  Sustainability Office, I was able to apply the curriculum that I was learning and become more engaged on my campus.',
  },
];
