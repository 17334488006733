import React from 'react';
import Layout from 'components/Layout';
import palette from 'styles/theme/palette';
import { Box, Link, Typography } from '@mui/material';
import classes from './styles';

const RedeemSuccess = () => {
  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Typography variant="h2" align="center">
          Success!
        </Typography>
        <Typography component="p" align="center">
          This redemption is permanently stored in your &nbsp;
          <Link href="/dashboard/my-gift-cards">Gift Cards</Link>.
        </Typography>
      </Box>
    </Layout>
  );
};

export default RedeemSuccess;
