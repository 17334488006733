import palette from 'styles/theme/palette';

const classes = {
  root: {
    paddingTop: '45px',
    paddingBottom: '85px',
  },
  detailsHeader: {
    textAlign: 'center',
    position: 'relative',
  },
  categoryName: {
    position: 'relative',
    paddingLeft: '12px',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '4px',
      height: '4px',
      background: palette.text.grey,
      borderRadius: '50%',
    },
  },
  promoTitle: {
    lineHeight: 1,
    paddingTop: 3,
    '@media (min-width: 768px)': {
      lineHeight: 1.2,
      paddingTop: 0,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '50px',
    flexDirection: 'column',
    padding: '50px 15px 0',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      padding: '50px 0 0',
    },
  },
  image: {
    height: '345px',
    marginBottom: '30px',
    '@media (min-width: 768px)': {
      flex: '1 0 50%',
      marginBottom: 0,
    },
    '@media (min-width: 1024px)': {
      paddingRight: '60px',
    },
    '& img': {
      boxShadow: '10px 10px 0 #2DAB72',
      borderRadius: '20px',
      height: '100%',
      '@media (min-width: 768px)': {
        boxShadow: '20px 20px 0 #2DAB72',
      },
    },
  },
  description: {
    '@media (min-width: 768px)': {
      flex: '1 0 50%',
      maxWidth: '50%',
      paddingLeft: '60px',
    },
  },
  selectWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  redeemSelect: {
    minWidth: '175px',
    maxWidth: '250px',
    marginBottom: 0,
  },
  redeemSelectOption: {
    whiteSpace: 'normal',
  },
  amountSelect: {
    minWidth: '150px',
    maxWidth: '250px',
    marginBottom: 0,
    marginLeft: '16px',
  },
  editBtn: {
    minWidth: 0,
    marginLeft: '16px',
    '&:hover, &:focus': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
};

export default classes;
