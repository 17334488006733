export const DONATE = 'DONATE';
export const REDEEM = 'REDEEM';
export const EARN = 'EARN';
export const PIPS_FOR_SCHOOL = 'PIPS_FOR_SCHOOL';
export const FORM_FIELD_DEFAULT_TYPE = 'checkbox-group';
export const FORM_FIELD_TYPE_OPTIONS = [
  {
    label: 'Short answer',
    value: 'text',
  },
  {
    label: 'Paragraph',
    value: 'textarea',
  },
  {
    label: 'Multiple choice',
    value: 'radio-group',
  },
  {
    label: 'Checkboxes',
    value: 'checkbox-group',
  },
  {
    label: 'Dropdown',
    value: 'select',
  },
];
export const FORM_FIELD_DEFAULT_VALUES = {
  label: 'Question',
  type: FORM_FIELD_DEFAULT_TYPE,
  isRequired: false,
  options: [{ value: 'Option 1', type: 'text', label: 'Option 1' }],
};
