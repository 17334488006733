import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import Badge from './Badge';
import { badgesDetails } from './settings';
import classes from './styles';

import { getBadges } from 'services/api';

const Badges = () => {
  const [badges, setBadges] = useState([]);

  useEffect(() => {
    getBadges().then((res) => {
      if (res.status === 'OK' && res?.badges && Array.isArray(res.badges)) {
        setBadges(
          res.badges.map((badge) => {
            const badgeDetails = badgesDetails.find(
              (b) => b.type === badge.type
            );
            return {
              ...badge,
              ...(badgeDetails && badgeDetails),
            };
          })
        );
      }
    });
  }, []);

  if (Array.isArray(badges) && badges.length === 0) return null;

  return (
    <Grid container spacing={2} sx={classes.root}>
      {badges.map((badge) => (
        <Grid key={badge.type} item md={2} sm={3} xs={6} sx={classes.badge}>
          <Badge badge={badge} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Badges;
