import { PADDING_MAIN } from 'config/ui';

const classes = {
  root: {
    paddingTop: `${PADDING_MAIN}px`,
    paddingLeft: `${PADDING_MAIN}px`,
    paddingRight: `${PADDING_MAIN}px`,
    paddingBottom: `${PADDING_MAIN}px`,
    maxWidth: '50%',
    margin: '0 auto',
  },
};

export default classes;
