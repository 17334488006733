import { grey } from 'styles/theme/palette';

const classes = {
  root: {
    paddingTop: '70px',
    paddingBottom: '70px',
    background: 'rgba(247, 247, 247, 1)',
  },
  title: {
    paddingBottom: '30px',
  },
  newsLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '20px',
    marginBottom: 3,
    boxShadow: '0px 3px 6px #00000029',
    textDecoration: 'none',
    color: grey,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
  },
  image: {
    width: '100%',
    height: '220px',
    borderRadius: '20px',
    marginBottom: 4,
    '@media (min-width: 768px)': {
      flex: '0 0 225px',
      width: 'auto',
      marginRight: 4,
      marginBottom: 0,
    },
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
};

export default classes;
