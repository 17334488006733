import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CardMedia, Button, Link } from '@mui/material';
import palette from 'styles/theme/palette';

import Layout from 'components/Layout';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classes from './styles';
import { slides } from './settings';
import NextArrow from 'components/sliderArrows/NextArrow';
import PrevArrow from 'components/sliderArrows/PrevArrow';

const HowPipsWorks = () => {
  const navigate = useNavigate();

  return (
    <Layout bgColor={palette.white}>
      <Box maxWidth="md" sx={classes.root}>
        <Slider
          autoPlay={false}
          infinite={false}
          slidesToShow={1}
          slidesToScroll={1}
          dots
          fade
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
        >
          {slides.map((slide, index) => (
            <Box key={index} sx={classes.slide}>
              <Box
                sx={classes.slideInner}
                style={{ backgroundColor: slide.bgColor }}
              >
                <Typography
                  variant="h2"
                  mb={slide.titleMarginBottom}
                  sx={classes.title}
                >
                  {slide.title}
                </Typography>
                <CardMedia
                  component="img"
                  src={slide.image}
                  sx={{
                    width: `${slide.imageWidth}px !important`,
                    height: `${slide.imageHeight}px`,
                    marginBottom: slide.imageMarginBottom,
                  }}
                />
                <Typography fontSize={20} align="center" lineHeight={1.2}>
                  {slide.description}
                </Typography>
                {slide.isFAQ && (
                  <Box sx={classes.actions}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('/faq')}
                    >
                      FAQ PAGE
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      href="mailto:support@pipsrewards.com"
                      underline="none"
                      component={Link}
                    >
                      SEND US AN EMAIL
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Layout>
  );
};

export default HowPipsWorks;
