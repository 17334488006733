import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CardMedia, Typography, Link } from '@mui/material';
import classes from './styles';

const EarnItem = ({ promo }) => {
  const navigate = useNavigate();

  const redirectToDetails = (id) => {
    navigate(`/promo/${id}`);
  };
  return (
    <Box component="li" sx={classes.root}>
      <Box sx={classes.image}>
        <CardMedia component="img" src={promo.imageUrl} />
      </Box>
      <Box sx={classes.wrapper}>
        <Box sx={classes.content}>
          <Typography sx={classes.categoryName}>
            {promo.categoryName}
          </Typography>
          <Typography variant="h4" color="primary" mb={1}>
            {promo.promoName}
          </Typography>
          <Typography sx={classes.promoDescription}>
            {promo.promoDescription}
          </Typography>
        </Box>
        <Box sx={classes.btnGroup}>
          <Link
            variant="link"
            color="secondary"
            onClick={() => redirectToDetails(promo.id)}
            sx={classes.link}
          >
            View Details
          </Link>
          {promo.buttonUrl && promo.buttonName && (
            <Button
              variant="contained"
              color="secondary"
              component="a"
              href={promo.buttonUrl}
              target="_blank"
            >
              {promo.buttonName}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EarnItem;
