import React from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

const Buttons = () => (
  <>
    <Box mb={4}>
      <Button variant="outlined" color="secondary">
        Outlined
      </Button>
      <Button variant="outlined" color="secondary" disabled>
        Outlined disabled
      </Button>
    </Box>
    <Box mb={4}>
      <Button variant="contained" color="secondary">
        Contained
      </Button>
      <Button variant="contained" color="secondary" disabled>
        Contained disabled
      </Button>
    </Box>
    <Box mb={1}>
      <Typography variant="subtitle2" gutterBottom>
        Icon-button
      </Typography>
      <Box display="flex" alignItems="center">
        <IconButton aria-label="back" color="primary">
          <ArrowBackIos />
        </IconButton>
        <IconButton aria-label="back" color="primary" disabled>
          <ArrowBackIos />
        </IconButton>
      </Box>
    </Box>
  </>
);

export default Buttons;
