import React from 'react';
import sx from 'mui-sx';
import { Box, CardMedia, Typography } from '@mui/material';

import Tooltip from '../Tooltip';
import classes from './styles';

const Badge = ({
  badge: { iconName, title, active, inActiveMessage, activeMessage },
}) => (
  <Box
    sx={sx(classes.root, {
      condition: !active,
      sx: classes.disabled,
    })}
  >
    <CardMedia component="img" src={`/assets/icons/badges/${iconName}.svg`} />
    <Typography align="center" fontSize={20} fontWeight="bold" mt={3}>
      {title}
    </Typography>
    <Tooltip message={active ? activeMessage : inActiveMessage} />
  </Box>
);

export default Badge;
