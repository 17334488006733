import React, { useState, useEffect, useCallback } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';
import debounce from 'lodash/debounce';
import sx from 'mui-sx';
import ReactPlayer from 'react-player';
import { HEADER_DESKTOP_HEIGHT } from 'config/ui';

import { ScrollIcon } from 'assets/icons';
import Layout from 'components/Layout';
import {
  FirstPip,
  JumpingPip,
  Medal,
  BikeMilkTree,
  Money,
  FallingPips,
  HandsBucketBoat,
  ColoredText,
  LastPip,
} from './components';
import classes from './styles';
import theme from 'styles/theme';

const slides = [
  FirstPip,
  JumpingPip,
  Medal,
  BikeMilkTree,
  Money,
  FallingPips,
  HandsBucketBoat,
  ColoredText,
  LastPip,
];

const Users = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const slideHeight = window.screen.height - HEADER_DESKTOP_HEIGHT;
  const positions = slides.map((u, i) => i * slideHeight);

  const isMacOS = window?.navigator?.userAgent.indexOf('Mac') !== -1;

  const showVideo = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!showVideo) {
      const scrollingStopListener = debounce(() => {
        const currentIndex = positions.findIndex((el, index, arr) => {
          return arr.length - 1 !== index
            ? el >= window.scrollY && window.scrollY < arr[++index]
            : el - 160 >= window.scrollY;
        });
        if (currentIndex >= 0) {
          setCurrentSlideIndex(currentIndex);
        }
      }, 100);
      window.addEventListener('scroll', scrollingStopListener);

      return () => {
        window.removeEventListener('scroll', scrollingStopListener);
      };
    }
  }, [positions]);

  const scrollToSlide = useCallback((slideIndex) => {
    const slide = document.getElementById(`slide_${slideIndex}`);
    window.scrollTo({
      top: slide.offsetTop - 160,
      left: 0,
      behavior: 'smooth',
    });
    setCurrentSlideIndex(slideIndex);
  }, []);

  return (
    <Layout>
      {showVideo ? (
        <Box sx={classes.videoPlayerWrapper}>
          <Box sx={classes.videoPlayer}>
            <ReactPlayer
              url="https://vimeo.com/104361111"
              style={{ position: 'absolute', top: 0, left: 0 }}
              width="100%"
            />
          </Box>
        </Box>
      ) : (
        <Box sx={classes.root}>
          <Box component="ul" sx={classes.dots}>
            {Object.keys(positions).map((_, index) => (
              <Box
                key={index}
                component="li"
                sx={sx({
                  condition: index === currentSlideIndex,
                  sx: classes.activeDot,
                })}
                onClick={() => scrollToSlide(index)}
              />
            ))}
          </Box>
          {slides.map((slide, index) => (
            <Box key={index} component="section">
              {slide({
                id: `slide_${index}`,
                shouldShowContent: currentSlideIndex >= index,
                isMacOS,
              })}
            </Box>
          ))}
          {currentSlideIndex !== slides.length - 1 && (
            <IconButton
              sx={classes.scrollBtn}
              onClick={() => scrollToSlide(currentSlideIndex + 1)}
            >
              <ScrollIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Layout>
  );
};

export default Users;
