import React from 'react';
import { IconButton } from '@mui/material';
import classes from './styles';
import { arrowPositionX } from '../Carousel/styles';
import { NextIcon } from '../../assets/icons';

const NextArrow = ({ onClick, styles }) => {
  return (
    <IconButton
      sx={{ ...classes.arrowStyles, right: arrowPositionX, ...styles }}
      onClick={onClick}
      className="next"
    >
      <NextIcon />
    </IconButton>
  );
};

export default NextArrow;
