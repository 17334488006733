import React from 'react';
import { useMatch } from 'react-router-dom';
import { Box, Container } from '@mui/material';

import Header from './Header';
import Footer from './Footer';
import classes from './styles';

const Layout = ({ children, bgColor }) => {
  const isUsers = useMatch('/users');
  const mainBackgroundColor = !bgColor ? '#ebebeb' : bgColor;

  return (
    <Box sx={classes.root}>
      <Header />
      <main style={{ background: mainBackgroundColor }}>
        {isUsers ? (
          children
        ) : (
          <Container sx={classes.container} maxWidth="lg">
            {children}
          </Container>
        )}
      </main>
      <Footer />
    </Box>
  );
};

export default Layout;
