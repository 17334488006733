import React from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import classes from './styles';

const MyGiftCardsPlaceholder = () => {
  return (
    <Box sx={classes.root}>
      <CardMedia
        component="img"
        src="/assets/images/Icon-Empty-State-My-Gift-Cards-red.png"
      />
      <Typography variant="h5" textAlign="center">
        You don’t have gift cards yet.
      </Typography>
      <Typography textAlign="center">
        Once you start using your PIPs, all your gift cards will be stored here.
      </Typography>
    </Box>
  );
};

export default MyGiftCardsPlaceholder;
