import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog as MuiDialog, DialogTitle, IconButton } from '@mui/material';

const Dialog = ({
  open,
  name,
  title,
  maxWidth,
  fullScreen = false,
  children,
  onClose,
  showCloseIcon,
  ...restProps
}) => {
  return (
    <MuiDialog
      open={open}
      aria-labelledby={`${name}-dialog`}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      disableScrollLock
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      {...restProps}
    >
      <DialogTitle>
        {title}
        {showCloseIcon && (
          <IconButton
            color="primary"
            aria-label="close"
            className="close-btn"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      {children}
    </MuiDialog>
  );
};

export default Dialog;
