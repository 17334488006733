const classes = {
  root: {},
  sliderWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  sliderInner: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default classes;
