import React, { useState } from 'react';
import { Link, DialogContent, Box, CardMedia, Typography } from '@mui/material';

import Dialog from 'components/dialogs/Dialog';
import classes from './styles';

const UserDialog = ({ user }) => {
  const [dialogVisibility, setDialogVisibility] = useState(false);

  const toggleDialog = () => {
    setDialogVisibility(!dialogVisibility);
  };

  return (
    <>
      <Link
        color="primary"
        fontWeight="bold"
        variant="subtitle1"
        component="button"
        onClick={toggleDialog}
      >
        Read More
      </Link>
      <Dialog
        name="user-information"
        maxWidth="sm"
        sx={classes.dialog}
        showCloseIcon
        open={dialogVisibility}
        onClose={toggleDialog}
      >
        <DialogContent sx={classes.dialogContent}>
          <Box display="flex" alignItems="center" mb={2} sx={classes.header}>
            <CardMedia component="img" src={user.avatar} sx={classes.img} />
            <div>
              <Typography color="primary" variant="h4" sx={classes.name}>
                {user.name}
              </Typography>
              <Typography>{user.occupation}</Typography>
            </div>
          </Box>
          <Box pb={2}>{user.descriptionFull}</Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UserDialog;
