const classes = {
  root: {
    '& li': {
      paddingBottom: '10px',
      marginBottom: '21px',
      '&:not(:last-of-type)': {
        borderBottom: '1px solid #70707029',
      },
    },
  },
  name: {
    marginLeft: '20px',
  },
};

export default classes;
