import palette from 'styles/theme/palette';
import { alpha } from '@mui/material/styles';

export default {
  styleOverrides: {
    root: {
      width: '100%',
      alignItems: 'flex-start',
      '& .MuiFormControlLabel-label.Mui-disabled': {
        color: alpha(palette.text.grey, 0.6),
      },
    },
    label: {
      fontSize: 14,
      fontWeight: 500,
      paddingBottom: 10,
    },
    labelPlacementEnd: {
      width: 'auto',
      marginRight: 30,
      display: 'flex',
      alignItems: 'center',
      '& .MuiFormControlLabel-label': {
        paddingBottom: 0,
        marginLeft: 8,
      },
    },
  },
};
