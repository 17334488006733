const classes = {
  root: {
    display: 'flex',
    marginBottom: '30px',
    flexDirection: 'column',
    '@media (min-width: 1024px)': {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '4px',
    },
    '@media (min-width: 1280px)': {
      alignItems: 'flex-start',
    },
  },
  image: {
    '@media (min-width: 1024px)': {
      flex: '0 0 185px',
      height: '85px',
    },
    '@media (min-width: 1280px)': {
      flex: '0 0 273px',
      height: '125px',
    },
    '& img': {
      height: '100%',
    },
  },
  imagePlaceholder: {
    objectFit: 'contain',
    maxWidth: '50%',
    height: '80%!important',
    margin: '0 auto',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderTop: '1px solid #70707029',
    '@media (min-width: 1024px)': {
      marginLeft: '24px',
      flexGrow: 1,
      flexDirection: 'row',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0',
    '@media (min-width: 1024px)': {
      flex: '1 0 60%',
      padding: '10px 15px 10px 0',
    },
    '@media (min-width: 1280px)': {
      padding: '24px 24px 24px 0',
      flex: '1 0 66.66666%',
    },
    '& h4': {
      fontSize: '20px',
      '@media (min-width: 1280px)': {
        fontSize: '26px',
      },
    },
  },
  description: {
    fontSize: '14px',
    fontWeight: 500,
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default classes;
