import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import { userSelector } from 'store/user/UserSlice';
import { linkThinkTVAccount } from 'utils/linkThinkTVAccount';

const ThinkHumanIntegration = () => {
  const params = useParams();
  const { isAuthenticated } = useSelector(userSelector);

  useEffect(() => {
    if (isAuthenticated) {
      linkThinkTVAccount(params.th_token);
    }
  }, []);

  return (
    <Layout bgColor={palette.white}>
      <Box maxWidth="lg" sx={{ paddingTop: '30px' }}>
        <Typography variant="h2" textAlign="center">
          ThinkHumanTV
        </Typography>
        <Typography textAlign="center">
          You see this page because you&apos;re trying to link your ThinkHumanTV
          account
        </Typography>
      </Box>
    </Layout>
  );
};

export default ThinkHumanIntegration;
