import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import classes from './styles';
import format from 'date-fns/format';
import Preloader from 'components/Preloader';
import { getGrants } from 'services/api';
import { handleError } from 'services/handleError';

const PipsForSchool = () => {
  const [grants, setGrants] = useState([]);
  const [availableToConvert, setAvailableToConvert] = useState(0);
  const [totalCommitted, setTotalCommitted] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    getGrants()
      .then((res) => {
        if (res.status === 'OK') {
          setGrants(res.grants);
          setAvailableToConvert(res.availableToConvert);
          setTotalCommitted(res.totalCommitted);
          setIsFetching(false);
        } else {
          handleError(res);
          setIsFetching(false);
        }
      })
      .catch((err) => {
        handleError(err);
        setIsFetching(false);
      });
  }, []);

  if (isFetching) return <Preloader />;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.grantsHeader}>
        <Box sx={classes.availableToConvert}>
          <Typography sx={classes.title}>
            PIPs committed <br />
            for next P4S Grant
          </Typography>
          <Typography sx={classes.number} fontWeight={700}>
            {availableToConvert && availableToConvert.toLocaleString()}
          </Typography>
        </Box>
        <Box sx={classes.totalCommitted}>
          <Typography sx={classes.title}>Total PIPs committed</Typography>
          <Typography sx={classes.number}>
            {totalCommitted.toLocaleString()}
          </Typography>
        </Box>
      </Box>
      {grants.length ? (
        <Box component="ul">
          <Box sx={classes.grantsItem} component="li">
            <Typography fontWeight={700} sx={classes.grantDate}>
              Date
            </Typography>
            <Box sx={classes.numbersWrapper}>
              <Typography fontWeight={700} textAlign="center">
                PIPs Converted
              </Typography>
              <Typography fontWeight={700} textAlign="right">
                Grants($)
              </Typography>
            </Box>
          </Box>
          {grants.map((grant, i) => {
            const dateFromString = new Date(grant.date);
            return (
              <Box sx={classes.grantsItem} component="li" key={grant.date + i}>
                <Typography sx={classes.grantDate}>
                  {format(dateFromString, 'MM/dd/yyyy')}
                </Typography>
                <Box sx={classes.numbersWrapper}>
                  <Typography fontWeight={700} textAlign="center">
                    {grant.pips.toLocaleString()}
                  </Typography>
                  <Typography fontWeight={700} textAlign="right">
                    {grant.grant.toLocaleString()}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
};

export default PipsForSchool;
