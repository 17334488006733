import React from 'react';
import { Box, Typography, CardMedia } from '@mui/material';
import classes from './styles';
import possibilities from './settings';

const Possibilities = () => {
  return (
    <Box id="P4S-empowers" component="section" sx={classes.root}>
      <Typography variant="h2" textAlign="center">
        P4S Empowers
      </Typography>
      <Typography variant="h4" textAlign="center" mb={5}>
        PIPs has created a trusted relationship with its student users who say
        the app…
      </Typography>
      <Box component="ul" sx={classes.list}>
        {possibilities.map((possibility) => (
          <Box component="li" sx={classes.listItem} key={possibility.id}>
            <Box sx={classes.image}>
              <CardMedia component="img" src={possibility.src} />
            </Box>
            <Typography variant="h4" sx={classes.text}>
              {possibility.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Possibilities;
