const classes = {
  root: {
    fontSize: 19,
    paddingTop: '45px',
    paddingBottom: '90px',
    '& .MuiAccordionSummary-expandIconWrapper': {
      order: -1,
      color: 'primary.main',
      marginRight: 2,
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordion-region': {
      paddingLeft: 7.5,
    },
    '& .MuiAccordion-root.Mui-expanded': {
      border: '1px solid grey',
      borderRadius: 2,
    },
    '& h4': {
      fontWeight: 500,
      marginBottom: 0,
    },
    '& h6': {
      marginBottom: 1.5,
    },
    '& p': {
      marginBottom: 2,
    },
    '& a': {
      color: 'primary.main',
      textDecoration: 'underline',
    },
    '& ul': {
      marginBottom: 2,
      '& li': {
        marginBottom: 1,
      },
      '&.list--disc': {
        listStyle: 'disc',
        paddingLeft: 4,
        '& li::marker': {
          color: 'primary.main',
        },
      },
    },
  },
};

export default classes;
