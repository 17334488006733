import pallete from 'styles/theme/palette';

const classes = {
  root: {
    background: '#fff',
    paddingTop: '45px',
    paddingBottom: '45px',
    '@media (min-width: 1024px)': {
      paddingTop: '70px',
      paddingBottom: '70px',
    },
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '@media (min-width: 768px)': {
      flex: '0 0 33.333333%',
      maxWidth: '33.333333%',
    },
  },
  image: {
    borderWidth: '10px',
    borderStyle: 'solid',
    borderColor: pallete.secondary.main,
    borderRadius: '50%',
    overflow: 'hidden',
    width: '275px',
    height: '275px',
    marginBottom: '15px',
    '@media (min-width: 768px)': {
      width: '175px',
      height: '175px',
    },
    '@media (min-width: 1024px)': {
      width: '275px',
      height: '275px',
      marginBottom: '40px',
    },
    '& img': {
      height: '100%',
    },
  },
  text: {
    paddingLeft: '15px',
    paddingRight: '15px',
    fontSize: '26px',

    '@media (min-width: 768px)': {
      fontSize: '18px',
    },
    '@media (min-width: 1024px)': {
      paddingLeft: '40px',
      paddingRight: '40px',
      fontSize: '26px',
    },
  },
};

export default classes;
