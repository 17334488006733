const classes = {
  root: {
    background: '#fff',
    '& video': {
      width: '94vh',
    },
    '& p': {
      width: '100%',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      top: '36.5vh',
      '@media(min-width: 1600px)': {
        top: '35vh',
        paddingRight: '60px',
      },
    },
  },
};

export default classes;
