import React, { useState } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import Layout from 'components/Layout';
import { NextIcon } from 'assets/icons';
import palette from 'styles/theme/palette';
import classes from './styles';

import { faqList } from './settings';

const FAQ = () => {
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) =>
    setExpanded(newExpanded ? panel : false);

  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root}>
        <Typography variant="h1" textAlign="center" mb={4}>
          FAQ
        </Typography>
        {faqList.map(({ id, title, description }) => (
          <Accordion
            key={id}
            disableGutters
            elevation={0}
            expanded={expanded === id}
            onChange={handleChange(id)}
          >
            <AccordionSummary
              id={`${id}-header`}
              aria-controls={`${id}-content`}
              expandIcon={<NextIcon />}
            >
              <Typography variant="h4">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{description}</AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Layout>
  );
};

export default FAQ;
