import React from 'react';
import {
  Box,
  FormControlLabel,
  TextField,
  FormGroup,
  Radio,
  RadioGroup,
  MenuItem,
} from '@mui/material';

import { Checkbox } from 'components/forms';
import classes from './styles';

const FormFieldRender = ({ field, index, register, getValues, errors }) => {
  switch (field.type) {
    case 'text':
      return (
        <div key={field.id}>
          <label style={classes.label} htmlFor={`${index}-${field.id}`}>
            {field.label}
            {field.isRequired ? '*' : ''}
          </label>
          <TextField
            required={field.isRequired}
            fullWidth
            inputProps={{
              autoComplete: 'off',
              placeholder: 'Your answer',
            }}
            id={`${index}-${field.id}`}
            {...register(`${field.id}`)}
          />
        </div>
      );
    case 'checkbox-group':
      return (
        <div key={field.id}>
          <Box sx={{ marginBottom: '30px' }}>
            <label style={classes.label}>
              {field.label}
              {field.isRequired ? '*' : ''}
            </label>
            <FormGroup>
              <Box sx={{ paddingLeft: '6px' }}>
                {field.options.map((option, optionIndex) => (
                  <FormControlLabel
                    key={`${field.id}-options-${optionIndex}`}
                    {...register(`${field.id}.${optionIndex}`, {
                      validate: () => {
                        if (field.isRequired) {
                          const errorMessage = 'This is a required question';
                          const values = getValues(field.id);
                          const isValid = Object.keys(values).some(
                            (k) => values[k]
                          );
                          return isValid || errorMessage;
                        }
                      },
                    })}
                    control={<Checkbox />}
                    label={option.label || option.value}
                  />
                ))}
              </Box>
              {errors && errors[field.id] && (
                <Box sx={classes.errorMessage}>This is a required question</Box>
              )}
            </FormGroup>
          </Box>
        </div>
      );
    case 'radio-group':
      return (
        <div key={field.id}>
          <Box sx={{ marginBottom: '30px' }}>
            <label style={classes.label}>
              {field.label}
              {field.isRequired ? '*' : ''}
            </label>
            <RadioGroup defaultValue={field.options[0].value}>
              <Box sx={{ paddingLeft: '6px' }}>
                {field.options.map((option, optionIndex) => (
                  <FormControlLabel
                    key={`${field.id}-options-${optionIndex}`}
                    control={<Radio sx={{ padding: 0 }} />}
                    label={option.label || option.value}
                    {...register(`${field.id}`)}
                    value={option.value}
                    defaultChecked={optionIndex === 0}
                  />
                ))}
              </Box>
            </RadioGroup>
          </Box>
        </div>
      );
    case 'select':
      return (
        <div key={field.id}>
          <label style={classes.label}>
            {field.label}
            {field.isRequired ? '*' : ''}
          </label>

          <TextField
            {...register(`${field.id}`)}
            select
            fullWidth
            defaultValue={field.options[0].value}
          >
            {field.options.map((option, optionIndex) => (
              <MenuItem key={optionIndex} value={option.value}>
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {option.value}
                </span>
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    case 'textarea':
      return (
        <div key={field.id}>
          <label style={classes.label} htmlFor={`${index}-${field.id}`}>
            {field.label}
            {field.isRequired ? '*' : ''}
          </label>
          <TextField
            {...register(`${field.id}`)}
            sx={classes.textArea}
            multiline
            rows={3}
            fullWidth
            inputProps={{
              autoComplete: 'off',
              placeholder: 'Your answer',
            }}
            required={field.isRequired}
            id={`${index}-${field.id}`}
          />
        </div>
      );
    default:
      return null;
  }
};

export default FormFieldRender;
