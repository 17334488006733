import client from 'services/httpClient';
import qs from 'qs';

export const login = async ({ email, password, remember, ttl }) => {
  try {
    const res = await client.post('/login/email', null, {
      params: {
        email,
        password,
        remember,
        ttl,
      },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const signup = async ({ body }) => {
  try {
    const res = await client.post('/user/register/email', null, {
      params: body,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const checkEmail = async (email) => {
  try {
    const res = await client.get('/user/email', {
      params: { email },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const verifyCode = async (code) => {
  try {
    const res = await client.post('/user/register/email/verify', null, {
      params: code,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const resendCode = async () => {
  try {
    const res = await client.post('/user/register/email/resend', null);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const forgotPassword = async (email) => {
  try {
    const res = await client.put('/user/password/reset/start', null, {
      params: email,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const resetPassword = async ({ body }) => {
  try {
    const res = await client.put('/user/password/reset/finish', null, {
      params: body,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getProfile = async () => {
  try {
    const res = await client.get('/user');
    return res.data;
  } catch (err) {
    return err;
  }
};

export const editProfile = async ({ body }) => {
  try {
    const res = await client.put('/user', null, {
      params: body,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getHomePromos = async () => {
  try {
    const res = await client.get('/home');
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getCarbonFootprint = async () => {
  try {
    const res = await client.get('/dashboard/carbon/footprint');
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getImpacts = async () => {
  try {
    const res = await client.get('/dashboard/impacts');
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getPromos = async ({ query }) => {
  try {
    const res = await client.get('/promos', {
      params: query,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getPromoDetails = async (id) => {
  try {
    const res = await client.get(`/promos/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getCategories = async () => {
  try {
    const res = await client.get('/categories');
    return res.data;
  } catch (err) {
    return err;
  }
};

export const redeemPromo = async ({ body }) => {
  try {
    const res = await client.post('/redeem', null, {
      params: body,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const donateOneTimePromo = async ({ body }) => {
  try {
    const res = await client.post('/donate/onetime', null, {
      params: body,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const donatePledgePromo = async ({ body }) => {
  try {
    const res = await client.post('/donate/pledge', null, {
      params: body,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const giftYourPips = async ({ body }) => {
  try {
    const res = await client.post('/gift', null, {
      params: body,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getActions = async ({ query }) => {
  try {
    const res = await client.get('/dashboard/actions', {
      params: query,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getMyGiftCards = async ({ query }) => {
  try {
    const res = await client.get('/dashboard/giftcards', {
      params: query,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getGiftCardDetails = async (id) => {
  try {
    const res = await client.get(`/dashboard/giftcards/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getGrants = async () => {
  try {
    const res = await client.get('/dashboard/grants');

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getBadges = async () => {
  try {
    const res = await client.get('/dashboard/badges');
    return res.data;
  } catch (err) {
    return err;
  }
};

export const mergeThinkHumanTVAccount = async (token) => {
  try {
    const res = await client.post(`/partners/thinkhumantv/merge/${token}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const switchToUserLogin = async (token) => {
  try {
    const res = await client.post('/login/token', null, {
      params: { token },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const earnPointsFromLink = async (q, id) => {
  try {
    const res = await client.post('/earn/link', null, {
      params: { q, id },
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });
    return res.data;
  } catch (err) {
    return err;
  }
};

export const getQuestionnaires = async ({ query }) => {
  try {
    const res = await client.get('/questionnaires', {
      params: query,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'comma' }),
    });

    return res.data;
  } catch (err) {
    return err;
  }
};

export const getQuestionnaireByID = async (id) => {
  try {
    const res = await client.get(`/questionnaires/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const deleteQuestionnaireByID = async (id) => {
  try {
    const res = await client.delete(`/questionnaires/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const createQuestionnaire = async ({ body }) => {
  try {
    const res = await client.post('/questionnaires', body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const updateQuestionnaire = async ({ id, body }) => {
  try {
    const res = await client.put(`/questionnaires/${id}`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};

export const submitQuestionnaireAnswer = async ({ id, body }) => {
  try {
    const res = await client.post(`/questionnaires/${id}/answers`, body);
    return res.data;
  } catch (err) {
    return err;
  }
};
