import React from 'react';
import { IconButton } from '@mui/material';
import classes from './styles';
import { arrowPositionX } from '../Carousel/styles';
import { NextIcon } from '../../assets/icons';

const PrevArrow = ({ onClick, styles }) => {
  return (
    <IconButton
      sx={{
        ...classes.arrowStyles,
        left: arrowPositionX,
        transform: 'scaleX(-1)',
        ...styles,
      }}
      className="prev"
      onClick={onClick}
    >
      <NextIcon />
    </IconButton>
  );
};

export default PrevArrow;
