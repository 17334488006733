import { HEADER_DESKTOP_HEIGHT } from 'config/ui';

const classes = {
  root: {
    zIndex: 2,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    boxShadow: '0 0 30px 0 rgba(0, 0, 0, 30%)',
    '& a.active': {
      fontWeight: 'bold',
    },
  },
  header: {
    color: 'white',
    height: HEADER_DESKTOP_HEIGHT,
    bgcolor: 'primary.main',
    '& > div': {
      height: '100%',
    },
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    '& a, & .username': {
      display: 'block',
      padding: '0 12px',
      fontSize: '18px',
      lineHeight: 1,
      '& + a': {
        borderLeft: '1px solid #3a333180',
      },
    },
    '& .username': {
      borderLeft: '1px solid #3a333180',
    },
  },
  editProfileLink: {
    display: 'block',
    width: '40px',
    height: '40px',
    marginRight: '24px',
  },
  subHeader: {
    color: 'primary.main',
    height: HEADER_DESKTOP_HEIGHT,
    background: 'white',
    '& > div': {
      height: '100%',
    },
  },
  subLinks: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& a': {
      display: 'block',
      fontSize: '18px',
      lineHeight: 1,
      marginRight: 6,
      '&:last-of-type': {
        marginRight: 0,
      },
      '&.logo': {
        color: '#3A3331',
      },
    },
  },
  login: {
    marginRight: '24px',
  },
};

export default classes;
