import React from 'react';
import palette from 'styles/theme/palette';
import { Box } from '@mui/material';

import NavTabs from 'components/NavTabs';
import Layout from 'components/Layout';
import {
  ImpactCarbonFootprint,
  ImpactActivity,
  Badges,
  Actions,
  PipsForSchool,
  MyGiftCards,
} from './components';
import classes from './styles';

const Dashboard = () => (
  <Layout bgColor={palette.white}>
    <Box maxWidth="lg" sx={classes.root}>
      <NavTabs
        ariaLabel="Dashboard"
        sx={classes.tabs}
        tabs={[
          {
            label: 'Impact Carbon Footprint',
            panel: <ImpactCarbonFootprint />,
            pathname: 'impact-carbon-footprint',
          },
          {
            label: 'Impact Activity',
            panel: <ImpactActivity />,
            pathname: 'impact-activity',
          },
          {
            label: 'Actions',
            panel: <Actions />,
            pathname: 'actions',
          },
          {
            label: 'My Gift Cards',
            panel: <MyGiftCards />,
            pathname: 'my-gift-cards',
          },
          {
            label: 'PIPs For School',
            panel: <PipsForSchool />,
            pathname: 'pips-for-school',
          },
          {
            label: 'Badges',
            panel: <Badges />,
            pathname: 'badges',
          },
        ]}
      />
    </Box>
  </Layout>
);

export default Dashboard;
