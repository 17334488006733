const classes = {
  root: {
    background: '#fff',
    '& p': {
      paddingBottom: '17vh',
    },
  },
};

export default classes;
