import React from 'react';
import Layout from 'components/Layout';
import classes from './styles';
import { Box } from '@mui/material';
import {
  GeneralInformation,
  Challenges,
  PipsForSchool,
  EarnPips,
  ActionsVerified,
  Possibilities,
  PartnerBenefits,
  PartnerOpportunities,
} from './components';

const Programs = () => {
  return (
    <Layout>
      <Box sx={classes.root}>
        <GeneralInformation />
        <Challenges />
        <PipsForSchool />
        <EarnPips />
        <ActionsVerified />
        <Possibilities />
        <PartnerBenefits />
        {/*<P4SPrograms />*/}
        <PartnerOpportunities />
      </Box>
    </Layout>
  );
};

export default Programs;
