import React from 'react';
import sx from 'mui-sx';
import { Box, Typography, CardMedia, Link } from '@mui/material';

import UserDialog from '../UserDialog';
import classes from './styles';

const UserCard = ({ user, isWhiteColor = false }) => {
  const { avatar, name, occupation, linkedin } = user;

  return (
    <Box
      sx={sx({
        condition: isWhiteColor,
        sx: { color: 'white' },
      })}
    >
      <CardMedia component="img" src={avatar} sx={classes.img} />
      {linkedin ? (
        <Link
          href={linkedin}
          target="_blank"
          rel="noopener noreferrer"
          sx={classes.name}
        >
          <Typography color={isWhiteColor ? 'white' : 'primary'} variant="h4">
            {name}
          </Typography>
        </Link>
      ) : (
        <Typography
          color={isWhiteColor ? 'white' : 'primary'}
          variant="h4"
          sx={classes.name}
        >
          {name}
        </Typography>
      )}
      <Box mb={3}>{occupation}</Box>
      {user?.description && (
        <>
          <Typography>{user.description}</Typography>
          <UserDialog user={user} name={name} />
        </>
      )}
    </Box>
  );
};

export default UserCard;
