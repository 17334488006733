import React from 'react';
import { Box } from '@mui/material';
import Layout from 'components/Layout';
import classes from './styles';
import palette from 'styles/theme/palette';
import EditProfileTab from './components/EditProfileTab';
import SettingsTab from './components/SettingsTab';
import PasswordTab from './components/PasswordTab';
import NavTabs from 'components/NavTabs';

const EditProfile = () => {
  return (
    <Layout bgColor={palette.white}>
      <Box sx={classes.root} maxWidth="lg">
        <NavTabs
          ariaLabel="Profile"
          tabs={[
            {
              label: 'Edit Profile',
              panel: <EditProfileTab />,
              pathname: 'edit-profile',
            },
            {
              label: 'Password',
              panel: <PasswordTab />,
              pathname: 'edit-password',
            },
            {
              label: 'Settings',
              panel: <SettingsTab />,
              pathname: 'edit-settings',
            },
          ]}
        />
      </Box>
    </Layout>
  );
};

export default EditProfile;
