const classes = {
  root: {
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '15px',
    paddingRight: '15px',
    maxWidth: '400px',
    margin: '0 auto',
    '@media (min-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '.MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  preloaderWrapper: {
    position: 'fixed',
    height: '100vh',
    width: '100%',
    zIndex: 10,
    top: 0,
    left: 0,
  },
};

export default classes;
