import palette from 'styles/theme/palette';

const classes = {
  root: {
    paddingTop: '8px',
  },
  tilesWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    fontSize: '20px',
    marginBottom: '40px',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      height: '150px',
    },
  },
  availablePips: {
    flex: '0 0 32%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: palette.primary.main,
    borderRadius: '24px',
    color: '#fff',
    marginBottom: 3,
    '@media (min-width: 768px)': {
      marginBottom: 0,
    },
  },
  totalPips: {
    flex: '0 0 66%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    border: `3px solid ${palette.primary.main}`,
    borderRadius: '24px',
    color: palette.primary.main,
    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
  },
  totalPipsItem: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
  },
  number: {
    fontSize: '48px',
    '@media (min-width: 1024px)': {
      fontSize: '52px',
    },
  },
  actionsList: {},
  actionsItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
    borderBottom: '1px solid #EBEBEB',
    fontSize: '18px',
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
};
export default classes;
