import { PADDING_MAIN } from 'config/ui';

const classes = {
  root: {
    background: '#2DAB72',
    paddingTop: '45px',
    paddingBottom: '45px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 1024px)': {
      flexDirection: 'row',
      paddingTop: '85px',
      paddingBottom: '85px',
    },
  },
  title: {
    '@media (min-width: 1024px)': {
      fontSize: '52px',
    },
  },
  text: {
    flex: '1 0 100%',
    marginBottom: '30px',
    '@media (min-width: 1024px)': {
      flex: '1 0 50%',
      marginBottom: 0,
    },
  },
  divider: {
    width: '200px',
    height: '1px',
    background: '#fff',
    marginBottom: '30px',
    marginTop: '30px',
  },
  images: {
    flex: '1 0 100%',
    '@media (min-width: 1024px)': {
      flex: '1 0 50%',
      paddingLeft: `${PADDING_MAIN}px`,
    },

    '& ul': {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0 -12px',
      '& li': {
        flex: '1 0 100%',
        padding: '0 12px 12px',
        '@media (min-width: 768px)': {
          flex: '1 0 50%',
          padding: '12px',
        },
        '& img': {
          borderRadius: '10px',
          height: '180px',
        },
      },
    },
  },
};

export default classes;
