import { PADDING_MAIN } from 'config/ui';

const classes = {
  root: {
    maxWidth: '80%',
    margin: '0 auto',
    paddingTop: `${PADDING_MAIN}px`,
  },
  resetPasswordForm: {
    padding: '25px 0',
  },
};

export default classes;
