import React from 'react';
import { Checkbox as MuiCheckbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const Checkbox = (props) => (
  <MuiCheckbox
    color="primary"
    icon={<CheckBoxOutlineBlankIcon />}
    checkedIcon={<CheckBoxIcon />}
    {...props}
  />
);

export default Checkbox;
