import { PADDING_MAIN } from '../../config/ui';

const classes = {
  backButton: {
    position: 'absolute',
    left: '15px',
    top: '-15px',
    minWidth: 0,
    padding: 0,
    height: 'auto',
    '&:hover': {
      boxShadow: 'none',
    },
    '@media (min-width: 768px)': {
      left: 0,
      top: 0,
    },
  },
  givePipsSuccess: {
    position: 'absolute',
    left: '24px',
    top: `${PADDING_MAIN}px`,
    minWidth: 0,
    padding: 0,
    height: 'auto',
    '&:hover': {
      boxShadow: 'none',
    },
  },
};

export default classes;
