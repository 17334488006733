const classes = {
  root: {
    paddingTop: '38px',
    paddingBottom: '52px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media (min-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '40px',
    '& h3': {
      marginBottom: 0,
    },
  },
};

export default classes;
