import React from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import classes from './styles';
import { earnList } from './settings';

const EarnPips = () => {
  return (
    <Box component="section" sx={classes.root}>
      <Typography variant="h2" textAlign="center" sx={classes.title}>
        How Are PIPs Earned?
      </Typography>
      <Box component="ul" sx={classes.list}>
        {earnList.map((earnItem) => (
          <Box component="li" key={earnItem.id} sx={classes.listItem}>
            <Box sx={classes.icon} borderColor={earnItem.color}>
              <CardMedia
                component="img"
                src={`assets/icons/earn/earn${earnItem.id}.svg`}
              />
            </Box>
            <Typography variant="body1">{earnItem.text}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EarnPips;
