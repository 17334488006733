import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { AnimatedText } from 'pages/Users/components';
import classes from './styles';

const LastPip = ({ id }) => {
  const navigate = useNavigate();

  return (
    <Box
      id={id}
      sx={classes.root}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Box component="video" autoPlay muted loop>
        <source src="/assets/animations/bigPip.mp4" type="video/mp4" />
      </Box>
      <AnimatedText text="Well, what are you waiting for?" />
      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate('/registration')}
      >
        START PIP&apos;N
      </Button>
    </Box>
  );
};
export default LastPip;
