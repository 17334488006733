import Utf8 from 'crypto-js/enc-utf8';
import CryptoAES from 'crypto-js/aes';
import CryptoJS from 'crypto-js/crypto-js';
import Base64url from 'crypto-js/enc-base64url';

export const encodePassword = (pass) => {
  const timestamp = Date.now();
  const encodedPassObject = {
    password: pass,
    timestampMs: timestamp,
  };

  const passStr = JSON.stringify(encodedPassObject);
  // const key = Utf8.parse(process.env.REACT_APP_DOUBLE_SECRET_KEY);
  const key = '9czGGB0Q9czGGB0Q';
  console.log('key is', key, process.env.REACT_APP_DOUBLE_SECRET_KEY);
  const encryptedPassword = CryptoAES.encrypt(passStr, key, {
    mode: CryptoJS.mode.ECB,
  });
  const encryptedPasswordString = encryptedPassword.ciphertext;

  return Base64url.stringify(encryptedPasswordString);
};

export const encodePasswordTwoFactor = (passwordOld, password, userId) => {
  const timestamp = Date.now();
  const authToken = localStorage.getItem('token');
  const encodedPassObject = {
    passwordOld: passwordOld,
    password: password,
    timestampMs: timestamp,
    userId: userId,
  };

  const secretKey = process.env.REACT_APP_DOUBLE_SECRET_KEY.slice(0, 8);
  const authKey = authToken.slice(0, 8);

  const twoFactorKey = secretKey + authKey;
  const passStr = JSON.stringify(encodedPassObject);

  const key = Utf8.parse(twoFactorKey);
  const encryptedPassword = CryptoAES.encrypt(passStr, key, {
    mode: CryptoJS.mode.ECB,
  });

  const encryptedPasswordString = encryptedPassword.ciphertext;

  return Base64url.stringify(encryptedPasswordString);
};
