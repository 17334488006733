import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValidationSchema } from './settings';
import classes from './styles';
import { Button, FormControlLabel, Grid, Typography, Box } from '@mui/material';
import { PasswordField } from 'components/forms';
import PasswordStrengthMeter from 'components/PasswordStrengthMeter';
import { encodePassword } from 'utils/encodePassword';
import useQuery from 'hooks/useQuery';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import {
  userSelector,
  clearState,
  handleResetPassword,
} from 'store/user/UserSlice';
import { handleError } from 'services/handleError';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const { isSuccess, isError, error } = useSelector(userSelector);

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }

    if (isSuccess) {
      dispatch(clearState());
      navigate('/reset-password-success');
    }
  }, [isError, isSuccess]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
    resolver: yupResolver(FormValidationSchema),
    mode: 'onSubmit',
  });

  const watchedFormFields = watch(['password', 'confirmPassword']);

  const onSubmit = handleSubmit((data) => {
    const body = {
      emailToken: query.get('emailToken'),
      passwordToken: encodePassword(data.password),
    };
    dispatch(handleResetPassword({ body }));
  });

  const isFormFilledCorrectly = watchedFormFields.every((val) => Boolean(val));

  return (
    <Layout bgColor={palette.white}>
      <Box component="div" sx={classes.root}>
        <Typography variant="h2" align="center">
          Reset your password
        </Typography>
        <Box
          component="form"
          sx={classes.resetPasswordForm}
          onSubmit={onSubmit}
        >
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    labelPlacement="top"
                    label="Password"
                    control={
                      <PasswordField
                        fullWidth
                        error={Boolean(errors.password)}
                        helperText={errors.password && errors.password.message}
                        inputProps={{
                          autoComplete: 'dis-p',
                          placeholder: 'Password',
                        }}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          field.onChange(e);
                        }}
                      />
                    }
                  />
                )}
              />
              <PasswordStrengthMeter password={password} />
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    labelPlacement="top"
                    label="Confirm Password"
                    control={
                      <PasswordField
                        {...field}
                        fullWidth
                        error={Boolean(errors.confirmPassword)}
                        helperText={
                          errors.confirmPassword &&
                          errors.confirmPassword.message
                        }
                        inputProps={{
                          autoComplete: 'dis-p',
                          placeholder: 'Confirm Password',
                        }}
                      />
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" component="div">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isFormFilledCorrectly}
                >
                  Update Password
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default ResetPassword;
