import React from 'react';
import classes from './styles';
import { Button, CardMedia, Typography } from '@mui/material';
import { Box } from '@mui/system';
import pallete from 'styles/theme/palette';

const PartnerOpportunities = () => {
  return (
    <Box component="section" sx={classes.root}>
      <Typography variant="h2">PIPs Partner Opportunities</Typography>
      <Box sx={classes.cardsWrapper}>
        <Box sx={{ ...classes.card, backgroundColor: pallete.secondary.main }}>
          <CardMedia
            component="img"
            src="/assets/images/programs/actions.png"
            sx={classes.image}
          />
          <Typography variant="h2">Platform Partner</Typography>
          <Typography variant="h4" sx={classes.text}>
            Partner licenses PIPs plug-&-play platform; self- and full-service
            options
          </Typography>
        </Box>
        <Box sx={{ ...classes.card, backgroundColor: pallete.background.blue }}>
          <CardMedia
            component="img"
            src="/assets/images/logo_with_text.png"
            sx={classes.image}
          />
          <Typography variant="h2">Action Partner</Typography>
          <Typography variant="h4" sx={classes.text}>
            Partner purchases PIPs’ currency of good to award its users for
            actions it wishes to motivate
          </Typography>
        </Box>
      </Box>
      <Typography textAlign="center">
        To learn more about PIPs Partner Offerings, contact us.
      </Typography>
      <Box sx={classes.contactBtn}>
        <Button
          variant="contained"
          color="primary"
          href="mailto:theteam@pipsrewards.com"
        >
          Contact for more information
        </Button>
      </Box>
    </Box>
  );
};

export default PartnerOpportunities;
