import React from 'react';
import { Box, Typography } from '@mui/material';
import Layout from 'components/Layout';
import classes from './styles';

const TermsOfService = () => (
  <Layout>
    <Box sx={classes.root}>
      <Typography variant="h1" align="center" mb={1}>
        Terms of Service
      </Typography>
      <Typography fontStyle="italic" align="center" mb={6}>
        As of August 7, 2020
      </Typography>
      <Typography mt={5}>
        THESE ARE THE GENERAL TERMS AND CONDITIONS GOVERNING YOUR USE OF OUR
        MOBILE APPLICATION, WEBSITE, AND ANY OF OUR OTHER SERVICES INCLUDING ANY
        OF OUR CHALLENGES, OUR PIPS FOR SCHOOL APP TOOL AND ANY UPDATES THERETO
        (COLLECTIVELY, THE “SERVICES”). BY ACCESSING, USING OR REGISTERING AS A
        USER OF ANY OF OUR SERVICES, YOU ARE CONCLUDING A LEGALLY BINDING
        AGREEMENT BASED ON THESE TERMS WITH PIPS REWARDS LLC. THESE TERMS OF
        SERVICE (THE “AGREEMENT”) SET FORTH THE TERMS AND CONDITIONS GOVERNING
        YOUR USE OF OUR SERVICES.
      </Typography>
      <Box mt={5} mb={5}>
        <Typography variant="h4" color="primary">
          Introduction
        </Typography>
        <p>
          Welcome to the PIPs Reward Program. All of our Services, including our
          mobile application (the “App”) and our website (the “Site”) and the
          content and products made available through our Services, are provided
          to you by PIPs Rewards LLC, a New York limited liability company (the
          “Company” or “We”).
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          PIPs Reward Program
        </Typography>
        <Typography variant="h5" fontStyle="italic">
          Membership
        </Typography>
        <p>
          Membership in the PIPs Reward Program is free. To be eligible to earn
          or redeem PIPs, you must create a PIPs Member Account. Once you create
          a PIPs Member Account you become a member of the PIPs Reward Program.
          Your participation in the PIPs Reward Program is personal to you and
          PIPs may not be assigned or transferred to any third-party except as
          expressly contemplated under and in accordance with these Terms of
          Service. As a member of the PIPs Reward Program (a ”Member”), you can
          earn and redeem PIPs in accordance with these Terms of Service. You
          are responsible for reviewing the balance in your PIPs Member Account
          on a regular basis and for checking to ensure that PIPs have been
          properly credited and debited in your PIPs Member Account. You may not
          have more than one PIPs Member Account. The sale, transfer, lending,
          exchange, pooling or barter of PIPs or any PIPs Member Account, other
          than by the Company, is expressly prohibited and any action taken in
          breach of these Term of Service may result in the confiscation or
          cancellation of your PIPs and/or your PIPs Reward Account in the
          Company’s sole discretion.
        </p>
        <Typography variant="h5" fontStyle="italic">
          Earning PIPs
        </Typography>
        <p>
          PIPs may be earned in a number of ways. PIPs may be earned by you when
          you make purchases with the Marketplace Partners featured through our
          Services (each, a “Marketplace Partner”). You may also earn PIPs when
          you take certain actions with our Preferred Partners who have created
          programs with the Company through which you are rewarded with PIPs in
          exchange for certain actions taken by you with the Preferred Partner
          (each, a “Preferred Partner”). In addition, the Company and certain
          partners (including employers and educational institutions that may
          offer a challenge and/or the PIPs for School Program, as the case may
          be) may create and/or offer special events and/or challenges together
          (each, a “Premium Partner”), and PIPs may be earned by you through
          your participation in these special events and/or challenges.
        </p>
        <p>
          From time to time, the Company may announce other ways that you can
          earn PIPs, such as upon creating your Membership Account and in
          connection with our PIPs Refer a Friend Program. In such cases the
          terms of such awards, including the amount of PIPs rewarded and any
          limitation on the number of Members eligible to receive PIPs in
          connection with the PIPs Refer a Friend Program, shall be as
          determined by the Company in its sole discretion. For example, to earn
          a Refer a Friend award, the Company may require that the referred new
          Member make a purchase with a value of at least $25.00 during the
          first 365 days of the Member’s membership in the PIPs Reward Program.
          Please note that any awards made in connection with the Refer a Friend
          Program are given for the purpose of attracting brand new Members to
          the PIPs Reward Program. Therefore, a Member may only earn one PIPs
          referral bonus per individual person referred.
        </p>
        <p>
          Our Marketplace Partners, Preferred Partners and Premium Partners
          (collectively, our “Partners”) may have special requirements for
          earning PIPs and you will not earn PIPs unless and until those
          requirements are met. In addition, to earn PIPs you must be 18 years
          of age or older, you must reside in the United States, and you must
          have Internet access and a valid working email account. The Company is
          not responsible for tracking problems resulting from incorrect usage
          of the Services, disabling cookies, computer issues resulting from
          unknown causes such as third-party software or other issues, partner
          errors or omissions, or from any other failure of tracking mechanisms.
        </p>
        <p>
          In addition, to earn PIPs through transactions with our Marketplace
          Partners, you must satisfy the following criteria: (i) you must log
          into your PIPs Membership Account before you link from the Services to
          a Marketplace Partner’s site; (ii) you must link to the Marketplace
          Partner’s site through our Services (unless we specify that is not
          necessary); (iii) you must have cookies enabled; (iv) you must
          complete your transaction; and (v) you must return to our Services
          each time you wish to conduct an additional PIPs-earning transaction
          with a Marketplace Partner. In addition, if any of the following
          occur, you may not earn PIPs in connection with a transaction with a
          Marketplace Partner: (i) you allow too much time to elapse between
          linking to the Marketplace Partner’s site and completing a transaction
          there; (ii) you fail to abide by the restrictions or requirements
          imposed by a Marketplace Partner; (iii) you click on any special
          offers or other coupons while making your transaction; (iv) you click
          on other toolbars while conducting your transaction; (v) you type a
          new address (URL) in any window opened by the PIPs Reward Program;
          (vi) the Marketplace Partner fails to transmit to the Company
          information about any purchase that would otherwise qualify for PIPs;
          (vii) a Marketplace Partner fails to comply with the terms of the
          Company’s agreement with such Marketplace Partner; (viii) you return
          the item purchased in a PIPs-earning transaction; and/or (ix) you are
          notified that you or certain purchases are not eligible to earn PIPs.
          The Company will not be responsible for any PIPs it has not awarded to
          you where it was unable to make the award due to incorrect,
          incomplete, inaccurate or outdated PIPs Member Account information.
          All determinations of whether or not a purchase made through a
          Marketplace Partner qualifies for PIPs are made by the Company in its
          sole discretion.
        </p>
        <p>
          Each time you earn PIPs through activity with a Partner, the number of
          PIPs to be awarded will be noted in your PIPs Member Account as
          “Pending” within a reasonable amount of time after the Partner reports
          the transaction to the Company. Depending on the applicable Partner’s
          terms and conditions, the PIPs may thereafter be immediately available
          for redemption or, if you have return rights, the PIPs will only
          become redeemable upon the expiration of the applicable return period.
          If you believe that you have earned PIPs that do not appear in your
          account, you must contact the Company Customer Support within sixty
          (60) days of the date of the event/challenge/purchase/order to which
          the discrepancy relates. If you fail to contact the Company within
          that sixty (60)-day period, the PIPs noted in your PIPs Member Account
          shall be deemed to be accurate as of that date and your hereby waive
          any right to dispute the number of PIPs earned by you in connection
          with the transaction to which the discrepancy relates. At such time as
          PIPs is redeemable, it will be noted as “Available” in your PIPs
          Reward Account and thereafter you may redeem your PIPs in accordance
          with the terms of this Terms of Service (See “Redeeming PIPs” below).
          The Company reserves the right to change the number of PIPs that may
          be earned for various earn opportunities and to impose limits and/or
          processing fees on PIPs earnings.
        </p>
        <p>
          The Company is not responsible for changes to or discontinuance of any
          Partner program, earning or redemption opportunity, offering, event or
          challenge, or any Partner’s withdrawal from the PIPs Reward Program,
          or for any effect on accrual of PIPs caused by such changes,
          discontinuance or withdrawal.
        </p>
        <Typography variant="h5" fontStyle="italic">
          Redeeming PIPs
        </Typography>
        <p>
          Whenever it’s noted in your PIPs Member Account that PIPs are
          “Available,” you may use those PIPs for various redemption options
          that may be made available by the Company, you may donate your PIPs to
          one of the non-profits listed through our Services, or you may invest
          your PIPs in one of the micro-investment Partners listed through our
          Services. Qualification for a given redemption, donation, or
          micro-investment option is subject to specific conditions that are set
          in the sole discretion of the Company and/or our Partners. Such
          conditions may be adjusted by the Company and/or our Partners at any
          time. The Company reserves the right to change the number of PIPs that
          may be required to redeem a reward or that may be used for various
          activities (e.g., donations and micro-investments) and to impose
          limits and/or processing fees on PIPs redemptions, donations, and
          investments. PIPs redemptions are subject to availability (which may
          be limited) of the redeemed product, service or other benefit (such as
          a scholarship), terms and conditions of the applicable Partner and/or
          as listed in connection with the redemption opportunity and may be
          subject to change at any time. In the event that a Partner refuses to
          honor a PIPs redemption for any reason, other than suspicion of theft
          or fraud, the Company’s sole obligation will be to credit the PIPs
          Member Account of the Member for the number of PIPs that were to be
          redeemed. Please note that to the extent that you wish to deduct from
          your taxes any donation of PIPs made by you to any of our non-profit
          Partners, you must obtain a tax deduction receipt directly from the
          Partner and you acknowledge and agree that the Company is not able to
          generate such certificate and the Company cannot guarantee the
          generation of any such certificate by the non-profit Partner. In
          addition, to the extent that you choose to invest your PIPs with any
          of our micro-investment Partners, the terms and conditions of such
          investment shall be as agreed upon by you and the micro-investment
          Partner and we shall not be party to that transaction now shall be we
          responsible for the outcome of any such investment.
        </p>
        <p>
          PIPs have no cash value until such time as you request and receive a
          redemption in accordance with these Terms of Service and you shall
          have no property rights or other legal interest in your PIPs Member
          Account or in any of the PIPs in your PIPs Member Account. PIPs are
          not redeemable for cash or any other form of currency or credit and
          cannot be returned, exchanged, refunded or replaced by cash or rewards
          other than those offered through our Services. PIPs are nonrefundable
          under any circumstances once PIPs are redeemed. You are responsible
          for any taxes that may be due on PIPs redeemed by you.
        </p>
        <p>
          Notwithstanding anything to the contrary, PIPs in your Member Account
          shall expire at such time as (i) your Member Account is closed in
          accordance with these Terms of Service, (ii) you are a Member who is
          not participating through a PIPs for School Program and you have not
          earned more than 125 PIPs, or redeemed, donated or invested more than
          500 PIPs, in a 24-month period, or (iii) you are a Member who is
          participating through a PIPs for School Program and you are no longer
          enrolled at the participating school, the PIPs shall terminate and be
          cancelled six (6) months after your last day of enrollment.
        </p>
        <Typography variant="h5" fontStyle="italic">
          Audit
        </Typography>
        <p>
          The Company reserves the right to (a) audit your PIPs Member Account
          at any time for compliance with this Agreement, without notice to you,
          and (b) to change accrued PIPs totals without notification if such
          audit reveals errors, inaccuracies or miscalculations.
        </p>
        <Typography variant="h5" fontStyle="italic">
          Termination of PIPs Reward Program
        </Typography>
        <p>
          The Company reserves the right to terminate the PIPs Reward Program in
          whole or in part at any time with or without notice and without any
          further liability or obligation to you.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Coupons, Discounts and Special Offers
        </Typography>
        <p>
          From time to time our Partners may provide coupons, discounts or other
          special offers (collectively, ”Offers”) to our Members. The Company is
          not responsible for the redemption, errors/omissions or expiration of
          any such Offers and it is your responsibility to make sure that any
          Offer that you wish to apply to a transaction is present in the
          checkout process with any Partner. All Offers are subject to change
          without notice. The Company has no control over the legality of any
          Offers made by our Partners, the ability of any of our Partners to
          complete a transaction in accordance with any Offer, or the quality of
          the goods and/or services offered by any Partner. The Company has no
          control over whether any Partner will honor any Offer shown on our
          Site or their site and the Company does not guarantee the accuracy or
          completeness of the information contained on our Site or their site in
          connection with any Offer. In the event that you have a dispute with a
          Partner that relates to the Company, including in connection with
          anything posted through our Services or the use of any information
          from our Services, you agree to waive and release the Company and its
          parent, and their respective officers, managers and trustees, and the
          other Related Parties (as defined below) from any and all claims,
          demands, actions, damages (actual and consequential), losses, costs or
          expenses of every kind and nature, known and unknown, disclosed and
          undisclosed, relating to any such dispute.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Intended Audience/Disclaimer for Access Outside of the United States
        </Typography>
        <p>
          Our Services are controlled, operated and administered by the Company
          from within the United States of America. &nbsp; The Company makes no
          representation that materials available through the Site or the App
          are appropriate or available for use at other locations outside of the
          United States and access to them from territories where their contents
          are illegal is prohibited. &nbsp; You may not use our Services or
          export the materials in violation of U.S. export laws and regulations.
          &nbsp; If you access our Services from locations outside of the United
          States, you are responsible for compliance with all local laws.
          Moreover, our Services are directed to adults in the United States and
          are not intended for children under the age of eighteen.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Privacy
        </Typography>
        <p>
          We describe our current practices related to personally identifiable
          information collected through the Services in our&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="/privacy">
            Privacy Policy
          </a>
          &nbsp;and we may update our policies and practices from time to time
          in our sole discretion. To the extent that there are inconsistencies
          between this Agreement and the Privacy Policy, this Agreement shall
          govern.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Your Use of Content and Information -- Disclaimer with Respect to
          Content
        </Typography>
        <p>
          A variety of information, content, and software, including for example
          challenges, events, articles, reviews, directories, advice, messages,
          comments, posts, text, data, recommendations, letters, guides, text,
          photographs, images, illustrations, graphics, music, sound, audio
          clips, video, html, source and object code, trademarks, logos, and the
          like (“Content”) is available through our Services. Some of the
          Content is provided by us or our Partners or suppliers, and other
          Content is provided by persons who use our Services (“Users”), such as
          User opinions and views provided via posts to chat rooms, blogs,
          bulletin boards, or discussion forums. While we strive to keep the
          Content that we post through our Services accurate, complete, and
          up-to-date, we cannot guarantee, and we are not responsible for, the
          accuracy, completeness, or timeliness of any Content, whether provided
          by us or our suppliers, or by Users of the Services. We do not have
          any obligation to prescreen, edit, or remove any Content provided by
          Users that is posted on or available through the Services. Any
          opinions, advice, statements or other information expressed or made
          available by Users or third parties, including but not limited to
          bloggers, are those of the respective User or other third party and
          not of the Company. We do not endorse and we are not responsible for
          the accuracy or reliability of any opinion, advice or statement made
          through the Services and we do not endorse or recommend any specific
          Partner or service providers or professionals mentioned through the
          Services. Notwithstanding the foregoing, we do have the right (but not
          the obligation), in our sole discretion and for any reason, to
          prescreen, edit, refuse to accept, remove, or move any such Content.
          Reliance on any information provided through the Services is solely at
          your own risk.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Ownership and Intellectual Property Rights
        </Typography>
        <Typography variant="h5" fontStyle="italic">
          Ownership
        </Typography>
        <p>
          The Services, including the Site and any the Company mobile
          applications, are owned by the Company. All right to, title to, and
          interest in the Content available via the Services, the Site and the
          Company mobile application’s look, feel, selection and arrangement,
          the designs, trademarks, service marks, and trade names displayed in
          connection with the Services, and the Site URLs, are the property of
          the Company or its affiliated companies, licensors or suppliers, and
          are protected by copyrights, trademarks, patents, or other proprietary
          rights and laws. The entire contents of the Site and the Company
          mobile applications are copyrighted as a collective work/compilation.
          The Company owns copyright in the selection, coordination,
          arrangement, and enhancement of such content, as well as in the
          content original to it. You may not modify, publish, transmit,
          participate in the transfer or sale, create derivative works, or in
          any way exploit, any of such content in whole or in part.
        </p>
        <Typography variant="h5" fontStyle="italic">
          License to Use Content
        </Typography>
        <p>
          The Company grants you a nonexclusive, nontransferable, revocable,
          limited license to use, view, copy, print, and distribute Content
          retrieved through the Services only for your personal, noncommercial
          use, and you may download or print a single copy of any portion of the
          Content solely for your personal, noncommercial use provided you do
          not remove or obscure any trademark, copyright or other notice
          displayed on or in conjunction with such Content. You may not use any
          Content available through the Services in any other manner or for any
          other purpose without the prior written permission of the Company. All
          rights not expressly granted in this Agreement are expressly reserved
          to the Company.
        </p>
        <Typography variant="h5" fontStyle="italic">
          Trademark Notices
        </Typography>
        <p>
          All trademarks, service marks, logos and designs used in connection
          with the Services, whether registered or unregistered, are owned by
          the Company or other third parties. You may not use or display any
          trademarks, service marks, logos or designs owned by the Company or
          its affiliates without our prior written consent.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Your Contact with Advertisers
        </Typography>
        <p>
          Your dealings with advertisers accessible through the Services are
          solely between you and the advertiser. Links are provided solely as a
          convenience to Users and should not be construed as an endorsement by
          the Company or any Partner of content, items, or services provided by
          advertisers. You access and use advertiser sites, including the
          content, items, or services on those sites, and you enter into
          dealings with such advertisers, solely at your own risk. The Company
          does not make any representations or warranties with respect to any
          content or privacy practices, or otherwise with respect to such
          advertisers or any items or services that may be obtained from such
          advertisers, and you agree that neither the Company nor its parent
          company will have any liability with respect to any loss or damage of
          any kind incurred as a result of any dealings between you and any
          advertiser, or as a result of the presence of such advertisers in
          connection with our Services.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Access
        </Typography>
        <p>
          You agree that you will not exceed the limited access to the Site
          granted to you or use any robot, spider, scraper or other automated
          means to access the Site for any purpose. You further agree that you
          will not (i) take any action that in our sole discretion imposes or
          may impose an unreasonable or disproportionately large load on our
          infrastructure; (ii) interfere with the proper working of the Site,
          our mobile applications or any activities conducted on the Site or
          through our mobile applications; or (iii) bypass any robot exclusion
          headers or other measures we may use to prevent or restrict access to
          the Site or any mobile application.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Disclaimer of Warranties and Limitation of Liability
        </Typography>
        <p>
          <strong>
            YOU ACKNOWLEDGE THAT YOU ARE USING THE SERVICES AT YOUR OWN RISK.
            THE SERVICES, INCLUDING THE SITE, THE MOBILE APPLICATIONS, AND THE
            CHALLENGES AND OTHER EVENTS THAT YOU MAY PARTICIPATE IN, THE
            MATERIALS ON THE SITE AND PROVIDED THROUGH THE MOBILE APPLICATIONS,
            AND THE SOFTWARE MADE AVAILABLE ON THE SITE AND THROUGH THE MOBILE
            APPLICATIONS, IF ANY, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
            BASIS WITHOUT ANY REPRESENTATION OR WARRANTY OF ANY KIND, AND THE
            COMPANY AND ITS PARENT, AND THEIR RESPECTIVE AFFILIATES, AGENTS,
            LICENSORS, CONTENT PROVIDERS, OFFICERS, MANAGERS, DIRECTORS,
            TRUSTEES, EMPLOYEES, PARTNERS, SUPPLIERS, SHAREHOLDERS,
            REPRESENTATIVES, CONTRACTORS AND THEIR ASSIGNS (COLLECTIVELY, THE
            “RELATED PARTIES”) HEREBY EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES
            OF ANY KIND, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING
            BUT NOT LIMITED TO ANY WARRANTIES OF ACCURACY, COMPLETENESS,
            RELIABILITY, TITLE, MERCHANTABILITY, NON-INFRINGEMENT, FITNESS FOR A
            PARTICULAR PURPOSE OR ANY OTHER WARRANTY, CONDITION, GUARANTEE OR
            REPRESENTATION, WHETHER ORAL, IN WRITING OR IN ELECTRONIC FORM,
            INCLUDING BUT NOT LIMITED TO THE ACCURACY, QUALITY, RELIABILITY,
            HEALTH BENEFITS, SAFETY OR COMPLETENESS OF ANY INFORMATION CONTAINED
            THEREIN OR PROVIDED BY THE SERVICES. &nbsp; THE COMPANY, ITS PARENT,
            AND THE RELATED PARTIES DO NOT REPRESENT OR WARRANT THAT ACCESS TO
            THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE OR
            THAT THERE WILL BE NO FAILURES, ERRORS OR OMISSIONS OR LOSS OF
            TRANSMITTED INFORMATION, OR THAT NO VIRUSES WILL BE TRANSMITTED
            THROUGH THE SERVICES OR THAT DEFECTS, IF ANY, WILL BE CORRECTED, OR
            THAT THE SERVICES WILL MEET YOUR REQUIREMENTS AND THE COMPANY
            ASSUMES NO RESPONSIBILITY FOR ANY DAMAGE CAUSED BY YOUR
            PARTICIPATION IN, ACCESS TO, OR INABILITY TO ACCESS, OUR SERVICES,
            INCLUDING , BUT NOT LIMITED TO, ANY INJURY OR DEATH THAT MAY OCCUR
            AS A RESULT OF YOUR PARTICIPATION IN ANY CHALLENGE OR OTHER EVENT
            THAT YOU MAY PARTICIPATE IN THAT IS OFFERED BY OR THROUGH THE
            SERVICES OR YOUR INABILITY TO RECEIVE PIPS IN CONNECTION WITH ANY
            TRANSACTION. &nbsp;NEITHER THE COMPANY, ITS PARENT, NOR ANY OF THE
            RELATED PARTIES ARE RESPONSIBLE OR LIABLE FOR CONTENT POSTED BY
            THIRD PARTIES, ACTIONS OF ANY THIRD PARTY, OR FOR ANY DAMAGE TO, OR
            VIRUS OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES THAT MAY
            INFECT YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY. IF YOUR USE OF THE
            SERVICES RESULTS IN THE NEED TO SERVICE OR REPLACE EQUIPMENT OR
            DATA, NO COMPANY RELATED PARTY SHALL BE RESPONSIBLE FOR THOSE COSTS.
            YOU AGREE THAT NEITHER THE COMPANY NOR ANY OTHER RELATED PARTY WILL
            BE LIABLE FOR ANY COSTS, DAMAGES OR OTHER LOSSES ARISING OUT OF A
            DISPUTE BETWEEN YOU AND ANY COMPANY PARTNER (INCLUDING ANY
            MARKETPLACE PARTNER, PREFERRED PARTNER OR PREMIUM PARTNER) AND YOU
            HEREBY EXPRESSLY WAIVE ANY SUCH CLAIM AGAINST THE COMPANY, ITS
            PARENT, AND THE OTHER RELATED PARTIES. THE COMPANY DOES NOT MAKE ANY
            REPRESENTATION OR WARRANTY REGARDING ANY GOODS OR SERVICES OFFERED
            OR PROVIDED BY ANY OF OUR PARTNERS (INCLUDING ANY MARKETPLACE
            PARTNER, PREFERRED PARTNER OR PREMIUM PARTNER); ANY ACT OR OMISSION
            BY THE COMPANY IN ADMINISTERING THE SERVICES OR THE PIPS REWARD
            PROGRAM; OR THE PURCHASE OR USE OF ANY GOODS OR SERVICES OF OUR
            PARTNERS (INCLUDING ANY MARKETPLACE PARTNER, PREFERRED PARTNER OR
            PREMIUM PARTNER), EVEN IF THE COMPANY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES, CLAIMS OR LOSSES AND NOTHWITHSTANDING
            ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
          </strong>
        </p>
        <p>
          <strong>
            NEITHER THE COMPANY, ITS PARENT, NOR ANY OF THE OTHER RELATED
            PARTIES ASSUME RESPONSIBILITY OR LIABILITY FOR ANY CONSEQUENCE,
            INJURY OR DEATH RELATING TO OR ARISING OUT OF, DIRECTLY OR
            INDIRECTLY, ANY ACTION OR INACTION YOU TAKE BASED ON THE CONTENT,
            CHALLENGES OR EVENTS OR ACTIVITIES AVAILABLE VIA THE SERVICES. YOU
            MUST EVALUATE AND BEAR ALL RISKS ASSOCIATED WITH THE USE OF ANY
            CONTENT, CHALLENGES, EVENTS AND ACTIVITIES, INCLUDING ANY RELIANCE
            ON THE ACCURACY, COMPLETENESS, OR USEFULNESS OF SUCH CONTENT. YOU
            SPECIFICALLY ACKNOWLEDGE THAT NEITHER THE COMPAHY, ITS PARENT NOR
            THE OTHER RELATED PARTIES ARE LIABLE FOR THE DEFAMATORY, OFFENSIVE
            OR ILLEGAL CONDUCT OF USERS OR THIRD PARTIES.
          </strong>
        </p>
        <p>
          <strong>
            ADDITIONALLY, IN NO EVENT WILL THE COMPANY, ITS PARENT, OR ANY OF
            THE OTHER RELATED PARTIES BE LIABLE FOR ANY SPECIAL, INDIRECT,
            INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT
            LIMITATION, ANY LOSS OF USE, LOSS OF PROFITS, LOSS OF DATA, COST OF
            PROCUREMENT OF SUBSTITUTE PRODUCTS OR SERVICES, OR ANY OTHER
            DAMAGES, HOWEVER CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR
            BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT
            LIABILITY), OR OTHERWISE RESULTING FROM (1) THE USE OF, OR THE
            INABILITY TO USE, THE SERVICES OR ANY ITEM PURCHASED THROUGH THE
            SERVICES; (2) THE COST OF PROCUREMENT OF SUBSTITUTE SERVICES, ITEMS,
            WEB SITES OR MOBILE APPLICATIONS; (3) UNAUTHORIZED ACCESS TO OR
            ALTERATION OF YOUR TRANSMISSIONS OR DATA; (4) THE STATEMENTS OR
            CONDUCT OF ANY THIRD PARTY ON THE SITE OR THROUGH THE SERVICES; OR
            (5) ANY OTHER MATTER RELATING TO THE SERVICES. YOU ALSO AGREE THAT
            THE COMPANY IS NOT RESPONSIBLE OR LIABLE FOR THE AVAILABILITY,
            ACCURACY, CONTENT, RELIABLITY OR ADVERTISING OF THE MERCHANDISE OR
            SERVICES OFFERED BY ANY COMPANY PARTNER (INCUDING WITHOUT LIMITATION
            ANY MARKETPLACE PARTNER, PREFERRED PARTNER, OR PREMIUM PARTNER).
            THESE LIMITATIONS WILL APPLY WHETHER OR NOT THE COMPANY, ITS PARENT
            OR ANY RELATED PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES AND NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY
            LIMITED REMEDY.
          </strong>
        </p>
        <p>
          <strong>
            IN THE EVENT OF ANY LIABILITY, THE COMPANY, ITS PARENT, AND THE
            RELATED PARTIES SHALL BE COLLECTIVELY LIABLE ONLY TO THE EXTENT OF
            DAMAGES INCURRED BY YOU, NOT TO EXCEED U.S. $50. ANY CAUSES OF
            ACTION YOU MAY HAVE WITH RESPECT TO THE COMPANY, ITS PARENT, THE
            RELATED PARTIES OR THE SERVICES MUST BE FILED IN A COURT OF
            COMPETENT JURISDICTION SITTING IN NEW YORK, NEW YORK WITHIN TWO (2)
            MONTHS OF THE TIME IN WHICH THE EVENTS GIVING RISE TO SUCH CLAIM
            BEGAN OR YOU AGREE TO WAIVE SUCH CLAIM. REMEDIES UNDER THESE TERMS
            OF SERVICE ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY PROVIDED
            FOR IN THESE TERMS OF SERVICE. SOME STATES DO NOT ALLOW THE
            EXCLUSION OF IMPLIED WARRANTIES OR THE LIMITATION OR EXCLUSION OF
            LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
            EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE
            OTHER RIGHTS, WHICH VARY FROM STATE TO STATE.
          </strong>
        </p>
        <p>
          <strong>
            SEE “ADDITIONAL TERMS FOR ACTIVITIES (INCLUDING CHALLENGES AND OTHER
            EVENTS)” FOR ADDITIONAL WAIVERS AND RELEASES.
          </strong>
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Additional Terms for Activities (Including Challenges, including
          Fitness Challenges, and Other Events)
        </Typography>
        <p>
          In addition to the other terms, conditions, waivers and limitations on
          liability set forth in this Agreement, the terms below apply
          specifically to your participation in any challenge or other event
          offered by or through the Services and/or any Partner. The Company and
          its Partners reserve the right to terminate any challenge or any event
          offered by or through the Services, in whole or in part, at any time
          with or without notice and without further liability or obligation to
          you. You hereby waive and release the Company, its parent, it Partners
          and other Related Parties from any and all claims, demands, actions,
          damages (including those that are actual, direct and/or
          consequential), losses, costs and/or expenses of every kind and
          nature, known or unknown, disclosed or undisclosed, that may arise as
          a result of the Services, including as a result of your participation
          in a challenge or event offered by or through the Services.
        </p>
        <p>
          <strong>
            YOU HEREBY ASSUME ALL OF THE RISKS OF PARTICIPATING IN ANY/ALL
            ACTIVITIES OFFERED BY OR THROUGH THE SERVICES INCLUDING ANY/AND ALL
            CHALLENGES, EVENTS AND ACTIVITIES
          </strong>{' '}
          (collectively, “Activities”), including by way of example and not
          limitation, any risks that may arise from negligence or carelessness
          on the part of the persons or entities being released, from dangerous
          or defective equipment or property owned, maintained, or controlled by
          them, or because of their possible liability without fault.
        </p>
        <p>
          If you participate in any Activities, to the extent applicable, you
          certify that you are physically fit, have sufficiently prepared or
          trained for participation in the Activity, and have not been advised
          to not participate by a qualified medical professional. You certify
          that there are no health-related reasons or problems which preclude
          your participation in the Activities in which you participate.
        </p>
        <p>
          You acknowledge that waivers and releases contained in this Agreement
          will be used by the Company, its parent, its Partners, and the Related
          Parties involved in the Activity in which you participate, and that
          they will govern your actions and responsibilities with respect to
          said Activity.
        </p>
        <p>
          In consideration of your permission to participate in any Activity,
          you hereby take action for yourself, your executors, administrators,
          heirs, next of kin, successors, and assigns as follows:
        </p>
        <p>
          (A) I WAIVE, RELEASE, AND DISCHARGE from any and all liability,
          including but not limited to, liability arising from the negligence or
          fault of the entities or persons released, for my death, disability,
          personal injury, property damage, property theft, or actions of any
          kind which may hereafter occur to me including my traveling to and
          from any Activity, THE FOLLOWING ENTITIES OR PERSONS: the Company, its
          parent, the Partners and the other Related Parties and/or their
          directors, trustees, officers, managers, members, employees,
          volunteers, representatives, and agents, and any activity holders,
          sponsors, and volunteers;
        </p>
        <p>
          (B) I PROMISE NOT TO SUE THE ENTITIES AND PERSONS MENTIONED HEREIN AND
          I INDEMNIFY AND HOLD HARMLESS the entities and persons mentioned
          herein from any and all liabilities or claims made as a result of my
          participation in an Activity, whether caused by negligence or
          otherwise.
        </p>
        <p>
          I acknowledge that the Company, its parent, its Partners and the
          Related Parties and their directors, trustees, officers, managers,
          members, employees, volunteers, representatives, and agents are NOT
          responsible for the errors, omissions, acts, or failures to act of any
          party or entity conducting a specific Activity on their behalf.
        </p>
        <p>
          I acknowledge that the Activities may involve a test of a person’s
          physical and mental limits and carry with them the potential for
          death, serious injury, and property loss. The risks include, but are
          not limited to, those caused by terrain, facilities, temperature,
          weather, condition of participants, equipment, vehicular traffic, lack
          of hydration, and actions of other people including, but not limited
          to, participants, volunteers, monitors, and/or producers of the
          Activities. These risks are not only inherent to participants, but are
          also present for volunteers.
        </p>
        <p>
          I understand while participating in an Activity, I may be
          photographed. I agree to allow my photo, video, or film likeness to be
          used for any legitimate purpose by the Company, its parent, and its
          Partners.
        </p>
        <p>
          The waivers and releases contained in this Agreement shall be
          construed broadly to provide releases and waivers to the maximum
          extent permissible under applicable law.
        </p>
        <p>
          <strong>
            I CERTIFY THAT I HAVE READ THIS AGREEMENT AND I FULLY UNDERSTAND ITS
            CONTENT. I AM AWARE THAT THIS AGREEMENT CONTAINS THE ABOVE RELEASE
            OF LIABILITY AND I ACCEPT AND AGREE TO ITS TERMS OF MY OWN FREE
            WILL.
          </strong>
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Additional Terms – PIPs for Schools
        </Typography>
        <p>
          Your use of our PIPs for School App is subject to any additional
          policies, rules, regulations, terms and conditions that are agreed to
          by the Company and your school and/or that you are subject to as a
          member of your school’s community and/or that are posted from time to
          time in connection with the PIPs for School Program.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Indemnification
        </Typography>
        <p>
          <strong>
            YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS THE COMPANY, ITS
            PARENT, AND THE OTHER RELATED PARTIES FROM AND AGAINST ANY AND ALL
            CLAIMS (INCLUDING, BUT NOT LIMITED TO, CLAIMS FOR DEFAMATION, TRADE
            DISPARAGEMENT, PRIVACY, INTELLECTUAL PROPERTY INFRINGEMENT, AND
            CLAIMS THAT YOU HAVE FOUND SOMETHING YOU HAVE HEARD, VIEWED OR
            DOWNLOADED FROM THIS SITE OR ANY COMPANY MOBILE APPLICATION OR
            ANOTHER WEBSITE OR APPLICATION TO WHICH OUR SITE OR MOBILE
            APPLICATIONS ARE LINKED TO BE OBSCENE, OFFENSIVE, DEFAMATORY, OR
            INFRIGING UPON YOUR INTELLECTUAL PROPERTY RIGHTS) AND DAMAGES
            (INCLUDING ATTORNEYS’ FEES AND COURT COSTS) ARISING FROM OR RELATING
            TO ANY ALLEGATIONS REGARDING: (1) YOUR ACCESS OR USE OF, OR YOUR
            INABILITY TO ACCESS OR USE, THE SITE, ANY COMPANY MOBILE APPLICATION
            OR THE INFORMATION PROVIDED THROUGH THE SERVICES AND/OR CONTAINED ON
            THE SITE, THE COMPANY MOBILE APPLICATION OR OTHER WEBSITES OR
            APPLICATIONS TO WHICH THEY ARE LINKED; (2) THE COMPANY’S USE OF ANY
            CONTENT YOU PROVIDE; (3) INFORMATION OR MATERIAL POSTED OR
            TRANSMITTED THROUGH YOUR PIPS MEMBERSHIP ACCOUNT, EVEN IF NOT POSTED
            BY YOU; AND, (4) ANY VIOLATION OF THIS AGREEMENT BY YOU.
          </strong>
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Your Account
        </Typography>
        <p>
          You are responsible for maintaining the confidentiality of any
          passwords associated with any account you have in connection with our
          Services, monitoring all activity under the account, and assuming full
          responsibility for all activities that occur under your account
          (unless we cause a security breach). The Company is entitled to act on
          instructions received under your username and password. The Company is
          not responsible for any redemptions made in your PIPs Member Account
          by someone else who uses your name or password. The Company is not
          responsible for any other losses or liabilities incurred through use
          of your password by a third party. The Company reserves the right to
          close a PIPs Member Account under certain circumstances, as reasonably
          determined by the Company in its sole discretion, including in cases
          where a student Member participating through the PIPs for School
          Program is no longer enrolled at the participating school, in which
          case the Company reserves the right to terminate the PIPs Member
          Account on the date that is six (6) months after the last day on which
          the student was enrolled at the applicable school, as well as in cases
          of general Member inactivity.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Submissions
        </Typography>
        <p>
          The Company always welcomes suggestions and comments regarding the
          Site, its mobile applications, and/or the Services we provide. Any
          comments or suggestions submitted to the Company, either online or
          offline, will become the Company’s property upon their submission.
          This policy is intended to avoid the possibility of future
          misunderstandings when projects developed by the Company might seem to
          others to be similar to their own submissions or comments.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Modification or Suspension of the Services
        </Typography>
        <p>
          The Company may at any time modify, discontinue, or suspend its
          operation of the Site, any of its mobile applications, and/or any of
          the Services, or any part thereof, temporarily or permanently, without
          notice to you. In addition, the Company may end the PIPs for Schools
          Program at your school at any time.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Termination
        </Typography>
        <p>
          Either party may terminate the Agreement for any or no cause, at any
          time, by notice, which shall be effective immediately or as specified
          in the notice. After termination, you shall no longer access the Site,
          any Company mobile application, or any of the Company’s other
          Services. The provisions of this Agreement which by their intent or
          meaning are intended to survive such termination shall continue to
          apply indefinitely.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Severability of Agreement
        </Typography>
        <p>
          If any provision of the Agreement is found by a court or other binding
          authority to be invalid, you agree that every attempt shall be made to
          give effect to the parties’ intentions as reflected in that provision,
          and the remaining provisions contained in the Agreement shall continue
          in full force and effect.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Applicable Law
        </Typography>
        <p>
          This Agreement and the resolution of any dispute related to this
          Agreement, the Site, any Company mobile application or any of the
          other Services shall be governed by and construed in accordance with
          the laws of the State of New York without giving effect to any
          principles of conflicts of law. Any legal action or proceeding between
          the Company and/or its parent and you related to the Agreement shall
          be brought exclusively in a court of competent jurisdiction sitting in
          New York, New York, and you agree to submit to the personal and
          exclusive jurisdiction of such courts.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Modifications to this Agreement
        </Typography>
        <p>
          From time to time, in our sole discretion, we may make changes to this
          Agreement by updating this posting on the Site and in our mobile
          applications. At such time, we will specify the effective date of the
          new version of the Agreement. Your continued use of our Services
          following the posting of a new version of the Agreement constitutes
          your acceptance of the new version of the Agreement, including all
          changes thereto.
        </p>
      </Box>
      <Box mb={5}>
        <Typography variant="h4" color="primary">
          Contact Information
        </Typography>
        <p>
          If you have any questions or concerns with respect to this Agreement
          or our Services you may contact the Company at{' '}
          <a href="mailto:support@pipsrewards.com">support@pipsrewards.com</a>.
        </p>
      </Box>
    </Box>
  </Layout>
);

export default TermsOfService;
