import React from 'react';
import { Box, Typography } from '@mui/material';
import { PieChart } from 'react-minimal-pie-chart';

import classes from './styles';

const TabLabel = ({ label, title, type, total, lb, actions }) => (
  <Box
    pb={2}
    display="flex"
    flexDirection="column"
    alignItems="center"
    sx={classes.root}
  >
    {type && (
      <Typography variant="h5" mb="21px">
        {label}
      </Typography>
    )}
    <Box className="pie" sx={classes.pie}>
      {Array.isArray(actions) && actions.length > 0 && (
        <PieChart
          lineWidth={14}
          data={actions.map((action) => ({
            title: action.name,
            value: action.percent,
            color: `#${action.color}`,
          }))}
        />
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={classes.text}
      >
        <p>
          {title}
          <br />
          {type}
        </p>
        {(total || total === 0) && (
          <Typography variant="h4" component="span" my="5px">
            {total.toLocaleString()}
          </Typography>
        )}
        {lb && lb}
      </Box>
    </Box>
  </Box>
);

export default TabLabel;
