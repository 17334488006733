const classes = {
  root: {
    paddingTop: '50px',
    background: '#fff',
    '& video': {
      height: '35vh',
      '@media(min-width: 1600px)': {
        height: '40vh',
      },
    },
    '& p': {
      marginBottom: 3,
    },
  },
};

export default classes;
