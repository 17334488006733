import React from 'react';
import { Box } from '@mui/material';

import classes from './styles';

const Tooltip = ({ message }) => (
  <Box sx={classes.root} className="tooltip-badge">
    {message}
  </Box>
);

export default Tooltip;
