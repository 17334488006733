import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import format from 'date-fns/format';
import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import {
  userSelector,
  clearState,
  signupUser,
  getUser,
} from 'store/user/UserSlice';
import { showErrorNotification } from 'services/notifications';

import { encodePassword } from 'utils/encodePassword';
import CreateAccountStep from './CreateAccountStep';
import ProfileInformationStep from './ProfileInformationStep';
import PrivacySettingsStep from './PrivacySettingsStep';
import { FormValidationSchema } from './settings';
import classes from './styles';
import { checkEmail } from 'services/api';
import Preloader from 'components/Preloader';
import { handleError } from 'services/handleError';
import { messages } from 'config/messages';

const steps = [
  'Create Your Account',
  'Profile Information',
  'Privacy Settings',
];

const renderSteps = (props) => {
  const { control, errors, activeStep, getValues } = props;
  const formData = getValues();

  switch (activeStep) {
    case 0:
      return (
        <CreateAccountStep
          formData={formData}
          control={control}
          errors={errors}
        />
      );
    case 1:
      return <ProfileInformationStep control={control} errors={errors} />;
    case 2:
      return <PrivacySettingsStep control={control} errors={errors} />;
  }
};

const RegistrationForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching, isSuccess, isError, error } = useSelector(userSelector);
  const thToken = localStorage.getItem('th_token');

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const handleCheckEmail = () => {
    const data = getValues();
    return checkEmail(data.email)
      .then((res) => res)
      .catch((err) => handleError(err.response.data));
  };

  const handleNext = async () => {
    let isValid = false;

    switch (activeStep) {
      case 0:
        isValid = await trigger([
          'email',
          'firstName',
          'lastName',
          'password',
          'confirmPassword',
        ]);
        break;
      case 1:
        isValid = await trigger(['dayOfBirth', 'phone']);
        break;
    }

    if (isValid) {
      if (activeStep === 0) {
        const res = await handleCheckEmail();
        if (res.existed) {
          showErrorNotification(messages.errors.ACCOUNT_EXISTS_ERROR);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const isValid = await trigger([
      'use',
      'donate',
      'earn',
      'customTermsAccepted',
    ]);

    if (isValid) {
      const data = getValues();
      const body = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        dayOfBirth: format(data.dayOfBirth, 'yyyy-MM-dd'),
        passwordToken: encodePassword(data.password),
        phone: data.phone,
        usePrivate: data.use === 'private',
        donatePrivate: data.donate === 'private',
        earnPrivate: data.earn === 'private',
        customTermsAccepted: data.customTermsAccepted,
        ...(thToken && { thinkHumanTv: true }),
      };
      dispatch(signupUser({ body })).then((res) => {
        if (res.payload && res.payload.status === 'OK') {
          dispatch(getUser());
          navigate('/verify-code');
        }
      });
    }
  };

  const {
    trigger,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      dayOfBirth: '',
      phone: '',
      earn: 'public',
      use: 'public',
      donate: 'public',
      customTermsAccepted: false,
    },
    resolver: yupResolver(FormValidationSchema),
  });

  if (isFetching) return <Preloader />;

  const stepProps = {
    getValues,
    activeStep,
    control,
    errors,
  };

  return (
    <Box sx={classes.root}>
      <Typography variant="h3" color="">
        {steps[activeStep]}
      </Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel />
          </Step>
        ))}
      </Stepper>

      <form>{renderSteps(stepProps)}</form>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          variant="outlined"
          color="secondary"
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Cancel
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            color="secondary"
            form={`form-step${activeStep}`}
            onClick={handleSubmit}
            type="submit"
          >
            Create Profile
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            form={`form-step${activeStep}`}
            onClick={handleNext}
            type="button"
          >
            Continue
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default RegistrationForm;
