import React from 'react';
import { Box, Typography } from '@mui/material';
import palette from 'styles/theme/palette';

const AnimationType = ({ card }) => {
  return (
    <Box>
      <Typography mb={2} sx={{ color: palette.text.primary }}>
        {card.description}
      </Typography>
      <Typography mb={2}>{card.instructions}</Typography>
      <Typography mb={2}>This coupon has been expired.</Typography>
    </Box>
  );
};

export default AnimationType;
