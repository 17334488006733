import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import classes from './styles';

const Preloader = ({ opacity }) => {
  const background = opacity && { background: '#ffffffb5' };
  return (
    <Box sx={{ ...classes.root, ...background }}>
      <Box sx={classes.imageContainer}>
        <CircularProgress />
      </Box>
    </Box>
  );
};

export default Preloader;
