const classes = {
  root: {
    paddingTop: '30px',
    paddingBottom: '100px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media (min-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  tabs: {
    marginBottom: '47px',
  },
};

export default classes;
