const classes = {
  root: {
    margin: '0 auto',
    position: ' relative',
    paddingTop: '30px',
    paddingBottom: '95px',
    background: 'white',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media (min-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '85px',
    },
    '& .slick-dots': {
      bottom: '-40px',
      '@media (min-width: 768px)': {
        bottom: '-25px',
      },
      '& li': {
        margin: '0 4px',
        '& button': {
          width: '14px',
          height: '14px',
          padding: 0,

          '&:before': {
            fontSize: '14px',
            lineHeight: 1,
            width: '14px',
            height: '14px',
          },
          '&:hover:before': {
            opacity: 0.25,
          },
          '&:focus:before': {
            color: '#fdae2c',
          },
        },
        '&.slick-active button:before': {
          color: '#fdae2c',
        },
      },
    },
  },
  slide: {
    display: 'block!important',
    height: '100%',
    width: '100%',

    '&:focus-visible': {
      outline: 'none',
    },
  },
  slideInner: {
    minHeight: '632px',
    maxWidth: '640px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    borderRadius: '20px',
    padding: '24px 16px 32px',
    textAlign: 'center',
    margin: '0 auto',
    '@media (min-width: 768px)': {
      minHeight: '537px',
    },
  },
  title: {
    fontSize: '32px',
    lineHeight: 1,
    '@media (min-width: 768px)': {
      fontSize: '38px',
    },
  },
  actions: {
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
      '& button': {
        margin: '0 16px',
      },
    },
    '& button': {
      margin: '16px 0',
      '@media (min-width: 768px)': {
        margin: '0 16px',
      },
    },
  },
};

export default classes;
