import React, { useMemo } from 'react';
import { Box } from '@mui/material';

import {
  hasDigits,
  hasUppercase,
  hasLowercase,
  hasEightLength,
  hasSpecialCharacter,
} from './settings';
import classes from './styles';
import palette from 'styles/theme/palette';

const strengthsLabel = {
  1: 'tooWeak',
  2: 'weak',
  3: 'normal',
  4: 'good',
  5: 'strong',
};

const PasswordStrengthMeter = ({ password }) => {
  const passwordScore = useMemo(() => {
    return [
      hasDigits(password),
      hasSpecialCharacter(password),
      hasUppercase(password),
      hasLowercase(password),
      hasEightLength(password),
    ].filter((num) => !!num).length;
  }, [password]);

  // Creates an array of length 5 for indicators
  const arrayOfFour = useMemo(
    () => Array.from({ length: 5 }, (_, i) => i + 1),
    []
  );

  const renderStrengthBars = () => {
    return arrayOfFour.map((num) => {
      const passEntered = password.length > 0 && num === 1;

      const barClass =
        passwordScore >= num || passEntered
          ? strengthsLabel[passwordScore]
          : '';

      return (
        <Box
          key={num}
          sx={[
            {
              width: '25px',
              height: '4px',
              background: '#cfd4dd',
              borderRadius: '50px',
              marginRight: '8px',
            },
            barClass === 'tooWeak' && {
              background: palette.error.dark,
            },
            barClass === 'weak' && {
              background: palette.error.light,
            },
            barClass === 'normal' && {
              background: palette.warning.main,
            },
            barClass === 'good' && {
              background: palette.text.primary,
            },
            barClass === 'strong' && {
              background: '#a2ca6f',
            },
          ]}
        />
      );
    });
  };

  return (
    <Box sx={classes.root}>
      <Box sx={classes.strengthTitleWrapper}>
        Password Strength:{' '}
        <Box sx={classes.strengthBarsWrapper}>{renderStrengthBars()}</Box>
      </Box>
    </Box>
  );
};

export default PasswordStrengthMeter;
