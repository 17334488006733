import React, { useEffect, useState } from 'react';
import { Box, Button, Slider, Typography } from '@mui/material';
import palette from 'styles/theme/palette';
import { donatePledgePromo } from 'services/api';
import classes from './styles';
import { handleError } from 'services/handleError';

const PledgeDonation = ({ promo, handleSuccessMessage }) => {
  const [percent, setPercent] = useState(1);

  useEffect(() => {
    setPercent(promo.pledgePercentPromoCurrent);
  }, []);

  const handleChangePercent = (val) => {
    setPercent(val);
  };

  const handleDonatePledgePromo = () => {
    const body = {
      promoId: promo.id,
      percent,
    };
    donatePledgePromo({ body })
      .then((res) => {
        if (res.status === 'OK') {
          handleSuccessMessage('Success!');
        } else {
          handleError(res);
        }
      })
      .catch((err) => handleError(err.response.data));
  };

  return (
    <Box>
      <Typography variant="h4">Give With PIPs</Typography>
      <Typography>
        Using the slider below, choose the percentage of future earned PIPs you
        would like to contribute.
      </Typography>
      <Typography>
        <b>Note:</b> At the beginning of each month, if a minimum of $10 is
        reached, pledged PIPs will be converted into dollars and sent to the
        NGO. You can opt to turn off the pledge at any time. All PIPs pledged as
        of that point will be donated.
      </Typography>
      <Typography color={palette.info.light} mb={2} mt={2}>
        What % of your future PIPs do you want to give?
      </Typography>
      <Box sx={classes.sliderWrapper} mb={2}>
        <Box sx={classes.sliderInner}>
          <Typography mr={3}>0%</Typography>
          <Slider
            min={0}
            max={promo.pledgePercentPromoMax}
            value={percent}
            onChange={(event) => handleChangePercent(event.target.value)}
          />
          <Typography ml={3}>{promo.pledgePercentPromoMax}%</Typography>
        </Box>
        <Typography textAlign="center" mb={2}>
          {percent}%
        </Typography>
        {100 - promo.pledgePercentPromoMax > 0 ? (
          <Typography>
            NOTE: You&apos;re already pledging{' '}
            {100 - promo.pledgePercentPromoMax}% of your PIPs to another
            organization.
          </Typography>
        ) : null}
      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDonatePledgePromo}
      >
        Pledge %age of Future Earned PIPs
      </Button>
    </Box>
  );
};

export default PledgeDonation;
