import yup from 'services/yup';

import { messages } from 'config/messages';
import { emailPattern } from 'config/patterns';

export const FormValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email(messages.validation.EMAIL)
    .matches(emailPattern, messages.validation.EMAIL)
    .typeError(messages.validation.EMAIL)
    .required(messages.validation.REQUIRED),
  password: yup.string().required(messages.validation.REQUIRED),
  remember: yup.boolean(),
});
