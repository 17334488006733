import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  DialogContent,
  FormControlLabel,
  Link,
} from '@mui/material';
import Dialog from 'components/dialogs/Dialog';
import classes from './styles';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox } from 'components/forms';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValidationSchema } from './settings';
import { getUser, updateUser } from 'store/user/UserSlice';
import { handleError } from 'services/handleError';

const TermsAcceptedDialog = ({ open, onClose, ...restProps }) => {
  const dispatch = useDispatch();
  const {
    trigger,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      customTermsAccepted: false,
    },
    resolver: yupResolver(FormValidationSchema),
  });

  const handleSubmit = async (e) => {
    const isValid = await trigger(['customTermsAccepted']);

    if (!isValid) {
      e.preventDefault();
    } else {
      const data = getValues();
      const body = {
        customTermsAccepted: data.customTermsAccepted,
      };
      dispatch(updateUser({ body })).then((res) => {
        if (res.payload.status === 'OK') {
          dispatch(getUser()).then(() => onClose());
        } else {
          handleError(res.payload);
        }
      });
    }
  };

  return (
    <Dialog
      open={open}
      name="customTermsAccepted"
      title="Custom Terms accepting"
      maxWidth="sm"
      onClose={onClose}
      sx={classes.root}
      {...restProps}
    >
      <DialogContent>
        <form>
          <Box sx={classes.checkbox}>
            <Controller
              name="customTermsAccepted"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      inputProps={{ 'aria-label': 'customTermsAccepted' }}
                      onChange={(e) => field.onChange(e.target.checked)}
                      {...field}
                    />
                  }
                  label={
                    <div>
                      <span>
                        Check here to indicate that you have read and agree to
                        the PIPs
                      </span>{' '}
                      <Link href="/privacy" target="_blank">
                        Privacy
                      </Link>{' '}
                      <span> & </span>{' '}
                      <Link href="/terms" target="_blank">
                        Terms of use
                      </Link>
                    </div>
                  }
                />
              )}
            />
            {errors.customTermsAccepted ? (
              <Box sx={classes.checkboxError}>
                {errors.customTermsAccepted.message}
              </Box>
            ) : null}
          </Box>
          <Box sx={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => handleSubmit(e)}
            >
              Save
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TermsAcceptedDialog;
