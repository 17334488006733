import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import palette from 'styles/theme/palette';

const BulkLinkType = ({ card }) => {
  const { coupon } = card;
  return (
    <Box>
      <Typography mb={2}>
        {coupon.links.length}x{' '}
        <span style={{ color: palette.text.primary }}>{card.description}</span>
      </Typography>
      <Typography mb={2}>{card.instructions}</Typography>
      <Box>
        {coupon.links.map((el, i) => (
          <Button
            key={i}
            variant="outlined"
            component="a"
            href={el.url}
            target="_blank"
            style={{ marginBottom: '16px' }}
          >
            {el.text}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default BulkLinkType;
