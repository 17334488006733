import React from 'react';
import { Typography } from '@mui/material';

export const challenges = [
  {
    id: 1,
    title: 'Paying school fees',
    description: (
      <Typography variant="body1">
        Nearly half of all college students end up dropping out over unpaid
        debts of as little as $1,000.
      </Typography>
    ),
  },
  {
    id: 2,
    title: 'Meeting basic needs',
    description: (
      <Typography variant="body1">
        A similar percentage experience some form of food or housing insecurity.
      </Typography>
    ),
  },
  {
    id: 3,
    title: 'Climate justice concern',
    description: (
      <Typography variant="body1">
        A majority of Gen Z believes climate change will cause them personal
        harm, with minorities and lower income communities hit hardest.
      </Typography>
    ),
  },
  {
    id: 4,
    title: 'Fear they don’t belong',
    description: (
      <Typography variant="body1">
        Many low-income and first-generation students believe that they are “not
        college material” and that they don’t belong.
      </Typography>
    ),
  },
];
