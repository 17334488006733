const classes = {
  root: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  pipsWrapper: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },
    '& > .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
  giftBtn: {
    marginBottom: '16px',
  },
};

export default classes;
