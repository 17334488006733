import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { createQuestionnaire } from 'services/api';
import { handleError } from 'services/handleError';
import { showSuccessNotification } from 'services/notifications';
import LayoutAdmin from 'components/LayoutAdmin';
import palette from 'styles/theme/palette';
import Preloader from 'components/Preloader';
import BackButton from 'components/BackButton';
import { FormBuilder } from 'components/FormBuilder';
import classes from './styles';

const AdminCreateQuestionnaire = () => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);

  const handleSubmit = (body) => {
    setIsFetching(true);
    createQuestionnaire({ body })
      .then((res) => {
        setIsFetching(false);
        if (res.status === 'OK') {
          showSuccessNotification('Questionnaire was created successfully');
          navigate('/admin/questionnaires');
        } else {
          handleError(res);
        }
      })
      .catch((err) => {
        setIsFetching(false);
        handleError(err.response.data);
      });
  };

  return (
    <LayoutAdmin bgColor={palette.background.default}>
      {isFetching && (
        <Box sx={classes.preloaderWrapper}>
          <Preloader opacity />
        </Box>
      )}
      <Box maxWidth="lg" sx={classes.root}>
        <Box sx={classes.backButtonWrapper}>
          <BackButton />
        </Box>
        <Box sx={classes.wrapper}>
          <FormBuilder onSubmit={(data) => handleSubmit(data)} />
        </Box>
      </Box>
    </LayoutAdmin>
  );
};

export default AdminCreateQuestionnaire;
