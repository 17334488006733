import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useNavigate,
  NavLink,
  useSearchParams,
  useMatch,
  useLocation,
} from 'react-router-dom';
import { Container, Button, Box, Link, useMediaQuery } from '@mui/material';
import { userSelector, logoutUser } from 'store/user/UserSlice';
import { LoginDialog } from 'components/dialogs';
import { LogoIcon, ProfileIcon } from 'assets/icons';
import classes from './styles';
import theme from 'styles/theme';
import HeaderMobile from '../HeaderMobile';

const Header = () => {
  const isRewardsActive = useMatch('/promo/rewards/*');
  const isDashboardActive = useMatch('/dashboard/*');
  const [searchParams, setSearchParams] = useSearchParams();
  const [loginDialogVisibility, setLoginDialogVisibility] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user, isAuthenticated } = useSelector(userSelector);
  const showHeaderMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (searchParams.has('showLogin')) {
      setLoginDialogVisibility(true);
    }
  }, [searchParams]);

  const openLoginDialog = () => {
    setLoginDialogVisibility(true);
  };

  const closeLoginDialog = () => {
    if (searchParams.has('showLogin')) {
      searchParams.delete('showLogin');
      setSearchParams(searchParams);
    }
    setLoginDialogVisibility(false);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/');
  };

  return (
    <Box component="header" sx={classes.root}>
      {showHeaderMobile ? (
        <HeaderMobile
          handleLogout={handleLogout}
          openLoginDialog={openLoginDialog}
          closeLoginDialog={closeLoginDialog}
        />
      ) : (
        <>
          <Box sx={classes.header}>
            <Container maxWidth="lg">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height={1}
              >
                <Box component="nav" sx={classes.nav}>
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/programs">Programs</NavLink>
                  {!isAuthenticated ? (
                    <NavLink to="/users">Users</NavLink>
                  ) : (
                    <>
                      {user.admin && (
                        <NavLink to="/admin/questionnaires">
                          Manage Questionnaires
                        </NavLink>
                      )}
                      <strong className="username">
                        Hello, {user.firstName} {user.lastName}
                      </strong>
                    </>
                  )}
                </Box>
                {!isAuthenticated ? (
                  <Box display="flex" alignItems="center">
                    <Link
                      color="white"
                      variant="subtitle1"
                      component="button"
                      sx={classes.login}
                      onClick={openLoginDialog}
                    >
                      Login
                    </Link>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => navigate('/registration')}
                    >
                      Sign Up
                    </Button>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center">
                    <NavLink
                      to="/profile/edit-profile"
                      style={classes.editProfileLink}
                    >
                      <ProfileIcon />
                    </NavLink>
                    <Link
                      color="white"
                      variant="subtitle1"
                      component="button"
                      onClick={handleLogout}
                    >
                      Logout
                    </Link>
                  </Box>
                )}
              </Box>
            </Container>
          </Box>
          <Box sx={classes.subHeader}>
            <Container maxWidth="lg">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height={1}
              >
                <Link to="/" className="logo" sx={{ mr: 6 }}>
                  <LogoIcon />
                </Link>
                <Box component="nav" sx={classes.subLinks}>
                  {isAuthenticated && (
                    <>
                      <NavLink to="/promo/earn">Earn</NavLink>
                      <NavLink
                        to="/promo/rewards/pips-for-school"
                        {...(isRewardsActive && { className: 'active' })}
                      >
                        Rewards
                      </NavLink>
                      <NavLink
                        to="/dashboard/impact-carbon-footprint"
                        {...(isDashboardActive && { className: 'active' })}
                      >
                        Dashboard
                      </NavLink>
                      <NavLink to="/how-pips-works">How PIPs Works</NavLink>
                    </>
                  )}
                  <Link
                    variant="link"
                    color="secondary"
                    href="https://pipseducationfund.org/donate/"
                    target="_blank"
                    ml="auto"
                  >
                    Support PIPs Education Fund
                  </Link>
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      )}
      {loginDialogVisibility && (
        <LoginDialog
          open={loginDialogVisibility}
          onClose={closeLoginDialog}
          state={state}
        />
      )}
    </Box>
  );
};

export default Header;
