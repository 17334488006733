const classes = {
  root: {},
  donationBox: {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
      marginLeft: '16px',
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& .MuiInputBase-root': {
      width: '110px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
  },
};

export default classes;
