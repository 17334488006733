import React from 'react';
import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material';
import classes from './styles';
import benefits from './settings';
import { useTheme } from '@mui/system';

const PartnerBenefits = () => {
  const theme = useTheme();
  const showLogo = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Box component="section" sx={classes.root}>
      <Typography variant="h2">Partner Benefits</Typography>
      <Box component="ul" sx={classes.list}>
        {benefits.map((benefit) => (
          <Box component="li" sx={classes.listItem} key={benefit.id}>
            <Box sx={classes.listItemInner}>
              <Box sx={classes.icon}>
                <CardMedia component="img" src={benefit.url} />
              </Box>
              <Typography fontWeight="bold">{benefit.text}</Typography>
            </Box>
            {benefit.list && benefit.list.length && (
              <ul>
                {benefit.list.map((item) => (
                  <li key={item.id}>{item.description}</li>
                ))}
              </ul>
            )}
          </Box>
        ))}
      </Box>
      {showLogo && (
        <CardMedia sx={classes.logo} component="img" src="logo512.png" />
      )}
    </Box>
  );
};

export default PartnerBenefits;
