import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormValidationSchema } from './settings';
import classes from './styles';
import {
  Button,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import palette from 'styles/theme/palette';
import Layout from 'components/Layout';
import {
  userSelector,
  clearState,
  handleForgotPassword,
} from 'store/user/UserSlice';
import { handleError } from 'services/handleError';

const ForgotPassword = () => {
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();
  const { isSuccess, isError, error } = useSelector(userSelector);

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }

    if (isSuccess) {
      setShowMessage(true);
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(FormValidationSchema),
    mode: 'onSubmit',
  });

  const watchedFormFields = watch(['email']);

  const onSubmit = handleSubmit((data) => {
    dispatch(handleForgotPassword({ email: data.email }));
  });

  const isFormFilledCorrectly = watchedFormFields.every((val) => Boolean(val));

  return (
    <Layout bgColor={palette.white}>
      <Box component="div" sx={classes.root}>
        <Typography variant="h2" align="center">
          Reset Password
        </Typography>
        <Typography>
          Enter the email address you used when creating your PIPs account.
          Then, hit the send button to receive an email with instructions on how
          to reset your password.
        </Typography>
        <Box
          component="form"
          sx={classes.forgotPasswordForm}
          onSubmit={onSubmit}
        >
          <Grid container>
            <Grid item xs={12} sx={classes.wrapper}>
              <Controller
                name="email"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <FormControlLabel
                    labelPlacement="top"
                    label="Email Address"
                    control={
                      <TextField
                        type="email"
                        name={name}
                        value={value}
                        fullWidth
                        inputProps={{
                          autoComplete: 'off',
                          placeholder: 'Email',
                        }}
                        error={Boolean(errors.email)}
                        helperText={errors.email && errors.email.message}
                        onChange={onChange}
                      />
                    }
                  />
                )}
              />
              <Typography style={{ display: showMessage ? 'block' : 'none' }}>
                Please check your email for instructions on resetting your
                password.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" component="div">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isFormFilledCorrectly}
                >
                  Send
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};

export default ForgotPassword;
