import React from 'react';
import orderBy from 'lodash/orderBy';
import { Box, Typography } from '@mui/material';

import { SquareIcon } from 'assets/icons';
import classes from './styles';

const TabPanel = ({ actions }) => (
  <Box component="ul" sx={classes.root}>
    {orderBy(actions, 'percent', 'desc').map((action) => (
      <Box key={action.name} component="li" display="flex" alignItems="center">
        <SquareIcon color={`#${action.color}`} />
        <Typography sx={classes.name}>{action.name}</Typography>
        <Typography align="right" fontWeight="bold" ml="auto">
          {action.percent}%
        </Typography>
      </Box>
    ))}
  </Box>
);

export default TabPanel;
