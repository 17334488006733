const classes = {
  root: {
    padding: '0 59px 70px',
    background: 'white',
  },
  title: {
    marginBottom: '30px',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '@media (min-width: 1024px)': {
      flexWrap: 'nowrap',
    },
  },
  listItem: {
    padding: '0 16px',
    flex: '0 0 100%',
    marginBottom: '16px',
    '@media (min-width: 768px)': {
      flex: '0 0 50%',
    },
    '@media (min-width: 1024px)': {
      flex: '0 1 auto',
      marginBottom: 0,
    },
  },
};

export default classes;
