import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import palette from 'styles/theme/palette';

const LinkType = ({ card }) => {
  const { coupon } = card;
  return (
    <Box>
      <Typography mb={2}>
        1x{' '}
        <span style={{ color: palette.text.primary }}>{card.description}</span>
      </Typography>
      <Typography mb={2}>{card.instructions}</Typography>
      <Box>
        <Button
          variant="outlined"
          component="a"
          href={coupon.url}
          target="_blank"
          style={{ marginBottom: '16px' }}
        >
          {coupon.text}
        </Button>
      </Box>
    </Box>
  );
};

export default LinkType;
