import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';
import palette from 'styles/theme/palette';
import Dialog from 'components/dialogs/Dialog';
import { copyToClipboard } from 'utils/copyToClipboard';

const TwoFactorType = ({ card }) => {
  const [open, setOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  const { coupon } = card;

  const handleCopyCoupon = (coupon) => {
    const callback = () => {
      setRedirectUrl(coupon.url);
      setOpen(true);
    };
    copyToClipboard(coupon.code, callback);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.href = redirectUrl;
  };

  return (
    <Box>
      <Typography mb={2}>
        1x{' '}
        <span style={{ color: palette.text.primary }}>{card.description}</span>
      </Typography>
      <Typography mb={2}>{card.instructions}</Typography>
      <Box>
        <Button
          variant="outlined"
          style={{ marginBottom: '16px' }}
          onClick={() => handleCopyCoupon(coupon)}
        >
          {coupon.text}
        </Button>
      </Box>
      <Dialog open={open} maxWidth="xs" name="prompt">
        <DialogContent>
          <Typography>
            Your coupon code was copied to the clip board. All you need to do is
            to paste it on the vendor&apos;s website when prompted
          </Typography>
        </DialogContent>
        <DialogActions sx={{ textAlign: 'right' }}>
          <Button variant="text" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TwoFactorType;
