import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Box,
  Button,
  FormControlLabel,
  TextField,
  Link,
  Typography,
} from '@mui/material';
import { PasswordField, Checkbox } from 'components/forms';
import { FormValidationSchema } from './settings';
import {
  loginUser,
  userSelector,
  clearState,
  getUser,
} from 'store/user/UserSlice';
import { handleError } from 'services/handleError';
import { linkThinkTVAccount } from 'utils/linkThinkTVAccount';

const LoginForm = ({ onClose, state }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFetching } = useSelector(userSelector);
  const thToken = localStorage.getItem('th_token');

  const redirectToForgotPassword = () => {
    onClose();
    navigate('/forgot-password');
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
    resolver: yupResolver(FormValidationSchema),
    mode: 'onSubmit',
  });

  useEffect(() => {
    dispatch(clearState());
  }, []);

  const watchedFormFields = watch(['email', 'password']);

  const onSubmit = handleSubmit((data) => {
    dispatch(
      loginUser({ email: data.email, password: data.password, ttl: 100 })
    ).then((res) => {
      if (thToken) {
        linkThinkTVAccount(thToken);
      }

      onClose();
      if (res.payload.status === 'OK') {
        const isVerified = res.payload.verified;
        dispatch(getUser()).then(() => {
          if (isVerified) {
            if (state && state.from) {
              navigate(`${state.from}${state.fromSearch}`);
            } else {
              navigate('/dashboard/impact-carbon-footprint');
            }
          } else {
            navigate('/verify-code');
          }
        });
      } else {
        handleError(res.payload);
        dispatch(clearState());
      }
    });
  });

  const isFormFilledCorrectly = watchedFormFields.every((val) => Boolean(val));

  return (
    <form onSubmit={onSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Email Address"
                control={
                  <TextField
                    type="email"
                    name={name}
                    value={value}
                    fullWidth
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: 'Enter Your Email',
                    }}
                    error={Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                    disabled={isFetching}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Password"
                control={
                  <PasswordField
                    {...field}
                    fullWidth
                    error={Boolean(errors.password)}
                    helperText={errors.password && errors.password.message}
                    inputProps={{
                      autoComplete: 'dis-p',
                      placeholder: 'Password',
                    }}
                    disabled={isFetching}
                  />
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Controller
              name="remember"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      inputProps={{ 'aria-label': 'remember me' }}
                      disabled={isFetching}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  }
                  label="Remember me"
                />
              )}
            />
            <Link
              variant="subtitle2"
              underline="none"
              component="button"
              onClick={redirectToForgotPassword}
            >
              Forgot Password?
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" component="div">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                width: 175,
                height: 50,
              }}
              disabled={!isFormFilledCorrectly || isFetching}
            >
              Login
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
