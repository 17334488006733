import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './styles';
import backButton from 'assets/icons/backButton.svg';
import { Button } from '@mui/material';
import sx from 'mui-sx';

const BackButton = ({ givePipsSuccess, navigateUrl }) => {
  const navigate = useNavigate();
  const navigateTo = navigateUrl ? navigateUrl : -1;
  return (
    <Button
      sx={sx(classes.backButton, {
        condition: givePipsSuccess,
        sx: classes.givePipsSuccess,
      })}
      onClick={() => navigate(navigateTo)}
    >
      <img src={backButton} alt="back" />
    </Button>
  );
};
export default BackButton;
