import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, FormControlLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import classes from './styles';
import { PasswordField } from 'components/forms';
import {
  clearState,
  getUser,
  updateUser,
  userSelector,
} from 'store/user/UserSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValidationSchema } from './settings';
import { handleError } from 'services/handleError';
import { showSuccessNotification } from 'services/notifications';
import { encodePasswordTwoFactor } from 'utils/encodePassword';

const PasswordTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSuccess, isError, error, user } = useSelector(userSelector);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormValidationSchema),
    mode: 'onSubmit',
  });

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const onSubmit = handleSubmit((data) => {
    const body = {
      passwordToken: encodePasswordTwoFactor(
        data.currentPassword,
        data.updatedPassword,
        user.userId
      ),
    };

    dispatch(updateUser({ body })).then((res) => {
      if (res.payload.status === 'OK') {
        dispatch(getUser()).then(() =>
          showSuccessNotification('Updated successfully')
        );
      } else {
        if (res.payload.code === 'INVALID_TOKEN') {
          const resObj = {
            ...res.payload,
            code: 'GENERIC_ERROR',
          };
          handleError(resObj);
        } else {
          handleError(res.payload);
        }
      }
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Box sx={classes.root}>
        <Box sx={classes.third}>
          <Controller
            name="currentPassword"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Current Password"
                control={
                  <PasswordField
                    fullWidth
                    error={Boolean(errors.currentPassword)}
                    helperText={
                      errors.currentPassword && errors.currentPassword.message
                    }
                    inputProps={{
                      autoComplete: 'new-password',
                      placeholder: 'Current Password',
                    }}
                    value={value}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Box>
        <Box sx={classes.third}>
          <Controller
            name="updatedPassword"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Update Password"
                control={
                  <PasswordField
                    fullWidth
                    error={Boolean(errors.updatedPassword)}
                    helperText={
                      errors.updatedPassword && errors.updatedPassword.message
                    }
                    inputProps={{
                      autoComplete: 'new-password',
                      placeholder: 'Update Password',
                    }}
                    onChange={onChange}
                    value={value}
                  />
                }
              />
            )}
          />
        </Box>
        <Box sx={classes.third}>
          <Controller
            name="confirmUpdatedPassword"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Verify Password"
                control={
                  <PasswordField
                    fullWidth
                    error={Boolean(errors.confirmUpdatedPassword)}
                    helperText={
                      errors.confirmUpdatedPassword &&
                      errors.confirmUpdatedPassword.message
                    }
                    inputProps={{
                      autoComplete: 'new-password',
                      placeholder: 'Verify Password',
                    }}
                    value={value}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Box>
      </Box>
      <Box sx={classes.btnGroup}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button variant="contained" color="secondary" type="submit">
          Save Changes
        </Button>
      </Box>
    </form>
  );
};

export default PasswordTab;
