import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import classes from './styles';
import { Checkbox } from 'components/forms';

const PrivacySettingsStep = ({ control, errors }) => {
  return (
    <Box sx={classes.privacySettingsStep}>
      <Typography>Who can see my actions:</Typography>
      <Box sx={classes.radioGroupWrapper}>
        <Box>
          <Controller
            control={control}
            name="earn"
            render={({ field }) => (
              <RadioGroup {...field} row sx={classes.radioGroup}>
                <Typography
                  variant="body2"
                  fontWeight={'bold'}
                  sx={classes.radioGroupLabel}
                >
                  Earn:{' '}
                </Typography>
                <Box sx={classes.radioGroupOptions}>
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Public"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Just Me"
                  />
                </Box>
              </RadioGroup>
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="use"
            render={({ field }) => (
              <RadioGroup {...field} row sx={classes.radioGroup}>
                <Typography
                  variant="body2"
                  fontWeight={'bold'}
                  sx={classes.radioGroupLabel}
                >
                  Use:{' '}
                </Typography>
                <Box sx={classes.radioGroupOptions}>
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Public"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Just Me"
                  />
                </Box>
              </RadioGroup>
            )}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="donate"
            render={({ field }) => (
              <RadioGroup {...field} row sx={classes.radioGroup}>
                <Typography
                  variant="body2"
                  fontWeight={'bold'}
                  sx={classes.radioGroupLabel}
                >
                  Donate:{' '}
                </Typography>
                <Box sx={classes.radioGroupOptions}>
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Public"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Just Me"
                  />
                </Box>
              </RadioGroup>
            )}
          />
        </Box>
        <Box sx={classes.checkbox}>
          <Controller
            name="customTermsAccepted"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={field.name}
                    checked={field.value}
                    inputProps={{ 'aria-label': 'customTermsAccepted' }}
                    onChange={(e) => field.onChange(e.target.checked)}
                    {...field}
                  />
                }
                label={
                  <div>
                    <span>I have read and agree to the PIPs</span>{' '}
                    <Link href="/privacy" target="_blank">
                      Privacy
                    </Link>{' '}
                    <span> & </span>{' '}
                    <Link href="/terms" target="_blank">
                      Terms of use
                    </Link>
                  </div>
                }
              />
            )}
          />
          {errors.customTermsAccepted ? (
            <Box sx={classes.checkboxError}>
              {errors.customTermsAccepted.message}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacySettingsStep;
