import React, { useEffect, useState } from 'react';
import { Tabs as MuiTabs, Tab } from '@mui/material';
import { Link, Routes, Route, useLocation } from 'react-router-dom';

const LinkTab = (props) => {
  return <Tab component={Link} to={props.pathname} {...props} />;
};

const NavTabs = ({ tabs, ariaLabel, sx, sxTab }) => {
  const [value, setValue] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const newTab = tabs.findIndex((tab) =>
      location.pathname.includes(tab.pathname)
    );
    setValue(newTab);
  }, [location]);

  return (
    <>
      <MuiTabs textColor="inherit" value={value} aria-label={ariaLabel} sx={sx}>
        {tabs.map(({ label, pathname }) => {
          return (
            <LinkTab
              key={pathname}
              id={`nav-tab-${pathname}`}
              aria-controls={`nav-tabpanel-${pathname}`}
              label={label}
              sx={sxTab}
              pathname={pathname}
            />
          );
        })}
      </MuiTabs>
      <Routes>
        {tabs.map(({ panel, pathname }) => (
          <Route exact path={pathname} element={panel} key={pathname} />
        ))}
      </Routes>
    </>
  );
};

export default NavTabs;
