import React from 'react';
import { Box, Grid, Typography, Link } from '@mui/material';

const TypographyUI = () => (
  <Grid container>
    <Grid item xs={4}>
      <Typography variant="h1">h1: Imagine…</Typography>
      <Typography variant="h2" color="primary">
        h2: Attending a tutoring session
      </Typography>
      <Typography variant="h3" color="primary">
        h3: PIPs Rewards LLC Staff, Advisors, PEF Board
      </Typography>
      <Box sx={{ fontStyle: 'italic' }}>
        <Typography variant="h4">h4: What are PIPs?</Typography>
      </Box>
      <Typography variant="h5">h5: Earn Activity</Typography>
      <Typography variant="h6">
        h6: 12-months access to PIPs Rewards™
      </Typography>
    </Grid>
    <Grid item xs={4}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Typography variant="subtitle2">Don’t have account?</Typography>
        <Link variant="subtitle2" underline="none" component="button">
          Sign Up
        </Link>
        <Link variant="link" color="secondary" href="#">
          Support PIPs Education Fund
        </Link>
        <Typography variant="caption">
          Earn 1300 PIPs for attending a tutoring session!
        </Typography>
        <Box sx={{ fontStyle: 'italic' }}>
          <Typography color="secondary">September 26, 2022</Typography>
        </Box>
        <Typography>
          body1: Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </Typography>
        <Typography fontWeight="bold">
          body2: Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

export default TypographyUI;
