import React, { useEffect, useState } from 'react';
import { Box, CardMedia, Typography, Link, useMediaQuery } from '@mui/material';
import sx from 'mui-sx';

import { getHomePromos } from 'services/api';
import classes, { PROMOS_HEIGHT } from './styles';
import { useTheme } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Promos = () => {
  const [promos, setPromos] = useState([]);
  const [promoIndex, setPromoIndex] = useState(0);
  const theme = useTheme();
  const showSlider = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    getHomePromos().then((response) => setPromos(response.promos));
  }, []);

  return (
    <Box component="section" sx={classes.root}>
      {showSlider ? (
        <Slider
          infinite
          autoplay
          autoplaySpeed={3000}
          slidesToShow={1}
          slidesToScroll={1}
          pauseOnHover
          dots
          className="promo-slider"
        >
          {promos.map((promo, index) => (
            <Box key={index} sx={classes.sliderItem}>
              <CardMedia
                sx={classes.media}
                component="img"
                src={promo.imageUrl}
              />
              <Box sx={classes.sliderCaption}>
                <Typography sx={classes.headline}>{promo.headline}</Typography>
                <Link
                  className="link"
                  variant="subtitle2"
                  color="white"
                  href={promo.callToActionUrl}
                  target="_blank"
                >
                  {promo.callToActionText}
                </Link>
              </Box>
            </Box>
          ))}
        </Slider>
      ) : (
        <Box sx={classes.promosWrapper}>
          <Box
            sx={classes.mediaWrapper}
            style={{
              transform: `translateY(-${PROMOS_HEIGHT * promoIndex}px)`,
            }}
          >
            {promos.map((promo, index) => (
              <CardMedia
                key={index}
                sx={classes.media}
                component="img"
                src={promo.imageUrl}
              />
            ))}
          </Box>
          <Box component="ul" sx={classes.promoButtons}>
            {promos.map((promo, index) => (
              <li key={index}>
                <Box
                  role="button"
                  sx={sx(classes.button, {
                    condition: index === promoIndex,
                    sx: classes.buttonActive,
                  })}
                  onClick={() => setPromoIndex(index)}
                >
                  <Typography variant="h5" component="span" sx={classes.title}>
                    0{index + 1}
                  </Typography>
                  <Typography sx={classes.headline}>
                    {promo.headline}
                  </Typography>
                  <Link
                    className="link"
                    variant="subtitle2"
                    color="white"
                    href={promo.callToActionUrl}
                    target="_blank"
                  >
                    {promo.callToActionText}
                  </Link>
                </Box>
              </li>
            ))}
          </Box>
        </Box>
      )}
      <Box sx={classes.promoText}>
        <Typography variant="h2" align="center">
          Want to drive positive change for a better world?
        </Typography>
        <Typography align="center" sx={classes.description}>
          There’s an app for that, and it’s powered by PIPs, a ‘currency of
          good’ called Positive Impact Points that can be earned and redeemed
          through game-like engagement in responsible behaviors - like riding
          the bus, biking, volunteering, etc. While small in the scheme of
          things, these actions can unlock transformational benefits to
          personal, community and planetary health.
        </Typography>
      </Box>
    </Box>
  );
};

export default Promos;
