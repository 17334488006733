import React from 'react';
import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material';
import classes from './styles';
import tools from './settings';
import { useTheme } from '@mui/system';

const ActionsVerified = () => {
  const theme = useTheme();
  const showImage = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box id="how-are-actions-verified" component="section" sx={classes.root}>
      <Box sx={classes.text}>
        <Typography variant="h2">How Are Actions Verified?</Typography>
        <Box component="div" sx={classes.divider} />
        <Typography variant="body1" mb={5} maxWidth="80%">
          PIPs uses a mix of action-tracking tools to capture and reward user
          behavior:
        </Typography>
        <Box component="ul" sx={classes.list}>
          {tools.map((tool) => (
            <Box component="li" sx={classes.listItem} key={tool.id}>
              <Box sx={classes.icon}>
                <CardMedia
                  component="img"
                  src={`assets/icons/tools/tool${tool.id}.svg`}
                />
              </Box>
              <Typography fontWeight="bold">{tool.text}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {showImage && (
        <Box sx={classes.image}>
          <CardMedia component="img" src="assets/images/programs/actions.png" />
        </Box>
      )}
    </Box>
  );
};

export default ActionsVerified;
