import React from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/user/UserSlice';
import { Navigate } from 'react-router-dom';
import useToken from 'hooks/useToken';
import { NotFound } from 'pages';

const AdminRoute = ({ children }) => {
  const { token } = useToken();
  const { user } = useSelector(userSelector);

  const renderRoute = () => {
    if (token && user.admin) {
      return children;
    } else if (token && !user.admin) {
      return <NotFound />;
    } else {
      return <Navigate to="/?showLogin" />;
    }
  };

  return renderRoute();
};

export default AdminRoute;
