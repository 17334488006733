import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {
  editProfile,
  forgotPassword,
  getProfile,
  login,
  resetPassword,
  signup,
  switchToUserLogin,
  verifyCode,
} from 'services/api';

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password, remember, ttl }, thunkAPI) => {
    try {
      const response = await login({ email, password, remember, ttl });
      if (response.status === 'OK') {
        localStorage.setItem('token', response.authToken);
        localStorage.setItem('verified', response.verified);
        Cookies.set('__uc__id', response.ucaptureToken);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const switchUser = createAsyncThunk(
  'auth/switchToUser',
  async (token, thunkAPI) => {
    try {
      const response = await switchToUserLogin(token);
      if (response.status === 'OK') {
        localStorage.setItem('token', response.authToken);
        localStorage.setItem('verified', response.verified);
        Cookies.set('__uc__id', response.ucaptureToken);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      thunkAPI.rejectWithValue(e);
    }
  }
);

export const signupUser = createAsyncThunk(
  'auth/signup',
  async ({ body }, thunkAPI) => {
    try {
      const response = await signup({ body });
      if (response.status === 'OK') {
        localStorage.setItem('token', response.authToken);
        localStorage.setItem('verified', false);
        Cookies.set('__uc__id', response.ucaptureToken);
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const handleForgotPassword = createAsyncThunk(
  'auth/handleForgotPassword',
  async ({ email }, thunkAPI) => {
    try {
      const response = await forgotPassword({ email });
      if (response.status === 'OK') {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const handleResetPassword = createAsyncThunk(
  'auth/handleResetPassword',
  async ({ body }, thunkAPI) => {
    try {
      const response = await resetPassword({ body });
      if (response.status === 'OK') {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const handleVerifyCode = createAsyncThunk(
  'auth/handleVerifyCode',
  async ({ code }, thunkAPI) => {
    try {
      const response = await verifyCode({ code });
      if (response.status === 'OK') {
        localStorage.removeItem('verified');
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getUser = createAsyncThunk('auth/get', async (thunkAPI) => {
  try {
    const response = await getProfile();
    if (response.status === 'OK') {
      return response;
    } else {
      return thunkAPI.rejectWithValue(response);
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateUser = createAsyncThunk(
  'auth/update',
  async ({ body }, thunkAPI) => {
    try {
      const response = await editProfile({ body });
      if (response.status === 'OK') {
        return response;
      } else {
        return thunkAPI.rejectWithValue(response);
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const logoutUser = createAsyncThunk('auth/logout', () => {
  localStorage.removeItem('token');
  localStorage.removeItem('verified');
  localStorage.removeItem('persist:root');
  Cookies.remove('__uc__id');
});

export const UserSlice = createSlice({
  name: 'user',
  initialState: {
    user: {
      customTermsAccepted: true,
    },
    isAuthenticated: !!localStorage.getItem('token'),
    isFetching: false,
    isSuccess: false,
    isError: false,
    isLoginModalError: false,
    error: {},
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isLoginModalError = false;

      return state;
    },
  },
  extraReducers: {
    [signupUser.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isAuthenticated = true;
    },
    [signupUser.pending]: (state) => {
      state.isFetching = true;
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [loginUser.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isAuthenticated = true;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isLoginModalError = true;
      state.error = payload;
    },
    [switchUser.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isAuthenticated = true;
    },
    [switchUser.pending]: (state) => {
      state.isFetching = true;
    },
    [switchUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = payload;
    },
    [handleForgotPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [handleForgotPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [handleForgotPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [handleResetPassword.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [handleResetPassword.pending]: (state) => {
      state.isFetching = true;
    },
    [handleResetPassword.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [handleVerifyCode.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [handleVerifyCode.pending]: (state) => {
      state.isFetching = true;
    },
    [handleVerifyCode.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.user = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        phone: payload.phone !== null ? payload.phone : '',
        dayOfBirth: payload.dateOfBirth,
        earn: payload.earnPrivate === true ? 'private' : 'public',
        use: payload.usePrivate === true ? 'private' : 'public',
        donate: payload.donatePrivate === true ? 'private' : 'public',
        userId: payload.userId,
        customTermsAccepted: payload.customTermsAccepted,
        admin: payload.admin,
      };
    },
    [getUser.pending]: (state) => {
      state.isFetching = true;
    },
    [getUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [updateUser.fulfilled]: (state) => {
      state.isFetching = false;
      state.isSuccess = true;
    },
    [updateUser.pending]: (state) => {
      state.isFetching = true;
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [logoutUser.fulfilled]: (state) => {
      state.isAuthenticated = false;
      state.user = {
        customTermsAccepted: true,
      };
    },
  },
});
export const userSelector = (state) => state.user;

export const { clearState } = UserSlice.actions;
export default UserSlice.reducer;
