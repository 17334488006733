import React from 'react';
import { Box, useMediaQuery } from '@mui/material';

import { AnimatedText } from 'pages/Users/components';
import classes from './styles';

const FallingPips = ({ id, shouldShowContent, isMacOS }) => {
  const isScreenWidth1600OrBigger = useMediaQuery('(min-width: 1600px)');

  return (
    <Box
      id={id}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        ...classes.root,
        background: isMacOS ? 'rgb(198,245,196)' : 'rgb(190,244,187)',
      }}
    >
      {shouldShowContent && (
        <>
          <Box component="video" autoPlay muted loop>
            <source src="/assets/animations/fallingPips.mp4" type="video/mp4" />
          </Box>
          <AnimatedText
            fontSize={isScreenWidth1600OrBigger ? '5vh' : '4vh'}
            text="use them as social currency to do even more good stuff"
          />
        </>
      )}
    </Box>
  );
};

export default FallingPips;
