const classes = {
  root: {
    background: '#fff',
    paddingTop: '45px',
    paddingBottom: '45px',
    '@media (min-width: 1024px)': {
      paddingTop: '100px',
      paddingBottom: '100px',
    },
  },
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
    flexDirection: 'column',
    '@media (min-width: 600px)': {
      paddingTop: 12,
    },
    '@media (min-width: 1024px)': {
      flexDirection: 'row',
      paddingBottom: 7,
    },
  },
  card: {
    position: 'relative',
    paddingX: 2,
    paddingY: 2,
    borderRadius: '10px',
    color: '#fff',
    flex: '0 0 49%',
    textAlign: 'center',
    marginBottom: 2,
    '@media (min-width: 600px)': {
      textAlign: 'right',
      paddingY: 8,
      marginBottom: 10,
    },
    '@media (min-width: 1024px)': {
      marginBottom: 0,
    },
  },
  image: {
    width: '150px',
    margin: '0 auto 16px',
    '@media (min-width: 600px)': {
      position: 'absolute',
      top: '-21%',
      left: '-12px',
      margin: 0,
    },
  },
  text: {
    fontWeight: 400,
    '@media (min-width: 600px)': {
      maxWidth: '70%',
      marginLeft: 'auto',
    },
  },
  contactBtn: {
    textAlign: 'center',
    paddingTop: 2,
    '@media (min-width: 600px)': {
      paddingTop: '60px',
    },
  },
};

export default classes;
