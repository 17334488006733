const classes = {
  arrowStyles: {
    position: 'absolute',
    zIndex: 1,
    bottom: '-50px',
    width: '45px',
    height: '45px',
    cursor: 'pointer',
    bgcolor: 'text.disabled',
    color: 'white',
    '@media (min-width: 768px)': {
      bottom: '-33px',
    },
    '&.MuiButtonBase-root.MuiIconButton-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&.prev': {
      left: 0,
      '@media (min-width: 768px)': {
        left: '15px',
      },
    },
    '&.next': {
      right: 0,
      '@media (min-width: 768px)': {
        right: '15px',
      },
    },
  },
};

export default classes;
