import { grey } from 'styles/theme/palette';

const classes = {
  root: {
    minWidth: '175px',
    marginBottom: 0,
    '& .MuiInputLabel-root': {
      color: grey,
      fontWeight: 700,
      transform: 'translate(30px, 13px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)',
      },
    },
    '& .MuiInputBase-root': {
      paddingLeft: '30px',
      paddingRight: '30px',
    },
    '& .MuiSvgIcon-root': {
      color: '#FDAE2C',
      right: '20px',
    },
    '& .MuiSelect-select': {
      paddingRight: '20px!important',
      paddingLeft: 0,
      lineHeight: 1,
      minHeight: '0!important',
    },
  },
  filter: {
    '& .MuiSelect-select': {
      paddingRight: '20px!important',
      paddingLeft: 0,
      lineHeight: 1,
      minHeight: '0!important',
    },
  },
};

export default classes;
