import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import classes from './styles';

const MyGiftCard = ({ card }) => {
  const navigate = useNavigate();

  const redirectToDetails = (id) => {
    navigate(`/card/${id}`);
  };
  return (
    <Box component="li" sx={classes.root}>
      <Box sx={classes.image}>
        {card.logoUrl ? (
          <CardMedia component="img" src={card.logoUrl} />
        ) : (
          <CardMedia
            sx={classes.imagePlaceholder}
            component="img"
            src="/assets/images/ic_blue_chip.png"
          />
        )}
      </Box>
      <Box sx={classes.wrapper}>
        <Box sx={classes.content}>
          <Typography variant="h4" color="primary" mb={1}>
            {card.title}
          </Typography>
          <Typography sx={classes.description}>{card.description}</Typography>
        </Box>
        <Box sx={classes.btnGroup}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => redirectToDetails(card.transactionId)}
          >
            Certificate
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MyGiftCard;
