const classes = {
  root: {
    margin: '0 auto',
    maxWidth: '100%',
    paddingTop: '30px',
    '@media (min-width: 768px)': {
      maxWidth: '75%',
      paddingTop: 0,
    },
    '@media (min-width: 1024px)': {
      maxWidth: '50%',
    },
    '@media (min-width: 1270px)': {
      maxWidth: '33%',
    },
  },
  radioGroupWrapper: {
    padding: '24px 0',
  },
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  radioGroupLabel: {
    textAlign: 'right',
    flex: '0 0 25%',
  },
  radioGroupOptions: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 66.666%',

    fontWeight: 400,
    fontSize: '18px',
    '@media (min-width: 768px)': {
      paddingLeft: '35px',
    },
    '& .MuiFormControlLabel-root': {
      width: '100%',
      marginRight: 0,
      '& .MuiFormControlLabel-label': {
        fontSize: '18px',
        fontWeight: 400,
        marginLeft: 0,
      },
    },
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media (min-width: 768px)': {
      flexDirection: 'row',
    },
    '& button + button': {
      marginTop: '24px',
      '@media (min-width: 768px)': {
        marginLeft: '24px',
        marginTop: 0,
      },
    },
  },
};

export default classes;
