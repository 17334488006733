import { HEADER_DESKTOP_HEIGHT } from 'config/ui';

const classes = {
  root: {
    '& video::-webkit-media-controls': {
      display: 'none !important',
    },
    '& section > div': {
      position: 'relative',
      height: 'calc(100vh - 80px)',
    },
  },
  dots: {
    zIndex: 1,
    position: 'fixed',
    right: 20,
    top: '50%',
    transform: 'translateY(calc(-50% + 60px))',
    '& li': {
      cursor: 'pointer',
      background: '#EBEBEB',
      width: '20px',
      height: '20px',
      marginBottom: '10px',
      borderRadius: '50%',
      transition: '0.1s',
    },
  },
  activeDot: {
    background: '#A2A2A2 !important',
  },
  scrollBtn: {
    position: 'fixed',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: 'transparent',
    },
    '& span': {
      display: 'none',
    },
    '& svg': {
      animationName: 'animatingScrollBtn',
      animationDuration: '2s',
      animationIterationCount: 'infinite',
      '@keyframes animatingScrollBtn': {
        '0%': {
          opacity: 0,
          transform: 'translateY(-15px)',
        },
        '100%': {
          opacity: 0.5,
          transform: 'translateY(15px)',
        },
      },
    },
  },
  videoPlayerWrapper: {
    paddingTop: `${HEADER_DESKTOP_HEIGHT / 2}px`,
    paddingBottom: `${HEADER_DESKTOP_HEIGHT}px`,
  },
  videoPlayer: {
    position: 'relative',
    paddingTop: `calc(56.25% + ${HEADER_DESKTOP_HEIGHT}px)`,
  },
};

export default classes;
