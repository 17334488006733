import { PADDING_MAIN } from 'config/ui';

const classes = {
  root: {
    paddingTop: `${PADDING_MAIN}px`,
    paddingBottom: `${PADDING_MAIN}px`,
  },
  image: {
    maxWidth: '33%',
    margin: '0 auto',
  },
};

export default classes;
