import React from 'react';
import { Box } from '@mui/material';

import classes from './styles';

const BikeMilkTree = ({ id, shouldShowContent, isMacOS }) => (
  <Box
    id={id}
    display="flex"
    alignItems="center"
    justifyContent="center"
    sx={{
      ...classes.root,
      background: isMacOS ? 'rgb(191,225,251)' : 'rgb(183,220,253)',
    }}
  >
    {shouldShowContent && (
      <Box component="video" autoPlay muted>
        <source src="/assets/animations/bikeMilkTree.mp4" type="video/mp4" />
      </Box>
    )}
  </Box>
);

export default BikeMilkTree;
