import { PADDING_MAIN } from 'config/ui';

const classes = {
  root: {
    paddingTop: `${PADDING_MAIN}px`,
    paddingBottom: `${PADDING_MAIN}px`,
  },
};

export default classes;
