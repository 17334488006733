import React from 'react';
import { Typography, CardMedia, Box, Button, Link } from '@mui/material';
import { recentNews } from 'pages/News/settings';
import classes from './styles';

const RecentNews = () => {
  return (
    <Box component="section" sx={classes.root}>
      <Typography variant="h2" align="center" sx={classes.title}>
        Recent News
      </Typography>
      <Box component="ul">
        {recentNews.map((item, index) => {
          return (
            <li key={index}>
              <Link
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                sx={classes.newsLink}
              >
                <CardMedia sx={classes.image} image={item.image} />
                <div>
                  <Typography variant="h4" color="primary" mb={1}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1" mb={2}>
                    {item.author}
                  </Typography>
                  <Box component="p" sx={classes.description}>
                    {item.shortDescription}
                  </Box>
                </div>
              </Link>
            </li>
          );
        })}
      </Box>
      <Box display="flex" justifyContent="center" pt={2}>
        <Button
          variant="contained"
          color="secondary"
          component="a"
          href="/news"
        >
          See More
        </Button>
      </Box>
    </Box>
  );
};

export default RecentNews;
