import palette from 'styles/theme/palette';

const classes = {
  root: {
    paddingTop: '10px',
  },
  pie: {
    width: '177px',
    height: '177px',
    borderRadius: '50%',
    position: 'relative',
    border: '9px solid #EBEBEB',
    background: palette.white,
  },
  text: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    color: 'black',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 500,
  },
};

export default classes;
