import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import palette from 'styles/theme/palette';

const QRCodeType = ({ card }) => {
  return (
    <Box>
      <Typography mb={2}>
        1x{' '}
        <span style={{ color: palette.text.primary }}>{card.description}</span>
      </Typography>
      <Typography mb={2}>{card.instructions}</Typography>

      {card.buttonUrl && card.buttonName && (
        <Button
          variant="contained"
          color="secondary"
          component="a"
          href={card.buttonUrl}
          target="_blank"
        >
          {card.buttonName}
        </Button>
      )}
    </Box>
  );
};

export default QRCodeType;
