import React, { useState, useEffect } from 'react';

import { getCarbonFootprint } from 'services/api';

import PieTabs from 'pages/Dashboard/components/PieTabs';

const ImpactCarbonFootprint = () => {
  const [carbonFootprints, setCarbonFootprints] = useState();

  useEffect(() => {
    getCarbonFootprint().then((res) => {
      if (res.status === 'OK') {
        setCarbonFootprints(
          res.footprints.reverse().map((impact) => ({
            ...impact,
            label: impact.type.toLowerCase(),
            title: (
              <>
                Total CO<sub>2</sub>
              </>
            ),
            type: 'Saved',
            lb: 'LBS',
          }))
        );
      }
    });
  }, []);

  return (
    <PieTabs ariaLabel="Impact Carbon Footprint" tabs={carbonFootprints} />
  );
};

export default ImpactCarbonFootprint;
