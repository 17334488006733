import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';

const ValidatePromoQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = decodeURIComponent(location.search);
    const searchObj = qs.parse(searchParams);
    // this is a workaround for those cases when user comes to website from mobile app
    // via url that looks like this /promo?uid%3D701 and it was decided that it's easier to implement
    // the redirection on frontend part to our usual url /promo/:id to render PromoDetails page
    if (searchObj['?uid']) {
      navigate(searchObj['?uid']);
    }
  }, []);
};

export default ValidatePromoQueryParams;
