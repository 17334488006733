import palette from 'styles/theme/palette';

const classes = {
  list: {
    width: '100%',
  },
  item: {
    display: 'flex',

    marginBottom: '30px',
    flexDirection: 'column',
    '@media (min-width: 1024px)': {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '4px',
    },
    '@media (min-width: 1280px)': {
      alignItems: 'flex-start',
    },
  },
  image: {
    '@media (min-width: 1024px)': {
      flex: '0 0 185px',
      height: '85px',
    },
    '@media (min-width: 1280px)': {
      flex: '0 0 273px',
      height: '125px',
    },
    '& img': {
      height: '100%',
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderTop: '1px solid #70707029',
    '@media (min-width: 1024px)': {
      marginLeft: '24px',
      flexGrow: 1,
      flexDirection: 'row',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0',
    '@media (min-width: 1024px)': {
      flex: '1 0 60%',
      padding: '10px 15px 10px 0',
    },
    '@media (min-width: 1280px)': {
      padding: '24px 24px 24px 0',
      flex: '1 0 66.66666%',
    },
    '& h4': {
      fontSize: '20px',
      '@media (min-width: 1280px)': {
        fontSize: '26px',
      },
    },
  },
  categoryName: {
    fontSize: '14px',
    position: 'relative',
    paddingLeft: '12px',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '4px',
      height: '4px',
      background: palette.text.grey,
      borderRadius: '50%',
    },
  },
  promoDescription: {
    fontSize: '14px',
    fontWeight: 500,
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    marginRight: '15px',
    fontSize: '16px',
    '@media (min-width: 1280px)': {
      fontSize: '18px',
      marginRight: '25px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
};

export default classes;
