const classes = {
  root: {
    paddingTop: '30px',
    paddingBottom: '100px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '@media (min-width: 768px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  backButtonWrapper: {
    position: 'absolute',
    paddingTop: '30px',
  },
  wrapper: {
    maxWidth: 700,
    margin: '30px auto 0',
    '@media (min-width: 768px)': {
      marginTop: 0,
    },
  },
  preloaderWrapper: {
    position: 'fixed',
    height: '100vh',
    width: '100%',
    zIndex: 10,
    top: 0,
    left: 0,
  },
};

export default classes;
