import React from 'react';
import { Box } from '@mui/material';

import { Promos, About, Testimonials, RecentNews } from './components';
import Layout from 'components/Layout';
import classes from './styles';

const Home = () => (
  <Layout>
    <Box sx={classes.root}>
      <Promos />
      <About />
      <Testimonials />
      <RecentNews />
    </Box>
  </Layout>
);

export default Home;
