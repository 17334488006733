import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import Layout from 'components/Layout';
import classes from './styles';
import palette from 'styles/theme/palette';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Box sx={classes.root}>
        <Typography variant="h1" align="center" mb={1}>
          Privacy Policy
        </Typography>
        <Typography fontStyle="italic" align="center" mb={6}>
          As of August 7, 2020
        </Typography>
        <Typography mb={6}>
          The privacy of your information is important to PIPS Rewards LLC (the
          “Company” or “we”). This Privacy Policy explains what information we
          collect about you, how we collect it, protect it, process it, share it
          and use it in connection with our Services, and your rights with
          respect to your personal information. All defined terms used but not
          otherwise defined in this Privacy Policy have the meanings given to
          them in our{' '}
          <Link href="/terms" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </Link>
          .
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          1. INFORMATION WE COLLECT.
        </Typography>
        <Typography mb={2}>
          We may collect and process certain information that can be used to
          identify you as an individual (“Personal Information”), such as your
          name, user name, email address, password, telephone number, home
          address, demographic information (such as your zip code or your age),
          your date of birth, gender, height, weight, profile photo, biography
          and/or your activity, including when such data is retrieved from
          connected third-party accounts (e.g. Apple Health, Fitbit, etc.).
          However, it is our policy to only collect and process that information
          which pertains to tracking PIPs earn actions.
        </Typography>
        <Typography mb={2}>
          From the time you create a PIPs Account, we will monitor your activity
          with the Services, including the PIPs earned, donated, invested or
          otherwise redeemed by you and your performance and/or engagement in
          any PIPs Challenges or other activities that you participate in. In
          addition, when using the Services, your device may collect data to
          estimate a variety of metrics such as the number of steps you take,
          your distance travelled, active minutes and location. Such data may be
          collected directly by the Company or through third-party fitness
          services such as Google Fit and Apple Health, in which case the terms
          of such third-party’s Privacy Policy will also apply to data collected
          as applicable. This information may be shared with third parties and
          third parties may have access to this information via the Company’s
          back-end services and opt-in account links. In addition, we will
          receive information about you from Partners, including but not limited
          to, information regarding any purchases made by you through use of the
          Services such as the products ordered, the order number, the purchase
          price paid for the order, the time and date of the purchase, and such
          other information about the purchase that the Partner may provide to
          the Company. The Services include features that use precise
          geolocation data, including GPS signals, device sensors, Wi-Fi access
          points and cell tower IDs. We may also derive your approximate
          location from your IP address.
        </Typography>
        <Typography mb={2}>
          In addition, whenever you visit, interact with or participate in the
          Services, we, as well as any Partners, third-party advertisers and/or
          service providers, may use a variety of technologies that
          automatically or passively collect and process information about how
          the Services are accessed and used (“Usage Information“). Usage
          Information may include browser type, device type, operating system,
          application version, the page served, the time, the preceding page
          views, and your use of features of the Services, such as purchase
          history, redemption history and other interactions with the Services.
        </Typography>
        <Typography mb={2}>
          We also automatically collect your IP address or other unique
          identifier (“Device Identifier“) for the computer, mobile phone,
          tablet or other device you use to access the Services. A Device
          Identifier is a number that is assigned to your device when you access
          a website or its servers, and our computers identify your device by
          its Device Identifier. We may use a Device Identifier to, among other
          things, administer the Services, help diagnose problems with our
          servers, analyze trends, track users’ web page movements, help
          identify you, and gather broad demographic information for aggregate
          use.
        </Typography>
        <Typography mb={2}>
          The Company uses certain technology to collect Usage Information. This
          technology includes but is not limited to mobile analytics software,
          app tagging, cookies, and pixel tags. The technology used by the
          Company includes Google Analytics for Firebase and Firebase
          Performance Monitoring. Through use of this technology, the Company
          collects data. Additional information regarding data collected through
          use of this technology may be found here{' '}
          <Link
            href="https://support.google.com/firebase/answer/6318039?hl=e"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/firebase/answer/6318039?hl=e
          </Link>
          .
        </Typography>
        <Typography mb={2}>
          Opt-in authentication links to third party accounts (e.g. via OAuth2.0
          authentication flows) may also be used to associate you with social
          networking sites like Facebook, Twitter and Apple and to enable
          interaction between the Services and such social networking sites. The
          Company and/or its vendors may place cookies (or similar files) on
          your device to facilitate site navigation, for security purposes,
          and/or to personalize your experience with our Services. Pixel tags
          may be used to tell your browser to get content from another server.
          To learn how you may be able to prevent cookies from being installed
          in your browser’s cookie folder, reduce the number of cookies you
          receive from us, or delete cookies that have already been installed in
          your browser’s cookie folder, please refer to your browser’s
          instructions and your{' '}
          <Link
            href="profile/edit-profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            account settings
          </Link>
          . If you do disable or opt out of receiving cookies, please note that
          some or all of the features of our Services may not work properly
          because we may not be able to recognize and associate you with your
          PIPs Account.
        </Typography>
        <Typography mb={6}>
          To the extent information we collect is personal data subject to the
          European Union’s General Data Protection Regulation (“GDPR”), we ask
          for your explicit consent to process the data. You can email{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>{' '}
          to withdraw your consent at any time and request removal of your
          account.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          2. HOW WE USE THE INFORMATION WE COLLECT.
        </Typography>
        <Typography mb={2}>
          We use the information we collect about and from you in various ways.
          These include providing you with the Services and access to features
          of the Services; working with our Partners; posting and sharing our
          Leader Boards and other activity updates; verifying your identity;
          communicating with you about your PIPs Account and use of the
          Services; rewarding you with PIPs and deducting PIPs from your
          Account; tailoring content, advertisements, and offers we serve you;
          responding to your questions and requests; improving the Services;
          complying with license obligations; providing us with analytical data,
          and upon opt-in, helping others find and follow you and/or your
          Company profile content, including through Facebook and other
          integrated services; and, if the Company chooses, notifying others,
          including other users of the Services, via email and through Facebook
          and other integrated services, that you have created a PIPs Account,
          and about activity by you in connection with the Services.
        </Typography>
        <Typography mb={6}>
          For personal data subject to the GDPR, we rely on several legal bases
          to process the data. These include when you have given consent, which
          you may withdraw at any time by emailing{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>{' '}
          to request account removal, and when the processing is necessary to
          perform a contract with you, such as our{' '}
          <Link href="/terms" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </Link>
          .
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          3. SHARING OF INFORMATION.
        </Typography>
        <Typography mb={2}>
          We may share your Personal Information with our Partners and people
          and entities involved in any PIPs Challenge that you participate in,
          including in connection with your earning and redemption of PIPs, and
          with our service partners. For example, if you choose to donate PIPs
          to a Partner, unless you choose to remain anonymous, your Personal
          Information will be shared with the Partner and, in any event, the
          amount of any donation you make will be shared with the Partner.
          Moreover, if you choose to invest PIPs in a Partner, your Personal
          Information will be shared with that Partner. In addition, we may
          share your Personal Information with third parties that perform
          functions on our behalf (or on behalf of our Partners) such as service
          providers that host our Services, analyze data, process transactions
          and payments, fulfill orders or provide customer service; advertisers;
          sponsors or other third parties that participate in or administer our
          promotions, contests, sweepstakes, surveys or provide marketing or
          promotional assistance and ”powered by” partners or partners in
          co-branded sites; or other users of the Services. Your Personal
          Information may also be used by us or shared with any subsidiaries and
          affiliates we may have. In the event that the Company or any of its
          affiliates is involved in a bankruptcy, merger, acquisition,
          reorganization or sale of assets, your information may be sold or
          transferred as part of that transaction.
        </Typography>
        <Typography mb={2}>
          In addition, we may transfer and disclose your Personal Information to
          third parties to comply with a legal obligation, if we believe in good
          faith that the law or a governmental authority requires it, to address
          fraud, security or technical issues, to respond to an emergency, to
          protect our rights or property or the security of third parties,
          and/or to verify or enforce our{' '}
          <Link href="/terms" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </Link>{' '}
          or other applicable policies.
        </Typography>
        <Typography mb={2}>
          While using our Services, you may receive information and/or marketing
          offers from third parties or your Personal Information may be shared
          with other third parties, including social networking sites such as
          Facebook or Twitter, in which case your Personal Information will be
          disclosed to the third party and the Personal Information you disclose
          will be subject to the privacy policy and business practices of that
          third party as well as the terms of this Privacy Policy. These other
          applications and sites may send their own cookies to your device, they
          may independently collect data or solicit Personal Information and may
          or may not have their own published privacy policies. You should also
          independently assess the authenticity of any application or site which
          appears or claims that it is one of our partners (including those
          linked to through an email or social networking page).
        </Typography>
        <Typography mb={6}>
          In addition, your user name and activity may be available to other
          Company users as part of the Services, including but not limited to in
          connection with PIPs Challenges that you participate in. From the time
          you subscribe for our Services, we will monitor your activity with
          respect to the Services. The Company may make information gathered in
          connection with the monitoring available to third parties to undertake
          development of new products and services and the Company may match
          this information with personally identifiable information. Please note
          that we also log and use statistical information in the aggregate and
          we may supply such information to third parties.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          4. INFORMATION FROM THIRD PARTIES.
        </Typography>
        <Typography mb={6}>
          We may receive information about you from third parties. If you are
          using another mobile application or you are on a third-party website
          and you opt-in to receive information from the Company, the
          third-party application or website will submit to us your email
          address and other information about you so that we may contact you as
          requested. You may also access Third-Party Content and/or participate
          in a third-party application or feature through which you allow us to
          collect or the third party to share information about you. Also, if
          you connect to the Services using your Facebook or Apple credentials,
          you authorize us to collect your authentication information. We may
          store this information so that it may be used in connection with the
          Services. In addition, we may receive information about you if other
          users of a third-party website or application give us access to their
          profiles and information about you is accessible through your
          ”connections’” web page, profile page, or similar page on a social
          networking or other third-party website, application or interactive
          service. We may also receive information about you from your employer
          or school or another third party.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          5. YOUR PRIVACY RIGHTS.
        </Typography>
        <Typography mb={2}>
          In accordance with our routine record keeping, we may delete certain
          records that contain Personal Information that has been submitted or
          collected through the Services and we are under no obligation to store
          such Personal Information indefinitely. The Company disclaims any
          liability arising out of, or related to, the destruction of such
          Personal Information. If you wish to cancel your PIPs Account, request
          that we no longer use your information to provide you services, or
          wish us to delete your data, contact us at{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>
          .
        </Typography>
        <Typography mb={6}>
          Pursuant to the Data Protection Acts 1988 and 2003, you have the right
          to be informed of, and to have access to, certain personally
          identifiable information we hold about you by emailing us at{' '}
          <Link href="mailto:privacy@pipsrewards.com">
            privacy@pipsrewards.com
          </Link>
          . We do not control certain privacy settings and preferences
          maintained by our Partners, third-party providers or by our social
          media partners, such as Facebook, Twitter and Apple. If you wish to
          make changes to those settings and preferences, you may do so by
          visiting the appropriate page of the appropriate third-party site(s).
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          6. SECURITY OF YOUR INFORMATION.
        </Typography>
        <Typography mb={6}>
          We take good care of your data by encrypting your passwords and
          ensuring the secure transmission of your data between our app and
          website and our servers.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          7. CHAT ROOMS.
        </Typography>
        <Typography mb={6}>
          The Services may make available chat rooms, forums, message boards,
          instant messaging, news groups and blogs with comment functionality.
          Remember that any information that you disclose in these areas becomes
          public information and is not subject to the provisions of this
          Privacy Policy.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          8. CONSENT TO PROCESSING AND TRANSFER OF INFORMATION.
        </Typography>
        <Typography mb={2}>
          The Services are operated from the United States, although the Company
          may use third-party service providers, including Third-Party
          Providers, to provide the Services that are outside of the United
          States. Your information may be stored on servers that are outside of
          the United States and your personal information may be processed by
          staff operating outside of the United States who are engaged directly
          or indirectly by us or by other service providers and/or our Partners.
          Such processing may be related to, among other things, the preparation
          of content shared through the Services and the provision of customer
          care services and by submitting your personal information, you agree
          to this transfer, storing and/or processing.
        </Typography>
        <Typography mb={6}>
          The Services are governed by and operated in accordance with the laws
          of the United States and are intended for the enjoyment of residents
          of the United States. To the extent that services are provided by
          Third-Party Providers, those services are provided in accordance with
          the laws referenced in their policies that apply to their services. By
          using the Services, or providing us with any information, you (a)
          acknowledge that the Services are subject to the laws of the United
          States and are only intended for use by residents of the United
          States, (b) consent to the collection, processing, maintenance and
          transfer of such information in the United States and other applicable
          territories in which the privacy laws may not be as comprehensive as
          or equivalent to those in the country where you reside and/or are a
          citizen, and (c) waive any claims that may arise under those laws to
          the extent waivable.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          9. YOUR RIGHTS TO ACCESS AND CONTROL YOUR PERSONAL DATA.
        </Typography>
        <Typography mb={2}>
          We give you{' '}
          <Link
            href="profile/edit-profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            account settings
          </Link>{' '}
          and tools to access and control your personal data, as described
          below, regardless of where you live. If you live in the European
          Economic Area, United Kingdom, and Switzerland (the “Designated
          Countries”), you have a number of legal rights with respect to your
          information, which your{' '}
          <Link
            href="profile/edit-profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            account settings
          </Link>{' '}
          and tools allow you to exercise, as outlined below. Accessing and
          Exporting Data. By logging into your account, you can access much of
          your personal information. You may also email{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>{' '}
          to request a download of your information in a commonly used file
          format.
        </Typography>
        <Typography mb={2}>
          Editing and Deleting Data. Your account settings let you change your
          personal information. For instance, you can edit the profile data you
          provide through your{' '}
          <Link
            href="profile/edit-profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            account settings
          </Link>
          . In addition, you may email{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>{' '}
          to request deletion of your account if you wish.
        </Typography>
        <Typography mb={2}>
          If you choose to delete your account, your personal data will
          generally stop being visible to others on our Services within 24
          hours. We generally delete closed account information within 30 days
          of account closure, except as noted below.
        </Typography>
        <Typography mb={2}>
          We retain your personal data even after you have closed your account
          (e.g. in secure backups) as reasonably necessary to comply with our
          legal obligations (including law enforcement requests), meet
          regulatory requirements, resolve disputes, maintain security, prevent
          fraud and abuse, enforce our Terms of Service, or fulfill your request
          to “unsubscribe” from further messages from us. We will retain
          de-personalized information after your account has been closed.
          Information you have shared with others will remain visible after you
          have closed your account or deleted the information from your own
          profile or mailbox, and we do not control data that third parties have
          copied out of our Services.
        </Typography>
        <Typography mb={2}>
          Objecting to Data Use. We give you{' '}
          <Link
            href="profile/edit-profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            account settings
          </Link>{' '}
          and tools to control our data use to the visible to other users of the
          Services; you can limit the notifications you receive from us; and you
          can revoke the access of third-party applications that you previously
          connected to your account. You can also use the application to unpair
          your device from your account at any time. If you live in a Designated
          Country, in certain circumstances, you can object to our processing of
          your information based on our legitimate interests. You have a general
          right to object to the use of your information for direct marketing
          purposes. Please see your{' '}
          <Link
            href="profile/edit-profile"
            target="_blank"
            rel="noopener noreferrer"
          >
            account settings
          </Link>
          , device settings or contact us at{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>{' '}
          to control our marketing communications to you about our products and
          your options for controlling how we and our Partners use cookies and
          similar technologies for advertising.
        </Typography>
        <Typography mb={2}>
          Restricting or Limiting Data Use. In addition to the various controls
          that we offer, if you reside in a Designated Country, you can seek to
          restrict our processing of your data in certain circumstances. Please
          note that you can always email{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>{' '}
          to request deletion of your account at any time.
        </Typography>
        <Typography mb={6}>
          If you need further assistance regarding your rights, please contact
          us at{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>{' '}
          and we will consider your request in accordance with applicable laws.
          If you reside in a Designated Country, you also have a right to lodge
          a complaint with your local data protection authority.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          10. DATA RETENTION.
        </Typography>
        <Typography mb={6}>
          We keep your account information, like your name, email address, and
          password, for as long as your account is in existence because we need
          it to operate your account. In some cases, when you give us
          information for a feature of the Services, we delete the data after it
          is no longer needed for the feature. For instance, when you provide
          your contact list for finding friends on the Services, we delete the
          list after it is used for adding contacts as friends. We keep other
          information, like your activity data, until you use request deletion
          of your account because we use this data to provide you with your
          personal statistics and other aspects of the Services. We also keep
          information about you and your use of the Services for as long as
          necessary for our legitimate business interests, for legal reasons,
          and to prevent harm, including as described in this Privacy Policy.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          11. CHILDREN.
        </Typography>
        <Typography mb={6}>
          We appreciate the importance of taking additional measures to protect
          children’s privacy. Persons under the age of 13, or any higher minimum
          age in the jurisdiction where that person resides, are not permitted
          to create accounts unless their parent has consented in accordance
          with applicable law. Some of the third-parties with which our Services
          are or may be integrated (e.g., Apple and Fitbit) allow parents to set
          up accounts for their children (“Children’s Account”). Children’s
          Accounts are subject to additional terms as set forth by the
          applicable third party. If we learn that we have collected the
          personal information of a child under the relevant minimum age without
          parental consent, we will take steps to delete the information as soon
          as possible. Parents who believe that their child has submitted
          personal information to us and would like to have it deleted may
          contact us at{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>
          .
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          12. NOTICE TO COLORADO RESIDENTS.
        </Typography>
        <Typography mb={6}>
          To the extent required by Colorado law, we will destroy your Personal
          Information when no longer needed and, in the event of a data breach,
          we will notify you within 30 days of any determination that a security
          breach has occurred if any such breach has resulted in, or is
          reasonably likely to result in, the misuse of your Personal
          Information.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          13. CALIFORNIA PRIVACY DISCLOSURES.
        </Typography>
        <Typography mb={2}>
          If you are a California resident, please review the following
          additional privacy disclosures under the California Consumer Privacy
          Act (”CCPA”).
        </Typography>
        <Typography
          mb={1}
          variant="h5"
          fontStyle="italic"
          textTransform="uppercase"
        >
          HOW TO EXERCISE YOUR LEGAL RIGHTS
        </Typography>
        <Typography mb={2}>
          You have the right to understand how we collect, use, and disclose
          your personal information, to access your information, to request that
          we delete certain information, and to not be discriminated against for
          exercising your privacy rights. You may exercise these rights using
          your account settings and tools as described in the Your Rights To
          Access and Control Your Personal Data section, for example:
        </Typography>
        <Typography mb={2}>
          By logging into your account and using your account settings, you may
          exercise your right to access your personal information and to
          understand how we collect, use, and disclose it. Your account settings
          also let you exercise your right to delete personal information. If
          you need further assistance regarding your rights, please contact us
          at{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>{' '}
          and we will consider your request in accordance with applicable laws.
        </Typography>
        <Typography
          mb={1}
          variant="h5"
          fontStyle="italic"
          textTransform="uppercase"
        >
          CATEGORIES OF INFORMATION WE COLLECT, USE, AND DISCLOSE FOR BUSINESS
          PURPOSES
        </Typography>
        <Typography mb={2}>
          As described in the Information We Collect section, we collect the
          categories of personal information listed therein. We receive this
          information from you, your device, your use of the Services, third
          parties, and as otherwise described in this policy. We use and
          disclose these categories of information for the business purposes
          described in the How We Use the Information We Collect and Sharing of
          Information sections, respectively.
        </Typography>
        <Typography mb={6}>
          In addition, we work with partners who provide us with advertising
          services as described in third Policy. To learn more about how these
          partners collect data and your options for controlling the use of your
          information for interest-based advertising, please read this Policy.
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          14. CHANGES.
        </Typography>
        <Typography mb={6}>
          We may update this Privacy Policy to reflect changes to our
          information practices. If we make any material changes we will notify
          you by email (sent to the e-mail address specified in your PIPs
          Account).
        </Typography>
        <Typography
          variant="h4"
          mb={3}
          textTransform="uppercase"
          color={palette.text.primary}
        >
          15. CONTACT US.
        </Typography>
        <Typography mb={6}>
          If you have any questions or concerns about this Privacy Policy, the
          practices of the Services, or your experiences with the Services,
          please contact us at{' '}
          <Link href="mailto:support@pipsrewards.com">
            support@pipsrewards.com
          </Link>
          .
        </Typography>
      </Box>
    </Layout>
  );
};

export default PrivacyPolicy;
