const classes = {
  root: {
    paddingTop: '50px',
    paddingBottom: '110px',
    background: 'white',
  },
  title: {
    marginBottom: '30px',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    // '@media (min-width: 1024px)': {
    //   flexWrap: 'nowrap',
    // },
  },
  listItem: {
    padding: '0 16px',
    flex: '0 0 100%',
    marginBottom: '16px',
    '@media (min-width: 768px)': {
      flex: '0 0 50%',
    },
    '@media (min-width: 1024px)': {
      flex: '0 1 33.333%',
      // marginBottom: 0,
    },
  },
};

export default classes;
