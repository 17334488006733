import React from 'react';
import { Box } from '@mui/material';

import classes from './styles';

export const articles = [
  {
    id: 1,
    title: 'How PIPs Works',
    description: (
      <p>
        Using behavioral science and a well-architected, flexible interface, the
        PIPs Rewards<sup>TM</sup> App generates PIPs each time a user takes a
        verifiable responsible action. As the currency builds up, it can be
        exchanged for highly valued rewards, including grants for school.
      </p>
    ),
  },
  {
    id: 2,
    title: 'Why PIPs Matters',
    description: (
      <p>
        Research shows that PIPs’ unique platform, which can go virtually
        anywhere students go and track a wide range of actions, is also able to
        maintain student engagement, motivate behavior change, and foster in
        students a sense of agency and responsibility to the world around them.
      </p>
    ),
    link: 'https://pipsrewards.medium.com/cu-boulder-students-learn-the-power-of-engagement-through-pips-c0465792bca7',
  },
  {
    id: 3,
    title: 'How PIPs Creates Value',
    description: (
      <>
        <Box mb={2}>Since 2017, student users of the PIPs App have:</Box>
        <Box component="ul" sx={classes.list}>
          <li>Taken over 2,000,000 positive actions</li>
          <li>Unlocked over $147,000 in grants, and</li>
          <li>
            Saved over 835,000 pounds of carbon, the same as planting roughly
            17,000 trees
          </li>
        </Box>
        <p>
          Over 60% of PIPs users say they have engaged with the PIPs App an
          average of 3+ semesters, while over 75% say they have come to
          internalize PIPs actions and grown more aware of the value of their
          actions on the world.
        </p>
      </>
    ),
  },
];
