import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, FormControlLabel, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import format from 'date-fns/format';
import classes from './styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormValidationSchema } from './settings';
import {
  updateUser,
  getUser,
  userSelector,
  clearState,
} from 'store/user/UserSlice';
import { showSuccessNotification } from 'services/notifications';
import { handleError } from 'services/handleError';

const EditProfileTab = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSuccess, isError, error, user } = useSelector(userSelector);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormValidationSchema),
    mode: 'onSubmit',
  });

  useEffect(() => {
    dispatch(clearState());
  }, []);

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    reset({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      dayOfBirth: user.dayOfBirth,
      phone: user.phone,
    });
  }, []);

  useEffect(() => {
    if (isError) {
      handleError(error);
      dispatch(clearState());
    }
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isError, isSuccess]);

  const onSubmit = handleSubmit((data) => {
    const dateOfBirth =
      typeof data.dayOfBirth === 'string'
        ? data.dayOfBirth
        : format(data.dayOfBirth, 'yyyy-MM-dd');

    const body = {
      ...data,
      dayOfBirth: dateOfBirth,
    };

    dispatch(updateUser({ body })).then((res) => {
      if (res.payload.status === 'OK') {
        dispatch(getUser()).then(() =>
          showSuccessNotification('Updated successfully')
        );
      } else {
        if (res.payload.code === 'INVALID_TOKEN') {
          const resObj = {
            ...res.payload,
            code: 'GENERIC_ERROR',
          };
          handleError(resObj);
        } else {
          handleError(res.payload);
        }
      }
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Box sx={classes.root}>
        <Box sx={classes.half}>
          <Controller
            name="firstName"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="First Name"
                control={
                  <TextField
                    name={name}
                    value={value}
                    fullWidth
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: 'Add First Name',
                    }}
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Box>
        <Box sx={classes.half}>
          <Controller
            name="lastName"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Last Name"
                control={
                  <TextField
                    name={name}
                    value={value}
                    fullWidth
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: 'Add Last Name',
                    }}
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Box>
        <Box sx={classes.third}>
          <Controller
            name="email"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Email Address"
                control={
                  <TextField
                    type="email"
                    name={name}
                    value={value}
                    fullWidth
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: 'Add Email Address',
                      readOnly: true,
                    }}
                    error={Boolean(errors.email)}
                    helperText={errors.email && errors.email.message}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Box>
        <Box sx={classes.third}>
          <Controller
            name="dayOfBirth"
            control={control}
            defaultValue={null}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Date of Birth"
                control={
                  <DatePicker
                    disableFuture
                    name={name}
                    value={value}
                    onChange={onChange}
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: 'MM/DD/YYYY',
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          id="dayOfBirth"
                          fullWidth
                          error={Boolean(errors.dayOfBirth)}
                          helperText={
                            errors.dayOfBirth && errors.dayOfBirth.message
                          }
                        />
                      );
                    }}
                  />
                }
              />
            )}
          />
        </Box>
        <Box sx={classes.third}>
          <Controller
            name="phone"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <FormControlLabel
                labelPlacement="top"
                label="Mobile Phone"
                control={
                  <TextField
                    name={name}
                    value={value}
                    fullWidth
                    inputProps={{
                      autoComplete: 'off',
                      placeholder: 'Add Mobile Phone',
                    }}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                    onChange={onChange}
                  />
                }
              />
            )}
          />
        </Box>
      </Box>
      <Box sx={classes.btnGroup}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
        <Button variant="contained" color="secondary" type="submit">
          Save Changes
        </Button>
      </Box>
    </form>
  );
};

export default EditProfileTab;
