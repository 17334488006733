import React, { useEffect, useState } from 'react';
import { Box, Typography, Pagination } from '@mui/material';
import format from 'date-fns/format';
import classes from './styles';
import palette from 'styles/theme/palette';
import Preloader from 'components/Preloader';
import { getActions } from 'services/api';
import { handleError } from 'services/handleError';

const Actions = () => {
  const [actions, setActions] = useState([]);
  const [availablePips, setAvailablePips] = useState(0);
  const [totalEarnedPips, setTotalEarnedPips] = useState(0);
  const [totalUsedPips, setTotalUsedPips] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const PER_PAGE = 10;

  useEffect(() => {
    setIsFetching(true);
    const query = { page: page, size: PER_PAGE };
    getActions({ query })
      .then((res) => {
        if (res.status === 'OK') {
          setActions(res.actions);
          setAvailablePips(res.availablePips);
          setTotalEarnedPips(res.totalEarnedPips);
          setTotalUsedPips(res.totalUsedPips);
          setIsFetching(false);
          setCount(res.pageCount);
        } else {
          handleError(res);
          setIsFetching(false);
        }
      })
      .catch((err) => {
        handleError(err);
        setIsFetching(false);
      });
  }, [page]);

  const handleChange = (e, page) => {
    setPage(page);
  };

  if (isFetching) return <Preloader />;

  return (
    <Box sx={classes.root}>
      <Box sx={classes.tilesWrapper}>
        <Box sx={classes.availablePips}>
          <Typography sx={classes.title}>Available PIPs</Typography>
          <Typography sx={classes.number} fontWeight={700}>
            {availablePips.toLocaleString()}
          </Typography>
        </Box>
        <Box sx={classes.totalPips}>
          <Box sx={classes.totalPipsItem}>
            <Typography sx={classes.title}>Total Earned PIPs</Typography>
            <Typography sx={classes.number}>
              {totalEarnedPips.toLocaleString()}
            </Typography>
          </Box>
          <Box sx={classes.totalPipsItem}>
            <Typography sx={classes.title}>Total Used PIPs</Typography>
            <Typography sx={classes.number}>
              {totalUsedPips.toLocaleString()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={classes.actionsList} component="ul">
        {actions.map((action) => {
          const dateFromString = new Date(action.date);
          return (
            <Box
              sx={classes.actionsItem}
              component="li"
              key={action.transactionId}
            >
              <Box>
                <Typography>{action.title}</Typography>
                <Typography color={palette.secondary.main} fontStyle="italic">
                  {format(dateFromString, 'MMMM dd, yyyy')}
                </Typography>
              </Box>
              <Typography
                fontWeight={700}
                color={action.pips < 0 ? '#e35d2f' : '#3a3331'}
              >
                {action.pips.toLocaleString()}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box display="flex" justifyContent="center">
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
};

export default Actions;
